import moment from "moment-timezone";
import _ from "lodash";

export const getTimeZone = () => {
  var Zonename = moment.tz.guess();
  var time = new Date();
  var timeZoneOffset = time.getTimezoneOffset();
  var timezone = moment.tz.zone(Zonename).abbr(timeZoneOffset);
  return timezone;
};

export const latLongString = (latitude, longitude) => {
  let locationVal = "not shared";

  if (_.isNumber(latitude) && _.isNumber(longitude)) {
    locationVal =
      parseFloat(latitude).toFixed(6) + ", " + parseFloat(longitude).toFixed(6);
  } else {
    //do nothng
  }
  return locationVal;
};

export const markupforCaptureImg = (
  canvasheight,
  canvaswidth,
  latitude,
  longitude,
  nooflines = 1,
  bookMark = null,
  imageLabel = null,
  offline = null
) => {
  const locationHTML = `Location: `;
  const dateStr = moment().format("MMM DD, YYYY");
  const timeStr = moment().format(" hh:mm:ss A");
  const timezone = getTimeZone();
  const time = dateStr + " " + timeStr + " " + timezone;
  const text = latLongString(latitude, longitude) + "";
  const markup =
    "data:image/svg+xml;base64," +
    window.btoa(
      '<svg xmlns="http://www.w3.org/2000/svg" height="' +
        canvasheight +
        '" width="' +
        canvaswidth +
        '">' +
        '<foreignObject width="100%" height="100%">' +
        '<div xmlns="http://www.w3.org/1999/xhtml">' +
        '<div style="position: absolute;' +
        "width: 100%;" +
        "left: 0;" +
        "bottom: -1px;" +
        "font-family: sans-serif;" +
        `font-size: ${
          canvaswidth >= 1080 ? "28px" : canvaswidth >= 720 ? "28px" : "16px"
        };` +
        "background:#fff;" +
        "color: #000;" +
        `padding: ${
          canvaswidth == 640 ? "5px 6px 12px 5px" : "6px 6px 12px 5px"
        }` +
        "opacity: 1;" +
        "margin: 0 0.025em 0.025em 0;" +
        '">' +
        (imageLabel
          ? `${imageLabel}`
              .replace(/&/g, "&amp;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;") + `<br/>`
          : "") +
        (bookMark
          ? ` ${bookMark} `
              .replace(/&/g, "&amp;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;") + `<br/>`
          : "") +
        "<b>" +
        locationHTML +
        "</b>" +
        text
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;") +
        `<br/>` +
        "<div style='position: absolute; right: 12px; top: -1px;'>" + // Adjust right: 5px as needed
        (offline
          ? `offline`
              .replace(/&/g, "&amp;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;") + `<br/>`
          : "") +
        "</div>" +
        "<b>" +
        // (nooflines > 1 ? "<br/>" : "") +
        "Date: " +
        "</b>" +
        time
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;") +
        `<br />` +
        "</div>" +
        "</div>" +
        "</foreignObject>" +
        "</svg>"
    );
  return markup;
};
