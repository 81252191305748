import * as actionTypes from "./actionTypes";
import authenticationService from "./../services/authService";
import { customToast } from "./../helpers/customToast";
import i18n from "i18next";
const fetchTokenStart = () => {
  return {
    type: actionTypes.FETCH_TOKEN_START,
  };
};

const fetchTokenSuccess = (data) => {
  return {
    type: actionTypes.FETCH_TOKEN_SUCCESS,
    payload: data,
  };
};

const fetchTokenError = (error) => {
  return {
    type: actionTypes.FETCH_TOKEN_ERROR,
    payload: error,
  };
};

export const fetchToken = (payload, history) => {
  return (dispatch) => {
    dispatch(fetchTokenStart());
    return authenticationService
      .fetchToken(payload)
      .then((res) => {
        dispatch(fetchTokenSuccess(res.data.data));
        authenticationService.setToken(res.data.data.loginUser.jwtToken);
        return res.data.data;
      })
      .catch((err) => {
        dispatch(fetchTokenError(err));
        history.push("/");
      });
  };
};
