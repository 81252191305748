export const INVITATION_SENT_SUCCESS_MESSAGE =
  "Invititation Was Sent Succcessfully";
export const IOS_SAFARI_HELP = "For best results, we recommend using Safari";
export const UNEXPECTED_ERROR = "Unexpected Errors Occured. Try Again Later.";
export const CALL_LINK_NOT_FOUND = "Call Link is not found.";
export const CREATE_BOOKMARK_MESSAGE =
  "Please Ask Administrator to Add Bookmark";
export const ATTACHMENT_UPLOAD_SIZE_EXCEEDED =
  "File size should be less than 10MB";
export const LIVE_POINTER_STARTED =
  "Started live pointer. You can move the pointer by dragging.";
export const LIVE_POINTER_STOPPED = "Stopped live pointer";
export const APPRAISAL_EXIT_APPROVED = "Exit acknowledged successfully.";
export const APPRAISAL_EXIT_DENIED = "Exit denied.";
export const MARKUP_CLOSED = "Markup is Stopped";
export const PRIVACY_POLICY_FAIL = "No valid Privacy Policy URL found!";
