import React from "react";
import { useTranslation } from "react-i18next";

export default function DistanceModel(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p>
              Onsite Contact is
              {` ${props.distanceBetweenLatLong} ${props.distanceUnit} `}
              away from the job order location. Confirm the location with the
              Onsite Contact.
            </p>
          </div>
          <div className="bottom-btn">
            <button
              className="green-btn"
              onClick={() => props.hideDistanceModel()}
            >
              {t("BUTTONS.OK")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
