import React, { Component } from "react";
import { connect } from "react-redux";
import "./participantViewModel.scss";
import { appConstants } from "../../../constants/";
import _ from "lodash";
import RBAC from "./../../RBAC";
import { invite_participant } from "./../../../constants/Permission";
import Popover from '@mui/material/Popover';
import Tooltip from "@mui/material/Tooltip";
import { withTranslation } from "react-i18next";
class ParticipantViewModel extends Component {
  constructor(props) {
    super();
    this.state = {
      anchorEl: null,
      openedPopoverId: null,
    };
  }

  viewNetworkBody = (e, id) => {
    this.setState({
      anchorEl: e.currentTarget,
      openedPopoverId: id,
    });
  };

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null,
      openedPopoverId: null,
    });
  };

  participantRow = (participant) => {
    const { openedPopoverId, anchorEl } = this.state;
    const { identity, t } = this.props;

    const isAudioEnabled = participant.allowedMicrophone == "1";
    const isVideoEnabled = participant.allowedCamera == "1";

    const deviceModelType =
      participant.deviceType == appConstants.DEVICE_TYPE.DEVICE_TYPE_ANDROID ||
      participant.deviceType == appConstants.DEVICE_TYPE.DEVICE_TYPE_IOS
        ? _.get(participant, ["deviceModel"], "")
        : _.get(participant, ["browserName"], "");
    const deviceModelVersion =
      participant.deviceType == appConstants.DEVICE_TYPE.DEVICE_TYPE_ANDROID ||
      participant.deviceType == appConstants.DEVICE_TYPE.DEVICE_TYPE_IOS
        ? _.get(participant, "osVersion", "")
        : _.get(participant, "browserVersion", "");
    const networkIcon =
      participant.networkQualityLevel != null
        ? "icon-network" + participant.networkQualityLevel
        : "icon-no-networks";

    const networkType =
      participant.networkType != null && participant.networkType != ""
        ? participant.networkType
        : "-";

    let osName = _.get(participant, "osName", "");
    if (
      (osName.trim().length > 0 &&
        participant.deviceType ==
          appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_PC) ||
      participant.deviceType == appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_IOS ||
      participant.deviceType == appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_ANDROID
    ) {
      osName = "Browser: " + osName + ", ";
    }

    return (
      <div
        className="participants-list"
        key={participant.participantSid}
        ref={(node) => (this.node = node)}
      >
        <div className="head">
          <div className="icn-name">
            {participant.userName}
            {participant.participantSid == identity && " (You)"}
          </div>
          <div className="network-list">
            <ul>
              {participant.batteryLevel != "" &&
                participant.batteryLevel != null && (
                  <>
                    <li> {`${participant.batteryLevel}`} </li>
                    <li>
                      <i className="icon-battery"></i>
                    </li>
                  </>
                )}
              <li>
                <i
                  className={
                    isAudioEnabled == true
                      ? "icon-microphone"
                      : "icon-mute-microphone"
                  }
                ></i>
              </li>

              <li>
                <i
                  className={
                    isVideoEnabled == true ? "icon-video" : "icon-camera-off"
                  }
                ></i>
              </li>
              <li>
                <Tooltip title={t("TOOLTIP.View_More")} arrow>
                  <button>
                    <i
                      className="icon-more-vertical"
                      onClick={(e) =>
                        this.viewNetworkBody(e, participant.participantSid)
                      }
                    ></i>
                  </button>
                </Tooltip>
                <Popover
                  id={participant.participantSid}
                  open={openedPopoverId === participant.participantSid}
                  anchorEl={anchorEl}
                  onClose={this.handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <p>{`${osName} ${deviceModelType} ${deviceModelVersion} `}</p>
                  <p>
                    {t("WEB_LABELS.Network_Type")}: {`${networkType}`}{" "}
                  </p>{" "}
                  <p>
                    {t("WEB_LABELS.Netword_Strength")}:&nbsp;&nbsp;{" "}
                    <i className={networkIcon}></i>
                  </p>
                </Popover>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    let participantViewList = [];
    const rawparticipants = _.values(this.props.callParticipants || {}) || [];

    const localParticipants = _.filter(rawparticipants, (part) => {
      return part.participantSid == this.props.identity;
    });
    const participants = _.filter(rawparticipants, (part) => {
      return part.participantSid != this.props.identity;
    });

    localParticipants.forEach((participant) => {
      participantViewList.push(this.participantRow(participant));
    });

    participants.forEach((participant) => {
      participantViewList.push(this.participantRow(participant));
    });

    return (
      <>
        <div className="comman-modal left-side open">
          <div className="comman-modal-main">
            <div className="side-head">
              {t("BUTTONS.Participants")}{" "}
              <span className="count">{`(${participants.length + 1})`}</span>
              <div className="side-head-buttons">
                <RBAC
                  action={invite_participant}
                  yes={
                    <div className="head-invite-participent">
                      <Tooltip title={t("TOOLTIP.Invite_Participant")} arrow>
                        <button
                          className="modal-button-effect"
                          onClick={this.props.showAddParticipant}
                        >
                          <i className="icon-add-participant"></i>
                        </button>
                      </Tooltip>
                    </div>
                  }
                />
                <button className="close-modal modal-button-effect">
                  <i
                    className="icon-close-image"
                    onClick={this.props.hideListParticipant}
                  ></i>
                </button>
              </div>
            </div>
            <div className="comman-modal-body scroll-bar-style">
              <div className="parti-modal-view">{participantViewList}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = ({ callParticipants }) => ({
  callParticipants: callParticipants.data || {},
});

const connectedParticipantViewModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ParticipantViewModel));
export { connectedParticipantViewModel as ParticipantViewModel };
