import * as _ from "lodash";
import api from "./apis/api";
import { dehydrateNote, dehydrateNotes } from "./transformers";

class NoteService {
  addNote(payload) {
    return api.note
      .addNote(payload)
      .then((res) => dehydrateNote(_.get(res, "data.data", {})));
  }

  getNotes(jobOrderId) {
    return api.note
      .getNotes(jobOrderId)
      .then((res) => dehydrateNotes(_.get(res, "data.data", [])));
  }

  deleteNote(noteId) {
    return api.note.deleteNote(noteId);
  }

  updateNote(noteId, payload) {
    return api.note
      .updateNote(noteId, payload)
      .then((res) => dehydrateNote(_.get(res, "data.data", {})));
  }
}

export default new NoteService();
