import _, { get } from "lodash";
import { appConstants } from "../constants";
import { isAndroid, isIOS } from "react-device-detect";

export function placholderFunction() {}

export function getWindowLocation() {
  return window.location.protocol + "//" + window.location.host;
}

export function getCallURL(inviteUUID) {
  return `/joincall/${inviteUUID}`;
}
export const extractUserName = (identity) => {
  if (identity) {
    const contents = identity.split("|");
    return get(contents, ["2"], null);
  } else return null;
};

export const extractUserRole = (identity) => {
  if (identity) {
    const contents = identity.split("|");
    return get(contents, ["1"], null);
  } else return null;
};

export const isAppraiserOrAssistant = (role) => {
  return role === appConstants.USER_ROLES.APPRAISER ||
    role === appConstants.USER_ROLES.ASSISTANT
    ? true
    : false;
};

export const isAppraiser = (role) => {
  return role && role === appConstants.USER_ROLES.APPRAISER;
};

export const isOwner = (role) => {
  return role === appConstants.USER_ROLES.PROPERTY_OWNER ||
    role == appConstants.userRoles.PROPERTY_OWNER
    ? true
    : false;
};

export const extractUserUUID = (identity) => {
  if (identity) {
    const contents = identity.split("|");
    return get(contents, ["0"], null);
  } else return null;
};

export const getFileFormData = (data, name, extraFields) => {
  const formData = new FormData();
  formData.append("file", data, name);
  if (extraFields) {
    _(extraFields).forOwn((value, key) => {
      if (key && value) formData.append(key, value);
    });
  }
  return formData;
};

export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const getDeviceType = () => {
  return isIOS
    ? appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_IOS
    : isAndroid
    ? appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_ANDROID
    : appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_PC;
};

export function imageUrlToBlob(imageUrl) {
  return new Promise((resolve, reject) => {
    fetch(imageUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a new blob with the desired image type
        const modifiedBlob = new Blob([blob], { type: "image/jpeg" });
        resolve(modifiedBlob);
      })
      .catch((error) => reject(error));
  });
}

// Function: formatBytes
// Description: Converts bytes to human-readable format
// Parameters:
// - bytes: The number of bytes to be converted
// - decimals: Number of decimal places to round the result (default is 2)
// Returns: A string representing the human-readable format of the bytes
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) {
    return "0 Bytes";
  }

  const k = 1024; // 1 kilobyte (KB) is equal to 1024 bytes
  const dm = decimals < 0 ? 0 : decimals; // Number of decimal places to round the result
  const sizes = [
    "Bytes", // The size unit for bytes
    "KiB", // Kibibyte (KB)
    "MiB", // Mebibyte (MB)
    "GiB", // Gibibyte (GB)
    "TiB", // Tebibyte (TB)
    "PiB", // Pebibyte (PB)
    "EiB", // Exbibyte (EB)
    "ZiB", // Zebibyte (ZB)
    "YiB", // Yobibyte (YB)
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k)); // Calculate the appropriate size unit index based on the bytes

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// Function: formatTime
// Description: Converts time in seconds to a formatted time string
// Parameters:
// - time: The time in seconds
// Returns: A string representing the formatted time
export const formatTime = (time) => {
  const minutes = Math.floor(time / 60); // Calculate the number of minutes
  const seconds = time % 60; // Calculate the remaining seconds
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

// Function: blobFromURL
// Description: Retrieves a Blob object from a given URL
// Parameters:
// - url: The URL of the resource
// Returns: A Promise that resolves to the Blob object
export const blobFromURL = async (url) => {
  const response = await fetch(url); // Fetch the resource from the URL
  const blob = await response.blob(); // Convert the response to a Blob object
  return blob;
};

// Function: convertToJPEG
// Description: Converts a Blob object to a JPEG image
// Parameters:
// - blob: The Blob object to be converted
// Returns: A Promise that resolves to the converted JPEG Blob object
export const convertToJPEG = (blob) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas"); // Create a canvas element for image manipulation
    const ctx = canvas.getContext("2d"); // Get the 2D rendering context of the canvas

    const image = new Image();
    image.onload = () => {
      canvas.width = image.width; // Set the canvas width to match the image width
      canvas.height = image.height; // Set the canvas height to match the image height
      ctx.drawImage(image, 0, 0); // Draw the image on the canvas

      canvas.toBlob(
        (jpegBlob) => {
          resolve(jpegBlob); // Resolve the promise with the converted JPEG Blob object
        },
        "image/jpeg", // Specify the desired MIME type for the converted image (JPEG)
        1 // Set the image quality (1 = maximum quality)
      );
    };
    image.onerror = (error) => {
      reject(error); // Reject the promise if the image fails to load
    };
    image.src = URL.createObjectURL(blob); // Create a temporary URL for the input Blob object and set it as the image source
  });
};

export function blobToBinary(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.readyState === FileReader.DONE) {
        const binaryData = reader.result;
        resolve(binaryData);
      }
    };

    reader.onerror = () => {
      reject(new Error("Error reading blob as binary data."));
    };

    reader.readAsArrayBuffer(blob);
  });
}

export async function binaryToBlob(binaryData, mimeType) {
  const blob = new Blob([binaryData], { type: mimeType });
  return blob;
}
