//React & Redux
import { connect } from "react-redux";

//Lodash
import _ from "lodash";
import { permissions } from "./../constants/Permission";

const checkroles = (myprops, action) => {

  const userrole = _.get(myprops, ["role"], "");
  const settings = _.get(myprops, ["configs", "settings"], {});
  const useractions = _.get(permissions, [userrole]);
  const isSettigsEnbled = _.get(settings, [action]);
 
  const granular_action =
    useractions && isSettigsEnbled && action ? useractions[action] : undefined;
  return granular_action || false;
};

const RBAC = (props) =>
  checkroles(props.me, props.action) ? props.yes : props.no;

RBAC.defaultProps = {
  yes: null,
  no: null,
};

export { RBAC as CORERBAC };
const mapStateToProps = (state, props) => ({
  me: state.me,
});
export default connect(mapStateToProps)(RBAC);
