import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import RestartOfflineScan from "./restartOfflineScan";
import UploadOfflineData from "./uploadOfflineData";
import {
  retrieveImages,
  deleteTableFromDB,
  dataExistInTable,
} from "./storeIndexedData";

export default function OfflineSideBarModel(props) {
  const { t } = useTranslation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [restartModelVisible, setRestartModelVisible] = useState(false);
  const [capturedImages, setCapturedImages] = useState(null);
  const [successFullyUploaded, setSuccessFullyUploaded] = useState({
    video: 0,
    image: [],
    isImageUpload: 0,
    isCancleUpload: 0,
    mapImage: [],
  });
  const [isUploadClicked, setIsUploadClicked] = useState(false);
  const [videoPlayBtn, setVideoPlayBtn] = useState(true);
  const [mapViewFetch, setMapViewFetch] = useState(false);
  const [mapViewRejected, setMapViewRejected] = useState(false);

  const videoRef = useRef(null);
  useEffect(() => {
    checkDBandFetchImage();
  }, [props.showSidebar, props.deleteImagePopUp, successFullyUploaded]);
  useEffect(() => {
    if (!mapViewFetch && capturedImages) {
      capturedImages.filter((element) => {
        if (element.mapView === true) {
          setMapViewFetch(true);
        }
      });
    }
  }, [capturedImages]);
  useEffect(() => {
    if (!videoPlayBtn) {
      videoRef.current.play();
    }
  }, [videoPlayBtn]);
  async function checkDBandFetchImage() {
    await dataExistInTable("videos")
      .then((dbExists) => {
        if (dbExists) {
          retrieveImages().then((imageData) => {
            setCapturedImages(imageData);
            props.setCapturedImages(imageData);
          });
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    if (props.showSidebar === true) {
      setShowSidebar(true);
    }
  }, [props.showSidebar]);

  function hideRestartModelModel() {
    setRestartModelVisible(false);
  }
  function onDeleteAllImage() {
    deleteTableFromDB(["videos", "images"]);
    setRestartModelVisible(false);
    props.restartInspection();
    setMapViewFetch(false);
    setMapViewRejected(false);
  }

  function returnImage(data, isSuccessImage = false, isGoogleImage = false) {
    return (
      <div className="gallery-model-items">
        <div className="gallery-model-img">
          <div>
            <img
              src={data.imageUrl}
              id="offlineImage"
              alt={props.alt ? props.alt : "Loading Captured Pic..."}
            />
          </div>
          <div className="gallery-overlay">
            <button
              onClick={() => {
                props.showGalleryZoomModel(data.id);
              }}
            >
              {t("BUTTONS.View")}
            </button>
          </div>
        </div>
        <div className="gallery-img-wrap">
          <div className="img-number">
            <i className="icon-save-images"></i>
            {data.mapView
              ? data.label
              : `Photo: ${capturedImages.indexOf(data) + 1}`}
          </div>
          {!isSuccessImage && !isGoogleImage && (
            <div className="trash-image">
              <Tooltip title={t("TOOLTIP.Delete_Image")} arrow>
                <button
                  onClick={() => {
                    props.deleteImagePopUp(data.id, data.image);
                  }}
                >
                  <i className="icon-fill-delete"></i>
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className={
          "comman-modal preview-model left-side " +
          (showSidebar ? "open" : "close")
        }
      >
        <div className="comman-modal-main">
          <div className="side-head">
            Preview Video Recording and Capture Photos
          </div>

          <div className="comman-modal-body scroll-bar-style">
            <div className="gallery-model-view ">
              <div
                className={
                  successFullyUploaded.video === 0
                    ? "gallery-model-head"
                    : successFullyUploaded.video == 1
                    ? "gallery-model-head-success"
                    : "gallery-model-head-error"
                }
              >
                {successFullyUploaded.video === 0
                  ? "Videos"
                  : successFullyUploaded.video === 1
                  ? "Videos: Successfully Uploaded (1)"
                  : "Videos: Failed To Upload (1)"}
              </div>
              <div className="gallery-model-items video-item">
                <div
                  className={`gallery-model-img gallery-video ${
                    videoPlayBtn ? "active" : ""
                  }`}
                >
                  <video
                    ref={videoRef}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: "5px 5px 0 0",
                    }}
                    src={props.recordedVideoUrl}
                    controls
                    playsInline
                    onPause={() => setVideoPlayBtn(true)}
                    onPlay={() => setVideoPlayBtn(false)}
                  />
                  <div
                    className="gallery-overlay"
                    onClick={() => setVideoPlayBtn((pre) => !pre)}
                  >
                    <i className="icon-video-play"></i>
                  </div>
                </div>
                <div className="gallery-img-wrap">
                  <div className="img-number">
                    {" "}
                    <i className="icon-participants-video"></i>Inspection Video
                    Recording
                  </div>
                </div>
              </div>
              {/* {!isUploadClicked || successFullyUploaded?.isImageUpload === 0 ? ( */}
              {!mapViewRejected && mapViewFetch ? (
                <>
                  {!isUploadClicked ||
                  successFullyUploaded?.isImageUpload === 0 ? (
                    <div className="gallery-model-head">Location Photos</div>
                  ) : (
                    <></>
                  )}
                  {mapViewFetch &&
                  (!isUploadClicked ||
                    successFullyUploaded?.isImageUpload === 0) ? (
                    <div className="gallery-model-list">
                      {capturedImages &&
                        [...capturedImages].map((data) => {
                          if (data.mapView) {
                            return returnImage(data, false, true);
                          }
                        })}
                    </div>
                  ) : (
                    <>
                      {capturedImages &&
                        3 !== successFullyUploaded?.mapImage?.length && (
                          <>
                            <div className="gallery-model-head-error">
                              Location Photos: Failed to Upload
                              {` (${
                                3 - successFullyUploaded?.mapImage?.length
                              })`}
                            </div>
                            <div className="gallery-model-list">
                              {capturedImages &&
                                [...capturedImages].map((data) => {
                                  if (
                                    !successFullyUploaded?.mapImage?.includes(
                                      data.id
                                    ) &&
                                    data.mapView
                                  ) {
                                    return returnImage(data, false, true);
                                  } else {
                                    return <></>;
                                  }
                                })}
                            </div>
                          </>
                        )}
                      {successFullyUploaded?.mapImage?.length > 0 && (
                        <>
                          <div className="gallery-model-head-success">
                            Location Photos: Successfully uploaded
                            {`(${successFullyUploaded?.mapImage?.length})`}
                          </div>
                          <div className="gallery-model-list">
                            {capturedImages &&
                              [...capturedImages].map((data) => {
                                if (
                                  successFullyUploaded?.mapImage?.includes(
                                    data.id
                                  )
                                ) {
                                  return returnImage(data, false, true);
                                } else {
                                  return <></>;
                                }
                              })}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="gallery-model-head">Location Photos</div>
                  <div className="gallery-model-list">
                    <div class="gallery-model-items">
                      <div className="no-photos">
                        <div className="inner-photos">
                          <i className="icon-save-images"></i>
                          <p>Aerial view</p>
                        </div>
                      </div>
                    </div>
                    <div class="gallery-model-items">
                      <div className="no-photos">
                        <div className="inner-photos">
                          <i className="icon-save-images"></i>
                          <p>Map view</p>
                        </div>
                      </div>
                    </div>
                    <div class="gallery-model-items">
                      <div className="no-photos">
                        <div className="inner-photos">
                          <i className="icon-save-images"></i>
                          <p>Street View</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {!isUploadClicked || successFullyUploaded?.isImageUpload === 0 ? (
                <>
                  <div className="gallery-model-head">Photos</div>
                  {!capturedImages?.length && (
                    <div className="no-photos">
                      <div className="inner-photos">
                        <i className="icon-save-images"></i>
                        <p>No Photos are Available For Upload</p>
                      </div>
                    </div>
                  )}
                  <div className="gallery-model-list">
                    {capturedImages &&
                      [...capturedImages].reverse().map((data) => {
                        if (!data.mapView) {
                          return returnImage(data);
                        }
                      })}
                  </div>
                </>
              ) : (
                <>
                  {capturedImages &&
                    (capturedImages?.length - 3) !==
                      successFullyUploaded?.image?.length && (
                      <>
                        <div className="gallery-model-head-error">
                          Photos: Failed to Upload
                          {` (${
                            (capturedImages?.length -
                            3)-
                            successFullyUploaded?.image?.length
                          })`}
                        </div>
                        <div className="gallery-model-list">
                          {capturedImages &&
                            [...capturedImages].reverse().map((data) => {
                              if (
                                !successFullyUploaded?.image?.includes(
                                  data.id
                                ) &&
                                !data.mapView
                              ) {
                                return returnImage(data);
                              } else {
                                return <></>;
                              }
                            })}
                        </div>
                      </>
                    )}
                  {successFullyUploaded?.image?.length > 0 && (
                    <>
                      <div className="gallery-model-head-success">
                        Photos: Successfully uploaded
                        {`(${successFullyUploaded?.image?.length})`}
                      </div>
                      <div className="gallery-model-list">
                        {capturedImages &&
                          [...capturedImages].reverse().map((data) => {
                            if (
                              successFullyUploaded?.image?.includes(data.id)
                            ) {
                              return returnImage(data, true);
                            } else {
                              return <></>;
                            }
                          })}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            {((!props.recordedVideoUrl && props.loader) || !capturedImages) && (
              <div class="ab-loader-wrapper">
                <div class="inner-wrap-data">
                  <div class="loader-spin"></div>
                  <div class="loading-name">Processing Captured Data</div>
                </div>
              </div>
            )}
          </div>
          <div className="footer-button-view ">
            <div className="footer-wrap">
              <button
                className="gray-btn"
                onClick={() => setRestartModelVisible(true)}
              >
                Restart Offline Inspection
              </button>
              <UploadOfflineData
                recordedVideoBlob={props.recordedVideoBlob}
                ShowEndScreen={() => props.ShowEndScreen()}
                ShowTokenScreen={() => props.ShowTokenScreen()}
                setSuccessFullyUploaded={setSuccessFullyUploaded}
                successFullyUploaded={successFullyUploaded}
                setIsUploadClicked={() => setIsUploadClicked(true)}
                capturedImages={capturedImages}
                reUpload={() => props.reUpload()}
                mapViewFetch={() => setMapViewFetch(true)}
                mapViewRejected={() => setMapViewRejected(true)}
                checkMapImageExists={mapViewFetch}
              />
            </div>
          </div>
        </div>
      </div>
      {restartModelVisible && (
        <RestartOfflineScan
          Cancel={() => hideRestartModelModel()}
          Continue={() => onDeleteAllImage()}
        />
      )}
    </>
  );
}
