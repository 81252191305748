import React, { useEffect, useRef } from "react";

const StreetView = (props) => {
  const ref = useRef();

  useEffect(() => {
    const map = new window.google.maps.StreetViewPanorama(ref.current, {
      position: {
        lat: props.remoteParticipantLatLong.lat,
        lng: props.remoteParticipantLatLong.long,
      },
      zoom: 15,
      visible: true,
    });
    map.addListener("zoom_changed", () => {});
    map.addListener("pov_changed", () => {
      props.onPovChanged(map.getPov());
    });
    map.addListener("center_changed", () => {});

    map.addListener("position_changed", () => {
      var position = map.getPosition();
      var streetViewRect = document
        .getElementById("map")
        .getBoundingClientRect();
      props.onPositionChanged(position, streetViewRect);
    });
    map.addListener("pano_changed", () => {
      // let pano = map.getPano();
    });
    const marker = new window.google.maps.Marker({
      position: {
        lat: props.remoteParticipantLatLong.lat,
        lng: props.remoteParticipantLatLong.long,
      },
      map,
      title: "Click to zoom",
    });
    // const panorama = map.getStreetView();
    // var streetViewMarker = new window.google.maps.Marker({
    //   position: {
    //     lat: props.remoteParticipantLatLong.lat,
    //     lng: props.remoteParticipantLatLong.long,
    //   },
    //   map: panorama,
    // });
  });
  return <div className="modal-body" ref={ref} id="map" />;
};

export default React.memo(StreetView);
