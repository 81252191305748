import api from "./apis/api";
import {
  dehydrateParticipant,
  dehydrateVideoCallToken,
  hydrateVideoCallToken,
  hydrateMeasurementJSON,
  hydrateDeleteFloorPlan,
  hydrateVideoCallOfflineToken,
} from "./transformers/";
class VideoCallService {
  getVideoCallToken(requestData) {
    return api.videoCall
      .getVideoCallToken(dehydrateVideoCallToken(requestData))
      .then((res) => hydrateVideoCallToken(res.data));
  }
  getVideoCallOfflineToken(requestData) {
    return api.videoCall
      .getVideoCallOfflineToken(dehydrateVideoCallToken(requestData))
      .then((res) => hydrateVideoCallOfflineToken(res.data.data));
  }
  inviteParticipant(callUUID, requestData) {
    return api.videoCall.inviteParticipant(
      callUUID,
      dehydrateParticipant(requestData)
    );
  }

  listParticipant(callUUID) {
    return api.videoCall.listParticipant(callUUID);
  }

  callEnd(callUUID) {
    return api.videoCall.callEnd(callUUID);
  }

  getMeasurementJSON(jobOrderId) {
    return api.videoCall
      .getMeasurementJSON(jobOrderId)
      .then((res) => hydrateMeasurementJSON(res.data));
  }

  deleteFloorPlan(requestData) {
    return api.videoCall
      .deleteFloorPlan(requestData)
      .then((res) => hydrateDeleteFloorPlan(res.data));
  }
}

export default new VideoCallService();
