import React, { Component } from "react";
import classnames from "classnames";

import { BookmarkLabelsForAppraisal } from "./BookmarkLabelsForAppraisal";
import { appConstants } from "../../../constants/app.constants";
import "./startAppraisal.scss";
import InstructionModel from "./instructionModel";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class StartAppraisal extends Component {
  constructor() {
    super();
    this.state = {
      floorData: appConstants.FLOOR_DATA,
      roomData: appConstants.ROOM_DATA,
      selectedFloor: null,
      selectedRoom: null,
      roomName: "",
      selectedGla: "GLA",
      floorError: {
        msg: i18n.t("ERROR_MESSAGES.Please_select_the_Floor"),
        error: false,
      },
      roomNameError: {
        msg: i18n.t("ERROR_MESSAGES.Please_enter_the_Room_Area_Name"),
        error: false,
      },
      instructions: false,
    };

    this.onSelectGla = this.onSelectGla.bind(this);
    this.onChangeFloor = this.onChangeFloor.bind(this);
    this.toggleInstruction = this.toggleInstruction.bind(this);
  }

  componentDidMount() {
    this.setState({});

    //console.log("StartAppraisal componentDidMount - ", this.props);

    if (this.props.selctedFloorIndexOnResume != null) {
      this.setState(
        {
          selectedFloor: this.props.selctedFloorIndexOnResume,
          selectedGla: this.props.selctedAreaTypeOnResume,
          roomName: this.props.selctedAreaNameOnResume,
          selectedRoom: this.props.selctedAreaNameOnResume,
        },
        () => {}
      );
    }
  }

  async componentDidUpdate(prevProps, prevState) {}

  onChangeFloor(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    if (value == "Select Floor") {
      this.setState({
        floorError: {
          msg: i18n.t("ERROR_MESSAGES.Please_select_the_Floor"),
          error: true,
        },
      });
    } else {
      this.setState({
        floorError: { msg: "", error: false },
      });
    }
  }

  onChangeRoom(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      roomName: this.state.roomData.find((i) => i.value === value).label,
    });
    if (value == "Select Room") {
      this.setState({
        roomNameError: {
          msg: i18n.t("ERROR_MESSAGES.Please_enter_the_Room_Area_Name"),
          error: true,
        },
      });
    } else {
      this.setState({
        roomNameError: { msg: "", error: false },
      });
    }
  }

  onChangeRoomName(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    if (!value) {
      this.setState({
        roomNameError: {
          msg: i18n.t("ERROR_MESSAGES.Please_enter_the_Room_Area_Name"),
          error: true,
        },
      });
    } else {
      this.setState({
        roomNameError: { msg: "", error: false },
      });
    }
  }

  onSelectGla(e, value) {
    const { name } = e.target;
    this.setState({
      [name]: value,
    });
  }

  validation() {
    const { selectedFloor, roomName, selectedGla, floorData } = this.state;
    let roomNameError;
    let floorError;
    if (!selectedFloor) {
      floorError = {
        msg: i18n.t("ERROR_MESSAGES.Please_select_the_Floor"),
        error: true,
      };
      this.setState({
        floorError: floorError,
      });
    } else {
      floorError = { msg: "", error: false };
    }
    if (!roomName) {
      roomNameError = {
        msg: i18n.t("ERROR_MESSAGES.Please_enter_the_Room_Area_Name"),
        error: true,
      };
      this.setState({
        roomNameError: roomNameError,
      });
    } else {
      roomNameError = { msg: "", error: false };
    }

    if (floorError.error || roomNameError.error) {
      return false;
    } else {
      return true;
    }
  }

  validateFloor(e) {
    if (e.target.value == "Select Floor") {
      this.setState({
        floorError: {
          msg: i18n.t("ERROR_MESSAGES.Please_select_the_Floor"),
          error: true,
        },
      });
    } else {
      this.setState({
        floorError: { msg: "", error: false },
      });
    }
  }

  validateRoomName(e) {
    if (!e.target.value) {
      this.setState({
        roomNameError: {
          msg: i18n.t("ERROR_MESSAGES.Please_enter_the_Room_Area_Name"),
          error: true,
        },
      });
    } else {
      this.setState({
        roomNameError: { msg: "", error: false },
      });
    }
  }

  submit(e) {
    e.preventDefault();
    if (this.validation()) {
      const { selectedFloor, roomName, selectedGla, floorData } = this.state;
      const data = {
        floorIndex: selectedFloor,
        floorName: floorData.find((i) => i.value === selectedFloor).label,
        areaName: roomName,
        areaType: selectedGla,
      };
      this.props.onSendFloorData(data);
      setTimeout(() => {
        this.props.checkMeasurementStarted();
      }, 30000);
    } else {
    }
  }

  toggleInstruction() {
    this.setState({ instructions: !this.state.instructions });
  }

  render() {
    const {
      floorData,
      roomData,
      selectedFloor,
      selectedRoom,
      roomName,
      selectedGla,
      floorError,
      roomNameError,
    } = this.state;
    const { t } = this.props;

    return (
      <>
        <div
          className={classnames(
            "comman-modal start-appraisal-tab-modal left-side",
            {
              open: this.props.isStartMeasurementModalCollapsed,
            }
          )}
        >
          <div className="comman-modal-main">
            <button
              className="side-toggle"
              onClick={() => this.props.toggleCollapseStartMeasurementModal()}
            >
              <i
                className={classnames({
                  "icon-arrow-left": this.props
                    .isStartMeasurementModalCollapsed,
                  "icon-arrow-right": !this.props
                    .isStartMeasurementModalCollapsed,
                })}
              ></i>
            </button>
            <div className="side-head">
              {t("TOOLTIP.Start_Inspection")}
              <div className="side-head-buttons">
                <button
                  className="close-modal modal-button-effect"
                  onClick={() => this.props.exitMeasurementProcess()}
                >
                  <i className="icon-close-image"></i>
                </button>
              </div>
            </div>
            <div className="comman-modal-body scroll-bar-style">
              <div className="custom-tab">
                <ul className="tab-list">
                  <li
                    className={
                      this.props.isStartAppraisalCaptureImageTabVisible
                        ? "active"
                        : ""
                    }
                  >
                    <button
                      onClick={() =>
                        this.props.toggleCaptureImageMeasurement(true)
                      }
                    >
                      {t("BUTTONS.Capture_Labeled_Images")}
                    </button>
                  </li>
                  <li
                    className={
                      !this.props.isStartAppraisalCaptureImageTabVisible
                        ? "active"
                        : ""
                    }
                  >
                    <button
                      onClick={() =>
                        this.props.toggleCaptureImageMeasurement(false)
                      }
                    >
                      {t("BUTTONS.Capture_Measurements")}
                    </button>
                  </li>
                </ul>
                {/* <form className=""> */}
                <div
                  className={
                    this.props.isStartAppraisalCaptureImageTabVisible
                      ? "tab-content active"
                      : "tab-content"
                  }
                >
                  <div className="appriser-bookmark">
                    <BookmarkLabelsForAppraisal
                      images={this.props.images}
                      fetchBookmark={this.props.fetchBookmark}
                      callUUID={this.props.callUUID}
                      captureImage={this.props.captureImage}
                      showSavedImages={this.props.showSavedImages}
                      hideBookmarkViewModel={this.props.hideBookmarkViewModel}
                      isLocalParticipantPinned={
                        this.props.isLocalParticipantPinned
                      }
                    />
                    <div className="instructions-button-list">
                      <ul>
                        <li>
                          <button onClick={() => this.toggleInstruction()}>
                            <i className="icon-Instructions"></i>
                            <div className="text">
                              {t("BUTTONS.Instructions")}
                            </div>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    !this.props.isStartAppraisalCaptureImageTabVisible
                      ? "tab-content active"
                      : "tab-content"
                  }
                >
                  <div className="measurement-form">
                    <div className="measurement-form-control">
                      <div className="form-group">
                        <label>{t("WEB_LABELS.Floor")}</label>
                        <div className="select-box-div">
                          <select
                            className="form-control"
                            onChange={this.onChangeFloor.bind(this)}
                            value={selectedFloor}
                            name="selectedFloor"
                            onBlur={this.validateFloor.bind(this)}
                          >
                            <option disabled selected>
                              Select Floor
                            </option>
                            {floorData.map((tz, i) => (
                              <>
                                <option key={i} value={tz.value}>
                                  {tz.label}
                                </option>
                                {i == 2 && (
                                  <option disabled>
                                    ─────────────────────────
                                  </option>
                                )}
                              </>
                            ))}
                          </select>
                          {floorError.error && (
                            <div className="form-error">{floorError.msg}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>{t("WEB_LABELS.Room_Area_Label")}</label>
                        <div className="select-box-div">
                          <select
                            className="form-control"
                            onChange={this.onChangeRoom.bind(this)}
                            value={selectedRoom}
                            name="selectedRoom"
                          >
                            <option disabled selected>
                              Select Room/Area Type
                            </option>
                            {roomData.map((tz, i) => (
                              <option key={i} value={tz.value}>
                                {tz.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>{t("WEB_LABELS.Room_Area_Name")}</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t(
                            "WEB_LABELS.Room_Area_Name_Placeholder"
                          )}
                          name="roomName"
                          value={roomName}
                          onChange={this.onChangeRoomName.bind(this)}
                          onBlur={this.validateRoomName.bind(this)}
                        />
                        {roomNameError.error && (
                          <div className="form-error">{roomNameError.msg}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="radio-btn">
                          <label>
                            <input
                              type="radio"
                              name="selectedGla"
                              value={selectedGla}
                              checked={selectedGla === "GLA"}
                              onChange={(e) => this.onSelectGla(e, "GLA")}
                            />
                            <span>GLA</span>
                          </label>
                        </div>
                        <div className="radio-btn">
                          <label>
                            <input
                              type="radio"
                              name="selectedGla"
                              checked={selectedGla === "Non-GLA"}
                              value={selectedGla}
                              onChange={(e) => this.onSelectGla(e, "Non-GLA")}
                            />
                            <span>Non-GLA</span>
                          </label>
                        </div>
                      </div>

                      {/* <div class="form-group auto-snapping-form">
                        <div class="form-text">
                          Auto Snapping During Measurement
                        </div>
                        <div class="switch-button">
                          <div class="toggle">
                            <div class="toggle-box">
                              <label class="radio-button">
                                <input
                                  type="radio"
                                  name="yes1"
                                  value={this.props.autoSnap}
                                  checked={this.props.autoSnap}
                                  onChange={(e) =>
                                    this.props.toggleAutoSnap(true)
                                  }
                                />
                                <span>On</span>
                              </label>
                              <label class="radio-button">
                                <input
                                  type="radio"
                                  name="yes1"
                                  value={this.props.autoSnap}
                                  checked={!this.props.autoSnap}
                                  onChange={(e) =>
                                    this.props.toggleAutoSnap(false)
                                  }
                                />
                                <span>Off</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="button-group text-center">
                        <button
                          type="button"
                          className="green-btn"
                          onClick={this.submit.bind(this)}
                        >
                          {t("BUTTONS.Start_Measurement_Process")}
                        </button>
                      </div>
                    </div>
                    <div className="instructions-button-list">
                      <ul>
                        <li>
                          <button onClick={() => this.toggleInstruction()}>
                            <i className="icon-Instructions"></i>
                            <div className="text">
                              {t("BUTTONS.Instructions")}
                            </div>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
        {this.state.instructions && (
          <InstructionModel
            type={this.props.isStartAppraisalCaptureImageTabVisible ? "1" : "2"}
            toggleInstruction={() => this.toggleInstruction()}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(StartAppraisal);
