import _ from "lodash";
import {
  chat,
  flash_on_off,
  notes,
  start_mark_up,
  start_pointer,
  bookmark,
  attachment,
  pinned_view,
  screen_share,
  screen_share_po,
  call_settings,
  capture_image,
  call_recording,
  view_participant_list,
  invite_participant,
  fill_up_inspection_form,
  questionnaire_update,
  view_captured_images,
  create_bookmark,
  tap_on_device,
  undo,
  start_appraiser_measurement,
  questionnaire,
  switch_camera,
} from "./../../constants/Permission";
export function dehydrateCallConfig(data) {
  return _.omitBy(
    {
      callLogo: data.callLogo,
      backgroundColor: data.backgroundColor,
      privacyPolicyUrl: data.privacyPolicyUrl,
      maxCallDuration: data.maxCallDuration,
      settings: dehydrateCallConfigParams(data.config || {}),
      lenderDetails: data.lenderDetails,
      jobOrderId: data.jobOrderId,
      latitude: data.latitude,
      longitude: data.longitude,
    },
    _.isUndefined
  );
}

export function dehydrateCallConfigParams(data) {
  return _.omitBy(
    {
      [chat]: _.get(data, "chats", true),
      [flash_on_off]: _.get(data, "flash", true),
      [notes]: _.get(data, ["notes"], true),
      [start_mark_up]: _.get(data, "mark_up", false),
      [start_pointer]: _.get(data, "pointer", false),
      [bookmark]: _.get(data, "bookmark", true),
      [attachment]: _.get(data, "attachment", true),
      [pinned_view]: _.get(data, "pinned_view", true),
      [screen_share]: _.get(data, "screen_share", true),
      [screen_share_po]: _.get(data, "screen_share_po", true),
      [call_settings]: _.get(data, "call_settings", true),
      [capture_image]: _.get(data, "capture_image", true),
      [call_recording]: _.get(data, "call_recording", true),
      [view_participant_list]: _.get(data, "list_participant", true),
      [invite_participant]: _.get(data, "invite_participant", true),
      [fill_up_inspection_form]: _.get(data, "fill_up_inspection_form", true),
      [questionnaire_update]: _.get(data, "questionnaire_update", true),
      [view_captured_images]: _.get(data, "view_captured_images", true),
      [create_bookmark]: _.get(data, "create_bookmark", true),
      [tap_on_device]: _.get(data, "tap_on_device", true),
      [undo]: _.get(data, "undo", true),
      [start_appraiser_measurement]: _.get(
        data,
        "start_appraiser_measurement",
        true
      ),
      localization: _.get(data, "localization", false),
      capture_image_panel: _.get(data, "capture_image_panel", false),
      // capture_image_panel:data.capture_image_panel,
      clientDisplayName: data.client_display_name,
      isPowerBy: _.get(data, "is_power_by", true),
      install_open_app: _.get(data, "install_open_app", true),
      [questionnaire]: _.get(data, "questionnaire", true),
      [switch_camera]: _.get(data, "switch_camera", false),
      isAwsImage: data.isAwsImage,
    },
    _.isUndefined
  );
}
