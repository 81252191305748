import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import * as _ from "lodash";
import classnames from "classnames";
import {
  validateNameWithRequired,
  validateTextLength,
} from "../../../helpers";
import "./beforeJoiningModel.scss";

import englishLanguage from "../../../images/all-flag/English.svg";
import spanishLanguage from "../../../images/all-flag/Spanish.svg";
import ArabicLanguage from "../../../images/all-flag/Arabic.svg";
import FrenchLanguage from "../../../images/all-flag/French.svg";
import IndiaLanguage from "../../../images/all-flag/India.svg";
import PhilippinesLanguage from "../../../images/all-flag/Philippines.svg";
import VietnameseLanguage from "../../../images/all-flag/Vietnamese.svg";
import ChineseLanguage from "../../../images/all-flag/Chinese.svg";
import KoreanLanguage from "../../../images/all-flag/Korean.svg";
import RussianLanguage from "../../../images/all-flag/Russian.svg";
import TurkishLanguage from "../../../images/all-flag/Turkish.svg";

import { withTranslation } from "react-i18next";
import i18n from "i18next";
let initialState = {
  userName: {
    value: "",
    error: "",
  },
  language: localStorage.getItem("selectedLanguage")
    ? localStorage.getItem("selectedLanguage")
    : "english",
  anchorEl: null,
};

class BeforeJoiningModel extends Component {
  constructor(props) {
    super();
    props.name && (initialState.userName.value = props.name);
    this.state = {
      ...initialState,
    };
    if (props.callConfig.settings.localization) {
      this.setState({
        language: localStorage.getItem("selectedLanguage")
          ? localStorage.getItem("selectedLanguage")
          : "english",
      });
    } else {
      this.setState({
        language: "english",
      });
      localStorage.setItem("selectedLanguage", "english");
      i18n.changeLanguage("en");
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit() {
    const userNameError = validateNameWithRequired(this.state.userName.value);

    if ([userNameError].every((e) => e === false)) {
      this.props.handleJoinClicked(this.state.userName.value);
    } else {
      this.setState((state) => ({
        userName: {
          ...state.userName,
          validateOnChange: true,
          error: userNameError,
        },
      }));
    }
  }

  async handleChange(validationFunc, e) {
    let { name, value } = e.target;

    let { value: val, error } = validateTextLength(value, 30);
    value = val;

    this.setState((state) => ({
      [name]: {
        ...state[name],
        value: value,
        validateOnChange: true,
        error: "",
      },
    }));
  }

  async componentDidMount() {
    this.nameInput.focus();
  }

  validatePolicyUrl = (url) => {
    if (url) {
      const extractedFileName = url.split("policy/");

      if (extractedFileName[1]) {
        return true;
      }
      return false;
    }
    return false;
  };

  openPrivacyPolicy() {
    const {
      callConfig: { privacyPolicyUrl },
    } = this.props;
    window.open(privacyPolicyUrl, "_blank");
    // if (this.validatePolicyUrl(privacyPolicyUrl)) {
    //   window.open(privacyPolicyUrl, "_blank");
    // } else {
    //   customToast.error(i18n.t("COMMON_STRING.PRIVACY_POLICY_FAIL"));
    // }
  }
  handleLanguageClose = (item, e) => {
    switch (item) {
      case "english": {
        i18n.changeLanguage("en");
        break;
      }
      case "arabic": {
        i18n.changeLanguage("ar");
        break;
      }
      case "cantonese": {
        i18n.changeLanguage("yue");
        break;
      }
      case "french": {
        i18n.changeLanguage("fr");
        break;
      }
      case "hindi": {
        i18n.changeLanguage("hi");
        break;
      }
      case "japanese": {
        i18n.changeLanguage("ja");
        break;
      }
      case "korean": {
        i18n.changeLanguage("ko");
        break;
      }
      case "mandarin": {
        i18n.changeLanguage("cmn");
        break;
      }
      case "tagalog": {
        i18n.changeLanguage("tl");
        break;
      }
      case "vietnamese": {
        i18n.changeLanguage("vi");
        break;
      }
      case "spanish": {
        i18n.changeLanguage("es");
        break;
      }
      case "russian": {
        i18n.changeLanguage("ru");
        break;
      }
      case "turkish": {
        i18n.changeLanguage("tr");
        break;
      }
    }
    if (typeof item == "string") {
      this.setState({ language: item });
      localStorage.setItem("selectedLanguage", item);
    }
    this.setState({ anchorEl: null });
  };
  handleLanguageClick = (event) => {
    this.setState({ anchorEl: !this.state.anchorEl });
  };
  render() {
    const { userName } = this.state;
    const { t, callConfig } = this.props;
    return (
      <>
        <div className="new-before-joining-modal">
          <div className="email-view">
            {callConfig.settings.localization && (
              <div className="form-group language-view">
                <label className="text-left">
                  {t("WEB_LABELS.Select_Language")}
                </label>
                <div
                  className={
                    this.state.anchorEl
                      ? "cutom-dropdown active"
                      : "cutom-dropdown "
                  }
                >
                  <div className="selected-item">
                    {this.state.language == "english" && (
                      <button onClick={this.handleLanguageClick}>
                        {" "}
                        <i className="flag-icon">
                          <img src={englishLanguage} alt="English" />
                        </i>{" "}
                        English, English{" "}
                      </button>
                    )}
                    {this.state.language == "spanish" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={spanishLanguage} alt="Spanish" />
                        </i>{" "}
                        Spanish, Español{" "}
                      </button>
                    )}
                    {this.state.language == "mandarin" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={ChineseLanguage} alt="Chinese" />
                        </i>{" "}
                        Mandarin, 普通话{" "}
                      </button>
                    )}
                    {this.state.language == "cantonese" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={ChineseLanguage} alt="Chinese" />
                        </i>{" "}
                        Cantonese, 粵語{" "}
                      </button>
                    )}
                    {this.state.language == "tagalog" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={PhilippinesLanguage} alt="Philippines" />
                        </i>{" "}
                        Tagalog, Tagalog{" "}
                      </button>
                    )}
                    {this.state.language == "french" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={FrenchLanguage} alt="French" />
                        </i>{" "}
                        French, Français{" "}
                      </button>
                    )}
                    {this.state.language == "vietnamese" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={VietnameseLanguage} alt="Vietnamese" />
                        </i>{" "}
                        Vietnamese, Tiếng Việt{" "}
                      </button>
                    )}
                    {this.state.language == "hindi" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={IndiaLanguage} alt="India" />
                        </i>{" "}
                        Hindi, हिन्दी{" "}
                      </button>
                    )}
                    {this.state.language == "arabic" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={ArabicLanguage} alt="Arabic" />
                        </i>{" "}
                        Arabic, عربى{" "}
                      </button>
                    )}
                    {this.state.language == "japanese" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={ChineseLanguage} alt="Japanese" />
                        </i>{" "}
                        Japanese, 日本{" "}
                      </button>
                    )}
                    {this.state.language == "korean" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={KoreanLanguage} alt="Korean" />
                        </i>{" "}
                        Korean, 한국인{" "}
                      </button>
                    )}
                    {this.state.language == "russian" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={RussianLanguage} alt="Russian" />
                        </i>{" "}
                        Russian, русский{" "}
                      </button>
                    )}
                    {this.state.language == "turkish" && (
                      <button onClick={this.handleLanguageClick}>
                        <i className="flag-icon">
                          <img src={TurkishLanguage} alt="Turkish" />
                        </i>{" "}
                        Turkish, Türk{" "}
                      </button>
                    )}
                    <i className="icon-arrow-down drop-aero"></i>
                  </div>
                  <div className="choose-item">
                    <ul className="choose-item-list">
                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("arabic")}
                        >
                          <i className="flag-icon">
                            <img src={ArabicLanguage} alt="Arabic" />
                          </i>{" "}
                          Arabic, عربى{" "}
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("cantonese")}
                        >
                          <i className="flag-icon">
                            <img src={ChineseLanguage} alt="Cantonese" />
                          </i>{" "}
                          Cantonese, 粵語{" "}
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("english")}
                        >
                          <i className="flag-icon">
                            <img src={englishLanguage} alt="English" />
                          </i>{" "}
                          English, English{" "}
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("french")}
                        >
                          <i className="flag-icon">
                            <img src={FrenchLanguage} alt="French" />
                          </i>{" "}
                          French, Français{" "}
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("hindi")}
                        >
                          <i className="flag-icon">
                            <img src={IndiaLanguage} alt="India" />
                          </i>{" "}
                          Hindi, हिन्दी{" "}
                        </button>
                      </li>

                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("japanese")}
                        >
                          <i className="flag-icon">
                            <img src={ChineseLanguage} alt="Japanese" />
                          </i>{" "}
                          Japanese, 日本{" "}
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("korean")}
                        >
                          <i className="flag-icon">
                            <img src={KoreanLanguage} alt="Korean" />
                          </i>{" "}
                          Korean, 한국인{" "}
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("mandarin")}
                        >
                          <i className="flag-icon">
                            <img src={ChineseLanguage} alt="Mandarin" />
                          </i>{" "}
                          Mandarin, 만다린 오렌지{" "}
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("spanish")}
                        >
                          <i className="flag-icon">
                            <img src={spanishLanguage} alt="Spanish" />
                          </i>{" "}
                          Spanish, Español{" "}
                        </button>
                      </li>

                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("tagalog")}
                        >
                          <i className="flag-icon">
                            <img src={PhilippinesLanguage} alt="Philippines" />
                          </i>{" "}
                          Tagalog, Tagalog{" "}
                        </button>
                      </li>

                      <li>
                        <button
                          onClick={(e) =>
                            this.handleLanguageClose("vietnamese")
                          }
                        >
                          <i className="flag-icon">
                            <img src={VietnameseLanguage} alt="Vietnamese" />
                          </i>{" "}
                          Vietnamese, Tiếng Việt{" "}
                        </button>
                      </li>

                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("russian")}
                        >
                          <i className="flag-icon">
                            <img src={RussianLanguage} alt="Russian" />
                          </i>{" "}
                          Russian, русский{" "}
                        </button>
                      </li>

                      <li>
                        <button
                          onClick={(e) => this.handleLanguageClose("turkish")}
                        >
                          <i className="flag-icon">
                            <img src={TurkishLanguage} alt="Turkish" />
                          </i>{" "}
                          Turkish, Türk{" "}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <p>{t("WEB_LABELS.Enter_Your_Name")}</p>
            <div className="form-group">
              <input
                type="text"
                ref={(input) => {
                  this.nameInput = input;
                }}
                value={this.state["userName"].value}
                className="form-control"
                name="userName"
                placeholder={t("WEB_LABELS.Enter_Your_Name")}
                onChange={(e) => this.handleChange(validateNameWithRequired, e)}
                onBlur={(e) => this.handleChange(validateNameWithRequired, e)}
              />
              <div className="text-danger">{t(userName.error)}</div>
            </div>
            {_.get(this.props, ["callConfig", "privacyPolicyUrl"], null) !==
              null && (
              <p>
                {t("WEB_LABELS.I_confirm_that_I_have_read_and_agree_to_the")}{" "}
                <button
                  className="btn-link"
                  onClick={() =>
                    this.openPrivacyPolicy(
                      this.props.callConfig.privacyPolicyUrl
                    )
                  }
                >
                  {t("BUTTONS.Privacy_Policy")}
                </button>
                .
              </p>
            )}
            <div className="text-center">
              <button className="blue-btn" onClick={this.handleSubmit}>
                {t("BUTTONS.Join")}
              </button>
            </div>
          </div>
        </div>
        <div className="top-right-menu">
          <ul>
            {!isMobile && (
              <li
                className={classnames({
                  active: this.props.isCallSettingsVisible,
                })}
              >
                <button onClick={() => this.props.showCallSettings()}>
                  {" "}
                  <i className="icon-call-setting"></i>
                </button>
              </li>
            )}
          </ul>
        </div>
      </>
    );
  }
}
export default withTranslation()(BeforeJoiningModel);
