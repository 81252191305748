import React, { Component, createRef } from "react";
import "./participantVideoView.scss";
import { appConstants } from "../../constants/";
import { extractUserName } from "./../../helpers/commonJSFunction";
import RBAC from "./../RBAC";
import {
  pinned_view,
  switch_camera,
} from "./../../constants/Permission";
import _ from "lodash";
import Tooltip from "@mui/material/Tooltip";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { fabric } from "fabric";
import AsyncImage from "../common/AsyncImage";

class ParticipantVideoView extends Component {
  trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  remoteVideoRef = createRef();
  remoteAudioRef = createRef();

  remoteParticipant = null;
  canvas;

  minX = 0;
  maxX = 0;
  minY = 0;
  maxY = 0;
  ratio = 0;
  canvasHeight = 200;
  canvasWidth = 200;
  cdata = {};

  constructor(props) {
    super(props);

    this.state = {
      remoteScreenShareTracks: [],
      remoteVideoTracks: [],
      remoteAudioTracks: [],
      locationNotShared: false,
      isSwitchCameraTooltipOpen: false,
    };

    this.remoteTrackSubscribed = this.remoteTrackSubscribed.bind(this);
    this.remoteTrackUnsubscribed = this.remoteTrackUnsubscribed.bind(this);
    this.toggleLocationNotShared = this.toggleLocationNotShared.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.pinType != prevProps.pinType &&
      this.props.pinType == "screen_share"
    ) {
      // console.log("remoteVideoTracks - ",this.state.remoteScreenShareTracks);
      if (this.state.remoteScreenShareTracks.length > 0) {
        let remoteScreenShareTrack = this.state.remoteScreenShareTracks[0];
        remoteScreenShareTrack.attach(this.remoteVideoRef.current);
      }
    }
    if (!_.isEqual(this.props.liveFloorPlanData, prevProps.liveFloorPlanData)) {
      this.cdata = this.props.liveFloorPlanData;
      if (this.canvas) {
        this.canvas.clear();
      }
      console.log(this.cdata);
      this.drawPlan();
    }
  }

  drawPlan = () => {
    if (
      this.cdata.coordinates &&
      this.cdata.coordinates.length > 0 &&
      this.props.remoteParticipant &&
      this.props.screenShareParticipantSid == this.props.remoteParticipant.sid
    ) {
      if (this.remoteVideoRef && this.remoteVideoRef.current) {
        this.canvasHeight = this.remoteVideoRef.current.clientHeight - 100;
        this.canvasWidth = this.remoteVideoRef.current.clientWidth - 100;
      } else {
        this.canvasHeight = 200;
        this.canvasWidth = 200;
      }
      this.canvas = new fabric.Canvas(this.props.remoteParticipant.sid, {
        selection: false,
        canvasHeight: this.canvasHeight,
        canvasWidth: this.canvasWidth,
      });

      let pointx = [],
        pointy = [];
      for (
        let pointIndex = 0;
        pointIndex < this.cdata.coordinates.length;
        pointIndex++
      ) {
        pointx.push(this.cdata.coordinates[pointIndex].x);
        pointy.push(this.cdata.coordinates[pointIndex].y);
      }
      this.maxX = Math.max(...pointx);
      this.minX = Math.min(...pointx);
      this.maxY = Math.max(...pointy);
      this.minY = Math.min(...pointy);

      const mainline = new fabric.Line(
        [this.minX, this.minY, this.maxX, this.maxY],
        {
          originX: "center",
          originY: "center",
        }
      );
      let hRatio = this.canvasWidth / mainline.width;
      let vRatio = this.canvasHeight / mainline.height;

      this.ratio = Math.min(hRatio, vRatio);

      const zoomlevel = this.cdata.zoomFactor;
      let metertofeet = 3.28084;
      let pixeltometer = 1 / zoomlevel;
      let pixeltofeet = pixeltometer * metertofeet;
      const PIXEL_TO_FOOT_CONSTANT = pixeltofeet;
      //canvas.setZoom(zoomlevel / 100);
      let separateLines1 = [],
        textList = [];
      for (
        let pointIndex = 0;
        pointIndex < this.cdata.coordinates.length;
        pointIndex++
      ) {
        if (pointIndex > 0) {
          const line = new fabric.Line(
            [
              (this.cdata.coordinates[pointIndex - 1].x - this.minX) *
                this.ratio,
              (this.cdata.coordinates[pointIndex - 1].y - this.minY) *
                this.ratio,
              (this.cdata.coordinates[pointIndex].x - this.minX) * this.ratio,
              (this.cdata.coordinates[pointIndex].y - this.minY) * this.ratio,
            ],
            {
              originX: "center",
              originY: "center",
              type: "CENTRE-BOX-TEXT",
              strokeWidth: 3,
              stroke:
                pointIndex == this.cdata.coordinates.length - 1 &&
                this.cdata.showSuggestion
                  ? "red"
                  : "black",
              strokeDashArray:
                pointIndex == this.cdata.coordinates.length - 1 &&
                this.cdata.showSuggestion
                  ? [2, 2]
                  : [],
            }
          );
          let width1 =
            Math.sqrt(line.width * line.width + line.height * line.height) *
            PIXEL_TO_FOOT_CONSTANT;

          width1 /= this.ratio;

          let tempFoot = parseInt(width1);
          let tempPoints = width1 - tempFoot;
          let inch = (tempPoints * 100 * 12) / 100;
          inch = parseInt(inch);
          let unroundedSize =
            tempFoot + "' " + (inch != "" && inch > 0 ? inch + "''" : "");
          let coordinateForLable = this.coordinateForlable(
            line.x1,
            line.x2,
            line.y1,
            line.y2,
            (line.x1 + line.x2) / 2,
            (line.y1 + line.y2) / 2
          );
          let text = new fabric.Text(unroundedSize.toString(), {
            fontFamily: '"Roboto", sans-serif',
            fontSize: 15,
            left: coordinateForLable.x,
            top: coordinateForLable.y,
            angle: coordinateForLable.orientation,
            fill: "#707070",
            objectCaching: false,
            type: "LABEL",
            selectable: false,
            originX: "center",
            originY: "center",
          });
          let midPointX = (line.x1 + line.x2) / 2; //mid-point of line
          let midPointY = (line.y1 + line.y2) / 2;
          text = this.getLabelPosition(
            text,
            midPointX,
            midPointY,
            coordinateForLable.orientation
          );
          separateLines1.push(line);
          textList.push(text);
        }
      }
      let group1 = new fabric.Group([...separateLines1, ...textList], {
        centeredRotation: true,
        hasBorders: false,
        id: "room-group",
        selectable: false,
        objectCaching: false,
      });
      this.canvas.setHeight(this.canvasHeight + 100);
      this.canvas.setWidth(this.canvasWidth + 100);
      group1.top = this.canvas.height / 2 - group1.height / 2;
      group1.left = this.canvas.width / 2 - group1.width / 2;
      this.canvas.add(group1);
      this.canvas.requestRenderAll();
    }
  };

  coordinateForlable = (x0, x1, y0, y1, centreX, centreY, length) => {
    let midPointX = (x0 + x1) / 2; //mid-point of line
    let midPointY = (y0 + y1) / 2;
    let distanceFromMidpoint = Math.sqrt(
      Math.pow(centreX - midPointX, 2) + Math.pow(centreY - midPointY, 2)
    );
    let lambda = 0.1;
    // let lambda = approximateDistanceFromLine /(distanceFromMidpoint - approximateDistanceFromLine);
    let lablePointX = (lambda * centreX + midPointX) / (lambda + 1);
    let lablePointY = (lambda * centreY + midPointY) / (lambda + 1);
    let orientation = Math.atan2(y1 - y0, x1 - x0) * (180.0 / Math.PI);

    return {
      x: lablePointX,
      y: lablePointY,
      orientation,
    };
  };
  getLabelPosition = (text, midPointX, midPointY, angleDeg) => {
    text.set("flipY", false);
    text.set("flipX", false);
    if (angleDeg >= -45 && angleDeg < 45) {
      text.set("left", midPointX);
      text.set("top", midPointY + 15);
    } else if (angleDeg >= 45 && angleDeg < 135) {
      text.set("left", midPointX - 13);
      text.set("top", midPointY);
    } else if (angleDeg >= 135 || angleDeg < -135) {
      text.set("left", midPointX);
      text.set("top", midPointY - 13);
      text.set("flipY", true);
      text.set("flipX", true);
    } else if (angleDeg >= -135 && angleDeg < -45) {
      text.set("left", midPointX + 15);
      text.set("top", midPointY);
    }
    return text;
  };
  async componentDidMount() {
    this.props.remoteParticipant.on(
      "trackSubscribed",
      this.remoteTrackSubscribed
    );
    this.props.remoteParticipant.on(
      "trackUnsubscribed",
      this.remoteTrackUnsubscribed
    );
  }

  async remoteTrackSubscribed(track) {
    // console.log("remoteTrackSubscribed pinType  = ",this.props.pinType);

    if (track.kind === "video") {
      // console.log("remoteTrackSubscribed track = ",track);

      if (track.name.startsWith("s_")) {
        // console.log("we have screenshare track");

        this.setState(
          {
            remoteScreenShareTracks: [track],
          },
          () => {
            track.attach(this.remoteVideoRef.current);
          }
        );
      } else {
        this.setState(
          {
            remoteVideoTracks: [track],
          },
          () => {
            if (this.props.pinType != "screen_share") {
              track.attach(this.remoteVideoRef.current);
            }
          }
        );
      }

      // console.log("Total remoteScreenShareTracks = ",this.state.remoteScreenShareTracks);
      // console.log("Total remoteVideoTracks = ",this.state.remoteVideoTracks);
    } else if (track.kind === "audio") {
      this.setState(
        {
          remoteAudioTracks: [track],
        },
        () => {
          track.attach(this.remoteAudioRef.current);
        }
      );
    }
  }

  async remoteTrackUnsubscribed(track) {
    // console.log("remoteTrackUnsubscribed = ",track);
    // console.log("remoteTrackUnsubscribed pinType  = ",this.props.pinType);

    if (track.kind === "video") {
      //console.log("remoteTrackUnsubscribed track = ",track);

      if (track.name.startsWith("s_")) {
        // console.log("remoteTrackUnsubscribed we have removed screenshare track");

        this.setState(
          {
            remoteScreenShareTracks: [],
          },
          () => {
            if (this.remoteVideoRef && this.remoteVideoRef.current) {
              track.detach(this.remoteVideoRef.current);
            }
          }
        );

        //Now check if video track is presnet of this user...

        // console.log("remoteVideoTracks - ",this.state.remoteVideoTracks);
        if (this.state.remoteVideoTracks.length > 0) {
          let remoteVideoTrack = this.state.remoteVideoTracks[0];
          remoteVideoTrack.attach(this.remoteVideoRef.current);
        }
      } else {
        this.setState(
          {
            remoteVideoTracks: [],
          },
          () => {
            if (this.remoteVideoRef && this.remoteVideoRef.current) {
              track.detach(this.remoteVideoRef.current);
            }
          }
        );

        // console.log("remoteVideoTracks - ",this.state.remoteVideoTracks);
        if (this.state.remoteScreenShareTracks.length > 0) {
          let remoteScreenShareTrack = this.state.remoteScreenShareTracks[0];
          remoteScreenShareTrack.attach(this.remoteVideoRef.current);
        }
      }

      // console.log("remoteTrackUnsubscribed Total remoteScreenShareTracks = ",this.state.remoteScreenShareTracks);
      // console.log("remoteTrackUnsubscribed Total remoteVideoTracks = ",this.state.remoteVideoTracks);
    } else if (track.kind === "audio") {
      this.setState(
        {
          remoteAudioTracks: [],
        },
        () => {
          if (this.remoteAudioRef && this.remoteAudioRef.current) {
            track.detach(this.remoteAudioRef.current);
          }
        }
      );
    }
  }

  async toggleLocationNotShared() {
    this.setState({ locationNotShared: !this.state.locationNotShared });
  }

  render() {
    // console.log("Total remoteScreenShareTracks at render = ",this.state.remoteScreenShareTracks);
    const { t } = this.props;
    let { userData, participant, remoteParticipant } = this.props;
    let remoteUserVideoDisplay = "";

    let remoteVideoArea = "";

    let classNameForZoom =
      this.props.zoomLevel == appConstants.ZOOM_LEVEL.ZOOM_LEVEL_1
        ? "scale1"
        : this.props.zoomLevel == appConstants.ZOOM_LEVEL.ZOOM_LEVEL_2
        ? "scale2"
        : "scale3";

    if (this.props.isMobileDevice) {
      classNameForZoom = "scale1";
    }

    if (
      (this.state.remoteVideoTracks.length > 0 &&
        this.state.remoteVideoTracks[0].isEnabled == true) ||
      (this.state.remoteScreenShareTracks.length > 0 &&
        this.state.remoteScreenShareTracks[0].isEnabled == true)
    ) {
      remoteVideoArea = (
        <video
          ref={this.remoteVideoRef}
          autoPlay={true}
          className={classNameForZoom}
        />
      );
    } else {
      remoteVideoArea = (
        <>
          <div className="generalize-text">
            <div className="common-text">
              {extractUserName(_.get(remoteParticipant, ["identity"], ""))}
            </div>
          </div>
        </>
      );
    }

    remoteUserVideoDisplay = (
      <>
        <div
          className={
            this.cdata.coordinates && this.cdata.coordinates.length > 0
              ? "live-stream canvas-active"
              : "live-stream"
          }
        >
          <audio ref={this.remoteAudioRef} autoPlay={true} muted={false} />
          {remoteVideoArea}
          {this.props.isPropertyOwnerPinned &&
            this.props.pinType == "screen_share" &&
            this.props.areMeasurementButtonsEnabled && (
              <div
                id={"div_" + remoteParticipant.sid}
                className={
                  this.cdata.coordinates && this.cdata.coordinates.length > 0
                    ? "live-floorplan"
                    : "live-floorplan hide"
                }
              >
                <div className="inner-measurement">
                  <canvas id={remoteParticipant.sid} />
                </div>
              </div>
            )}
        </div>

        {this.props.lastCapturedImageURL.trim().length > 0 &&
          this.props.isOwner &&
          this.props.isPinned &&
          this.props.captureImagePanel &&
          !this.props.isPointerStarted && (
            <div className="captured-stream">
              {this.props.isAwsImage ? (
                <img
                  src={this.props.lastCapturedImageURL}
                  alt="Loading Captured Pic..."
                />
              ) : (
                <AsyncImage imageUrl={this.props.lastCapturedImageURL} />
              )}
            </div>
          )}
      </>
    );

    const classnameforwrapper = this.props.isPinned
      ? "participants-view full-screen"
      : "participants-view";
    let participantLabel = extractUserName(
      _.get(remoteParticipant, ["identity"], "")
    );
    const classForPinnedicon = this.props.isPinned
      ? "icon-return-to-gallery"
      : "icon-pin-video";
    const hidepinnedView =
      this.props.ispinnedViewActive && !this.props.isPinned;

    let LocationLabel = "";
    let isLocationAvailable = false;

    if (this.props.isPinned) {
      if (
        this.props.latitude.toString().trim().length > 0 &&
        this.props.longitude.toString().trim().length > 0
      ) {
        LocationLabel +=
          t("WEB_LABELS.Location") +
          ": " +
          parseFloat(this.props.latitude).toFixed(6) +
          ", " +
          parseFloat(this.props.longitude).toFixed(6);
        isLocationAvailable = true;
      } else {
        LocationLabel += t("WEB_LABELS.Location_Not_shared");
      }
    }

    return (
      <>
        <div className={classnameforwrapper}>
          <div className="name">
            <i
              className={
                this.state.remoteAudioTracks.length > 0 &&
                this.state.remoteAudioTracks[0].isEnabled == true
                  ? "icon-microphone"
                  : "icon-mute-microphone"
              }
            ></i>
            <div className="location-name">
              {((this.state.remoteVideoTracks.length > 0 &&
                this.state.remoteVideoTracks[0].isEnabled) ||
                (this.state.remoteScreenShareTracks.length > 0 &&
                  this.state.remoteScreenShareTracks[0].isEnabled)) && (
                <span className="user-name">
                  {participantLabel}
                  <span className="comma">,</span>
                </span>
              )}

              <span
                className="location"
                style={{ display: this.props.isPinned ? "" : "none" }}
              >
                {LocationLabel}
              </span>
            </div>
            {this.props.isAppraiser &&
              this.props.isPropertyOwnerPinned &&
              this.props.latitude.toString().trim().length == 0 &&
              this.props.longitude.toString().trim().length == 0 && (
                <div className="street-view">
                  <button
                    onClick={() => {
                      this.toggleLocationNotShared();
                      !this.state.locationNotShared &&
                        this.props.handleLocationNotshared();
                    }}
                  >
                    <i className="icon-help"></i>
                  </button>
                  {this.state.locationNotShared && (
                    <div className="street-drop tooltip">
                      <button>
                        {t("WEB_LABELS.Ask_PO_to_Turn_on_Location")}
                      </button>
                    </div>
                  )}
                </div>
              )}
            <div
              style={{
                display:
                  isLocationAvailable &&
                  this.props.isPinned &&
                  this.props.isCurrentUserAppraiserOrAssistant
                    ? this.props.isOwner
                      ? "inline-block"
                      : "none"
                    : "none",
              }}
              className="street-view"
            >
              <button
                onClick={() => {
                  this.props.toggleStreetViewOption();
                }}
              >
                <i className="icon-more-vertical"></i>
              </button>
              <div
                style={{
                  display: this.props.isStreetViewOptionVisible
                    ? "block"
                    : "none",
                }}
                className="street-drop"
              >
                <button
                  onClick={() => {
                    this.props.toggleStreetViewMap();
                  }}
                >
                  {t("BUTTONS.Street_View")}
                </button>
              </div>
            </div>
          </div>
          {this.state.remoteVideoTracks.length > 0 &&
            this.state.remoteVideoTracks[0].isEnabled == true &&
            !hidepinnedView && (
              <>
                <div className="pin-video-share">
                  <RBAC
                    action={pinned_view}
                    yes={
                      <Tooltip
                        title={
                          this.props.isPinned
                            ? t("TOOLTIP.Unpin_Video")
                            : t("TOOLTIP.Pin_Video")
                        }
                        arrow
                      >
                        <button
                          onClick={() => {
                            this.props.startWaitForacknowledgement();
                            this.props.setTimeoutForAcknowledgement();
                            if (this.props.isPinned) {
                              this.props.clearPinnedViewandBroadCast();
                            } else {
                              this.props.setPinnedViewandBroadCast(
                                remoteParticipant.sid
                              );
                            }
                          }}
                          disabled={this.props.waitForAcknowledgement}
                        >
                          <i className={classForPinnedicon}></i>
                        </button>
                      </Tooltip>
                    }
                  />

                  {this.props.isAppraiser && (
                    <RBAC
                      action={switch_camera}
                      yes={
                        <Tooltip
                            title={t("TOOLTIP.Switch_Camera")}
                            arrow
                            className={{
                              popper: this.props.switchCameraParticipantSid.includes(
                                remoteParticipant.sid
                              )
                                ? "disabled-tooltip"
                                : "",
                            }}
                            open={this.state.isSwitchCameraTooltipOpen}
                          >
                          <button
                            onClick={() => {
                              this.props.handleSwitchCamera(
                                remoteParticipant.sid
                              );
                              this.setState({
                                isSwitchCameraTooltipOpen: !this.state
                                  .isSwitchCameraTooltipOpen,
                              });
                            }}
                            disabled={this.props.switchCameraParticipantSid.includes(
                              remoteParticipant.sid
                            )}
                            onMouseOver={() =>
                              this.setState({ isSwitchCameraTooltipOpen: true })
                            }
                            onMouseOut={() =>
                              this.setState({
                                isSwitchCameraTooltipOpen: false,
                              })
                            }
                          >
                            <i className="icon-flip-camera"></i>
                          </button>
                        </Tooltip>
                      }
                    />
                  )}

                  {/* <RBAC
                    action={start_appraiser_measurement}
                    yes={
                      <Tooltip title={"Screenshare"} arrow>
                        <button
                          className={classnames({
                            active: !!this.props
                              .isRemoteParticipantScreenShareOn,
                          })}
                          onClick={() => {
                            // if (this.props.isPinned) {
                            //   this.props.clearPinnedScreenshareandBroadCast();
                            // } else {
                            this.props.setPinnedScreenshareandBroadCast(
                              remoteParticipant.sid
                            );
                            // }
                          }}
                        >
                          <i className="icon-share-screen"></i>
                        </button>
                      </Tooltip>
                    }
                  /> */}
                </div>
              </>
            )}

          <div
            className={classnames("video-img", {
              "live-captured-stream":
                this.props.ispinnedViewActive &&
                this.props.captureImagePanel &&
                !this.props.isPointerStarted,
              "captured-stream-active":
                this.props.ispinnedViewActive &&
                this.props.captureImagePanel &&
                this.props.isParticipantImageCaptured &&
                !this.props.isPointerStarted,
            })}
          >
            {remoteUserVideoDisplay}
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(ParticipantVideoView);