import React from "react";
import "./zoomoption.scss";
import { appConstants } from "./../../constants/app.constants";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
const ZOOM_LEVEL = appConstants.ZOOM_LEVEL;
const ZoomOptions = ({
  updateZoomLevel,
  isScaleZoomVisible,
  zoomLevel,
  isSavedImagesFullViewVisible,
  isAppraiserOrAssistant,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={classnames("zoom_outer", {
          open: isSavedImagesFullViewVisible && isAppraiserOrAssistant,
        })}
        style={{ display: isScaleZoomVisible ? "block" : "none" }}
      >
        <span className="zoom-level">{t("WEB_LABELS.Zoom_Level")}</span>
        <div className="zoom_main">
          <div className="level">
            <label>
              <input
                type="radio"
                name="zoomlevel"
                value={ZOOM_LEVEL.ZOOM_LEVEL_3}
                onClick={() => updateZoomLevel(ZOOM_LEVEL.ZOOM_LEVEL_3)}
                checked={zoomLevel == ZOOM_LEVEL.ZOOM_LEVEL_3}
              />
              <span>3X</span>
            </label>
          </div>

          <div className="level">
            <label>
              <input
                type="radio"
                name="zoomlevel"
                value={ZOOM_LEVEL.ZOOM_LEVEL_2}
                className={
                  zoomLevel == ZOOM_LEVEL.ZOOM_LEVEL_2 ? "checked" : ""
                }
                onClick={() => updateZoomLevel(ZOOM_LEVEL.ZOOM_LEVEL_2)}
                checked={zoomLevel == ZOOM_LEVEL.ZOOM_LEVEL_2}
              />
              <span>2X</span>
            </label>
          </div>

          <div className="level">
            <label>
              <input
                type="radio"
                name="zoomlevel"
                value={ZOOM_LEVEL.ZOOM_LEVEL_1}
                className={
                  zoomLevel == ZOOM_LEVEL.ZOOM_LEVEL_1 ? "checked" : ""
                }
                onClick={() => updateZoomLevel(ZOOM_LEVEL.ZOOM_LEVEL_1)}
                checked={zoomLevel == ZOOM_LEVEL.ZOOM_LEVEL_1}
              />
              <span>1X</span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default ZoomOptions;
