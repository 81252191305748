import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { JSEncrypt } from "jsencrypt";

import {
  dataExistInTable,
  deleteTableFromDB,
  retrieveCallDetails,
  storeCallDetails,
} from "./storeIndexedData";
import IndependentOfflinePage from "./independentOfflinePage";
import ScreenRecorder from "./ScreenAndVideoRecord";
import SpinLoader from "./SpinLoader";
import {
  getVideoCallToken,
  getVideoCallOfflineToken,
} from "../../actions/videoCallAction";
import { fetchToken } from "../../actions/authenticationAction";
import { history, StorageUtil } from "./../../helpers";
import { publicKey } from "../VideoCall/shortCallLink";
import { loadBookmark } from "../../actions/bookmarkAction";
import { LocalStorage } from "../../helpers/LocalStorage";
import { getCurrentLocation } from "../../helpers/geoLocation";
import OfflineDataManageJo from "./offlineDataManageJo";
import OfflineLocationCheckPage from "./offlineLocationCheckPage";
// Constant
const PO_OFFLINE = "PO Offline";

const OfflineAuth = (props) => {
  const [callData, setCallData] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [continueInspection, setContinueInspection] = useState(false);
  const { inviteUUID } = useParams();
  const [locationAllowed, setLocationAllowed] = useState(false);
  const [isScreenRecorderVisible, setIsScreenRecorderVisible] = useState(false);

  const encryptLink = () => {
    let RSAEncrypt = new JSEncrypt();
    RSAEncrypt.setPublicKey(publicKey);
    return RSAEncrypt.encrypt(inviteUUID);
  };
  const handleLocationPermission = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // User allowed location access
          setLocationAllowed(true);
          setTimeout(() => {
            setIsLoader(false);
          }, 2000);
          // You can also access the user's position data here: position.coords.latitude and position.coords.longitude
        },
        (error) => {
          // User denied location access or there was an error
          setLocationAllowed(false);
          setTimeout(() => {
            setIsLoader(false);
          }, 2000);
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.log("Geolocation is not available in this browser.");
    }
  };
  useEffect(() => {
    // If directly open by offline link
    async function getCallDetails() {
      try {
        const callDetails = await retrieveCallDetails();
        const callID = callDetails?.[0]?.callId;
        const shortLink = callDetails?.[0]?.shortLink;
        handleLocationPermission();
        if (shortLink !== inviteUUID) {
          (async () => {
            try {
              const dbExists = await new Promise((resolve) => {
                dataExistInTable("videos").then((exists) => {
                  resolve(exists);
                });
              });

              if (dbExists) {
                setIsDataAvailable(true);
                setTimeout(() => {
                  setIsLoader(false);
                }, 2000);
              }
            } catch (error) {
              // Handle any errors that might occur
              console.error("Error checking data existence:", error);
            }
          })();
        }

        if ((!callID || continueInspection) && locationAllowed) {
          if (inviteUUID && inviteUUID.length == 8) {
            StorageUtil.clearAll();
            let encryptedPass = encryptLink();

            const data = await props.fetchToken(encryptedPass, history);
            // Get Query Params
            const a = () =>
              new URLSearchParams(data?.originalUrl?.split("?")[1]);
            const query = a();

            // Call UUID
            const splittedData = data?.originalUrl?.split("?")[0]?.split("/");
            const callUUID = splittedData[splittedData.length - 1];
            let requestData = {
              identity: query.get("name") || PO_OFFLINE,
              inviteUUID: callUUID,
            };
            // const videoCallDetail = await props.getVideoCallToken(requestData);
            const videoCallDetail = await props.getVideoCallOfflineToken(
              requestData
            );
console.log("VideoCallDetail => " ,videoCallDetail )
            if (videoCallDetail) {
              let longitude, latitude;
              // Store into localstorage
              StorageUtil.setCallUUID(videoCallDetail?.callUUID);
              StorageUtil.setRole(videoCallDetail?.role);

              LocalStorage.setItem("shortLink", inviteUUID);
              LocalStorage.setItem("token", data?.loginUser?.jwtToken);
              LocalStorage.setItem("userName", videoCallDetail?.name);
              // Location Permission
              getCurrentLocation()
                .then((position) => {
                  latitude = position.coords.latitude;
                  longitude = position.coords.longitude;

                  let callDetails = {
                    callId: videoCallDetail?.callUUID,
                    token: data?.loginUser?.jwtToken,
                    jobOrderId: videoCallDetail?.jobOrderId,
                    latitude: latitude,
                    longitude: longitude,
                    shortLink: inviteUUID,
                  };
                  storeCallDetails(callDetails);

                  setTimeout(() => {
                    setIsLoader(false);
                  }, 2000);
                })
                .catch((err) => console.log("--err---", err));

              await props.fetchBookmark(videoCallDetail?.callUUID);
            }
          }
        } else {
          setTimeout(() => {
            setIsLoader(false);
            setCallData(callDetails?.[0]);
          }, 2000);
        }
      } catch (err) {
        console.log("----err---", err);
      }
    }
    getCallDetails();
  }, [continueInspection, locationAllowed]);

  if (isScreenRecorderVisible) {
    return <ScreenRecorder openSidebar={isScreenRecorderVisible} />;
  }
  if (isLoader) {
    return <SpinLoader />;
  } else if (!locationAllowed) {
    return <OfflineLocationCheckPage />;
  } else if (isDataAvailable) {
    return (
      <OfflineDataManageJo
        continue={() => {
          deleteTableFromDB(["videos", "images"]);
          setIsDataAvailable(false);
          setContinueInspection(true);
        }}
        clickNo={() => {
          setIsScreenRecorderVisible(true);
          setIsDataAvailable(false);
        }}
      />
    );
  } else if (!isLoader && callData && callData.callId) {
    return <ScreenRecorder />;
  } else {
    return <IndependentOfflinePage />;
  }
};

const mapDispatchToProps = (dispatch) => ({
  // getVideoCallToken: (requestData) => dispatch(getVideoCallToken(requestData)),
  getVideoCallOfflineToken: (requestData) =>
    dispatch(getVideoCallOfflineToken(requestData)),
  fetchToken: (data, history) => dispatch(fetchToken(data, history)),
  fetchBookmark: (data) => dispatch(loadBookmark(data)),
});

export default connect(null, mapDispatchToProps)(OfflineAuth);
