export const TRANSLATIONS_Spanish = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired:
      "La sesión de videollamada segura caducó",
    Secure_Video_Call_Ended: "Videollamada segura finalizada",
    Thank_you_for_connecting_with_us: "¡Gracias por conectar con nosotros!",
    Connected_Waiting_for_Other_Participants:
      "Conectado. Esperando a otros participantes ...",
    Powered_by: "Energizado por",
    Connecting: "Conectando ...",
    Local_Pike: "Lucio local",
    Would_you_like_to_save_image: "¿Le gustaría guardar la imagen?",
    Message: "Mensaje",
    Location_Not_shared: "Ubicación: no compartida",
    Location: "Localización",
    Tools: "Instrumentos",
    Ask_PO_to_Turn_on_Location:
      "Pídale al dueño de la propiedad que habilite la ubicación",
    Preparing_image_for_markup: "Preparando imagen para marcado ...",
    Download_Message: "Descargar la aplicación de evaluación remota",
    Disclaimer:
      "Es necesario descargar la aplicación RemoteVal de la App Store. Una vez que se complete la descarga, vuelva a abrir su invitación de mensaje de texto / correo electrónico y haga clic en el enlace una vez más para unirse automáticamente a la llamada.",
    MeasurementLinkDisclaimer:
      "Es necesario descargar la aplicación RemoteVal de la App Store. Una vez que se complete la descarga, vuelva a abrir su mensaje de texto / correo electrónico de invitación y haga clic en el enlace una vez más para iniciar automáticamente la inspección.",
    Zoom_Level: "Nivel del zoom",
    enter_cell_phone: "entrar celular",
    Email: "Correo electrónico",
    Cell_Phone: "Teléfono móvil",
    Participant_Name: "Nombre del participante",
    Invite_Participant: "Invitar participante",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "Únase a la videollamada segura haciendo clic en el siguiente enlace:",
    There_are_no_Attachments_available: "No hay archivos adjuntos disponibles.",
    Drop_Or_Select_Your_File: "Suelta o selecciona tu archivo",
    Attach_Document: "Documento adjunto",
    Enter_Your_Name: "Introduzca su nombre",
    I_confirm_that_I_have_read_and_agree_to_the:
      "Confirmo que he leído y acepto las",
    Search_or_Add_Captured_Image_Label:
      "Buscar o agregar etiqueta de imagen capturada",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "Presione Entrar o haga clic en Agregar nueva etiqueta a la imagen capturada con nueva etiqueta",
    To: "Para",
    Everyone: "Todo el mundo",
    Type_a_message: "Escribe un mensaje",
    Are_you_sure_you_want_to_delete: "Estas seguro que quieres borrarlo ?",
    Room_is_full_Please_try_again_later:
      "Cuarto lleno. Por favor, inténtelo de nuevo más tarde",
    Comment: "Comentario",
    Room_2_Measurement: "Medida de la habitación 2",
    Room_1_Measurement: "Medida de la habitación 1",
    Room_2_Image: "Imagen de la habitación 2",
    Room_1_Image: "Imagen de la habitación 1",
    No_of_Rooms: "Número de habitaciones",
    Property_Type: "tipo de propiedad",
    House: "casa",
    Inspection_Form: "Formulario de inspección",
    Instructions: "Instrucciones",
    Guide_user_to_position_camera_on_the_target:
      "Guiar al usuario para colocar la cámara en el objetivo",
    Click_on_the_Label_once_to_capture_labeled_image:
      "Haga clic en la etiqueta una vez para capturar la imagen etiquetada.",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "Seleccione el piso de la casa, el tipo de habitación y el nombre de la habitación.",
    Choose_GLA_or_Non_GLA: "Elija GLA o No GLA.",
    Click_on_the_Start_Measurement_button:
      "Haga clic en el botón Iniciar medición",
    Click_the_Auto_Corner_Detection_to_On_Off:
      'Haga clic en "Detección automática de esquinas" para activar / desactivar',
    Position_vertical_Green_Floor:
      "Coloque el marcador de piso verde vertical en una esquina de la habitación y asegúrese de que toque el piso.",
    App_will_auto_mark_a_corner:
      'La aplicación marcará automáticamente una esquina o tocará el botón "Capturar esquina manualmente"',
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "Guíe al usuario para que camine hasta cada rincón de la habitación para marcar cada rincón",
    When_last_line_connects:
      'Cuando la última línea se conecte al punto de partida, se volverá roja. La aplicación se conectará automáticamente y completará el boceto o puede usar el botón "Conectar esquina y terminar boceto" para completar la medición.',
    button_to_view_final_sketch:
      'Haga clic en el botón "Mostrar boceto de plano de planta terminado" para ver el boceto final.',
    Are_You_Sure_You_Want_to_Leave_This_Call:
      "¿Está seguro de que desea dejar esta llamada?",
    Street_View: "vista de calle",
    Select_Measurement_Type: "Seleccione el tipo de medida",
    Floor: "Suelo",
    Ceiling: "Techo",
    View_Sketches_by_Floor: "Ver bocetos por piso",
    Uploading_Image: "Subiendo imagen",
    Call_Setting: "Ajuste de llamada",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "Permita el acceso a su micrófono. Haga clic en el candado",
    to_the_left_of_the_address_bar:
      "a la izquierda de la barra de direcciones.",
    Speakers: "Altavoces",
    Allow_access_to_your_Camera_Click_the_padlock:
      "Permita el acceso a su cámara. Haga clic en el candado",
    to_the_address_bar: "a la barra de direcciones.",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "No se pudo iniciar la cámara de video. Seleccione otra cámara de video.",
    Title: "Título",
    New_Note: "Nueva nota...",
    There_are_no_Notes_available: "No hay notas disponibles.",
    Are_you_sure_you_want_to_delete_this_note:
      "¿Estás seguro que quieres eliminar esta nota?",
    Network_Type: "Tipo de red",
    Netword_Strength: "Fuerza de la red",
    To_share_live_video:
      "Para compartir video en vivo. Active el permiso de la cámara en la configuración de su navegador y luego",
    click_here: "haga clic aquí",
    to_reload_the_page: "para recargar la página.",
    we_cant_access_your_microphone:
      "no podemos acceder a su micrófono. Para dar acceso, vaya a la configuración de su navegador y luego",
    Questionnaire_Form: "Formulario de cuestionario",
    No_images_found: "¡No se encontraron imágenes!",
    Image: "Imagen",
    Captured_Images: "Imágenes capturadas",
    Share_your_screen: "Compartir tu pantalla",
    Share_your_live_video: "¿Comparte su video en vivo?",
    This_will_stop_your_video: "Esto detendrá su video. ¿Quieres continuar?",
    This_will_stop_screen_share:
      "Esto detendrá la pantalla compartida. ¿Quieres continuar?",
    Room_Area_Label: "Etiqueta de habitación / área",
    Room_Area_Name:
      "Nombre de la habitación / área (por ejemplo, dormitorio 1, baño 2, etc.)",
    Room_Area_Name_Placeholder: "Nombre de la habitación / área",
    You: "usted",
    Are_you_sure_you_want_to_delete_captured_image:
      "¿Está seguro de que desea eliminar la imagen capturada?",
    Start_Over:
      "Empiece de nuevo: ¿está seguro de que desea eliminar los planos de planta?",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "¿Está seguro de que desea salir del proceso de medición?",
    Labels: "Etiquetas",
    Select_Language: "Seleccione el idioma",
    This_call_is_being_recorded: "Esta videollamada se está grabando",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "Su tasador está grabando esta llamada para su archivo de trabajo privado.",
    It_will_not_be_shared: `No se compartirá con ninguna parte externa, incluida su hipoteca.
    prestamista, y su tasador no podrá descargarlo del
    Sitio de RemoteVal. Se utilizará únicamente para que el tasador complete
    su tasación.`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "Si no desea que se grabe su pantalla, informe a su tasador ahora.",
    Measurement: "Medición",
    Create_Measurement: "Crear medida",
    Fencing: "Esgrima",
    Show: "Show",
    Hide: "Esconder",
    Live_Floor_Plan: "Plano de planta en vivo",
    Expand: "Expandir",
    Collapse: "Colapso",
    Instruction_for_Call_Settings:
      "Instrucciones para la configuración de llamadas",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      'El altavoz, el micrófono y la cámara se pueden probar a través de la configuración de llamadas desde el menú "más"',
    To_Check_the_call_settings_now_click_on_the_below_button:
      "Para comprobar la configuración de llamadas, haga clic en el botón de abajo.",
    Call_recording_by_virtual_inspector:
      "Su inspector virtual está grabando esta videollamada para su archivo de trabajo privado y para fines de auditoría interna por parte de la organización que lo solicitó. No se compartirá con ninguna parte externa.",
    Call_and_Screen_Recording_Opt_Out:
      "Si no desea su pantalla y esta llamada para ser grabada, informe a su inspector virtual ahora.",
    Test_Audio_Video_Components_Button:
      "El altavoz, el micrófono y la cámara se pueden probar haciendo clic en el botón de abajo.",
  },
  BUTTONS: {
    Re_Connect: "Vuelva a conectar",
    End_Session: "Finalizar sesión",
    Chat: "Chat",
    Participants: "Participantes",
    Notes: "Notas",
    Attach_Document: "Documento adjunto",
    Questionnaire: "Cuestionario",
    Labels: "Etiquetas",
    Pointer: "Puntero",
    Markup: "Margen",
    Flash_Off: "Flash apagado",
    Flash_On: "Parpadeará en",
    Call_Recording: "Grabacion de llamada",
    Capture_Image: "Capturar imagen",
    Screen_Share: "Compartir pantalla",
    Stop_Screen_Share: "Detener pantalla compartida",
    Call_Settings: "Ajustes de llamada",
    Clear_All: "Limpiar todo",
    Exit: "Salida",
    Save: "Ahorrar",
    Close: "Cerrar",
    Save_and_Exit: "Guardar y Salir",
    Continue: "Continuar",
    Street_View: "vista de calle",
    Open_in_App: "Abrir en la aplicación",
    Privacy_Policy: "Política de privacidad",
    Invite: "Invitación",
    Join: "Entrar",
    Add_Captured_Image_Label: "Agregar etiqueta de imagen capturada",
    No: "No",
    Yes: "sí",
    Delete: "Borrar",
    Cancel: "Cancelar",
    RETRY: "REVER",
    OK: "OK",
    Back: "atrás",
    Leave_Call: "Dejar llamada",
    Got_It: "Entendido",
    Start_New_Inspection: "Iniciar nueva inspección",
    Resume_Existing_Inspection: "Reanudar la inspección existente",
    View_All_Current_Sketch: "Ver todos los bocetos actuales",
    Open_Sketch: "Abrir boceto",
    Auto_Corner_Detection_ON: "Detección automática de esquinas activada",
    Auto_Corner_Detection_OFF: "Detección automática de esquinas desactivada",
    Manually_Capture_Corner: "Capturar manualmente la esquina",
    Undo_Last_Corner_Capture: "Deshacer la captura de la última esquina",
    Connect_Corner_Finish_Sketch: "Conectar esquina y terminar boceto",
    Capture_3D_Room_Height: "Captura de la altura de la habitación en 3D",
    Set_Fixed_3D_Room_Height: "Establecer la altura fija de la habitación 3D",
    Capture_Door: "Puerta de captura",
    Set_Door: "Establecer puerta",
    Show_Finished_Floor_Plan_Sketch:
      "Mostrar boceto de plano de planta terminado",
    Back_to_Previous_Screen: "Volver a la pantalla anterior",
    Add_Room: "Agregar habitación",
    Publish_the_floor_plan: "Publicar plano de planta",
    Exit_Return_to_Inspection: "Salida y regreso a inspección",
    Audio: "Audio",
    Video: "Video",
    Microphone: "Micrófono",
    Microphone_not_found: "Micrófono no encontrado",
    Microphone_Blocked: "Micrófono bloqueado",
    Default: "Defecto",
    Grant_Permission: "Conceder permiso",
    Speaker_Blocked: "Altavoz bloqueado",
    Play_Test_Sound: "Reproducir sonido de prueba",
    Preview: "Avance",
    Camera: "Cámara",
    Camera_not_found: "Cámara no encontrada",
    Camera_Blocked: "Cámara bloqueada",
    Done: "Hecho",
    View: "Vista",
    Capture_Labeled_Images: "Capturar imágenes etiquetadas",
    Capture_Measurements: "Capturar medidas",
    Instructions: "Instrucciones",
    Start_Measurement_Process: "Iniciar proceso de medición",
    Flash_Light_Not_Supported:
      "La linterna remota no es compatible con el dispositivo invitado o no está listo",
  },
  TOOLTIP: {
    Mute_My_Audio: "Silenciar mi audio",
    Unmute_My_Audio: "Activar el sonido de mi audio",
    Stop_My_Video: "Detener mi video",
    Start_My_Video: "Iniciar mi video",
    Capture_Images_with_No_Labels: "Capture imágenes sin etiquetas",
    Start_Inspection: "Iniciar inspección",
    Chat: "Chat",
    More_Options: "Mas opciones",
    End_Call: "Finalizar llamada",
    Unpin_Video: "Desanclar video",
    Pin_Video: "Pin de vídeo",
    Add_New_Note: "Agregar nueva nota",
    Refresh: "Actualizar",
    View_More: "Ver más",
    Invite_Participant: "Invitar participante",
    Delete_Image: "Eliminar Imagen",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "La invitación se envió correctamente",
    IOS_SAFARI_HELP:
      "Para obtener los mejores resultados, recomendamos utilizar Safari",
    UNEXPECTED_ERROR:
      "Ocurrieron errores inesperados. Inténtelo de nuevo más tarde.",
    CALL_LINK_NOT_FOUND: "No se encuentra el enlace de llamada.",
    CREATE_BOOKMARK_MESSAGE: "Pídale al administrador que agregue un marcador",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED:
      "El tamaño del archivo debe ser inferior a 10 MB.",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT: "Formato de archivo no soportado",
    LIVE_POINTER_STARTED:
      "Puntero en vivo iniciado. Puede mover el puntero arrastrando.",
    LIVE_POINTER_STOPPED: "Puntero en vivo detenido",
    APPRAISAL_EXIT_APPROVED: "Salida reconocida con éxito.",
    APPRAISAL_EXIT_DENIED: "Salida denegada.",
    MARKUP_CLOSED: "El marcado está detenido",
    PRIVACY_POLICY_FAIL:
      "No se ha encontrado una URL válida de política de privacidad.",
    SWITCH_CAMERA_REQUEST_REJECTED:
      "El propietario de la casa no compartió la vista de cámara solicitada.",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email:
      "Por favor seleccione teléfono celular o correo electrónico",
    This_field_is_required: "Este campo es obligatorio",
    Cell_Phone_Must_Be_Valid: "El teléfono celular debe ser válido",
    Name_is_Required: "Se requiere el nombre",
    Name_Must_Be_Valid: "El nombre debe ser válido",
    Invalid_Text: "Texto inválido",
    Email_Must_Be_Valid: "El email debe ser válido",
    Name_must_be: "El nombre debe ser",
    characters_max: "caracteres como máximo",
    Please_select_the_Floor: "Seleccione el piso",
    Please_enter_the_Room_Area_Name:
      "Ingrese el nombre de la habitación / área",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "Necesita usar HTTPS para seleccionar el dispositivo de salida de audio",
    Something_went_wrong:
      "Algo salió mal. Por favor, inténtelo de nuevo más tarde.",
    Oops_Call_duration_is_over_and_session_is_ended:
      "¡UPS! La duración de la llamada finaliza y la sesión finaliza.",
    No_participant_has_joined_yet_Ending_the_call_session:
      "¡Ningún participante se ha unido todavía! Finalizar la sesión de llamada",
    You_are_offline_Check_your_internet_connection:
      "Estas desconectado. Comprueba tu conexión a Internet.",
  },
  starting_inspection: "Inspección inicial",
  starting_measurement: "Medición inicial",
  error: "Error",
  contact_us: "Contáctenos",
  support_popup_msg:
    "Vaya, se ha producido un error. Inténtalo de nuevo más tarde o informa un problema a nuestro equipo de asistencia.",
  select_po: "Seleccione el propietario para iniciar el proceso de inspección",
  request_po_for_camera:
    "Solicite al propietario de la propiedad que encienda la vista de la cámara para iniciar el proceso de inspección.",
  wait_until_po_joins:
    "Espere hasta que el propietario de la propiedad se una a la llamada para iniciar el proceso de inspección.",
  select_measurement_method: "Seleccione el método de medición",
  inspection_inprogress: "La inspección ya está en marcha",
  model_msg_retry:
    "No se pudo establecer un canal de comunicación con el propietario de la propiedad. Vuelva a intentarlo.",
  model_msg_leaveCall:
    "Parece que hay un problema de conectividad a Internet en ambos lados, así que desconecte la llamada actual y vuelva a conectarla.",
  please_wait: "Espere por favor...",
  loading_sketch: "Cargando croquis...",
  connection_timeout_msg:
    "Se ha agotado el tiempo de conexión. Ha ocurrido algo inusual y está tardando más de lo esperado en responder. Inténtalo de nuevo.",
  previous_measurement_prblm_msg:
    "Ops, algo salió mal con la medición anterior. Vuelva a intentarlo con el nuevo proceso de medición.",
  measurement_retry_msg:
    "El proceso de medición no ha comenzado. Por favor, intenta de nuevo.",
  opensketch_retry_msg:
    "El proceso de Open Sketch no ha comenzado. Por favor, intenta de nuevo.",
};
