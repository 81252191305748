import React from "react";

export default function OfflineLocationCheckPage() {
  return (
    <div className="live-stream permisstion-required ">
      <div className="video-audio-permission">
        <div className="inner-video-permission">
          <div className="permission-icons">
            <i className="icon-location"></i>
          </div>
          <h1>Permission Required</h1>

          <p>
            To access certain features, please enable location permissions in
            your device settings.
          </p>
        </div>
      </div>
    </div>
  );
}
