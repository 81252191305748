import React, { Component } from "react";
import "./prpearingForMarkup.scss";
import { useTranslation } from "react-i18next";
export default function PrpearingForMarkup(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="markup-preparing">
        <div className="box">
          <div className="img">
            <i className="icon-preparing-markup"></i>
          </div>
          <h4>{t("WEB_LABELS.Preparing_image_for_markup")}</h4>
        </div>
      </div>
    </>
  );
}
