import React, { Component } from "react";
import "./bookmarkLabelsForAppraisal.scss";
import { connect } from "react-redux";
import { createBookmark } from "../../../actions/bookmarkAction";
import { CREATE_BOOKMARK_MESSAGE } from "../../../constants/commonStrings";

import * as _ from "lodash";
import { validateTextLength } from "../../../helpers";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
class BookmarkLabelsForAppraisal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      bookmarks: [],
      bookmarkCountByName: {},
    };
  }

  bookmarkLabelSorter = (a, b) =>
    _.get(a, "bookmarkLabel", "").localeCompare(_.get(b, "bookmarkLabel", ""));

  componentDidMount() {
    this.setState({
      bookmarks: [...this.props.bookmarks].sort(this.bookmarkLabelSorter),
    });
    this.updateBookmarkCount();
  }

  searchFilter = (e) =>
    _.get(e, "bookmarkLabel", "")
      .toLowerCase()
      .includes(this.state.search.toLowerCase());

  getFilteredBookmarks = (search) => {
    return search && search.length > 0
      ? [...this.props.bookmarks].filter(this.searchFilter)
      : this.props.bookmarks;
  };

  updateBookmarkCount = () => {
    const { images } = this.props;
    const bookmarkCountByName = _.countBy(images, (image) => {
      return _.get(image, "bookmarkLabel", "").toLowerCase();
    });
    this.setState({
      bookmarkCountByName,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.bookmarks !== prevProps.bookmarks) {
      const { search } = this.state;
      const bookmarks = this.getFilteredBookmarks(search).sort(
        this.bookmarkLabelSorter
      );
      this.setState({
        bookmarks,
      });
    }
    if (this.props.images != prevProps.images) {
      this.updateBookmarkCount();
    }
  }

  handleSearch(e) {
    let search = e.target.value;

    let { value: val, error } = validateTextLength(search, 30);
    search = val;

    const bookmarks = this.getFilteredBookmarks(search);
    this.setState({
      search,
      bookmarks,
      error,
    });
  }

  captureBookmark(bookmarkLabel, bookmarkId) {
    this.props.captureImage({ bookmarkLabel, bookmarkId });
  }

  IfBookmarkNotExist(search) {
    const isBookmarkExist = this.state.bookmarks.filter(
      (availableBookMarks) => {
        return (
          _.get(availableBookMarks, "bookmarkLabel", "")
            .trim()
            .toLowerCase() === search.trim().toLowerCase()
        );
      }
    );
    return isBookmarkExist.length ? false : true;
  }

  onAddBookmark() {
    if (this.state.search.trim()) {
      if (
        _.get(
          this.props,
          ["me", "configs", "settings", "create_bookmark"],
          true
        )
      ) {
        this.captureBookmark(this.state.search, "");
        this.setState({
          search: "",
          bookmarks: this.props.bookmarks,
        });
      } else {
        alert(i18n.t("COMMON_STRING.CREATE_BOOKMARK_MESSAGE"));
        const bookmarks = this.getFilteredBookmarks("");
        this.setState({
          search: "",
          bookmarks,
        });
      }
    }
  }

  render() {
    const { search, bookmarks, bookmarkCountByName, error } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="search-view">
          <input
            type="search"
            className="form-control"
            name=""
            placeholder={t("WEB_LABELS.Search_or_Add_Captured_Image_Label")}
            value={search}
            onChange={(e) => this.handleSearch(e)}
            onKeyPress={(e) => {
              if (e.key === "Enter" && this.IfBookmarkNotExist(search)) {
                this.onAddBookmark();
              }
            }}
          />
          {(!search || search.length === 0) && (
            <button className="search">
              <i className="icon-search"></i>
            </button>
          )}
          <div className="text-danger">{t(error)}</div>
          {search && search.length > 0 && this.IfBookmarkNotExist(search) && (
            <button
              className="create-book"
              onClick={() => {
                this.onAddBookmark();
              }}
            >
              {t("BUTTONS.Add_Captured_Image_Label")}: ‘{search}’
            </button>
          )}
        </div>
        <div
          className={classnames("bookmark-list scroll-bar-style", {
            active:
              search && search.length > 0 && this.IfBookmarkNotExist(search),
          })}
        >
          {!bookmarks || (bookmarks && bookmarks.length === 0) ? (
            <div className="no-bookmark">
              <p>
                {t(
                  "WEB_LABELS.Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label"
                )}
              </p>
            </div>
          ) : (
            <ul>
              {bookmarks.map((bookmark, i) => (
                //
                <li key={i}>
                  <button
                    onClick={() => {
                      this.setState({
                        search: "",
                        bookmarks: this.props.bookmarks,
                      });
                      this.captureBookmark(
                        bookmark.bookmarkLabel,
                        bookmark.bookmarkId
                      );
                    }}
                  >
                    {bookmark.bookmarkLabel}{" "}
                    <span>
                      {
                        bookmarkCountByName[
                          bookmark.bookmarkLabel.toLowerCase()
                        ]
                      }
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addBookmark: (data) => dispatch(createBookmark(data)),
});

const mapStateToProps = ({ bookmarkView, me }) => ({
  bookmarks: bookmarkView.bookmarks || [],
  me: me,
});

const connectedBookMarkViewModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BookmarkLabelsForAppraisal));
export { connectedBookMarkViewModel as BookmarkLabelsForAppraisal };
