export const DETAIL_START = "DETAIL_START";
export const DETAIL_SUCCESS = "DETAIL_SUCCESS";
export const DETAIL_ERROR = "DETAIL_ERROR";

// Video call Token

export const GET_VIDEO_CALL_TOKEN_START = "GET_VIDEO_CALL_TOKEN_START";
export const GET_VIDEO_CALL_TOKEN_SUCCESS = "GET_VIDEO_CALL_TOKEN_SUCCESS";
export const GET_VIDEO_CALL_TOKEN_ERROR = "GET_VIDEO_CALL_TOKEN_ERROR";

export const INVITE_PARTICIPANT_START = "INVITE_PARTICIPANT_START";
export const INVITE_PARTICIPANT_SUCCESS = "INVITE_PARTICIPANT_SUCCESS";
export const INVITE_PARTICIPANT_ERROR = "INVITE_PARTICIPANT_ERROR";

export const LIST_PARTICIPANT_START = "LIST_PARTICIPANT_START";
export const LIST_PARTICIPANT_SUCCESS = "LIST_PARTICIPANT_SUCCESS";
export const LIST_PARTICIPANT_ERROR = "LIST_PARTICIPANT_ERROR";

export const UPDATE_PARTICIPATN_TRACK_STATUS =
  "UPDATE_PARTICIPATN_TRACK_STATUS";

export const ADD_CALL_PARTICIPANT = "ADD_CALL_PARTICIPANT";
export const UPDATE_CALL_PARTICIPANT = "UPDATE_CALL_PARTICIPANT";

export const REMOVE_CALL_PARTICIPANT = "REMOVE_CALL_PARTICIPANT";
export const CLEAR_CALL_PARTICIPANT = "CLEAR_CALL_PARTICIPANT";

/* Capture Images */

export const CAPTURE_IAMGE = "CAPTURE_IMAGE";
export const PROCESS_IMAGE_UPLOAD_QUEUE = "PROCESS_IMAGE_UPLOAD_QUEUE";
export const CAPTURE_IMAGE_UPLOAD_COMPLETED = "CAPTURE_IMAGE_UPLOAD_COMPLETED";
export const CAPTURE_IMAGE_UPLOAD_FAILED = "CAPTURE_IMAGE_UPLOAD_FAILED";
export const ADD_CAPTURE_IMAGE = "ADD_CAPTURE_IMAGE";
export const DELETE_CAPTURE_IMAGE = "DELETE_CAPTURE_IMAGE";
export const DEQUEUE_CAPTURE_IMAGE = "DEQUEUE_CAPTURE_IMAGE";
export const CLEAR_QUEUE = "CLEAR_QUEUE";
export const START_QUEUE = "START_QUEUE";
export const ADD_CACHED_CAPTURED_IMAGE = "ADD_CACHED_CAPTURED_IMAGE";

/* BookmarkViewModel */
export const ADD_BOOKMARK = "ADD_BOOKMARK";
export const GET_BOOKMARKS = "GET_BOOKMARKS";

/** update me */

export const UPDATE_LOCALUSER_ROLE = "UPDATE_LOCALUSER_ROLE";

export const START_LOAD_CALL_CONFIG = "START_LOAD_CALL_CONFIG";
export const LOAD_CALL_CONFIG_SUCCESS = "LOAD_CALL_CONFIG_SUCCESS";
export const LOAD_CALL_CONFIG_ERROR = "LOAD_CALL_CONFIG_ERROR";

export const START_LOAD_BOOKMARK = "START_LOAD_BOOKMARK";
export const LOAD_BOOKMARK_SUCCESS = "LOAD_BOOKMARK_SUCCESS";
export const LOAD_BOOKMARK_ERROR = "LOAD_BOOKMARK_ERROR";
export const START_CREATE_BOOKMARK = "START_CREATE_BOOKMARK";
export const CREATE_BOOKMARK_SUCCESS = "CREATE_BOOKMARK_SUCCESS";
export const CREATE_BOOKMARK_ERROR = "CREATE_BOOKMARK_ERROR";

/* Markup Images */

export const MARKUP_IAMGE = "MARKUP_IAMGE";
export const MARKUP_IMAGE_UPLOAD_COMPLETED = "MARKUP_IMAGE_UPLOAD_COMPLETED";
export const MARKUP_IMAGE_UPLOAD_FAILED = "MARKUP_IMAGE_UPLOAD_FAILED";

export const SEND_MESSAGE = "SEND_MESSAGE";

export const START_LOAD_NOTE = "START_LOAD_NOTE";
export const LOAD_NOTE_SUCCESS = "LOAD_NOTE_SUCCESS";
export const LOAD_NOTE_ERROR = "LOAD_NOTE_ERROR";
export const START_CREATE_NOTE = "START_CREATE_NOTE";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_ERROR = "CREATE_NOTE_ERROR";
export const START_DELETE_NOTE = "START_DELETE_NOTE";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_ERROR = "DELETE_NOTE_ERROR";
export const START_UPDATE_NOTE = "START_UPDATE_NOTE";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_ERROR = "UPDATE_NOTE_ERROR";

export const START_LOAD_ATTACHMENTS = "START_LOAD_ATTACHMENTS";
export const LOAD_ATTACHMENTS_SUCCESS = "LOAD_ATTACHMENTS_SUCCESS";
export const LOAD_ATTACHMENTS_ERROR = "LOAD_ATTACHMENTS_ERROR";
export const START_UPLOAD_ATTACHMENT = "START_UPLOAD_ATTACHMENT";
export const UPLOAD_ATTACHMENT_SUCCESS = "UPLOAD_ATTACHMENT_SUCCESS";
export const UPLOAD_ATTACHMENT_ERROR = "UPLOAD_ATTACHMENT_ERROR";
export const START_DELETE_ATTACHMENT = "START_DELETE_ATTACHMENT";
export const DELETE_ATTACHMENT_SUCCESS = "DELETE_ATTACHMENT_SUCCESS";
export const DELETE_ATTACHMENT_ERROR = "DELETE_ATTACHMENT_ERROR";
export const ATTACHMENT_RECEIVED_SUCCESS = "ATTACHMENT_RECEIVED_SUCCESS";
export const START_DOWNLOAD_ATTACHMENT = "START_DOWNLOAD_ATTACHMENT";
export const DOWNLOAD_ATTACHMENT_SUCCESS = "DOWNLOAD_ATTACHMENT_SUCCESS";
export const DOWNLOAD_ATTACHMENT_ERROR = "DOWNLOAD_ATTACHMENT_ERROR";

export const APPRAISAL_START_APPRAISAL = "APPRAISAL_START_APPRAISAL";
export const APPRAISAL_MEASUREMENT = "APPRAISAL_MEASUREMENT";
export const APPRAISAL_CORNER_MARKING = "APPRAISAL_CORNER_MARKING";
export const APPRAISAL_2D_FLOOR_PLAN = "APPRAISAL_2D_FLOOR_PLAN";
export const MEDIA_PERMISSIONS = "MEDIA_PERMISSIONS";
export const SELECT_CAMERA = "SELECT_CAMERA";
export const SELECT_MICROPHONE = "SELECT_MICROPHONE";
export const SELECT_SPEAKER = "SELECT_SPEAKER";

export const GET_MEASUREMENT_JSON_START = "GET_MEASUREMENT_JSON_START";
export const GET_MEASUREMENT_JSON_SUCCESS = "GET_MEASUREMENT_JSON_SUCCESS";
export const GET_MEASUREMENT_JSON_ERROR = "GET_MEASUREMENT_JSON_ERROR";

export const DELETE_FLOOR_PLAN_START = "DELETE_FLOOR_PLAN_START";
export const DELETE_FLOOR_PLAN_SUCCESS = "DELETE_FLOOR_PLAN_SUCCESS";
export const DELETE_FLOOR_PLAN_ERROR = "DELETE_FLOOR_PLAN_ERROR";

export const SET_CAPTURED_IMAGE_LABELS = "SET_CAPTURED_IMAGE_LABELS";
export const GET_QUESTIONNAIRE_START = "GET_QUESTIONNAIRE_START";
export const GET_QUESTIONNAIRE_SUCCESS = "GET_QUESTIONNAIRE_SUCCESS";
export const GET_QUESTIONNAIRE_ERROR = "GET_QUESTIONNAIRE_ERROR";

export const FETCH_TOKEN_START = "FETCH_TOKEN_START";
export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS";
export const FETCH_TOKEN_ERROR = "FETCH_TOKEN_ERROR";

export const OFFLINE_IMAGE_UPLOAD = "OFFLINE_IMAGE_UPLOAD";