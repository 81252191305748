import axios from "axios";
import AuthAPI from "./authApi";
import VideoCallAPI from "./videoCallApi";
import PictureAPI from "./pictureApi";
import MarkupAPI from "./markupApi";
import CallConfigAPI from "./callConfig";
import BookmarkAPI from "./bookmarkApi";
import NoteAPI from "./noteApi";
import AttachmentAPI from "./attachmentApi";
import QuestionnaireAPI from "./questionnaireApi";
import UploadOfflineDataAPI from "./uploadOfflineDataApi";
import i18n from "./../../translations/i18n";
import _ from "lodash";
import {
  dataExistInTable,
  retrieveCallDetails,
} from "../../components/VideoCallOffline/storeIndexedData";
import { customToast } from "../../helpers/customToast";
const BASEURL = process.env.REACT_APP_API_END_POINT;
let isToastExist = false;
const TIMEOUTFORUPLOADFILE = 1800000;
class API {
  __auth = new AuthAPI();
  __bookmark = new BookmarkAPI();
  __videoCall = new VideoCallAPI();
  __picture = new PictureAPI();
  __offlineUpload = new UploadOfflineDataAPI();
  __callConfig = new CallConfigAPI();
  __markup = new MarkupAPI();
  __note = new NoteAPI();
  __attachment = new AttachmentAPI();
  __questionnaire = new QuestionnaireAPI();
  api = axios.create({
    baseURL: BASEURL,
    transformRequest: [(data) => JSON.stringify(data)],
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  formsApi = axios.create({
    baseURL: BASEURL,
    timeout: TIMEOUTFORUPLOADFILE,
    headers: { "Content-Type": "multipart/form-data" },
  });

  get auth() {
    return this.__auth;
  }

  get videoCall() {
    return this.__videoCall;
  }

  get bookmark() {
    return this.__bookmark;
  }

  get picture() {
    return this.__picture;
  }
  get offlineUpload() {
    return this.__offlineUpload;
  }
  get markup() {
    return this.__markup;
  }

  get callConfig() {
    return this.__callConfig;
  }

  get note() {
    return this.__note;
  }

  get attachment() {
    return this.__attachment;
  }

  get questionnaire() {
    return this.__questionnaire;
  }

  get(url, ...args) {
    return this.sendRequestInternal(this.api.get, url, ...args);
  }

  post(url, ...args) {
    return this.sendRequestInternal(this.api.post, url, ...args);
  }

  patch(url, ...args) {
    return this.sendRequestInternal(this.api.patch, url, ...args);
  }

  delete(url, ...args) {
    return this.sendRequestInternal(this.api.delete, url, ...args);
  }

  put(url, ...args) {
    return this.sendRequestInternal(this.api.put, url, ...args);
  }

  postMultipartFormData(url, data, onUploadProgress) {
    return this.sendRequestInternal(
      (u, a) => this.formsApi.post(u, a, { onUploadProgress }),
      url,
      data
    );
  }

  async sendRequestInternal(requestFunc, url, ...args) {
    let token;
    token = localStorage.getItem("token");

    await dataExistInTable("videos").then((dbExists) => {
      if (dbExists) {
        retrieveCallDetails().then((CallDetails) => {
          if (token == undefined) {
            token = CallDetails?.[0]?.token;
          }
        });
      }
    });
    if (token) {
      this.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      this.formsApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    }
    return requestFunc(url, ...args)
      .then((response) => {
        isToastExist = false;
        return response;
      })
      .catch((error) => {
        const hideErrorToast = args?.[0]?.isToastMessageVisible;
        if (hideErrorToast == false) {
          isToastExist = true;
          return;
        }
        if (error.response) {
          if (
            _.get(error, ["response", "data", "code"], "") === 400 ||
            _.get(error, ["response", "data", "code"], "") === 404
          ) {
            const {
              response: { data },
            } = error;
            if (!isToastExist) {
              isToastExist = true;
              customToast.error(data.message);
            }
          } else if (
            _.get(error, ["response", "data", "code"], "") === 401 ||
            _.get(error, ["response", "data", "status"], "") === 401
          ) {
            const {
              response: { data },
            } = error;
            if (!isToastExist) {
              isToastExist = true;
              customToast.error(data.message);
            }
          } else if (_.get(error, ["response", "data", "code"], "") === 403) {
            window.location.href = "/unauthorized";
          } else if (
            _.get(error, ["response", "data", "code"], "") === 500 ||
            _.get(error, ["response", "data", "status"], "") === 500
          ) {
            const {
              response: { data },
            } = error;
            if (!isToastExist) {
              isToastExist = true;
              customToast.error(data.message);
            }
          } else {
            if (!isToastExist && !error?.response && !navigator.onLine) {
              isToastExist = true;
              customToast.error(
                i18n.t(
                  "ERROR_MESSAGES.You_are_offline_Check_your_internet_connection"
                )
              );
            } else if (!isToastExist) {
              isToastExist = true;
              customToast.error(i18n.t("COMMON_STRING.UNEXPECTED_ERROR"));
            }
          }
        } else if (!error?.response && !navigator.onLine) {
          if (!isToastExist) {
            isToastExist = true;
            customToast.error(
              i18n.t(
                "ERROR_MESSAGES.You_are_offline_Check_your_internet_connection"
              )
            );
          }
        } else {
          if (!isToastExist) {
            isToastExist = true;
            customToast.error(i18n.t("COMMON_STRING.UNEXPECTED_ERROR"));
          }
        }

        setTimeout(() => {
          isToastExist = false;
        }, 2000);

        throw error;
      });
  }
}

export default new API();
