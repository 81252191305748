import React, { Component, createRef } from "react";
import "./addParticipantModel.scss";
import PhoneInput from "./../../common/PhoneInput";
import {
  INVITATION_SENT_SUCCESS_MESSAGE,
  UNEXPECTED_ERROR,
} from "./../../../constants/commonStrings";

import {
  customLog,
  validateEmailWithRequired,
  validatePhoneWithRequired,
  validateTextWithRequired,
  validateRequired,
  getWindowLocation,
} from "../../../helpers";
import { customToast } from "../../../helpers/customToast";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
const initialState = {
  name: {
    value: "",
    error: "",
  },

  phone: {
    value: "",
    error: "",
  },
  email: {
    value: "",
    error: "",
  },

  message: {
    value: "",
    error: "",
  },
  charLimit: 400,
  callUUID: "",
  country_code: "us",
  isemail: false,
  isphone: true,
  emailPhoneError: false,
};

const textBoxMessage =
  "WEB_LABELS.Join_secure_video_call_by_clicking_on_the_following_link";
let callURL = "";

class AddParticipantModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    let message = textBoxMessage;

    this.setState(
      {
        message: {
          value: message,
        },
        isphone: true,
      },
      () => {}
    );
  }

  toggleEmailSelection = () => {
    this.setState(
      {
        isemail: !this.state.isemail,
      },
      () => {
        this.setState({
          emailPhoneError: this.isEmailORPhoneValidation(),
        });
      }
    );
  };

  togglePhoneSelection = () => {
    this.setState(
      {
        isphone: !this.state.isphone,
      },
      () => {
        this.setState({
          emailPhoneError: this.isEmailORPhoneValidation(),
        });
      }
    );
  };

  async resetState() {
    this.setState(
      {
        ...initialState,
        addParticipantSelectedTab: "sms",
      },
      () => {}
    );
  }

  async handlePhoneChange(name, value) {
    this.setState((state) => ({
      [name]: {
        ...state[name],
        value: value,
        validateOnChange: true,
        error: validatePhoneWithRequired(value),
      },
    }));
  }

  async handleChange(validationFunc, e) {
    const { name, value } = e.target;

    this.setState((state) => ({
      [name]: {
        ...state[name],
        value: value,
        validateOnChange: true,
        error: validationFunc(value),
      },
    }));
  }

  isEmailORPhoneValidation = () => {
    if (!this.state.isemail && !this.state.isphone) {
      return "ERROR_MESSAGES.Please_Select_Cellphone_or_Email";
    }
    return false;
  };

  async sendInvitation(e) {
    const nameError = validateTextWithRequired(this.state.name.value);
    const messageError = validateRequired(this.state.message.value);
    let phoneError = false;
    let emailError = false;
    let emailPhoneError = this.isEmailORPhoneValidation();

    let requestData = {
      message: this.state.message.value,
      templateId: "337e5140-3b7e-4000-b674-3e09f9423da3",
      name: this.state.name.value || "",
    };

    if (this.state.isphone) {
      requestData.phone = this.state.phone.value;
      phoneError = validatePhoneWithRequired(this.state.phone.value);
    }
    if (this.state.isemail) {
      requestData.email = this.state.email.value;
      emailError = validateEmailWithRequired(this.state.email.value);
    }

    if (
      [nameError, messageError, phoneError, emailError, emailPhoneError].every(
        (e) => e === false
      )
    ) {
      try {
        let inviteResult = await this.props.inviteParticipant(
          this.props.callUUID,
          requestData
        );

        customToast.success(
          i18n.t("COMMON_STRING.INVITATION_SENT_SUCCESS_MESSAGE")
        );

        this.props.hideAddParticipant();
      } catch (err) {
        customToast.error(i18n.t("COMMON_STRING.UNEXPECTED_ERROR"));
      }
    } else {
      this.setState((state) => ({
        name: {
          ...state.name,
          validateOnChange: true,
          error: nameError,
        },
        phone: {
          ...state.phone,
          validateOnChange: true,
          error: phoneError,
        },
        email: {
          ...state.email,
          validateOnChange: true,
          error: emailError,
        },
        message: {
          ...state.message,
          validateOnChange: true,
          error: messageError,
        },
        emailPhoneError,
      }));
    }

    // customLog("Done1 - ", this.videoCallData);
  }

  render() {
    const {
      name,
      email,
      message,
      phone,
      country_code,
      emailPhoneError,
    } = this.state;
    const {
      videoCall: { inviteParticipantLoading },
      t,
    } = this.props;
    // var characterLimitText = "";
    // if (this.state.charLimit - message.value.length > 0) {
    //   characterLimitText =
    //     this.state.charLimit - message.value.length + " characters remaining";
    // } else {
    //   characterLimitText = "Character Limit Exceeded";
    // }

    return (
      <>
        <div className="comman-modal left-side open">
          <div className="comman-modal-main">
            <div className="side-head">
              {t("WEB_LABELS.Invite_Participant")}
              <div className="side-head-buttons">
                <button className="close-modal modal-button-effect">
                  <i
                    className="icon-close-image"
                    onClick={() => {
                      this.props.hideAddParticipant();
                    }}
                  ></i>
                </button>
              </div>
            </div>
            <div className="comman-modal-body scroll-bar-style">
              <div className="participant-from">
                <div className="form-group">
                  <label>{t("WEB_LABELS.Participant_Name")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder={t("WEB_LABELS.Participant_Name")}
                    onChange={(e) =>
                      this.handleChange(validateTextWithRequired, e)
                    }
                    onBlur={(e) =>
                      this.handleChange(validateTextWithRequired, e)
                    }
                    value={name.value}
                  />
                  {name.error && (
                    <div className="text-danger">{t(name.error)}</div>
                  )}
                </div>

                <div className="form-group">
                  <div className="check-btn">
                    <label>
                      <input
                        type="checkbox"
                        id="sms"
                        name="isphone"
                        onClick={(e) => this.togglePhoneSelection()}
                        checked={this.state.isphone}
                      />
                      <span>{t("WEB_LABELS.Cell_Phone")}</span>
                    </label>
                  </div>
                  <div className="check-btn">
                    <label>
                      <input
                        type="checkbox"
                        id="email"
                        name="isemail"
                        checked={this.state.isemail}
                        onClick={(e) => this.toggleEmailSelection()}
                      />
                      <span>{t("WEB_LABELS.Email")}</span>
                    </label>
                  </div>
                  {emailPhoneError && (
                    <div className="text-danger">{t(emailPhoneError)}</div>
                  )}
                </div>

                {/* Phone number */}

                {this.state.isphone && (
                  <div className="form-group">
                    <PhoneInput
                      country={"us"}
                      name={"phone"}
                      value={this.state.phone.value}
                      onChange={(value) => {
                        this.handlePhoneChange("phone", value);
                      }}
                      countryCodeEditable={false}
                      onlyCountries={["us"]}
                      enableSearch={false}
                      placeholder={t("WEB_LABELS.enter_cell_phone")}
                    />
                    {phone.error && (
                      <div className="text-danger">{t(phone.error)}</div>
                    )}
                  </div>
                )}

                {/* Phone number ends */}

                {/* Email address */}
                {this.state.isemail && (
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="abc@gmail.com"
                      onChange={(e) =>
                        this.handleChange(validateEmailWithRequired, e)
                      }
                      onBlur={(e) =>
                        this.handleChange(validateEmailWithRequired, e)
                      }
                      value={email.value}
                    />
                    {email.error && (
                      <div className="text-danger">{t(email.error)}</div>
                    )}
                  </div>
                )}

                {/* Email address ends */}

                {/* <div className="form-group">
                  <label>Message</label>
                  <textarea
                    disabled
                    className="form-control"
                    rows="5"
                    placeholder=""
                    name="message"
                    onBlur={(e) => this.handleChange(validateRequired, e)}
                    onChange={(e) => this.handleChange(validateRequired, e)}
                    value={message.value}
                  ></textarea>
                  <div className="message text-right">{characterLimitText}</div>

                  <div className="text-danger">{message.error}</div>
                </div> */}

                <div className="button-group">
                  <button
                    className="green-btn invite-participant"
                    onClick={(e) => {
                      this.sendInvitation(e);
                    }}
                  >
                    <span>{t("BUTTONS.Invite")}</span>{" "}
                    {inviteParticipantLoading && (
                      <div className="loader-spin"></div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(AddParticipantModel);
