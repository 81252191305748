import { appConstants } from "./app.constants";
export const view_participant_list = "view_participant_list";
export const pinned_view = "pinned_view";
export const capture_image = "capture_image";
export const start_mark_up = "start_mark_up";
export const invite_participant = "invite_participant";
export const flash_on_off = "flash_on_off";
export const notes = "notes";
export const bookmark = "bookmark";
export const chat = "chat";
export const start_pointer = "start_pointer";
export const call_settings = "call_settings";
export const call_recording = "call_recording";
export const fill_up_inspection_form = "fill_up_inspection_form";
export const questionnaire_update = "questionnaire_update";
export const view_captured_images = "view_captured_images";
export const attachment = "attachment";
export const screen_share = "screen_share";
export const screen_share_po = "screen_share_po";
export const create_bookmark = "create_bookmark";
export const tap_on_device = "tap_on_device";
export const undo = "undo";
export const start_appraiser_measurement = "start_appraiser_measurement";
export const questionnaire = "questionnaire";
export const switch_camera = "switch_camera";

export const permissions = {
  [appConstants.USER_ROLES.PROPERTY_OWNER]: {
    [view_participant_list]: false,
    [pinned_view]: false,
    [capture_image]: false,
    [start_mark_up]: false,
    [invite_participant]: false,
    [flash_on_off]: true,
    [notes]: false,
    [bookmark]: false,
    [chat]: true,
    [start_pointer]: true,
    [call_recording]: false,
    [call_settings]: true,
    [fill_up_inspection_form]: false,
    [questionnaire_update]: false,
    [view_captured_images]: false,
    [attachment]: true,
    [screen_share]: true,
    [screen_share_po]: true,
    [create_bookmark]: false,
    [tap_on_device]: false,
    [undo]: false,
    [start_appraiser_measurement]: false,
    [questionnaire]: false,
    [switch_camera]: false,
  },
  [appConstants.USER_ROLES.APPRAISER]: {
    [view_participant_list]: true,
    [pinned_view]: true,
    [capture_image]: true,
    [start_mark_up]: true,
    [invite_participant]: true,
    [flash_on_off]: true,
    [notes]: true,
    [bookmark]: true,
    [chat]: true,
    [start_pointer]: true,
    [call_recording]: true,
    [call_settings]: true,
    [fill_up_inspection_form]: true,
    [questionnaire_update]: true,
    [view_captured_images]: true,
    [attachment]: true,
    [screen_share]: true,
    [screen_share_po]: false,
    [create_bookmark]: true,
    [tap_on_device]: true,
    [undo]: true,
    [start_appraiser_measurement]: true,
    [questionnaire]: true,
    [switch_camera]: true,
  },
  [appConstants.USER_ROLES.ASSISTANT]: {
    [view_participant_list]: true,
    [pinned_view]: true,
    [capture_image]: true,
    [start_mark_up]: true,
    [invite_participant]: true,
    [flash_on_off]: true,
    [notes]: true,
    [bookmark]: true,
    [chat]: true,
    [start_pointer]: true,
    [call_recording]: true,
    [call_settings]: true,
    [fill_up_inspection_form]: false,
    [questionnaire_update]: false,
    [view_captured_images]: true,
    [attachment]: true,
    [screen_share]: true,
    [screen_share_po]: false,
    [create_bookmark]: true,
    [tap_on_device]: false,
    [undo]: false,
    [start_appraiser_measurement]: false,
    [questionnaire]: false,
    [switch_camera]: false,
  },
};
