/* eslint-disable import/no-anonymous-default-export */
import { SEND_MESSAGE } from "../actions/actionTypes";

export const defaultState = {
  messages: [],
};

export default function (
  state = defaultState,
  action = { type: null, payload: {} }
) {
  switch (action.type) {
    case SEND_MESSAGE: {
      const messages = [...state.messages, action.payload];
      return {
        ...state,
        messages,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
