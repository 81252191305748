import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import "./offline.scss";

export default function OfflineImageModel(props) {
  const { t } = useTranslation();
  const [showSidebar, setShowSidebar] = useState(false);
  const isFirstRender = useRef(true);
  
  useEffect(() => {
    props.capturedImages.length === 0
      ? (isFirstRender.current = true)
      : (isFirstRender.current = false);
  }, [props.capturedImages]);
  useEffect(() => {
    if (!isFirstRender.current) {
      setShowSidebar(true);
      setTimeout(() => {
        setShowSidebar(false);
      }, 1000);
    }
  }, [props.capturedImages]);

  const renderImages = () => {
    if (!props.capturedImages.length) {
      return (
        <div className="no-image-found">{t("WEB_LABELS.No_images_found")}</div>
      );
    }

    return (
      <>
        <div className="thumb-body scroll-bar-style">
          {[...props.capturedImages].reverse().map((data, index) => {
            return (
              <div className="thumb-image-div" key={index}>
                <div className="thumb_bottom_postion">
                  <div className="viewbtn">
                    <button
                      onClick={() => {
                        props.showGalleryZoomModel(data.id);
                        props.hideShowBookMarkModel();
                      }}
                    >
                      {t("BUTTONS.View")}
                    </button>
                  </div>
                  <div className="thumb-image">
                    <img
                      src={data.imageUrl}
                      id="offlineImage"
                      alt={props.alt ? props.alt : "Loading Captured Pic..."}
                    />
                  </div>
                </div>
                <div className="thumb-image-info">
                  <div className="caption">
                    <i className="icon-save-images"></i>
                    Photo: {props.capturedImages.indexOf(data) + 1}
                  </div>
                  <div className="thumb-image-delete">
                    <Tooltip title={t("TOOLTIP.Delete_Image")} arrow>
                      <button
                        onClick={() => {
                          props.deleteImagePopUp(data.id, data.image);
                          props.hideShowBookMarkModel();
                        }}
                      >
                        <i className="icon-fill-delete"></i>
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };
  return (
    <>
      <div
        className={`thumb-container ${showSidebar ? "open" : ""} ${
          props.activeIndex !== -1 ? "thumb-conatiner-z-index" : ""
        }`}
      >
        <button
          className="side-toggle"
          onClick={() => {
            setShowSidebar((pre) => !pre);
            props.hideShowBookMarkModel();
          }}
        >
          <i
            className={showSidebar ? "icon-arrow-right" : "icon-arrow-left"}
          ></i>
          <span>
            {props.capturedImages.length ? props.capturedImages.length : 0}
          </span>
        </button>

        {showSidebar && (
          <div className="thumb-container-inner">
            <div className="side-head">
              <div className="icon">
                <i className="icon-save-images"></i>
                <span className="count">
                  {props.capturedImages.length
                    ? props.capturedImages.length
                    : 0}
                </span>
              </div>
              Saved Photos
              <button
                className="close-modal"
                onClick={() => {
                  setShowSidebar(false);
                  props.hideShowBookMarkModel();
                }}
              >
                <i className="icon-close-image"></i>
              </button>
            </div>
            {renderImages()}
          </div>
        )}
      </div>
    </>
  );
}
