import React from "react";
import { useTranslation } from "react-i18next";
export default function ConfirmExitMeasurementProcess(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p>{t(props.title)}</p>
          </div>
          <div className="bottom-btn">
            <button
              className="red-btn"
              onClick={() => props.cancelExitMeasurementProcess()}
            >
              {t("BUTTONS.No")}
            </button>
            <button
              className="green-btn"
              onClick={() => props.confirmExitMeasurementProcess()}
            >
              {t("BUTTONS.Yes")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
