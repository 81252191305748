import React, { useState } from "react";

export default function OfflineEndPage(props) {
  return (
    <>
      <section className="callend-pagenot-found z-index3">
        <div className="box-center">
          <div className="content">
            <div className="content-div">
              <h2>Thank you for Your Submission</h2>
              <p>
                Please contact the virtual inspector who will review the data
                and provide further instructions as necessary. We appreciate
                your cooperation and will be in contact soon.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
