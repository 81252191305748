export class LocalStorage {
  static USER_NAME = "USER_NAME";
  static CALL_UUID = "CALL_UUID";
  static INVITE_UUID = "INVITE_UUID";
  static VIDEO_CALL_DATA = "VIDEO_CALL_DATA";
  static ROLE = "ROLE";

  static setItem(key, value) {
    sessionStorage.setItem(key, value);
  }

  static getItem(key) {
    return sessionStorage.getItem(key) || "";
  }

  static clear(key) {
    sessionStorage.removeItem(key);
  }

  static clearAll() {
    sessionStorage.clear();
  }
}
