import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function UploadPopUp(props) {
  const { t } = useTranslation();
  const [cancelPopup, setCancelPopup] = useState(false);
  const [dots, setDots] = useState("");
  useEffect(() => {
    if (props.uploadProgress == 100 && props.reUploadScreen) {
      props.ShowEndScreen();
    }
  }, [props.uploadProgress]);

  useEffect(() => {
    const intervelId = setInterval(() => {
      setDots((prevDot) => (prevDot.length >= 3 ? "" : prevDot + "."));
    }, 500);

    return () => clearInterval(intervelId);
  }, []);
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p className="content-title">
              {props.capture}
              <span className="loading-dots">{dots}</span>
            </p>
          </div>
          <div className="progress-section">
            <div className="my-prograss">
              <div
                id="myUpload"
                style={{ width: `${props.uploadProgress}%` }}
              ></div>
            </div>
            <p>{`${props.uploadProgress}%`}</p>
          </div>
          <div className="bottom-btn">
            <button
              className="gray-btn"
              onClick={() => {
                setCancelPopup(true);
              }}
            >
              Cancel Upload
            </button>
          </div>
        </div>
      </div>
      {cancelPopup && (
        <div className="error-popup open">
          <div className="error-info">
            <div className="content">
              <p>Are you sure you want to cancel the data upload?</p>
            </div>
            <div className="bottom-btn">
              <button
                className="blue-btn"
                onClick={() => {
                  props.stopUploading();
                  props.SetInfoCancelUploadModal(true);
                  props.SetReUploadButtonText("Re-Upload Data");
                  setCancelPopup(false);
                }}
              >
                {t("BUTTONS.Yes")}
              </button>
              <button
                className="gray-btn"
                onClick={() => {
                  setCancelPopup(false);
                }}
              >
                {t("BUTTONS.No")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
