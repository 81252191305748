import React from "react";
import { useTranslation } from "react-i18next";
import "./RequestEndCallModel.scss";

const RequestEndCallModel = (props) => {
  const { t } = useTranslation();
  return (
    <div className="error-popup open">
      <div className="error-info text-left">
        <div className="content">
          <p>
            {!props.retryDTFailed
              ? `201: ${t("model_msg_retry")}`
              : `201: ${t("model_msg_leaveCall")}`}
          </p>
        </div>
        <div className="bottom-btn retry">
          <button
            className={`model-abtn ${
              !props.retryDTFailed ? "green-btn " : "red-btn"
            } ${props.waitingForDT ? "btnDisabled" : ""}`}
            onClick={
              !props.retryDTFailed
                ? props.setTimeoutForRetryDT
                : () => props.disconnectCall(false)
            }
            disabled={props.waitingForDT}
          >
            {!props.retryDTFailed
              ? t("BUTTONS.RETRY")
              : t("BUTTONS.Leave_Call")}
            {props.waitingForDT && <div className="model-loader-spin"></div>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestEndCallModel;
