/* eslint-disable import/no-anonymous-default-export */
import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

export const defaultState = {
  uploading: false,
  iscompleted: false,
  queue: [],
  successCount: 0,
  failedCount: 0,
  totalCount: 0,
  uploaded: {},
  images: [],
  selectedImageLabels: [],
};

export default function (state = defaultState, action = {}) {
  if (actionTypes.CAPTURE_IAMGE == action.type) {
    const prevqueue = _.isArray(state.queue) ? [...state.queue] : [];
    const queue = [...prevqueue, action.payload];
    const totalCount = state.totalCount + 1;
    return {
      ...state,
      queue,
      totalCount,
    };
  } else if (actionTypes.CAPTURE_IMAGE_UPLOAD_COMPLETED == action.type) {
    const successCount = state.successCount + 1;

    return {
      ...state,
      uploaded: action.payload,
      successCount,
    };
  } else if (actionTypes.CAPTURE_IMAGE_UPLOAD_FAILED == action.type) {
    const failedCount = state.failedCount + 1;
    return {
      ...state,
      failedCount,
    };
  } else if (actionTypes.DEQUEUE_CAPTURE_IMAGE == action.type) {
    const queue = [...state.queue];
    queue.shift();
    return {
      ...state,
      queue: [...queue],
    };
  } else if (actionTypes.CLEAR_QUEUE == action.type) {
    return {
      ...state,
      iscompleted: true,
      uploading: false,
      queue: [],
      successCount: 0,
      failedCount: 0,
      totalCount: 0,
      uploaded: {},
    };
  } else if (actionTypes.START_QUEUE == action.type) {
    return {
      ...state,
      uploading: true,
      iscompleted: false,
    };
  } else if (actionTypes.ADD_CAPTURE_IMAGE == action.type) {
    const images = [...state.images, action.payload];
    return {
      ...state,
      images,
    };
  } else if (actionTypes.DELETE_CAPTURE_IMAGE == action.type) {
    const images = [
      ...state.images.filter((_, index) => index !== action.payload),
    ];
    return {
      ...state,
      images,
    };
  } else if (actionTypes.SET_CAPTURED_IMAGE_LABELS === action.type) {
    return {
      ...state,
      selectedImageLabels: [...state.selectedImageLabels, action.payload],
    };
  } else {
    return {
      ...state,
    };
  }
}
