import {
  dataExistInTable,
  retrieveCallDetails,
  storeImage,
  retrieveImages,
} from "./storeIndexedData";
import {
  offlineUploadImageAction,
  offlineUploadVideoAction,
} from "../../actions/offlineUploadData";
import { useDispatch } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import UploadPopUp from "./UploadPopup";
import { binaryToBlob, blobToBinary } from "../../helpers";
import { customToast } from "../../helpers/customToast";
import ShowSummaryModel from "./ShowSummaryModel";
import { appConstants } from "../../constants";
import TokenExpiredPopup from "./tokenExpiredPopup";
import { markupforCaptureImg } from "../../helpers/markups";

export default function UploadOfflineData(props) {
  const dispatch = useDispatch();
  const [uplodPopupVisible, setUplodPopupVisible] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isCancleUpload, setIsCancleUpload] = useState(false);
  const [capturedImages, setCapturedImages] = useState(null);
  const [uploadCount, setUploadCount] = useState({
    total: 0,
    success: 0,
    failed: 0,
  });
  const [showSummaryModel, setShowSummaryModel] = useState(false);
  const [reUploadButtonText, setReUploadButtonText] = useState("Upload Data");
  const [infoCancelUploadModal, setInfoCancelUploadModal] = useState(false);
  const [mapViewFetch, setMapViewFetch] = useState(false);
  const [mapViewRejected, setMapViewRejected] = useState(false);
  const [videoUploadFinishProcess, setVideoUploadFinishProcess] =
    useState(true);
  const [imageUploadFinishProcess, setImageUploadFinishProcess] =
    useState(true);
  const [uploadSuccess, setUploadSuccess] = useState({
    video: 0,
    image: [],
    mapImage: [],
    isCancleUpload: 0,
    isImageUpload: 0,
  });
  const shouldCancelRef = useRef(false);
  const [capture, setCapture] = useState(null);
  const [showTokenExpired, setShowTokenExpired] = useState(false);
  const [showPopUpTokenExpired, setShowPopUpTokenExpired] = useState(false);
  
  useEffect(() => {
    if (uploadProgress === 100) {
      props.setSuccessFullyUploaded(uploadSuccess);
      setUplodPopupVisible(false);
      if (
        uploadSuccess.video === 1 &&
        uploadSuccess.image?.length + uploadSuccess.mapImage?.length ===
          capturedImages?.length &&
        uploadSuccess?.isCancleUpload === 0
      ) {
        props.reUpload();
        props.ShowEndScreen();
      } else {
        setShowSummaryModel(true);
      }
    }
  }, [uploadProgress, uploadSuccess]);
  useEffect(() => {
    checkDBandFetchImage();
  }, [mapViewFetch]);
  useEffect(() => {
    if (shouldCancelRef.current == true) {
      setImageUploadFinishProcess(false);
    }
  }, [shouldCancelRef.current]);

  async function checkDBandFetchImage() {
    await dataExistInTable("videos")
      .then((dbExists) => {
        if (dbExists) {
          retrieveImages().then(async (imageData) => {
            if (mapViewFetch && !mapViewRejected) {
              uploadImagesOffline(imageData);
            }
            setCapturedImages(imageData);
          });
        }
      })
      .catch((error) => {});
  }
  useEffect(() => {
    setUploadSuccess({
      video: 0,
      image: [],
      mapImage: [],
      fetchMapView: true,
      isCancleUpload: 0,
      isImageUpload: 0,
    });
  }, []);
  useEffect(() => {
    if (isCancleUpload) {
      props.setSuccessFullyUploaded(uploadSuccess);
    }
  }, [uploadSuccess]);
  useEffect(() => {
    if (uploadCount.failed > 0) {
      setReUploadButtonText("Re-Upload Data");
    }
  }, [uploadCount]);

  const captureMapViewImage = async (
    mapType,
    zoomLevel,
    JOId,
    latitude,
    longitude,
    count
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        let response;
        let canvasHeight = 640;
        let canvasWidth = 640;
        const imageLabel =
          mapType === "streetview"
            ? "Street View"
            : mapType === "roadmap"
            ? "Map View"
            : "Aerial View";

        let marker = "";
        if (latitude !== 0 && longitude !== 0) {
          marker =
            "markers=color:red%7Clabel:L%7C" + latitude + "," + longitude;
        }
        if (mapType === "streetview") {
          const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=${canvasWidth}x${canvasHeight}&location=${latitude},${longitude}&key=${appConstants.API_KEYS.GOOGLE_MAP_API_KEY}`;
          response = await fetch(streetViewUrl);
        } else {
          let imgurl =
            "https://maps.googleapis.com/maps/api/staticmap?" +
            "maptype=" +
            mapType +
            "&center=" +
            latitude +
            "," +
            longitude +
            "&size=" +
            canvasWidth +
            "x" +
            canvasHeight +
            "&zoom=" +
            zoomLevel +
            "&" +
            marker +
            "&key=" +
            appConstants.API_KEYS.GOOGLE_MAP_API_KEY;
          response = await fetch(imgurl);
        }
        if (!response.ok) {
          // Handle non-successful response here
          setUploadSuccess((prevState) => ({
            ...prevState,
            fetchMapView: false,
          }));
          props.mapViewRejected();
          setMapViewRejected(true);
          setUplodPopupVisible(false);
          customToast.error(`${imageLabel} fail to fetch , please try again.`);
          resolve();
          return;
          // throw new Error("Failed to fetch map image");
        }
        const blob = await response.blob();
        const binaryData = await blobToBinary(blob);
        const mapImg = new Image();
        mapImg.src = URL.createObjectURL(blob); // Use URL.createObjectURL to load the image

        mapImg.onload = async () => {
          const offScreenCanvas = document.createElement("canvas");
          offScreenCanvas.width = canvasWidth;
          offScreenCanvas.height = canvasHeight;
          const context = offScreenCanvas.getContext("2d");

          // Draw the map image onto the canvas
          context.drawImage(mapImg, 0, 0);

          // Load and draw the additional image
          const markup = markupforCaptureImg(
            canvasHeight,
            canvasWidth,
            latitude,
            longitude,
            1,
            imageLabel
          );
          const image = new Image();

          image.onload = async () => {
            // Draw the additional image below the map image
            context.drawImage(image, 0, 0);

            // Obtain a new blob from the canvas
            offScreenCanvas.toBlob(async (newBlob) => {
              if (!newBlob) {
                // Handle the case where the blob couldn't be generated
                reject(
                  new Error("Failed to generate a new blob from the canvas.")
                );
                return;
              }

              // Convert the new blob into binary data
              const newBinaryData = await blobToBinary(newBlob);

              // Convert the canvas to a data URL (JPEG)
              const dataURL = offScreenCanvas.toDataURL("image/jpeg");

              // Create a data object with image details
              const data = {
                image: newBinaryData, // Use the new binary data here
                imageUrl: dataURL,
                id: count,
                label: imageLabel,
                labelID: "",
                mapView: true,
                isOffline: true,
              };

              // Store the image data
              storeImage(data)
                .then(() => {
                  resolve();
                })
                .catch((error) => {
                  reject(error);
                });
            }, "image/jpeg");
          };

          image.src = markup;
        };
      } catch (error) {
        // Handle any other errors
        // ...
        props.mapViewRejected();
        reject(error);
      }
    });
  };

  const uploadImagesOffline = async (ImageDataWithMapView = null) => {
    setUploadProgress(0);
    props.setIsUploadClicked();
    if (!navigator.onLine) {
      customToast.error(
        'It appears that you are currently offline. Kindly establish a network connection, and then click on "Upload Data" to save the progress made during your offline inspection. Thank you for your attention to this matter.'
      );
      return <></>;
    }

    const dbExists = await dataExistInTable("videos");
    if (!dbExists) {
      return;
    }

    const CallDetails = await retrieveCallDetails();
    const callID = CallDetails?.[0]?.callId;
    const JOId = CallDetails?.[0]?.jobOrderId;
    const latitude = CallDetails?.[0]?.latitude;
    const longitude = CallDetails?.[0]?.longitude;
    let imageData = ImageDataWithMapView
      ? ImageDataWithMapView
      : capturedImages;
    imageData = imageData.filter((imageDataItem) => {
      // Check if the id is not in props.successFullyUploaded.image array
      const isInImageArray = !props.successFullyUploaded.image?.includes(
        imageDataItem.id
      );

      // Check if the id is not in props.successFullyUploaded.mapImage array
      const isInMapImageArray = !props.successFullyUploaded.mapImage?.includes(
        imageDataItem.id
      );

      // Keep the item if it satisfies both conditions
      return isInImageArray && isInMapImageArray;
    });

    const totalImages = imageData.length;
    let totalUploads = totalImages;
    if (totalUploads === 0) {
      customToast.success("Your data is already uploaded");
    }
    setUploadCount((prevState) => ({
      ...prevState,
      success: 0,
      failed: 0,
    }));
    const batchUploadSize = 5;
    let successfulUploads = 0;
    if ((!mapViewFetch || mapViewRejected) && !props.checkMapImageExists) {
      let count1 = totalImages;
      let count2 = totalImages + 1;
      let count3 = totalImages + 2;
      setUplodPopupVisible(true);
      setUploadProgress(0);
      setCapture("Capturing Aerial View");
      // Capture the satellite view image
      await captureMapViewImage(
        "satellite",
        "19",
        JOId,
        latitude,
        longitude,
        count1
      );

      // Update the progress here if needed
      setUploadProgress(50);

      setUplodPopupVisible(true);
      setCapture("Capturing Map View");

      // Capture the roadmap view image
      await captureMapViewImage(
        "roadmap",
        "15",
        JOId,
        latitude,
        longitude,
        count2
      );
      setUploadProgress(75);

      setUplodPopupVisible(true);
      setCapture("Capturing Street View");

      await captureMapViewImage(
        "streetview",
        "15",
        JOId,
        latitude,
        longitude,
        count3
      );
      setMapViewFetch(true);
      props.mapViewFetch();

      return;
    }

    setUplodPopupVisible(true);
    setUploadProgress(0);

    setCapture("Upload Data");
    if (props.successFullyUploaded.video !== 1) {
      totalUploads = totalImages + 1;
      const videoBlob = props.recordedVideoBlob;
      if (shouldCancelRef.current) {
        props.setSuccessFullyUploaded(uploadSuccess);
        return;
      } else {
        setVideoUploadFinishProcess(false);
        dispatch(offlineUploadVideoAction(JOId, videoBlob, callID))
          .then((res) => {
            setVideoUploadFinishProcess(true);
            if (res == 401) {
              if (!showTokenExpired) {
                props.ShowTokenScreen();
                setShowTokenExpired(true);
                setShowPopUpTokenExpired(true);
                setShowSummaryModel(false);
                setUplodPopupVisible(false);
              }
            }
            if (res.code === 200) {
              setUploadSuccess((prevState) => ({
                ...prevState,
                video: 1,
              }));
              successfulUploads++;
              setUploadCount((prevState) => ({
                ...prevState,
                success: prevState.success + 1,
              }));
            } else {
              setVideoUploadFinishProcess(true);
              setUploadSuccess((prevState) => ({
                ...prevState,
                video: 2,
              }));
              successfulUploads++;
              setUploadCount((prevState) => ({
                ...prevState,
                failed: prevState.failed + 1,
              }));
            }

            const progress = Math.round(
              (successfulUploads / totalUploads) * 100
            );
            setUploadProgress(progress);
          })
          .catch(() => {
            setVideoUploadFinishProcess(true);
            successfulUploads++;
            setUploadCount((prevState) => ({
              ...prevState,
              failed: prevState.failed + 1,
            }));
            const progress = Math.round(
              (successfulUploads / totalUploads) * 100
            );
            setUploadProgress(progress);
            setUploadSuccess((prevState) => ({
              ...prevState,
              video: 2,
            }));
          });
      }
    }

    setImageUploadFinishProcess(false);

    for (let i = 0; i < totalImages; i += batchUploadSize) {
      if (i > 0) {
        setImageUploadFinishProcess(true);
      }

      if (shouldCancelRef.current) {
        props.setSuccessFullyUploaded(uploadSuccess);
        return;
      }

      const imageBatch = imageData.slice(i, i + batchUploadSize);
      const uploadPromises = imageBatch.map((imageDataItem) => {
        if (shouldCancelRef.current) {
          return;
        }

        if (
          (!uploadSuccess.image.includes(imageDataItem.id) || isCancleUpload) &&
          !uploadSuccess.mapImage.includes(imageDataItem.id)
        ) {
          setUploadSuccess((prevState) => ({
            ...prevState,
            isImageUpload: 1,
          }));
          return binaryToBlob(imageDataItem.image)
            .then((imageUrl) =>
              dispatch(
                offlineUploadImageAction(JOId, imageUrl, {
                  label: imageDataItem.label,
                  bookmarkId: imageDataItem.labelID,
                  isOffline: true,
                })
              )
            )
            .then((response) => {
              if (response == 401) {
                if (!showTokenExpired) {
                  props.ShowTokenScreen();
                  setShowTokenExpired(true);
                  setShowPopUpTokenExpired(true);
                  setShowSummaryModel(false);
                  setUplodPopupVisible(false);
                }
              }
              if (response.originalName) {
                if (imageDataItem.mapView) {
                  setUploadSuccess((prevState) => ({
                    ...prevState,
                    mapImage: [...prevState.mapImage, imageDataItem.id],
                  }));
                } else {
                  setUploadSuccess((prevState) => ({
                    ...prevState,
                    image: [...prevState.image, imageDataItem.id],
                  }));
                }
                successfulUploads++;
                setUploadCount((prevState) => ({
                  ...prevState,
                  success: prevState.success + 1,
                }));
                const progress = Math.round(
                  (successfulUploads / totalUploads) * 100
                );
                setUploadProgress(progress);
              } else {
                setImageUploadFinishProcess(true);
                successfulUploads++;
                setUploadCount((prevState) => ({
                  ...prevState,
                  failed: prevState.failed + 1,
                }));
                const progress = Math.round(
                  (successfulUploads / totalUploads) * 100
                );

                setUploadProgress(progress);
              }
            })
            .catch(() => {
              setImageUploadFinishProcess(true);
              successfulUploads++;
              setUploadCount((prevState) => ({
                ...prevState,
                failed: prevState.failed + 1,
              }));
              const progress = Math.round(
                (successfulUploads / totalUploads) * 100
              );

              setUploadProgress(progress);
            });
        }
      });
      setUploadCount((prevState) => ({
        ...prevState,
        total: totalUploads,
      }));
      await Promise.all(uploadPromises);
      props.setSuccessFullyUploaded(uploadSuccess);
    }
  };

  return (
    <div>
      {showSummaryModel && (
        <ShowSummaryModel
          uploadCount={uploadCount}
          ShowEndScreen={() => props.ShowEndScreen()}
          onOk={() => {
            setShowSummaryModel(false);
            setUploadCount((prevState) => ({
              ...prevState,
              success: 0,
              failed: 0,
            }));
          }}
        />
      )}
      <button
        className="blue-btn"
        onClick={() => {
          if (!videoUploadFinishProcess || !imageUploadFinishProcess) {
            customToast.error(
              "The upload process is already in progress. Please try again later."
            );
            return;
          }
          shouldCancelRef.current = false;

          uploadImagesOffline();
        }}
      >
        {reUploadButtonText}
      </button>
      {uplodPopupVisible && (
        <UploadPopUp
          capture={capture}
          ShowEndScreen={() => props.ShowEndScreen()}
          uploadProgress={uploadProgress}
          reUploadScreen={
            uploadSuccess?.video === 1 &&
            uploadSuccess?.image?.length + uploadSuccess?.mapImage?.length ===
              capturedImages?.length &&
            uploadSuccess?.isCancleUpload === 0
          }
          uplodPopupVisible={() => setUplodPopupVisible(false)}
          stopUploading={() => {
            shouldCancelRef.current = true;
            setUploadSuccess((prevState) => ({
              ...prevState,
              isCancleUpload: 1,
            }));
            setIsCancleUpload(true);
            setUplodPopupVisible(false);
          }}
          successFullyUploaded={props.successFullyUploaded}
          SetInfoCancelUploadModal={setInfoCancelUploadModal}
          SetReUploadButtonText={setReUploadButtonText}
        />
      )}
      {showPopUpTokenExpired && <TokenExpiredPopup />}
      {infoCancelUploadModal && (
        <div className="error-popup open">
          <div className="error-info">
            <div className="content">
              <p className="content-title">
                <b>Instructions</b>
              </p>
              <p>
                The upload process has been cancelled. Please note that some
                data may have already been published before the cancellation
                request was initiated.
              </p>
            </div>
            <div className="bottom-btn">
              <button
                className="blue-btn"
                onClick={() => {
                  setInfoCancelUploadModal(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
