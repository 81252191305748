import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { retrieveBookmarks } from "./storeIndexedData";

export default function OfflineBookMarkModel(props) {
  const { t } = useTranslation();
  const [bookMark, setBookMark] = useState([]);
  const [search, setSearch] = useState("");
  const [clickCounts, setClickCounts] = useState({});
  const sidebarRef = useRef();
  const [debounceClick, setDebounceClick] = useState(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    retrieveBookmarks("bookmarks")
      .then((data) => {
        if (data?.[0]?.label) {
          setBookMark([...data[0].label]);
        }
      })
      .catch((error) => {});
  }, []);

  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      props.hideShowBookMarkModel();
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredNames = bookMark.filter((data) => {
    return data.label.toLowerCase().includes(search.toLowerCase());
  });

  const IfBookmarkNotExist = (search) => {
    const isNameExist = bookMark.some(
      (data) => data.label.trim().toLowerCase() === search.trim().toLowerCase()
    );
    return !isNameExist;
  };

  const onAddBookmark = (bookmark) => {
    props.captureBookmark(search);
  };

  const handleNameClick = (label) => {
    setClickCounts((prevCounts) => ({
      ...prevCounts,
      [label]: (prevCounts[label] || 0) + 1,
    }));
  };

  const handleDebounceClick = (label, id) => {
    props.captureBookmark(label, id);
    handleNameClick(label);
  };

  const handleCaptureBookmarkClick = (label, id) => {
    if (debounceClick) {
      clearTimeout(debounceClick);
    }

    setDebounceClick(
      setTimeout(() => {
        handleDebounceClick(label, id);
      }, 500)
    );
  };

  return (
    <>
      <div
        className={
          "comman-modal left-side offline-label-model " +
          (props.showSidebar ? "open" : "close")
        }
        ref={sidebarRef}
      >
        <div className="comman-modal-main">
          <div className="side-head">
            <div className="icon">
              <i className="icon-bookmark" />
              <span className="count">
                {" "}
                {bookMark.length > 0 ? bookMark.length : ""}
              </span>
            </div>
            {t("WEB_LABELS.Labels")}
            <button
              className="close-modal"
              onClick={() => {
                setSearch("");
                props.hideShowBookMarkModel();
              }}
            >
              <i className="icon-close-image"></i>
            </button>
          </div>
          <div className="comman-modal-body bookmark-modal-body">
            <div className="bookmark-view">
              <div className="search-view">
                <input
                  type="search"
                  className="form-control"
                  name=""
                  placeholder="Search or Add Photo Label"
                  value={search}
                  onChange={handleSearch}
                  enterKeyHint={"done"}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.target.blur();
                    }
                  }}
                />
                {(!search || search.length === 0) && (
                  <button className="search">
                    <i className="icon-search"></i>
                  </button>
                )}

                {search && search.length > 0 && IfBookmarkNotExist(search) && (
                  <button
                    className="create-book"
                    onClick={() => {
                      onAddBookmark();
                      setSearch("");
                    }}
                  >
                    {`Search or Add Photo Label: ${search}`}
                  </button>
                )}
              </div>
              {!bookMark || (bookMark && bookMark.length === 0) ? (
                <div className="no-bookmark">
                  <p>
                    Press Enter or Click on Add New Label to Captured Image with
                    New Label
                  </p>
                </div>
              ) : (
                <div className="bookmark-list scroll-bar-style">
                  <ul
                    style={
                      search && search.length > 0 && IfBookmarkNotExist(search)
                        ? { marginTop: "35px" }
                        : {}
                    }
                  >
                    {filteredNames.map((data) => {
                      return (
                        <li key={data.id}>
                          <button
                            onClick={() => {
                              handleCaptureBookmarkClick(data.label, data.id);
                            }}
                          >
                            {data.label}
                            <span>{clickCounts[data.label]}</span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
