export const TRANSLATIONS_Japanese = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired:
      "安全なビデオ通話セッションの有効期限が切れました",
    Secure_Video_Call_Ended: "安全なビデオ通話が終了しました",
    Thank_you_for_connecting_with_us: "ご連絡ありがとうございます！",
    Connected_Waiting_for_Other_Participants:
      "接続されています。他の参加者を待っています...",
    Powered_by: "搭載",
    Connecting: "接続しています...",
    Local_Pike: "ローカルパイク",
    Would_you_like_to_save_image: "画像を保存しますか？",
    Message: "メッセージ",
    Location_Not_shared: "場所：共有されていません",
    Location: "位置",
    Tools: "ツール",
    Ask_PO_to_Turn_on_Location:
      "プロパティの所有者に場所を有効にするよう依頼する",
    Preparing_image_for_markup: "マークアップ用の画像を準備しています...",
    Download_Message: "リモート評価アプリケーションをダウンロードする",
    Disclaimer:
      "RemoteValアプリケーションをAppStoreからダウンロードする必要があります。ダウンロードが完了したら、テキスト/メールメッセージの招待状を再度開き、リンクをもう一度クリックして、自動的に通話に参加してください。",
    MeasurementLinkDisclaimer:
      "RemoteValアプリケーションをAppStoreからダウンロードする必要があります。 ダウンロードが完了したら、テキスト/メールメッセージの招待状を再度開き、リンクをもう一度クリックして、検査を自動的に開始してください。",
    Zoom_Level: "ズームレベル",
    enter_cell_phone: "携帯電話を入力してください",
    Email: "Eメール",
    Cell_Phone: "携帯電話",
    Participant_Name: "参加者名",
    Invite_Participant: "参加者を招待する",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "次のリンクをクリックして、安全なビデオ通話に参加してください。",
    There_are_no_Attachments_available: "利用可能な添付ファイルはありません。",
    Drop_Or_Select_Your_File: "ファイルをドロップまたは選択します",
    Attach_Document: "書類を添付する",
    Enter_Your_Name: "あなたの名前を入力してください",
    I_confirm_that_I_have_read_and_agree_to_the:
      "私は読んだことを確認し、同意します",
    Search_or_Add_Captured_Image_Label:
      "キャプチャした画像ラベルを検索または追加",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "Enterキーを押すか、[新しいラベルを使用してキャプチャした画像に新しいラベルを追加]をクリックします",
    To: "に",
    Everyone: "みんな",
    Type_a_message: "メッセージを入力してください",
    Are_you_sure_you_want_to_delete: "消去してもよろしいですか ？",
    Room_is_full_Please_try_again_later:
      "部屋はいっぱいです。後でもう一度やり直してください",
    Comment: "コメント",
    Room_2_Measurement: "部屋2の測定",
    Room_1_Measurement: "部屋1の測定",
    Room_2_Image: "部屋2の画像",
    Room_1_Image: "部屋1の画像",
    No_of_Rooms: "部屋数",
    Property_Type: "プロパティタイプ",
    House: "家",
    Inspection_Form: "検査票",
    Instructions: "手順",
    Guide_user_to_position_camera_on_the_target:
      "ターゲットにカメラを配置するようにユーザーをガイドします",
    Click_on_the_Label_once_to_capture_labeled_image:
      "ラベルを1回クリックして、ラベル付きの画像をキャプチャします。",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "ホームのフロア、部屋のタイプ、部屋の名前を選択します。",
    Choose_GLA_or_Non_GLA: "GLAまたは非GLAを選択します。",
    Click_on_the_Start_Measurement_button: "[測定の開始]ボタンをクリックします",
    Click_the_Auto_Corner_Detection_to_On_Off:
      "「自動コーナー検出」をクリックしてオン/オフします",
    Position_vertical_Green_Floor:
      "部屋の隅に垂直の緑の床マーカーを配置し、床に接触していることを確認します。",
    App_will_auto_mark_a_corner:
      "アプリはコーナーを自動マークするか、「手動でコーナーをキャプチャ」ボタンをタップします",
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "部屋の各隅に歩いて各隅に印を付けるようにユーザーをガイドします",
    When_last_line_connects:
      "最後の線が始点に接続すると、赤に変わります。アプリは自動接続してスケッチを完了します。または、[コーナーを接続してスケッチを終了]ボタンを使用して測定を完了できます。",
    button_to_view_final_sketch:
      "「完成した平面図のスケッチを表示」ボタンをクリックして、最終的なスケッチを表示します。",
    Are_You_Sure_You_Want_to_Leave_This_Call:
      "この通話を終了してもよろしいですか？",
    Street_View: "ストリートビュー",
    Select_Measurement_Type: "測定タイプを選択します",
    Floor: "床",
    Ceiling: "シーリング",
    View_Sketches_by_Floor: "フロアごとにスケッチを表示",
    Uploading_Image: "画像のアップロード",
    Call_Setting: "通話設定",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "マイクへのアクセスを許可します。南京錠をクリックします",
    to_the_left_of_the_address_bar: "アドレスバーの左側にあります。",
    Speakers: "スピーカー",
    Allow_access_to_your_Camera_Click_the_padlock:
      "カメラへのアクセスを許可します。南京錠をクリックします",
    to_the_address_bar: "アドレスバーに。",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "ビデオカメラの起動に失敗しました。別のビデオカメラを選択してください。",
    Title: "題名",
    New_Note: "新しいメモ...",
    There_are_no_Notes_available: "利用可能なメモはありません。",
    Are_you_sure_you_want_to_delete_this_note:
      "このメモを削除してもよろしいですか？",
    Network_Type: "ネットワークタイプ",
    Netword_Strength: "ネットワードの強さ",
    To_share_live_video:
      "ライブビデオを共有します。ブラウザの設定でカメラの許可をオンにしてから",
    click_here: "ここをクリック",
    to_reload_the_page: "ページをリロードします。",
    we_cant_access_your_microphone:
      "マイクにアクセスできません。アクセスを許可するには、ブラウザの設定に移動してから",
    Questionnaire_Form: "アンケートフォーム",
    No_images_found: "画像が見つかりません！",
    Image: "画像",
    Captured_Images: "キャプチャされた画像",
    Share_your_screen: "画面を共有する",
    Share_your_live_video: "ライブビデオを共有しますか？",
    This_will_stop_your_video: "これにより、ビデオが停止します。続けますか？",
    This_will_stop_screen_share:
      "これにより、画面共有が停止します。続けますか？",
    Room_Area_Label: "部屋/エリアラベル",
    Room_Area_Name: "部屋/エリア名（例：ベッドルーム1、バス2など）",
    Room_Area_Name_Placeholder: "部屋/エリア名",
    You: "君は",
    Are_you_sure_you_want_to_delete_captured_image:
      "キャプチャした画像を削除してもよろしいですか？",
    Start_Over: "最初からやり直す-間取り図を削除してもよろしいですか？",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "測定プロセスを終了してもよろしいですか？",
    Labels: "ラベル",
    Select_Language: "言語を選択する",
    This_call_is_being_recorded: "このビデオ通話は記録されています",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "この通話は、鑑定士がプライベートワークファイル用に録音しています。",
    It_will_not_be_shared: `それはあなたの住宅ローンを含むいかなる外部の当事者とも共有されません
    貸し手、そしてあなたの鑑定士はからそれをダウンロードすることができなくなります
    RemoteValサイト。鑑定士が完了するためにのみ使用されます
    あなたの評価。`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "画面を記録したくない場合は、今すぐ鑑定士に知らせてください。",
    Measurement: "計測",
    Create_Measurement: "測定値を作成する",
    Fencing: "フェンシング",
    Show: "見せる",
    Hide: "隠れる",
    Live_Floor_Plan: "ライブフロアプラン",
    Expand: "拡大",
    Collapse: "崩壊",
    Instruction_for_Call_Settings: "通話設定の手順",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      "スピーカー、マイク、カメラは、[その他]メニューの通話設定でテストできます",
    To_Check_the_call_settings_now_click_on_the_below_button:
      "通話設定を確認するには、下のボタンをクリックしてください。",
    Call_recording_by_virtual_inspector:
      "このビデオ通話は、プライベートワークファイルと、それを要求した組織による内部監査目的のために、仮想検査官によって記録されています。外部の当事者と共有されることはありません。",
    Call_and_Screen_Recording_Opt_Out:
      "画面とこの呼び出しが録音されることを望まない場合は、今すぐ仮想検査官にお知らせください。",
    Test_Audio_Video_Components_Button:
      "スピーカー、マイク、カメラは、下のボタンをクリックしてテストできます。",
  },
  BUTTONS: {
    Re_Connect: "再接続",
    End_Session: "セッションの終了",
    Chat: "チャット",
    Participants: "参加者",
    Notes: "ノート",
    Attach_Document: "書類を添付する",
    Questionnaire: "アンケート",
    Labels: "ラベル",
    Pointer: "ポインタ",
    Markup: "マークアップ",
    Flash_Off: "フラッシュオフ",
    Flash_On: "フラッシュオン",
    Call_Recording: "通話録音",
    Capture_Image: "画像をキャプチャする",
    Screen_Share: "画面共有",
    Stop_Screen_Share: "画面共有を停止する",
    Call_Settings: "通話設定",
    Clear_All: "すべてクリア",
    Exit: "出口",
    Save: "保存する",
    Close: "選ぶ",
    Save_and_Exit: "保存して終了",
    Continue: "継続する",
    Street_View: "ストリートビュー",
    Open_in_App: "アプリで開く",
    Privacy_Policy: "プライバシーポリシー",
    Invite: "招待",
    Join: "加入",
    Add_Captured_Image_Label: "キャプチャした画像のラベルを追加",
    No: "番号",
    Yes: "はい",
    Delete: "消去",
    Cancel: "キャンセル",
    RETRY: "リトライ",
    OK: "わかった",
    Back: "戻る",
    Leave_Call: "電話を切る",
    Got_It: "とった",
    Start_New_Inspection: "新しい検査を開始します",
    Resume_Existing_Inspection: "既存の検査を再開する",
    View_All_Current_Sketch: "現在のスケッチをすべて表示",
    Open_Sketch: "Sketchを開く",
    Auto_Corner_Detection_ON: "自動コーナー検出オン",
    Auto_Corner_Detection_OFF: "自動コーナー検出オフ",
    Manually_Capture_Corner: "手動でコーナーをキャプチャ",
    Undo_Last_Corner_Capture: "ラストコーナーキャプチャを元に戻す",
    Connect_Corner_Finish_Sketch: "コーナーを接続してスケッチを仕上げる",
    Capture_3D_Room_Height: "3Dの部屋の高さをキャプチャする",
    Set_Fixed_3D_Room_Height: "固定3D部屋の高さを設定する",
    Capture_Door: "キャプチャドア",
    Set_Door: "セットドア",
    Show_Finished_Floor_Plan_Sketch: "完成した平面図のスケッチを表示する",
    Back_to_Previous_Screen: "前の画面に戻る",
    Add_Room: "部屋を追加",
    Publish_the_floor_plan: "フロアプランを公開する",
    Exit_Return_to_Inspection: "終了して検査に戻る",
    Audio: "オーディオ",
    Video: "ビデオ",
    Microphone: "マイクロフォン",
    Microphone_not_found: "マイクが見つかりません",
    Microphone_Blocked: "マイクがブロックされました",
    Default: "ディフォルト",
    Grant_Permission: "許可を与える",
    Speaker_Blocked: "スピーカーがブロックされました",
    Play_Test_Sound: "テストサウンドを再生する",
    Preview: "プレビュー",
    Camera: "カメラ",
    Camera_not_found: "カメラが見つかりません",
    Camera_Blocked: "カメラがブロックされました",
    Done: "終わり",
    View: "意見",
    Capture_Labeled_Images: "ラベル付き画像をキャプチャする",
    Capture_Measurements: "測定値をキャプチャする",
    Instructions: "手順",
    Start_Measurement_Process: "測定プロセスを開始します",
    Flash_Light_Not_Supported:
      "ゲストデバイスでサポートされていない、または準備ができていないリモート懐中電灯",
  },
  TOOLTIP: {
    Mute_My_Audio: "オーディオをミュートする",
    Unmute_My_Audio: "オーディオのミュートを解除する",
    Stop_My_Video: "私のビデオを停止します",
    Start_My_Video: "マイビデオを開始",
    Capture_Images_with_No_Labels: "ラベルなしで画像をキャプチャする",
    Start_Inspection: "検査を開始します",
    Chat: "チャット",
    More_Options: "より多くのオプション",
    End_Call: "通話終了",
    Unpin_Video: "ビデオの固定を解除する",
    Pin_Video: "ピンビデオ",
    Add_New_Note: "新しいメモを追加",
    Refresh: "更新",
    View_More: "もっと見る",
    Invite_Participant: "参加者を招待する",
    Delete_Image: "画像を削除",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "招待状が正常に送信されました",
    IOS_SAFARI_HELP: "最良の結果を得るには、Safariの使用をお勧めします",
    UNEXPECTED_ERROR:
      "予期しないエラーが発生しました。あとでもう一度試してみてください。",
    CALL_LINK_NOT_FOUND: "通話リンクが見つかりません。",
    CREATE_BOOKMARK_MESSAGE: "管理者にブックマークの追加を依頼してください",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED:
      "ファイルサイズは10MB未満である必要があります",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT: "サポートされていないファイル形式",
    LIVE_POINTER_STARTED:
      "ライブポインタを開始しました。ドラッグすることでポインタを動かすことができます。",
    LIVE_POINTER_STOPPED: "ライブポインタを停止しました",
    APPRAISAL_EXIT_APPROVED: "終了が正常に確認されました。",
    APPRAISAL_EXIT_DENIED: "終了が拒否されました。",
    MARKUP_CLOSED: "マークアップが停止しました",
    PRIVACY_POLICY_FAIL: "有効なプライバシーポリシーのURLが見つかりません！",
    SWITCH_CAMERA_REQUEST_REJECTED:
      "住宅所有者は、要求されたカメラビューを共有しませんでした。",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email: "携帯電話またはメールを選択してください",
    This_field_is_required: "この項目は必須です",
    Cell_Phone_Must_Be_Valid: "携帯電話は有効でなければなりません",
    Name_is_Required: "名前が必要です",
    Name_Must_Be_Valid: "名前は有効である必要があります",
    Invalid_Text: "無効なテキスト",
    Email_Must_Be_Valid: "メールは有効である必要があります",
    Name_must_be: "名前は",
    characters_max: "最大文字数",
    Please_select_the_Floor: "フロアを選択してください",
    Please_enter_the_Room_Area_Name: "部屋/エリア名を入力してください",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "オーディオ出力デバイスの選択にはHTTPSを使用する必要があります",
    Something_went_wrong:
      "何かがうまくいかなかった。後でもう一度やり直してください。",
    Oops_Call_duration_is_over_and_session_is_ended:
      "おっとっと！通話時間が終了し、セッションが終了します。",
    No_participant_has_joined_yet_Ending_the_call_session:
      "まだ参加者はいません！通話セッションを終了する",
    You_are_offline_Check_your_internet_connection:
      "オフラインです。 インターネット接続を確認してください。",
  },
  starting_inspection: "検査開始",
  starting_measurement: "測定開始",
  error: "エラー",
  contact_us: "お問い合わせ",
  support_popup_msg:
    "おっと、問題が発生しました。しばらくしてからもう一度お試しいただくか、サポートに問題を報告してください。",
  select_po: "プロパティの所有者を選択して検査プロセスを開始します",
  request_po_for_camera:
    "検査プロセスを開始するには、プロパティ所有者にカメラビューをオンにするように依頼してください。",
  wait_until_po_joins:
    "物件所有者が電話に参加して検査プロセスを開始するまでお待ちください",
  select_measurement_method: "測定方法を選択します",
  inspection_inprogress: "検査はすでに行われています",
  model_msg_retry:
    "プロパティ所有者との通信チャネルを確立できません。再試行してください。",
  model_msg_leaveCall:
    "どちらかの側にインターネット接続の問題があるようです。現在の通話を切断して、もう一度接続してください。",
  please_wait: "お待ちください...",
  loading_sketch: "スケッチを読み込んでいます...",
  connection_timeout_msg:
    "接続がタイムアウトしました。 何か異常が発生し、応答に予想以上に時間がかかっています。 もう一度やり直してください。",
  previous_measurement_prblm_msg:
    "Ops、前の測定で問題が発生しました。 新しい測定プロセスで再試行してください。",
  measurement_retry_msg:
    "測定プロセスは開始されていません。 再試行してください。",
  opensketch_retry_msg:
    "OpenSketchプロセスが開始されていません。 再試行してください。",
};
