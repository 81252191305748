import React from "react";
import { useTranslation } from "react-i18next";
const ShowSummaryModel = (props) => {
  const { t } = useTranslation();
  if (
    props.uploadCount.success == props.uploadCount.total &&
    props.uploadCount.failed == 0
  ) {
    props.ShowEndScreen();
  }
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p className="content-title">
              <b>Summary</b>
            </p>
            <div>
              <ul className="summaryDivUl">
                <li>Total Attempted: {props.uploadCount.total} </li>
                <li>Successfully Uploaded: {props.uploadCount.success}</li>
                <li>Failed to Upload: {props.uploadCount.failed} </li>
              </ul>
            </div>
            <p>
              To re-upload images or video that failed to upload, please click
              on “Re-Upload data”. Thank you for your cooperation.
            </p>
          </div>
          <div className="bottom-btn">
            <button className="blue-btn" onClick={() => props.onOk()}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ShowSummaryModel;
