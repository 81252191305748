export const TRANSLATIONS_Turkish = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired:
      "Güvenli Görüntülü Görüşme Oturumunun Süresi Doldu",
    Secure_Video_Call_Ended: "Güvenli Görüntülü Görüşme Sona Erdi",
    Thank_you_for_connecting_with_us:
      "Bizimle bağlantı kurduğunuz için teşekkür ederiz!",
    Connected_Waiting_for_Other_Participants:
      "Bağlı. Diğer Katılımcıları Bekliyoruz...",
    Powered_by: "Tarafından desteklenmektedir",
    Connecting: "Bağlanıyor...",
    Local_Pike: "yerel turna",
    Would_you_like_to_save_image: "Resmi kaydetmek ister misiniz?",
    Message: "İleti",
    Location_Not_shared: "Konum: Paylaşılmadı",
    Location: "Konum",
    Tools: "Araçlar",
    Ask_PO_to_Turn_on_Location:
      "Mülk Sahibinden Konumu etkinleştirmesini isteyin",
    Preparing_image_for_markup: "Görüntü işaretleme için hazırlanıyor...",
    Download_Message: "Uzaktan Değerleme Uygulamasını İndirin",
    Disclaimer: `RemoteVal uygulamasını App Store'dan indirmek gereklidir. İndirme işlemi tamamlandıktan sonra, lütfen kısa mesaj/e-posta mesajı davetinizi yeniden açın ve aramaya otomatik olarak katılmak için bağlantıyı bir kez daha tıklayın.`,
    MeasurementLinkDisclaimer:
      "RemoteVal uygulamasını App Store'dan indirmek gereklidir. İndirme işlemi tamamlandıktan sonra, lütfen metin/e-posta mesajı davetinizi yeniden açın ve denetimi otomatik olarak başlatmak için bağlantıya bir kez daha tıklayın.",
    Zoom_Level: "Yakınlaştırma seviyesi",
    enter_cell_phone: "cep telefonuna gir",
    Email: "E-posta",
    Cell_Phone: "Cep telefonu",
    Participant_Name: "Katılımcı İsmi",
    Invite_Participant: "Katılımcı Davet",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "Aşağıdaki bağlantıya tıklayarak güvenli görüntülü görüşmeye katılın:",
    There_are_no_Attachments_available: "Kullanılabilir Ek yok.",
    Drop_Or_Select_Your_File: "Dosyanızı Bırakın veya Seçin",
    Attach_Document: "Belge ekle",
    Enter_Your_Name: "Adınızı giriniz",
    I_confirm_that_I_have_read_and_agree_to_the:
      "okuduğumu ve kabul ettiğimi onaylıyorum",
    Search_or_Add_Captured_Image_Label:
      "Yakalanan Görüntü Etiketini Arayın veya Ekleyin",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "Yeni Etiket eklemek için Enter'a basın veya Yeni Etiket Ekle'ye tıklayın",
    To: "NS",
    Everyone: "Herkes",
    Type_a_message: "Bir mesaj yazın",
    Are_you_sure_you_want_to_delete: "Silmek istediğine emin misin ?",
    Room_is_full_Please_try_again_later:
      "Oda dolu. Lütfen daha sonra tekrar deneyiniz",
    Comment: "Yorum Yap",
    Room_2_Measurement: "Oda 2 Ölçümü",
    Room_1_Measurement: "Oda 1 Ölçümü",
    Room_2_Image: "Oda 2 Resmi",
    Room_1_Image: "Oda 1 Resim",
    No_of_Rooms: "Oda sayısı",
    Location: "Konum",
    Property_Type: "Emlak Tipi",
    House: "ev",
    Inspection_Form: "Muayene Formu",
    Instructions: "Talimatlar",
    Guide_user_to_position_camera_on_the_target:
      "Hedefe kamerayı konumlandırmak için kullanıcıyı yönlendirin",
    Click_on_the_Label_once_to_capture_labeled_image:
      "Etiketli görüntüyü yakalamak için Etikete bir kez tıklayın.",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "Evin katını, Oda Tipini ve Oda Adını seçin.",
    Choose_GLA_or_Non_GLA: "GLA veya GLA Olmayan'ı seçin.",
    Click_on_the_Start_Measurement_button: "Ölçümü Başlat düğmesine tıklayın",
    Click_the_Auto_Corner_Detection_to_On_Off:
      'Açmak/Kapatmak için "Otomatik Köşe Algılama" yı tıklayın',
    Position_vertical_Green_Floor:
      "Dikey Yeşil Zemin İşaretleyicisini Oda Köşesine yerleştirin ve zemine değdiğinden emin olun.",
    App_will_auto_mark_a_corner:
      'Uygulama bir köşeyi otomatik olarak işaretler veya "Köşeyi Manuel Olarak Yakala" düğmesine dokunur',
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "Kullanıcıyı, her köşeyi işaretlemek için odanın her köşesine yürümesi için yönlendirin",
    When_last_line_connects: `Son satır başlangıç noktasına bağlandığında kırmızıya döner. Uygulama otomatik olarak bağlanacak ve taslağı tamamlayacaktır veya ölçümü tamamlamak için "Köşeyi Bağla ve Krokiyi Bitir" düğmesini kullanabilirsiniz.`,
    button_to_view_final_sketch:
      'Nihai taslağı görüntülemek için "Bitmiş Kat Planı Krokisini Göster" düğmesine tıklayın.',
    Are_You_Sure_You_Want_to_Leave_This_Call:
      "Bu Çağrıdan Ayrılmak İstediğinizden Emin misiniz?",
    Street_View: "sokak Görünümü",
    Select_Measurement_Type: "Ölçüm Türünü Seçin",
    Floor: "Zemin",
    Ceiling: "Tavan",
    View_Sketches_by_Floor: "Çizimleri Kat Bazında Görüntüle",
    Uploading_Image: "Resim Yükleniyor",
    Call_Setting: "Çağrı ayarı",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "Mikrofonunuza erişime izin verin. asma kilidi tıklayın",
    to_the_left_of_the_address_bar: "adres çubuğunun solunda.",
    Speakers: "Hoparlörler",
    Allow_access_to_your_Camera_Click_the_padlock:
      "Kameranıza erişime izin verin. asma kilidi tıklayın",
    to_the_address_bar: "adres çubuğuna.",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "Video kamera başlatılamadı. Lütfen başka bir video kamera seçin.",
    Title: "Başlık",
    New_Note: "Yeni Not...",
    There_are_no_Notes_available: "Kullanılabilir Not yok.",
    Are_you_sure_you_want_to_delete_this_note:
      "Bu notu silmek istediğinizden emin misiniz?",
    Network_Type: "Ağ tipi",
    Netword_Strength: "Ağ Gücü",
    To_share_live_video:
      "Upang magbahagi ng canlı video. yönetim i-on ve pahintulot ng kamera s i ng mga ayar ng tarayıcı pagkatapos",
    click_here: "pindutin dito",
    to_reload_the_page: "upang mai-yeniden yükleme ang pahina.",
    we_cant_access_your_microphone:
      "hintçe namin ma-access ve iyong mikropono. Upang magbigay ng erişimi, pagkatapos'ta Pumunta sa mga ayar ng tarayıcısı",
    Questionnaire_Form: "Katanungan'ı oluşturmak",
    No_images_found: "Hayal kırıklığına uğradım!",
    Image: "Laravan",
    Captured_Images: "Nakunan ng Mga Larawan",
    Share_your_screen: "Ekranınızı paylaşın",
    Share_your_live_video: "Canlı videonuzu paylaşın?",
    This_will_stop_your_video:
      "Bu, videonuzu durduracaktır. Devam etmek istiyor musun?",
    This_will_stop_screen_share:
      "Bu, ekran paylaşımını durduracaktır. Devam etmek istiyor musun?",
    Floor: "Zemin",
    Room_Area_Label: "Oda/Alan Etiketi",
    Room_Area_Name: "Oda/Alan Adı(ör. Yatak Odası 1, Banyo 2, vb.)",
    Room_Area_Name_Placeholder: "Oda/Alan Adı",
    You: "Sen",
    Are_you_sure_you_want_to_delete_captured_image:
      "Yakalanan görüntüyü silmek istediğinizden emin misiniz?",
    Start_Over:
      "Baştan Başlayın - Kat Planlarını Silmek İstediğinizden Emin misiniz?",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "Ölçüm işleminden çıkmak istediğinizden emin misiniz?",
    Labels: "Etiketler",
    Select_Language: "Dil Seçin",
    This_call_is_being_recorded: "Bu video görüşmesi kaydediliyor",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "Bu arama, değerleme uzmanınız tarafından özel çalışma dosyası için kaydedilmektedir.",
    It_will_not_be_shared: `İpoteğiniz dahil olmak üzere herhangi bir dış tarafla paylaşılmayacaktır.
    borç veren ve ekspertizciniz onu şuradan indiremez.
    RemoteVal sitesi. Yalnızca değerleme uzmanının tamamlaması için kullanılacaktır.
    senin değerlendirmen.`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "Ekranınızın kaydedilmesini istemiyorsanız, lütfen şimdi değerleme uzmanınıza bildirin.",

    Measurement: "Ölçüm",
    Create_Measurement: "Ölçüm Oluştur",
    Fencing: "eskrim",
    Show: "Göstermek",
    Hide: "Saklamak",
    Live_Floor_Plan: "Canlı Kat Planı",
    Expand: "Genişletmek",
    Collapse: "Yıkılmak",
    Instruction_for_Call_Settings: "Çağrı Ayarları Talimatı",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      'Hoparlör, Mikrofon ve Kamera, "daha fazla" menüsünden arama ayarları ile test edilebilir',
    To_Check_the_call_settings_now_click_on_the_below_button:
      "Çağrı ayarlarını şimdi kontrol etmek için aşağıdaki düğmeye tıklayın.",
    Call_recording_by_virtual_inspector:
      "Bu görüntülü görüşme, sanal müfettişiniz tarafından özel çalışma dosyaları ve talep eden kuruluş tarafından iç denetim amaçları için kaydedilmektedir. Herhangi bir dış tarafla paylaşılmayacaktır.",
    Call_and_Screen_Recording_Opt_Out:
      "Ekranınızın ve bu çağrının kaydedilmesini istemiyorsanız, lütfen sanal müfettişinizi şimdi bilgilendirin.",
    Test_Audio_Video_Components_Button:
      "Hoparlör, mikrofon ve kamera aşağıdaki düğmeye tıklayarak test edilebilir.",
  },
  BUTTONS: {
    Re_Connect: "Yeniden Bağlan",
    End_Session: "Oturum sonu",
    Chat: "Sohbet etmek",
    Participants: "Katılımcılar",
    Notes: "Notlar",
    Attach_Document: "Belge ekle",
    Questionnaire: "anket",
    Labels: "Etiketler",
    Pointer: "Işaretçi",
    Markup: "işaretleme",
    Flash_Off: "Flaş Kapalı",
    Flash_On: "Flaş Açık",
    Call_Recording: "Arama kaydı",
    Capture_Image: "Görüntüyü yakala",
    Screen_Share: "Ekran paylaşımı",
    Stop_Screen_Share: "Ekran Paylaşımını Durdur",
    Call_Settings: "Çağrı ayarları",
    Clear_All: "Hepsini temizle",
    Exit: "çıkış",
    Save: "Kaydetmek",
    Close: "Kapat",
    Save_and_Exit: "Kaydet ve çık",
    Continue: "Devam et",
    Markup: "işaretleme",
    Street_View: "sokak Görünümü",
    Open_in_App: "Uygulamada aç",
    Privacy_Policy: "Gizlilik Politikası",
    Invite: "Davet etmek",
    Join: "Katılmak",
    Add_Captured_Image_Label: "Yakalanan Görüntü Etiketi Ekle",
    No: "Numara",
    Yes: "Evet",
    Delete: "Silmek",
    Cancel: "İptal",
    RETRY: "YENİDEN DENE",
    OK: "Tamam",
    Back: "Geri",
    Leave_Call: "Çağrıdan Ayrıl",
    Got_It: "Anladım",
    Start_New_Inspection: "Yeni İnceleme Başlat",
    Resume_Existing_Inspection: "Mevcut Denetimi Sürdür",
    View_All_Current_Sketch: "Tüm Mevcut Çizim(ler)i Görüntüle",
    Open_Sketch: "Çizimi Aç",
    Auto_Corner_Detection_ON: "Otomatik Köşe Algılama AÇIK",
    Auto_Corner_Detection_OFF: "Otomatik Köşe Algılama KAPALI",
    Manually_Capture_Corner: "Köşeyi Manuel Olarak Yakala",
    Undo_Last_Corner_Capture: "Son Köşe Yakalamayı Geri Al",
    Connect_Corner_Finish_Sketch: "Köşeyi Bağla ve Çizimi Bitir",
    Capture_3D_Room_Height: "3D Oda Yüksekliğini Yakala",
    Set_Fixed_3D_Room_Height: "Sabit 3D Oda Yüksekliğini Ayarla",
    Capture_Door: "Kapı Yakalama",
    Set_Door: "Kapıyı Ayarla",
    Show_Finished_Floor_Plan_Sketch: "Bitmiş Kat Planı Krokisini Göster",
    Back_to_Previous_Screen: "Önceki Ekrana Dön",
    Add_Room: "Oda Ekle",
    Publish_the_floor_plan: "Kat planını yayınla",
    Exit_Return_to_Inspection: "Çıkış ve Denetime Geri Dön",
    Audio: "Ses",
    Video: "Video",
    Microphone: "Mikrofon",
    Microphone_not_found: "Mikrofon bulunamadı",
    Microphone_Blocked: "Mikrofon Engellendi",
    Default: "Varsayılan",
    Grant_Permission: "İzin Ver",
    Speaker_Blocked: "Hoparlör Engellendi",
    Play_Test_Sound: "Test Sesini Çal",
    Preview: "Ön izleme",
    Camera: "Kamera",
    Camera_not_found: "Kamera bulunamadı",
    Camera_Blocked: "Kamera Engellendi",
    Done: "Tamamlandı",
    View: "görüş",
    Capture_Labeled_Images: "Etiketli Görüntüleri Yakalayın",
    Capture_Measurements: "Yakalama Ölçümleri",
    Instructions: "Talimatlar",
    Start_Measurement_Process: "Ölçüm İşlemini Başlat",
    Flash_Light_Not_Supported:
      "Konuk cihazında desteklenmeyen veya hazır değil uzak el feneri",
  },
  TOOLTIP: {
    Mute_My_Audio: "Sesi Kapat",
    Unmute_My_Audio: "Sesi Aç",
    Stop_My_Video: "Videomu Durdur",
    Start_My_Video: "Videomu Başlat",
    Capture_Images_with_No_Labels: "Etiketsiz Görüntüler Yakalayın",
    Start_Inspection: "Denetimi Başlat",
    Chat: "Sohbet etmek",
    More_Options: "Daha fazla seçenek",
    End_Call: "Son Arama",
    Unpin_Video: "Videonun sabitlemesini kaldır",
    Pin_Video: "Videoyu Sabitle",
    Add_New_Note: "Yeni Not Ekle",
    Refresh: "Yenile",
    View_More: "Daha fazla göster",
    Invite_Participant: "Katılımcı Davet",
    Delete_Image: "Resmi Sil",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "Davetiye Başarıyla Gönderildi",
    IOS_SAFARI_HELP: "En iyi sonuçlar için Safari kullanmanızı öneririz",
    UNEXPECTED_ERROR: "Beklenmeyen Hatalar Oluştu. Daha sonra tekrar deneyin.",
    CALL_LINK_NOT_FOUND: "Çağrı Bağlantısı bulunamadı.",
    CREATE_BOOKMARK_MESSAGE:
      "Lütfen Yöneticiden Yer İşareti Eklemesini İsteyin",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED: "Dosya boyutu 10 MB'tan küçük olmalıdır",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT: "Desteklenmeyen dosya formatı",
    LIVE_POINTER_STARTED:
      "Canlı işaretçi başladı. İşaretçiyi sürükleyerek hareket ettirebilirsiniz.",
    LIVE_POINTER_STOPPED: "Durdurulmuş canlı işaretçi",
    APPRAISAL_EXIT_APPROVED: "Çıkış başarıyla onaylandı.",
    APPRAISAL_EXIT_DENIED: "Çıkış reddedildi.",
    MARKUP_CLOSED: "İşaretleme Durduruldu",
    PRIVACY_POLICY_FAIL: "Geçerli bir Gizlilik Politikası URL'si bulunamadı!",

    SWITCH_CAMERA_REQUEST_REJECTED:
      "Ev Sahibi, istenen kamera görüntüsünü paylaşmadı.",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email:
      "Lütfen Cep Telefonu veya E-posta Seçiniz",
    This_field_is_required: "Bu alan gereklidir",
    Cell_Phone_Must_Be_Valid: "Cep Telefonu Geçerli Olmalıdır",
    Name_is_Required: "İsim gerekli",
    Name_Must_Be_Valid: "Ad Geçerli Olmalıdır",
    Invalid_Text: "Geçersiz Metin",
    Email_Must_Be_Valid: "E-posta Geçerli Olmalıdır",
    Name_must_be: "Ad olmalıdır",
    characters_max: "maksimum karakter",
    Please_select_the_Floor: "Lütfen Zemini seçin",
    Please_enter_the_Room_Area_Name: "Lütfen Oda/Alan Adını giriniz",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "Ses çıkış cihazını seçmek için HTTPS kullanmanız gerekir",
    Something_went_wrong:
      "Bir şeyler yanlış gitti. Lütfen daha sonra tekrar deneyiniz.",
    Oops_Call_duration_is_over_and_session_is_ended:
      "Hata! Çağrı süresi bitti ve oturum sonlandırıldı.",
    No_participant_has_joined_yet_Ending_the_call_session:
      "Henüz hiçbir katılımcı katılmadı! Çağrı oturumunu sonlandırma",
    You_are_offline_Check_your_internet_connection:
      "Çevrimdışısınız. İnternet bağlantınızı kontrol edin.",
  },
  starting_inspection: "Denetime Başlama",
  starting_measurement: "Ölçüme Başlama",
  error: "Hata",
  contact_us: "Bize Ulaşın",
  support_popup_msg:
    "Hata! Bir şeyler ters gitti, lütfen arada bir tekrar deneyin veya bir sorunu destek birimimize bildirin.",
  select_po: "Denetim Sürecini Başlatmak için Mülk Sahibini Seçin",
  request_po_for_camera:
    "Lütfen Mülk Sahibinden Denetim sürecini başlatmak için kamera görünümünü açmasını isteyin",
  wait_until_po_joins:
    "Lütfen Mülk Sahibinin Denetim sürecini başlatmak için çağrıya katılmasını bekleyin",
  select_measurement_method: "Ölçüm Yöntemini Seçin",
  inspection_inprogress: "Muayene zaten devam ediyor",
  model_msg_retry:
    "Mülk Sahibi ile iletişim kanalı kurulamıyor, Lütfen tekrar deneyin.",
  model_msg_leaveCall:
    "Her iki tarafta da internet bağlantısı sorunu var gibi görünüyor, bu nedenle lütfen mevcut aramanın bağlantısını kesin ve tekrar bağlanın.",
  please_wait: "Lütfen bekle...",
  loading_sketch: "Çizim yükleniyor...",
  connection_timeout_msg:
    "Bağlantı zaman aşımına uğradı. Olağandışı bir şey oldu ve yanıt vermesi beklenenden uzun sürüyor. Lütfen tekrar deneyin.",
  previous_measurement_prblm_msg:
    "Ops, önceki ölçümde bir sorun oluştu. Lütfen yeni Ölçüm İşlemi ile tekrar deneyin.",
  measurement_retry_msg: "Ölçüm süreci başlamadı. Lütfen tekrar deneyiniz.",
  opensketch_retry_msg:
    "Sketch'i aç işlemi başlamadı. Lütfen tekrar deneyiniz.",
};
