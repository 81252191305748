/* eslint-disable import/no-anonymous-default-export */
import {
  START_LOAD_BOOKMARK,
  LOAD_BOOKMARK_SUCCESS,
  LOAD_BOOKMARK_ERROR,
  START_CREATE_BOOKMARK,
  CREATE_BOOKMARK_SUCCESS,
  CREATE_BOOKMARK_ERROR,
} from "../actions/actionTypes";

export const createBookmarkDefaultState = {
  creating: false,
  created: {},
  creatingError: null,
};

export const defaultState = {
  bookmarks: [],
  loading: false,
  error: null,
  ...createBookmarkDefaultState,
};

export default function (
  state = defaultState,
  action = { type: null, payload: {} }
) {
  switch (action.type) {
    case START_LOAD_BOOKMARK: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOAD_BOOKMARK_SUCCESS: {
      return {
        ...state,
        bookmarks: [...action.payload],
        loading: false,
      };
    }

    case LOAD_BOOKMARK_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case START_CREATE_BOOKMARK: {
      return {
        ...state,
        creating: true,
      };
    }

    case CREATE_BOOKMARK_SUCCESS: {
      return {
        ...state,
        created: {
          ...action.payload,
        },
        creating: false,
      };
    }

    case CREATE_BOOKMARK_ERROR: {
      return {
        ...state,
        creatingError: action.payload,
        creating: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
