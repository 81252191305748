export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    return window.navigator.geolocation.getCurrentPosition(
      resolve,
      reject,
      options
    );
  });
};
