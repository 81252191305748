import api from "./api";

export default class QuestionnaireAPI {
  getQuestionnaire(jobOrderId) {
    return api.get(`questionnaire/${jobOrderId}`);
  }

  saveQuestionnaire(data) {
    return api.put(`questionnaire/update`, data);
  }
}
