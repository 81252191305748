import api from "./apis/api";
import { getFileFormData } from "../helpers/commonJSFunction";
import { dehydrateCaptureMarkupImage } from "./transformers";

class MarkupImageService {
  upload(callUUId, data, extraPayload = {}) {
    let extraFields = {};
    if (extraPayload && (extraPayload.bookmarkId || extraPayload.label)) {
      extraFields.bookmarkId = extraPayload.bookmarkId;
      extraFields.bookmarkLabel = extraPayload.label;
      extraFields.isOffline = extraPayload.isOffline;
    }
    const fileFormData = getFileFormData(data, "abc.jpg", extraFields);
    return api.markup
      .uploadCaptureMarkupImage(callUUId, fileFormData)
      .then((res) => dehydrateCaptureMarkupImage(res.data));
  }
}

export default new MarkupImageService();
