import * as actionTypes from "./actionTypes";
import CaptureImageService from "../services/captureImageService";

const captureImage = (payload) => {
  return {
    type: actionTypes.CAPTURE_IAMGE,
    payload,
  };
};

export const uploadImage = (
  eitherCallUUIdOrJobOrderId,
  data,
  extraPayload = {}
) => {
  return (dispatch, getstate) => {
    const requestData = { image: data, extraPayload };
    dispatch(captureImage(requestData));
    dispatch(processImageQueue(eitherCallUUIdOrJobOrderId));
  };
};

const processImageQueue = (eitherCallUUIdOrJobOrderId) => {
  return (dispatch, getstate) => {
    const isuplaoding = getstate().captureImages.uploading || false;
    if (!isuplaoding) {
      dispatch(startQueueProcess());
      dispatch(uploadfromQueue(eitherCallUUIdOrJobOrderId));
    } else {
      /** do nothing as already something is running */
    }
  };
};

const uploadfromQueue = (eitherCallUUIdOrJobOrderId) => {
  return (dispatch, getstate) => {
    const queue = getstate().captureImages.queue;
    const requestData = [...queue].shift();
    if (requestData) {
      dispatch(dequeue());
      CaptureImageService.upload(
        eitherCallUUIdOrJobOrderId,
        requestData.image,
        requestData.extraPayload
      )
        .then((result) => {
          dispatch(captureImageUploadCompleted(result));
          dispatch(addCapturedImages(result));
          return dispatch(uploadfromQueue(eitherCallUUIdOrJobOrderId));
        })
        .catch((error) => {
          dispatch(captureImageUploadFailed());
          return dispatch(uploadfromQueue(eitherCallUUIdOrJobOrderId));
        });
    } else {
      setTimeout(() => {
        dispatch(queueCompleted(eitherCallUUIdOrJobOrderId));
      }, 10);
    }
  };
};

const queueCompleted = (eitherCallUUIdOrJobOrderId) => {
  return (dispatch, getstate) => {
    const queue = getstate().captureImages.queue;
    if (queue.length == 0) {
      dispatch(clearQueue());
    } else {
      dispatch(processImageQueue(eitherCallUUIdOrJobOrderId));
    }
  };
};

const dequeue = () => {
  return {
    type: actionTypes.DEQUEUE_CAPTURE_IMAGE,
  };
};

const clearQueue = () => {
  return {
    type: actionTypes.CLEAR_QUEUE,
  };
};

const startQueueProcess = () => {
  return {
    type: actionTypes.START_QUEUE,
  };
};

const captureImageUploadCompleted = (payload) => {
  return {
    type: actionTypes.CAPTURE_IMAGE_UPLOAD_COMPLETED,
    payload,
  };
};
const captureImageUploadFailed = () => {
  return {
    type: actionTypes.CAPTURE_IMAGE_UPLOAD_FAILED,
  };
};

export const addCapturedImages = (payload) => {
  return {
    type: actionTypes.ADD_CAPTURE_IMAGE,
    payload,
  };
};

export const deleteCapturedImage = (payload) => {
  return {
    type: actionTypes.DELETE_CAPTURE_IMAGE,
    payload,
  };
};

export const setSelectedImages = (labelData) => {
  return {
    type: actionTypes.SET_CAPTURED_IMAGE_LABELS,
    payload: labelData,
  };
};

export const deleteImage = (data) => {
  return (dispatch, getstate) => {
    return CaptureImageService.deleteImage(data)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  };
};

export const addCachedCapturedImage = (key, value) => {
  return {
    type: actionTypes.ADD_CACHED_CAPTURED_IMAGE,
    key: key,
    value: value,
  };
};
