import React from "react";
import { useTranslation } from "react-i18next";

export default function CommonModel(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p>{props.commonModelMsg}</p>
          </div>
          <div className="bottom-btn">
            <button
              className="green-btn"
              onClick={
                props.commonModelBtn == t("BUTTONS.OK")
                  ? props.toggleCommonModel
                  : props.retryForAPIError
              }
            >
              {props.commonModelBtn}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
