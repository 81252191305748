import React from "react";
import "./callRemainingTimer.scss";
import { appConstants } from "../../constants/app.constants";
import classnames from "classnames";


const CallRemainingTimer = ({
  hours,
  minutes,
  seconds,
  remainingTimeInSecs,
  remainingTimeAlertInSec,

  
  
}) => {
  
  
  
  return (
    <>
      <div className="page-timer" style={{"color":(remainingTimeInSecs < remainingTimeAlertInSec)?"red":""}}>
        {hours}:{minutes}:{seconds}
      </div>

    </>
  );
};

export default CallRemainingTimer;
