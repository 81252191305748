import React, { Component } from "react";
import "./markupView.scss";
import { fabric } from "fabric";
import "fabric-history";

import _, { times } from "lodash";
import { extractUserName } from "./../../helpers/commonJSFunction";
import { appConstants } from "../../constants/";
import { customToast } from "../../helpers/customToast";
import { MARKUP_CLOSED } from "./../../constants/commonStrings";
import { isMobile } from "react-device-detect";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import axios from "axios";
class MarkupView extends Component {
  canvas = null;
  //strokeColor = "#B2B2B2";

  isDrawingMode = false;

  showPalette = true;
  canvasImage = null;
  scale = 1;

  pointerPosition = [];
  XPOINTS_START = 0;
  YPOINTS_START = 0;
  HEIGHT = 0;
  WIDTH = 0;

  COLOR = "#000000";

  isDrawing = false;
  objectId = null;

  calculatedCanvasHeight = 0;
  calculatedCanvasWidth = 0;
  shapeType = appConstants.SHAPE_TYPE.PENCIL_DRAW;

  constructor(props) {
    super(props);

    this.state = {
      closeMarkUpModalVisible: false,
      markupClosedBy: "",
      markupClosedByCurrentUser: false,
      drawType: "pencil",
      strokeColor: "#000000",
      isColorPaletteVisible: false,
      borderWidth: appConstants.MARKUP_VIEW.PENCIL_STROKE_WIDTH,
      isShapePopUpOpen: false,
    };

    this.togglePalette = this.togglePalette.bind(this);
    this.closePalette = this.closePalette.bind(this);
    this.closeShapePopUp = this.closeShapePopUp.bind(this);
  }

  async componentDidMount() {
    let containS3ImageUrl = this.props.markupImageURL.includes(
      appConstants.S3Image
    );
    if (containS3ImageUrl) {
      this.getMarkupImage(this.props.markupImageURL);
    } else {
      axios
        .get(this.props.markupImageURL, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          responseType: "blob",
        })
        .then((v) => {
          const reader = new window.FileReader();
          reader.readAsDataURL(v.data);
          reader.onload = () => {
            this.getMarkupImage(reader.result);
          };
        });
    }
  }

  async getMarkupImage(newImg) {
    let canvasDimension = 640;
    let canvasWidth = 640;

    //Initial
    //canvasDimension = window.innerWidth < canvasDimension ? window.innerWidth : canvasDimension;

    //Try:1
    //canvasDimension = window.innerWidth > canvasDimension ? window.innerWidth : canvasDimension;

    //Try:2
    canvasDimension = window.innerHeight;

    // console.log("markup - canvasDimension - ", canvasDimension);
    let canvas;

    this.props.sendDownloadingMarkupImageDataTrack();

    // console.log("markup - this.props.markupImageURL - ", this.props.markupImageURL);

    fabric.Image.fromURL(
      newImg,
      function (img) {
        this.canvasImage = img;

        let imageDimensionToScale =
          this.canvasImage.height > this.canvasImage.width
            ? this.canvasImage.height
            : this.canvasImage.width;

        // console.log("markup - this.canvasImage.height and width - ", this.canvasImage.height, this.canvasImage.width);
        // console.log("markup - imageDimensionToScale - ", imageDimensionToScale);

        let imageDimensionScale =
          this.canvasImage.height > this.canvasImage.width
            ? this.canvasImage.width / this.canvasImage.height
            : this.canvasImage.height / this.canvasImage.width;

        // console.log("markup - imageDimensionScale - ", imageDimensionScale);

        this.scale =
          imageDimensionToScale < canvasDimension
            ? canvasDimension / imageDimensionToScale
            : imageDimensionToScale / canvasDimension;

        // console.log("markup - scale - ", this.scale);

        let calculatedCanvasHeight = this.canvasImage.height * this.scale;
        let calculatedCanvasWidth = this.canvasImage.width * this.scale;

        // console.log("markup - calculatedCanvasHeight and  calculatedCanvasWidth 1 - ", calculatedCanvasHeight, calculatedCanvasWidth);

        //Updated scale based on height

        // console.log("markup - this.canvasImage.height 2 - ", this.canvasImage.height);

        let imageDimensionScaleForHeight =
          this.canvasImage.height > canvasDimension
            ? canvasDimension / this.canvasImage.height
            : canvasDimension / this.canvasImage.height;

        // console.log("markup - imageDimensionScaleForHeight - ", imageDimensionScaleForHeight);

        // //Updated scale
        this.scale = imageDimensionScaleForHeight;

        calculatedCanvasHeight = this.canvasImage.height * this.scale;
        calculatedCanvasWidth = this.canvasImage.width * this.scale;
        // console.log("markup - calculatedCanvasHeight and  calculatedCanvasWidth - ", calculatedCanvasHeight, calculatedCanvasWidth);

        this.calculatedCanvasHeight = calculatedCanvasHeight;
        this.calculatedCanvasWidth = calculatedCanvasWidth;

        fabric.Object.prototype.toObject = (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              id: this.id,
              radius: this.radius,
            });
          };
        })(fabric.Object.prototype.toObject);

        canvas = new fabric.Canvas("markupcanvas", {
          height: calculatedCanvasHeight,
          width: calculatedCanvasWidth,
          objectCaching: true,
        });

        fabric.Canvas.prototype.getItemByAttr = function (attr, name) {
          let object = null,
            objects = this.getObjects();

          for (let i = 0, len = this.size(); i < len; i++) {
            if (objects[i][attr] && objects[i][attr] === name) {
              object = objects[i];
              break;
            }
          }
          return object;
        };

        fabric.LineArrow = fabric.util.createClass(fabric.Line, {
          type: "lineArrow",

          initialize: function (element, options) {
            options || (options = {});
            this.callSuper("initialize", element, options);
          },

          toObject: function () {
            return fabric.util.object.extend(this.callSuper("toObject"));
          },

          _render: function (ctx) {
            this.callSuper("_render", ctx);

            // do not render if width/height are zeros or object is not visible
            if (this.width === 0 || this.height === 0 || !this.visible) {
              return;
            }

            ctx.save();

            let xDiff = this.x2 - this.x1;
            let yDiff = this.y2 - this.y1;
            let angle = Math.atan2(yDiff, xDiff);
            ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
            ctx.rotate(angle);
            ctx.beginPath();
            //move 10px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
            ctx.moveTo(10, 0);
            ctx.lineTo(-20, 15);
            ctx.lineTo(-20, -15);
            ctx.closePath();
            ctx.fillStyle = this.stroke;
            ctx.fill();

            ctx.restore();
          },
        });

        fabric.LineArrow.fromObject = function (object, callback) {
          callback &&
            callback(
              new fabric.LineArrow(
                [object.x1, object.y1, object.x2, object.y2],
                object
              )
            );
        };

        fabric.LineArrow.async = true;

        canvas.setBackgroundImage(
          this.canvasImage,
          canvas.renderAll.bind(canvas),
          {
            scaleX: this.scale,
            scaleY: this.scale,
          }
        );

        canvas.set("dirty", true);

        this.props.sendStartedMarkupImageDataTrack();

        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush.width =
          appConstants.MARKUP_VIEW.PENCIL_STROKE_WIDTH;
        canvas.freeDrawingBrush.color = this.state.strokeColor;

        this.canvas = canvas;

        canvas
          .on("object:moving", function (e) {
            let p = e.target;
          })
          .on(
            "object:moved",
            function (e) {
              let objectMoved = e.target;

              let shapeObject = {
                id: objectMoved.id,
                objectId: objectMoved.id,
                top: objectMoved.top,
                left: objectMoved.left,
                angle: objectMoved.angle,
                scaleX: objectMoved.scaleX,
                scaleY: objectMoved.scaleY,
                HEIGHT: this.calculatedCanvasHeight,
                WIDTH: this.calculatedCanvasWidth,
                text: objectMoved.text,
                flipX: objectMoved.flipX,
                flipY: objectMoved.flipY,
              };
              this.props.markupShapeMoved(shapeObject, objectMoved.id);
            }.bind(this)
          )
          .on(
            "object:modified",
            function (e) {
              let objectMoved = e.target;

              let shapeObject = {
                id: objectMoved.id,
                objectId: objectMoved.id,
                top: objectMoved.top,
                left: objectMoved.left,
                angle: objectMoved.angle,
                scaleX: objectMoved.scaleX,
                scaleY: objectMoved.scaleY,

                HEIGHT: this.calculatedCanvasHeight,
                WIDTH: this.calculatedCanvasWidth,
                text: objectMoved.text,
                flipX: objectMoved.flipX,
                flipY: objectMoved.flipY,
              };
              this.props.markupShapeMoved(shapeObject, objectMoved.id);
            }.bind(this)
          )
          .on(
            "object:rotated",
            function (e) {
              let objectRotated = e.target;

              let shapeObject = {
                id: objectRotated.id,
                objectId: objectRotated.id,
                top: objectRotated.top,
                left: objectRotated.left,
                angle: objectRotated.angle,
                scaleX: objectRotated.scaleX,
                scaleY: objectRotated.scaleY,
                HEIGHT: this.calculatedCanvasHeight,
                WIDTH: this.calculatedCanvasWidth,
                text: objectRotated.text,
                flipX: objectRotated.flipX,
                flipY: objectRotated.flipY,
              };
              this.props.markupShapeMoved(shapeObject, objectRotated.id);
            }.bind(this)
          )
          .on(
            "object:scaled",
            function (e) {
              let objectScaled = e.target;

              let shapeObject = {
                id: objectScaled.id,
                objectId: objectScaled.id,
                top: objectScaled.top,
                left: objectScaled.left,
                angle: objectScaled.angle,
                scaleX: objectScaled.scaleX,
                scaleY: objectScaled.scaleY,
                HEIGHT: this.calculatedCanvasHeight,
                WIDTH: this.calculatedCanvasWidth,
                text: objectScaled.text,
                flipX: objectScaled.flipX,
                flipY: objectScaled.flipY,
              };
              this.props.markupShapeMoved(shapeObject, objectScaled.id);
            }.bind(this)
          )
          .on(
            "text:editing:exited",
            function (e) {
              let objectScaled = e.target;

              let shapeObject = {
                id: objectScaled.id,
                objectId: objectScaled.id,
                top: objectScaled.top,
                left: objectScaled.left,
                angle: objectScaled.angle,
                scaleX: objectScaled.scaleX,
                scaleY: objectScaled.scaleY,
                HEIGHT: this.calculatedCanvasHeight,
                WIDTH: this.calculatedCanvasWidth,
                text: objectScaled.text,
                text: objectScaled.text,
                flipX: objectScaled.flipX,
                flipY: objectScaled.flipY,
              };
              this.props.markupShapeMoved(shapeObject, objectScaled.id);
            }.bind(this)
          )
          .on(
            "path:created",
            function (e) {
              const canvasObject = this.getJSONData(this.canvas);
              let annontationObject =
                canvasObject.objects[canvasObject.objects.length - 1];
              annontationObject.id = this.objectId;
              fabric.Object.prototype.id = this.objectId;
              canvasObject.objects[canvasObject.objects.length - 1] =
                annontationObject;

              this.canvas.loadFromJSON(
                canvasObject,
                this.canvas.renderAll.bind(this.canvas)
              );

              // e.path.set();
            }.bind(this)
          )
          .on(
            "mouse:down",
            function ({ e }) {
              if (
                !(
                  this.state.drawType == "pencil" ||
                  this.state.drawType == "highlight"
                )
              ) {
                return;
              }

              this.isDrawing = true;
              this.pointerPosition = [];

              this.objectId = new Date().getTime();

              //onMouseDown(e);

              const pointer = canvas.getPointer(e);

              this.XPOINTS_START = pointer.x;
              this.YPOINTS_START = pointer.y;

              pointer.XPOINTS_START = pointer.x;
              pointer.YPOINTS_START = pointer.y;

              pointer.XPOINTS = pointer.x;
              pointer.YPOINTS = pointer.y;
              pointer.HEIGHT = calculatedCanvasHeight;
              pointer.WIDTH = calculatedCanvasWidth;

              pointer.borderWidth = this.state.borderWidth;
              pointer.COLOR = this.state.strokeColor;

              this.pointerPosition.push(pointer);
            }.bind(this)
          )
          .on(
            "mouse:up",
            function ({ e }) {
              if (
                !(
                  this.state.drawType == "pencil" ||
                  this.state.drawType == "highlight"
                )
              ) {
                return;
              }

              this.isDrawing = false;

              const pointer = canvas.getPointer(e);

              pointer.XPOINTS_START = this.XPOINTS_START;
              pointer.YPOINTS_START = this.XPOINTS_START;

              pointer.XPOINTS = pointer.x;
              pointer.YPOINTS = pointer.y;

              pointer.borderWidth = this.state.borderWidth;
              pointer.COLOR = this.state.strokeColor;

              pointer.HEIGHT = calculatedCanvasHeight;
              pointer.WIDTH = calculatedCanvasWidth;

              this.pointerPosition.push(pointer);

              this.props.markupPathCreated(this.pointerPosition, this.objectId);
            }.bind(this)
          )
          .on(
            "mouse:move",
            function ({ e }) {
              if (this.isDrawing) {
                const pointer = canvas.getPointer(e);

                pointer.XPOINTS_START = this.XPOINTS_START;
                pointer.YPOINTS_START = this.XPOINTS_START;

                pointer.XPOINTS = pointer.x;
                pointer.YPOINTS = pointer.y;

                pointer.borderWidth = this.state.borderWidth;
                pointer.COLOR = this.state.strokeColor;
                pointer.HEIGHT = calculatedCanvasHeight;
                pointer.WIDTH = calculatedCanvasWidth;

                this.pointerPosition.push(pointer);
              }
            }.bind(this)
          );
      }.bind(this),
      {
        crossOrigin: "anonymous",
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.incomingMarkupAnnotationObject.timestamp !=
      this.props.incomingMarkupAnnotationObject.timestamp
    ) {
      if (
        this.props.incomingMarkupAnnotationObject.statusShape ==
        appConstants.SHAPE_STATUS.DRAW
      ) {
        let annotationPoint =
          this.props.incomingMarkupAnnotationObject.annotationPoint;

        if (
          this.props.incomingMarkupAnnotationObject.shapeType ==
          appConstants.SHAPE_TYPE.CIRCLE
        ) {
          annotationPoint = JSON.parse(annotationPoint);

          let shapeObject = {
            id: "" + annotationPoint.objectId,
            objectId: "" + annotationPoint.objectId,
            top:
              annotationPoint.top *
              (this.calculatedCanvasHeight / annotationPoint.HEIGHT),
            left:
              annotationPoint.left *
              (this.calculatedCanvasWidth / annotationPoint.WIDTH),
            radius: 50,
            fill: "",
            stroke: annotationPoint.stroke,
            strokeWidth: 2,
            type: "circle",
          };

          let drawObject = new fabric.Circle(shapeObject);
          this.canvas.add(drawObject);
          this.canvas.renderAll();

          let canvasObject = this.getJSONData(this.canvas);
          var annontationObject =
            canvasObject.objects[canvasObject.objects.length - 1];

          //canvasObject = JSON.stringify(canvasObject);
          //console.log("canvasObject - ",canvasObject);

          annontationObject.id = annotationPoint.objectId;

          fabric.Object.prototype.id = annotationPoint.objectId;
          fabric.Object.prototype.radius = 50;
          canvasObject.objects[canvasObject.objects.length - 1] =
            annontationObject;

          this.canvas.loadFromJSON(
            canvasObject,
            this.canvas.renderAll.bind(this.canvas)
          );
        } else if (
          this.props.incomingMarkupAnnotationObject.shapeType ==
          appConstants.SHAPE_TYPE.RECTANGLE
        ) {
          annotationPoint = JSON.parse(annotationPoint);

          let shapeObject = {
            id: "" + annotationPoint.objectId,
            objectId: "" + annotationPoint.objectId,
            top:
              annotationPoint.top *
              (this.calculatedCanvasHeight / annotationPoint.HEIGHT),
            left:
              annotationPoint.left *
              (this.calculatedCanvasWidth / annotationPoint.WIDTH),
            height:
              annotationPoint.height *
              (this.calculatedCanvasHeight / annotationPoint.HEIGHT),
            width:
              annotationPoint.width *
              (this.calculatedCanvasWidth / annotationPoint.WIDTH),

            fill: "",
            stroke: annotationPoint.stroke,
            strokeWidth: 2,
          };

          let drawObject = new fabric.Rect(shapeObject);
          this.canvas.add(drawObject);
          this.canvas.renderAll();

          let canvasObject = this.getJSONData(this.canvas);
          var annontationObject =
            canvasObject.objects[canvasObject.objects.length - 1];

          annontationObject.id = annotationPoint.objectId;

          fabric.Object.prototype.id = annotationPoint.objectId;
          canvasObject.objects[canvasObject.objects.length - 1] =
            annontationObject;

          this.canvas.loadFromJSON(
            canvasObject,
            this.canvas.renderAll.bind(this.canvas)
          );
        } else if (
          this.props.incomingMarkupAnnotationObject.shapeType ==
          appConstants.SHAPE_TYPE.TEXT
        ) {
          annotationPoint = JSON.parse(annotationPoint);
          let drawObject = new fabric.IText(
            annotationPoint.text,
            annotationPoint
          );
          this.canvas.add(drawObject);
          this.canvas.renderAll();

          let canvasObject = this.getJSONData(this.canvas);
          var annontationObject =
            canvasObject.objects[canvasObject.objects.length - 1];

          annontationObject.id = annotationPoint.objectId;

          fabric.Object.prototype.id = annotationPoint.objectId;
          canvasObject.objects[canvasObject.objects.length - 1] =
            annontationObject;

          this.canvas.loadFromJSON(
            canvasObject,
            this.canvas.renderAll.bind(this.canvas)
          );
        } else if (
          this.props.incomingMarkupAnnotationObject.shapeType ==
          appConstants.SHAPE_TYPE.LINE
        ) {
          annotationPoint = JSON.parse(annotationPoint);

          let shapeObject = {
            id: "" + annotationPoint.objectId,
            objectId: "" + annotationPoint.objectId,
            top:
              annotationPoint.top *
              (this.calculatedCanvasHeight / annotationPoint.HEIGHT),
            left:
              annotationPoint.left *
              (this.calculatedCanvasWidth / annotationPoint.WIDTH),
            height:
              annotationPoint.height *
              (this.calculatedCanvasHeight / annotationPoint.HEIGHT),
            width:
              annotationPoint.width *
              (this.calculatedCanvasWidth / annotationPoint.WIDTH),

            fill: "",
            stroke: annotationPoint.stroke,
            strokeWidth: annotationPoint.strokeWidth,
          };

          let drawObject = new fabric.Line([50, 100, 200, 200], shapeObject);
          this.canvas.add(drawObject);
          this.canvas.renderAll();

          let canvasObject = this.getJSONData(this.canvas);
          var annontationObject =
            canvasObject.objects[canvasObject.objects.length - 1];

          annontationObject.id = annotationPoint.objectId;

          fabric.Object.prototype.id = annotationPoint.objectId;
          canvasObject.objects[canvasObject.objects.length - 1] =
            annontationObject;

          this.canvas.loadFromJSON(
            canvasObject,
            this.canvas.renderAll.bind(this.canvas)
          );
        } else if (
          this.props.incomingMarkupAnnotationObject.shapeType ==
          appConstants.SHAPE_TYPE.ARROW
        ) {
          annotationPoint = JSON.parse(annotationPoint);

          let shapeObject = {
            id: "" + annotationPoint.objectId,
            objectId: "" + annotationPoint.objectId,
            strokeWidth: annotationPoint.strokeWidth,
            left: annotationPoint.left,
            top: annotationPoint.top,
            fill: annotationPoint.stroke,
            stroke: annotationPoint.stroke,
            originX: "center",
            originY: "center",
            hasBorders: false,
            hasControls: true,
            scaleX: 1,
            scaleY: 1,
            angle: 0,
            HEIGHT: annotationPoint.HEIGHT,
            WIDTH: annotationPoint.WIDTH,
            flipX: false,
            flipY: false,
          };

          let drawObject = new fabric.LineArrow(
            [50, 100, 200, 200],
            shapeObject
          );
          this.canvas.add(drawObject);
          this.canvas.renderAll();

          let canvasObject = this.getJSONData(this.canvas);
          var annontationObject =
            canvasObject.objects[canvasObject.objects.length - 1];

          annontationObject.id = annotationPoint.objectId;

          fabric.Object.prototype.id = annotationPoint.objectId;
          canvasObject.objects[canvasObject.objects.length - 1] =
            annontationObject;

          this.canvas.loadFromJSON(
            canvasObject,
            this.canvas.renderAll.bind(this.canvas)
          );
        } else if (
          this.props.incomingMarkupAnnotationObject.shapeType ==
          appConstants.SHAPE_TYPE.PENCIL_DRAW
        ) {
          this.drawFromPoints(
            annotationPoint,
            this.props.incomingMarkupAnnotationObject.objectId,
            this.props.incomingMarkupAnnotationObject.borderWidth
          );
        }
      } else if (
        this.props.incomingMarkupAnnotationObject.statusShape ==
        appConstants.SHAPE_STATUS.REDO
      ) {
        let annotationPoint =
          this.props.incomingMarkupAnnotationObject.annotationPoint;

        if (
          this.props.incomingMarkupAnnotationObject.shapeType ==
          appConstants.SHAPE_TYPE.CIRCLE
        ) {
          annotationPoint = JSON.parse(annotationPoint);

          let shapeObject = {
            id: "" + annotationPoint.objectId,
            objectId: "" + annotationPoint.objectId,
            top:
              annotationPoint.top *
              (this.calculatedCanvasHeight / annotationPoint.HEIGHT),
            left:
              annotationPoint.left *
              (this.calculatedCanvasWidth / annotationPoint.WIDTH),
            radius: 50,
            fill: "",
            stroke: annotationPoint.stroke,
            strokeWidth: 2,
            type: "circle",
          };

          let drawObject = new fabric.Circle(shapeObject);
          this.canvas.add(drawObject);
          this.canvas.renderAll();

          let canvasObject = this.getJSONData(this.canvas);
          var annontationObject =
            canvasObject.objects[canvasObject.objects.length - 1];

          annontationObject.id = annotationPoint.objectId;

          fabric.Object.prototype.id = annotationPoint.objectId;
          fabric.Object.prototype.radius = 50;
          canvasObject.objects[canvasObject.objects.length - 1] =
            annontationObject;

          this.canvas.loadFromJSON(
            canvasObject,
            this.canvas.renderAll.bind(this.canvas)
          );
        } else if (
          this.props.incomingMarkupAnnotationObject.shapeType ==
          appConstants.SHAPE_TYPE.PENCIL_DRAW
        ) {
          this.drawFromPoints(
            annotationPoint,
            this.props.incomingMarkupAnnotationObject.objectId,
            this.props.incomingMarkupAnnotationObject.borderWidth
          );
        }
      } else if (
        this.props.incomingMarkupAnnotationObject.statusShape ==
        appConstants.SHAPE_STATUS.UNDO
      ) {
        let objectToDelete = this.props.incomingMarkupAnnotationObject;
        const canvasObject = this.getJSONData(this.canvas);
        let annotationPoints = canvasObject.objects;
        let matchingIndex = _.findIndex(annotationPoints, function (o) {
          return o.id == objectToDelete.objectId;
        });

        if (matchingIndex != -1) {
          annotationPoints.splice(matchingIndex, 1);
        }

        canvasObject.objects = annotationPoints;

        this.canvas.loadFromJSON(
          canvasObject,
          this.canvas.renderAll.bind(this.canvas)
        );
      } else if (
        this.props.incomingMarkupAnnotationObject.statusShape ==
        appConstants.SHAPE_STATUS.MOVE
      ) {
        let annotationPoint =
          this.props.incomingMarkupAnnotationObject.annotationPoint;

        annotationPoint = JSON.parse(annotationPoint);

        let objectToMove = this.canvas
          .getObjects()
          .find((obj) => obj.id == annotationPoint.objectId);

        let top =
          annotationPoint.top *
          (this.calculatedCanvasHeight / annotationPoint.HEIGHT);
        let left =
          annotationPoint.left *
          (this.calculatedCanvasWidth / annotationPoint.WIDTH);
        let angle = annotationPoint.angle;
        let scaleX = annotationPoint.scaleX;
        let scaleY = annotationPoint.scaleY;
        let flipX = annotationPoint.flipX;
        let flipY = annotationPoint.flipY;
        if (objectToMove) {
          if (annotationPoint.text)
            objectToMove.set("text", annotationPoint.text);
          objectToMove.set("left", left);
          objectToMove.set("top", top);
          objectToMove.set("angle", angle);
          objectToMove.set("scaleX", scaleX);
          objectToMove.set("scaleY", scaleY);
          !(typeof flipX === "undefined") && objectToMove.set("flipX", flipX);
          !(typeof flipY === "undefined") && objectToMove.set("flipY", flipY);
          objectToMove.setCoords();
        }
        this.canvas.renderAll();

        /*
        const canvasObject = this.getJSONData(this.canvas);
        var annotationPoints = canvasObject.objects;
        var matchingIndex = _.findIndex(annotationPoints, function (o) {
          return o.id == objectToDelete.objectId;
        });

        if (matchingIndex != -1) {
          annotationPoints.splice(matchingIndex, 1);
        }

        canvasObject.objects = annotationPoints;

        this.canvas.loadFromJSON(
          canvasObject,
          this.canvas.renderAll.bind(this.canvas)
        );
          */
      } else if (
        this.props.incomingMarkupAnnotationObject.status ==
        appConstants.ANNOTATION_ACK.CLEAR
      ) {
        this.clearCanvas(this.canvas);

        //this.canvas.setBackgroundImage(this.props.markupImageURL, this.canvas.renderAll.bind(this.canvas), {});
      } else if (
        this.props.incomingMarkupAnnotationObject.status ==
        appConstants.ANNOTATION_ACK.STOPPED
      ) {
        if (this.props.role == appConstants.USER_ROLES.PROPERTY_OWNER) {
          this.exitWithoutSaveMarkupView();
        } else {
          this.setState(
            {
              closeMarkUpModalVisible: true,
              markupClosedBy: extractUserName(
                this.props.incomingMarkupAnnotationObject.participantIdentity
              ),
              markupClosedByCurrentUser: false,
            },
            () => {}
          );
        }
      }
    }
  }

  togglePalette() {
    this.setState(
      {
        isColorPaletteVisible: !this.state.isColorPaletteVisible,
      },
      () => {}
    );
  }

  closePalette() {
    this.setState(
      {
        isColorPaletteVisible: false,
      },
      () => {}
    );
  }

  drawFromPoints = (annotationPoint, objectId, borderWidth) => {
    this.canvas.isDrawingMode = false;

    this.objectId = objectId;

    annotationPoint = JSON.parse(annotationPoint);
    let pointer = annotationPoint[0];
    let options = { pointer, e: {} };

    //this.canvas.freeDrawingBrush.width = pointer.borderWidth;
    this.canvas.freeDrawingBrush.width = borderWidth;
    this.canvas.freeDrawingBrush.color = pointer.COLOR;

    let calculatedX =
      (this.calculatedCanvasWidth * pointer.XPOINTS) / pointer.WIDTH;
    let calculatedY =
      (this.calculatedCanvasHeight * pointer.YPOINTS) / pointer.HEIGHT;

    pointer.y = calculatedY;
    pointer.x = calculatedX;

    this.canvas.freeDrawingBrush.onMouseDown(pointer, options);
    for (let i = 1; i < annotationPoint.length - 1; i++) {
      pointer = annotationPoint[i];
      options = { pointer, e: {} };

      calculatedX =
        (this.calculatedCanvasWidth * pointer.XPOINTS) / pointer.WIDTH;
      calculatedY =
        (this.calculatedCanvasHeight * pointer.YPOINTS) / pointer.HEIGHT;

      pointer.y = calculatedY;
      pointer.x = calculatedX;

      this.canvas.freeDrawingBrush.onMouseMove(pointer, options);
    }

    pointer = annotationPoint[annotationPoint.length - 1];

    calculatedX =
      (this.calculatedCanvasWidth * pointer.XPOINTS) / pointer.WIDTH;
    calculatedY =
      (this.calculatedCanvasHeight * pointer.YPOINTS) / pointer.HEIGHT;

    pointer.y = calculatedY;
    pointer.x = calculatedX;

    //options = { pointer, e: {} };
    options = { e: {} };

    this.canvas.freeDrawingBrush.onMouseUp(options);

    this.canvas.freeDrawingBrush.color = this.state.strokeColor;
    this.canvas.freeDrawingBrush.width = this.state.borderWidth;
    if (this.state.drawType != "") {
      this.canvas.isDrawingMode = true;
    }
  };

  freeDraw = (canvas) => {
    canvas.isDrawingMode = true;

    let pencilColor = this.state.strokeColor;

    /*
    if (pencilColor == "rgba(0,0,0,0.5)") {
      pencilColor = "#000000";
    } else {
      pencilColor = "#B2B2B2";
    }
    */

    //this.borderWidth = appConstants.MARKUP_VIEW.PENCIL_STROKE_WIDTH;

    canvas.freeDrawingBrush.width =
      appConstants.MARKUP_VIEW.PENCIL_STROKE_WIDTH;
    canvas.freeDrawingBrush.color = pencilColor;
    canvas.renderAll();

    this.isDrawingMode = true;

    this.setState(
      {
        drawType: "pencil",
        strokeColor: pencilColor,
        borderWidth: appConstants.MARKUP_VIEW.PENCIL_STROKE_WIDTH,
      },
      () => {}
    );
  };

  highlight = (canvas) => {
    this.isDrawingMode = true;
    canvas.isDrawingMode = true;

    let highlightColor = this.state.strokeColor;

    /*
    if (this.state.strokeColor == "#000000") {
      highlightColor = "rgba(0,0,0,0.5)";
    } else {
      highlightColor = "rgba(178,178,178,0.5)";
    }
    */

    this.borderWidth = appConstants.MARKUP_VIEW.HIGHLIGHT_STROKE_WIDTH;
    canvas.freeDrawingBrush.color = highlightColor;
    canvas.freeDrawingBrush.width =
      appConstants.MARKUP_VIEW.HIGHLIGHT_STROKE_WIDTH;

    this.setState(
      {
        drawType: "highlight",
        strokeColor: highlightColor,
        borderWidth: appConstants.MARKUP_VIEW.HIGHLIGHT_STROKE_WIDTH,
      },
      () => {}
    );

    canvas.renderAll();
  };

  clearCanvas = (canvas) => {
    //canvas.setBackgroundImage(this.props.markupImageURL, canvas.renderAll.bind(canvas), {});

    canvas.clear();
    canvas.setBackgroundImage(this.canvasImage, canvas.renderAll.bind(canvas), {
      scaleX: this.scale,
      scaleY: this.scale,
    });

    this.props.markupClear();
  };

  undo = (canvas) => {
    if (this.props.userMarkupObjectArray.length > 0) {
      const userMarkupObject =
        this.props.userMarkupObjectArray[
          this.props.userMarkupObjectArray.length - 1
        ];

      this.props.markupUndo(userMarkupObject);
    }
  };

  redo = (canvas) => {
    if (this.props.userUndoObjectArray.length > 0) {
      const userMarkupObject =
        this.props.userUndoObjectArray[
          this.props.userUndoObjectArray.length - 1
        ];
      this.props.markupRedo(userMarkupObject);
    }

    //canvas.redo()
    //canvas.setBackgroundImage(this.props.markupImageURL, canvas.renderAll.bind(canvas), {})
  };

  download = (canvas) => {
    const image = canvas.toDataURL("image/png", 1.0);
    this.props.saveMarkupImage(image);
  };

  setDrawingColor = (canvas, color) => {
    let highlightColor = color;
    if (this.state.drawType == "highlight") {
      /*
      if (color == "#000000") {
        highlightColor = "rgba(0,0,0,0.5)";
      } else {
        highlightColor = "rgba(178,178,178,0.5)";
      }
      */
    }

    canvas.freeDrawingBrush.color = highlightColor;

    this.setState(
      {
        strokeColor: highlightColor,
      },
      () => {}
    );
  };

  exitMarkupView = () => {
    this.setState(
      {
        closeMarkUpModalVisible: true,
        // markupClosedBy:this.props.userName,
        markupClosedBy: "You",
        markupClosedByCurrentUser: true,
      },
      () => {}
    );

    //this.props.markupClose();
  };

  closeExitMarkupView = () => {
    this.setState(
      {
        closeMarkUpModalVisible: false,
        markupClosedBy: "",
      },
      () => {}
    );

    //this.props.markupClose();
  };

  saveAndExitMarkupView = () => {
    const image = this.canvas.toDataURL("image/png", 1.0);
    this.props.saveMarkupImage(image);

    this.props.markupClose();
    customToast.success(i18n.t("COMMON_STRING.MARKUP_CLOSED"));
  };

  exitWithoutSaveMarkupView = () => {
    //Some API to save markup image
    this.props.markupClose();
    customToast.success(i18n.t("COMMON_STRING.MARKUP_CLOSED"));
  };

  async drawShape(shape) {
    let drawObject = null;
    let shapeObject = {};
    let shapeType = "";
    this.canvas.isDrawingMode = false;

    this.objectId = new Date().getTime();

    if (shape == appConstants.SHAPE_TYPE.CIRCLE) {
      this.setState(
        {
          drawType: "circle",
        },
        () => {}
      );

      shapeObject = {
        id: "" + this.objectId,
        objectId: "" + this.objectId,
        top: 10,
        left: 100,
        radius: 50,
        fill: "",
        // backgroundColor:this.state.strokeColor,
        stroke: this.state.strokeColor,
        // stroke: "rgba(0,0,0,1)",

        strokeWidth: 2,
        HEIGHT: this.calculatedCanvasHeight,
        WIDTH: this.calculatedCanvasWidth,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        // type: "circle",
      };

      drawObject = new fabric.Circle(shapeObject);
    } else if (shape == appConstants.SHAPE_TYPE.RECTANGLE) {
      this.setState(
        {
          drawType: "rectangle",
        },
        () => {}
      );

      shapeObject = {
        id: "" + this.objectId,
        objectId: "" + this.objectId,
        top: 10,
        left: 100,
        width: 75,
        height: 100,
        fill: "",
        stroke: this.state.strokeColor,
        strokeWidth: 2,
        HEIGHT: this.calculatedCanvasHeight,
        WIDTH: this.calculatedCanvasWidth,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        // type: "circle",
      };

      drawObject = new fabric.Rect(shapeObject);
    } else if (shape == appConstants.SHAPE_TYPE.LINE) {
      this.setState(
        {
          drawType: "line",
        },
        () => {}
      );

      shapeObject = {
        id: "" + this.objectId,
        objectId: "" + this.objectId,
        top: 10,
        left: 100,
        width: 100,
        height: 10,
        fill: "",
        stroke: this.state.strokeColor,
        strokeWidth: 5,
        HEIGHT: this.calculatedCanvasHeight,
        WIDTH: this.calculatedCanvasWidth,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
      };

      drawObject = new fabric.Line([50, 100, 200, 200], shapeObject);
    } else if (shape == appConstants.SHAPE_TYPE.ARROW) {
      this.setState(
        {
          drawType: "arrow",
        },
        () => {}
      );

      let points = [50, 100, 200, 200];

      shapeObject = {
        id: "" + this.objectId,
        objectId: "" + this.objectId,
        strokeWidth: 5,
        left: 130,
        top: 80,
        fill: this.state.strokeColor,
        stroke: this.state.strokeColor,
        originX: "center",
        originY: "center",
        hasBorders: false,
        hasControls: true,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        HEIGHT: this.calculatedCanvasHeight,
        WIDTH: this.calculatedCanvasWidth,
        flipX: false,
        flipY: false,
      };

      drawObject = new fabric.LineArrow(points, shapeObject);
    } else if (shape == appConstants.SHAPE_TYPE.TEXT) {
      this.setState(
        {
          drawType: "text",
        },
        () => {}
      );

      shapeObject = {
        id: "" + this.objectId,
        objectId: "" + this.objectId,
        left: 150,
        top: 100,
        fill: this.state.strokeColor,
        stroke: this.state.strokeColor,
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        originX: "center",
        originY: "center",
        hasBorders: false,
        hasControls: true,
        type: "i-text",
        text: "Tap to Type",
        HEIGHT: this.calculatedCanvasHeight,
        WIDTH: this.calculatedCanvasWidth,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
      };

      drawObject = new fabric.IText("Tap to Type", shapeObject);
    }
    this.props.markupShapeAdded(shapeObject, this.objectId, shape);

    this.canvas.add(drawObject);
    // this.canvas.renderAll();

    let canvasObject = this.getJSONData(this.canvas);
    let annontationObject =
      canvasObject.objects[canvasObject.objects.length - 1];

    annontationObject.id = this.objectId;
    fabric.Object.prototype.id = this.objectId;
    fabric.Object.prototype.radius = 50;
    canvasObject.objects[canvasObject.objects.length - 1] = annontationObject;

    this.canvas.loadFromJSON(
      canvasObject,
      this.canvas.renderAll.bind(this.canvas)
    );
  }
  getJSONData(canvas) {
    let canvasObject = canvas.toJSON();
    for (let i = 0; i < canvasObject.objects.length; i++) {
      canvas.getObjects().forEach(function (o) {
        if (
          o.id === canvasObject.objects[i].id &&
          canvasObject.objects[i].type == "i-text"
        ) {
          if (o.text) canvasObject.objects[i].text = o.text;
        }
      });
    }
    console.log(canvasObject);
    return canvasObject;
  }

  // Click event handler
  closeShapePopUp(isOpen) {
    // Update the state when the element is clicked
    this.setState({
      isShapePopUpOpen: isOpen,
    });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="markup-view markup-view-mobile">
          <div className="mobile-button">
            <div className="left-side">
              <button
                className="white-btn"
                onClick={() => this.clearCanvas(this.canvas)}
              >
                {t("BUTTONS.Clear_All")}
              </button>{" "}
              <button
                className="white-btn"
                onClick={() => this.exitMarkupView()}
              >
                {t("BUTTONS.Exit")}
              </button>
            </div>
            <div className="right-side">
              <button
                className="white-btn"
                onClick={() => this.download(this.canvas)}
              >
                {t("BUTTONS.Save")}
              </button>
            </div>
          </div>
          <div className="markup-menu">
            <ul>
              <li
                className={classnames("clear", {
                  disable:
                    this.props.globalMarkupObjectArray.length === 0 &&
                    this.props.userMarkupObjectArray.length === 0 &&
                    this.props.userUndoObjectArray.length === 0,
                })}
              >
                <button onClick={() => this.clearCanvas(this.canvas)}>
                  <i className={"icon-clear"}></i>
                </button>
              </li>
              <li
                className={
                  "undo-p " +
                  (this.props.userMarkupObjectArray.length > 0
                    ? " "
                    : " disable")
                }
              >
                <button onClick={() => this.undo(this.canvas)}>
                  <i className="icon-undo-prev"></i>
                </button>
              </li>
              <li
                className={
                  "undo-n" +
                  (this.props.userUndoObjectArray.length > 0 ? " " : " disable")
                }
              >
                <button onClick={() => this.redo(this.canvas)}>
                  <i className="icon-undo-next"></i>
                </button>
              </li>
              <li className="color">
                <button onClick={() => this.togglePalette()}>
                  <i
                    className="icon-color"
                    style={{ color: this.state.strokeColor }}
                  ></i>
                </button>
                <div
                  className="dropdown-menu"
                  style={{
                    display: this.state.isColorPaletteVisible
                      ? "block"
                      : "none",
                  }}
                >
                  <button className="close" onClick={() => this.closePalette()}>
                    <i className="icon-close-image"></i>
                  </button>
                  <ul>
                    <li
                      onClick={() =>
                        this.setDrawingColor(this.canvas, "#B2B2B2")
                      }
                    >
                      <div className="radio-btn">
                        <label>
                          <input type="radio" name="selectcolor" />
                          <span style={{ background: "#B2B2B2" }}></span>
                        </label>
                      </div>
                    </li>

                    <li
                      onClick={() =>
                        this.setDrawingColor(this.canvas, "#000000")
                      }
                    >
                      <div className="radio-btn">
                        <label>
                          <input type="radio" name="selectcolor" />
                          <span style={{ background: "#000000" }}></span>
                        </label>
                      </div>
                    </li>

                    <li
                      onClick={() =>
                        this.setDrawingColor(this.canvas, "#FF0000")
                      }
                    >
                      <div className="radio-btn">
                        <label>
                          <input type="radio" name="selectcolor" />
                          <span style={{ background: "#FF0000" }}></span>
                        </label>
                      </div>
                    </li>

                    <li
                      onClick={() =>
                        this.setDrawingColor(this.canvas, "#FFFFFF")
                      }
                    >
                      <div className="radio-btn">
                        <label>
                          <input type="radio" name="selectcolor" />
                          <span style={{ background: "#FFFFFF" }}></span>
                        </label>
                      </div>
                    </li>

                    <li
                      onClick={() =>
                        this.setDrawingColor(this.canvas, "#334AA6")
                      }
                    >
                      <div className="radio-btn">
                        <label>
                          <input type="radio" name="selectcolor" />
                          <span style={{ background: "#334AA6" }}></span>
                        </label>
                      </div>
                    </li>

                    <li
                      onClick={() =>
                        this.setDrawingColor(this.canvas, "#17AA25")
                      }
                    >
                      <div className="radio-btn">
                        <label>
                          <input type="radio" name="selectcolor" />
                          <span style={{ background: "#17AA25" }}></span>
                        </label>
                      </div>
                    </li>

                    <li
                      onClick={() =>
                        this.setDrawingColor(this.canvas, "#F9D52E")
                      }
                    >
                      <div className="radio-btn">
                        <label>
                          <input type="radio" name="selectcolor" />
                          <span style={{ background: "#F9D52E" }}></span>
                        </label>
                      </div>
                    </li>

                    <li
                      onClick={() =>
                        this.setDrawingColor(this.canvas, "#FF6700")
                      }
                    >
                      <div className="radio-btn">
                        <label>
                          <input type="radio" name="selectcolor" />
                          <span style={{ background: "#FF6700" }}></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className={
                  "draw" + (this.state.drawType == "pencil" ? " active" : " ")
                }
              >
                <button onClick={() => this.freeDraw(this.canvas)}>
                  <i className="icon-draw"></i>
                </button>
              </li>
              <li
                className={
                  "edit" +
                  (this.state.drawType == "highlight" ? " active" : " ")
                }
              >
                <button onClick={() => this.highlight(this.canvas)}>
                  <i className="icon-edit"></i>
                </button>
              </li>

              <li className={`${this.state.isShapePopUpOpen ? "active" : ""}`}>
                <button
                  onClick={() => {
                    this.closeShapePopUp(true);
                  }}
                >
                  <i className="icon-square-markup"></i>
                </button>
                {this.state.isShapePopUpOpen && (
                  <div className="dropdown-menu">
                    <button
                      className="close"
                      onClick={() => {
                        this.closeShapePopUp(false);
                      }}
                    >
                      <i className="icon-close-image"></i>
                    </button>
                    <ul className="menu-icons-btn">
                      <li>
                        <div className="">
                          <button
                            onClick={() => {
                              this.drawShape(appConstants.SHAPE_TYPE.CIRCLE);
                            }}
                          >
                            <i className="icon-circle-markup"></i>
                          </button>
                        </div>
                      </li>
                      <li className="active">
                        <div>
                          <button
                            onClick={() => {
                              this.drawShape(appConstants.SHAPE_TYPE.RECTANGLE);
                            }}
                          >
                            <i className="icon-square-markup"></i>
                          </button>
                        </div>
                      </li>
                      <li className="disabled">
                        <div>
                          <button
                            onClick={() => {
                              this.drawShape(appConstants.SHAPE_TYPE.LINE);
                            }}
                          >
                            <i className="icon-lines-markup"></i>
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="">
                          <button
                            onClick={() => {
                              this.drawShape(appConstants.SHAPE_TYPE.ARROW);
                            }}
                          >
                            <i className="icon-arrow-markup"></i>
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="">
                          <button
                            onClick={() => {
                              this.drawShape(appConstants.SHAPE_TYPE.TEXT);
                            }}
                          >
                            <i className="icon-text-selection"></i>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              {/* 
              <li className="download">
                <button onClick={() => this.download(this.canvas)}>
                  <i className="icon-download"></i>
                </button>
              </li> */}
              <li onClick={() => this.exitMarkupView()} className="cancel">
                <button>
                  <i className="icon-cancel"></i>
                </button>
              </li>
            </ul>
          </div>
          <div className="image-view">
            <canvas id="markupcanvas" />
          </div>
        </div>

        <div
          className="markup-message"
          style={{
            display: this.state.closeMarkUpModalVisible ? "flex" : "none",
          }}
        >
          {/* <div className="right-message">
            {this.state.markupClosedBy} Stopped Markup
          </div> */}
          <div className="box">
            <div className="title">
              <h4>{t("WEB_LABELS.Message")}</h4>
            </div>
            <p>{t("WEB_LABELS.Would_you_like_to_save_image")}</p>
            <div className="markup-action">
              <button
                className="red-btn"
                onClick={() => this.exitWithoutSaveMarkupView()}
              >
                {t("BUTTONS.Close")} {!isMobile && " " + t("BUTTONS.Markup")}
              </button>
              <button
                className={
                  this.state.markupClosedByCurrentUser ? "green-btn" : "green-btn"
                }
                onClick={() => {
                  if (this.state.markupClosedByCurrentUser) {
                    this.closeExitMarkupView();
                  }
                }}
              >
                {t("BUTTONS.Continue")} {!isMobile && " " + t("BUTTONS.Markup")}
              </button>
              <button
                className="green-btn"
                onClick={() => this.saveAndExitMarkupView()}
              >
                {t("BUTTONS.Save_and_Exit")}{" "}
                {!isMobile && " " + t("BUTTONS.Markup")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(MarkupView);
