import React, { useState, useEffect } from "react";
// Internal
import ScreenRecorder from "./ScreenAndVideoRecord";
import RefreshModal from "./RefreshModal";

const OfflineInstructionModel = ({
  setIsMemoryModel,
  setIsOfflineMode,
  iosTextMessage,
}) => {
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p className="content-title">
              <b>Instructions</b>
            </p>
            <p>
              For a smooth offline inspection, ensure your device has at least
              2GB of available disk space. This will facilitate storage and
              access to essential data throughout the inspection process.
            </p>
            <p>
              If you need to pause and then restart a Video Inspection or
              continue an offline Video Inspection, please ensure that you
              continue to use the same browser window you used to start the
              inspection. Do not attempt to restart the inspection in different
              tab, window or browser. If you do restart the inspection in a
              different tab, window or browser, you will lose all data
              previously collected and you will need to restart the entire
              inspection.
              {iosTextMessage
                ? "We recommend using the Safari browser for the best experience."
                : ""}
            </p>
          </div>
          <div className="bottom-btn">
            <button
              className="gray-btn"
              onClick={() => {
                setIsMemoryModel(false);
              }}
            >
              Cancel
            </button>
            <button
              className="blue-btn"
              onClick={() => {
                setIsMemoryModel(false);
                setIsOfflineMode(true);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const IndependentOfflinePage = () => {
  const [isMemoryModel, setIsMemoryModel] = useState(false);
  const [isRefreshModel, setIsRefreshModel] = useState(false);
  const [isOfflineMode, setIsOfflineMode] = useState(false);
  const [iosTextMessage, setIosTextMessage] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIosTextMessage(true);
    }
  }, []);

  const handleOkClick = () => {
    setIsRefreshModel(false);
    setIsMemoryModel(true);
  };
  return !isOfflineMode ? (
    <>
      <div className="after-done-status">
        <div className="inner-after-status">
          <h4>Welcome to the Offline Video Inspection platform</h4>
          <div className="bottom-re-upload">
            <p>
              Please choose "Continue Offline" to proceed with the virtual
              inspection. Following this selection, you will receive
              step-by-step guidance. we advise you to read the instructions
              thoroughly to ensure a smooth and successful offline inspection
              experience.
              {iosTextMessage
                ? "We recommend using the Safari browser for the best experience."
                : ""}
            </p>
          </div>
          <div className="bottom-btn text-center">
            <button
              className="blue-btn"
              onClick={() => setIsRefreshModel(true)}
            >
              Countinue Offline
            </button>
          </div>
        </div>
      </div>
      {false && <div className="icon-Tap"></div>}
      {isRefreshModel && <RefreshModal onOk={handleOkClick} />}
      {isMemoryModel && (
        <OfflineInstructionModel
          setIsMemoryModel={setIsMemoryModel}
          setIsOfflineMode={setIsOfflineMode}
          iosTextMessage={iosTextMessage}
        />
      )}
    </>
  ) : (
    <ScreenRecorder />
  );
};
export default IndependentOfflinePage;
