import _ from "lodash";
import { storeBookmarks } from "../../components/VideoCallOffline/storeIndexedData";
import { appConstants } from "../../constants";
export function dehydrateBookmark(data) {
  return _.omitBy(
    {
      bookmarkId: data.id,
      bookmarkLabel: data.label,
    },
    _.isUndefined
  );
}

export const dehydrateBookmarks = (data) => {
  if (data) {
    let role = sessionStorage.getItem("ROLE");
    if (
      role == appConstants.USER_ROLES.PROPERTY_OWNER ||
      role == appConstants.USER_ROLES.OPEN_USER
    ) {
      storeBookmarks(data);
    }
  }
  return data.map((d) => dehydrateBookmark(d));
};
