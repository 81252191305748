import { useTranslation } from "react-i18next";

const CallSettingsInstructionsModel = (props) => {
  const { t } = useTranslation();
  return (
    <div
    className="error-popup open"
      style={{
        display: props.showCallSettingsInstructionsModel ? "flex" : "none",
      }}
    >
      <div className="error-info text-left">
        <div className="content">
          <button
            onClick={() => props.hideCallSettingsInstructionsModel()}
            className="close-modal"
          >
            <i className="icon-close-image"></i>
          </button>
          <p>
            <b>{t("WEB_LABELS.Instruction_for_Call_Settings")}</b>
          </p>
          <p>
            {t("WEB_LABELS.Test_Audio_Video_Components_Button")}
          </p>
          <div className="button-group">
            <button
              onClick={() => {
                props.hideCallSettingsInstructionsModel();
                props.showCallSettings();
              }}
              className="green-btn"
            >
              {t("BUTTONS.Call_Settings")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallSettingsInstructionsModel;
