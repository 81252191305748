export const TRANSLATIONS_Vietnamese = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired:
      "Phiên cuộc gọi điện video an toàn đã hết hạn",
    Secure_Video_Call_Ended: "Cuộc gọi điện video an toàn đã kết thúc",
    Thank_you_for_connecting_with_us: "Cảm ơn bạn đã kết nối với chúng tôi!",
    Connected_Waiting_for_Other_Participants:
      "Đã kết nối. Đang chờ những người tham gia khác ...",
    Powered_by: "Được cung cấp bởi",
    Connecting: "Đang kết nối...",
    Local_Pike: "Pike địa phương",
    Would_you_like_to_save_image: "Bạn có muốn lưu hình ảnh?",
    Message: "Thông điệp",
    Location_Not_shared: "Vị trí: Không được chia sẻ",
    Location: "Vị trí",
    Tools: "Công cụ",
    Ask_PO_to_Turn_on_Location: "Yêu cầu Chủ sở hữu bất động sản bật Vị trí",
    Preparing_image_for_markup: "Đang chuẩn bị hình ảnh để đánh dấu ...",
    Download_Message: "Tải xuống ứng dụng thẩm định từ xa",
    Disclaimer:
      "Cần tải xuống ứng dụng RemoteVal từ App Store. Khi quá trình tải xuống hoàn tất, vui lòng mở lại lời mời bằng tin nhắn văn bản / email của bạn và nhấp vào liên kết một lần nữa để tự động tham gia cuộc gọi.",
    MeasurementLinkDisclaimer:
      "Cần tải xuống ứng dụng RemoteVal từ App Store. Khi quá trình tải xuống hoàn tất, vui lòng mở lại lời mời bằng văn bản / email của bạn và nhấp vào liên kết một lần nữa để tự động bắt đầu kiểm tra.",
    Zoom_Level: "Mức độ phóng to",
    enter_cell_phone: "nhập điện thoại di động",
    Email: "E-mail",
    Cell_Phone: "Điện thoại di động",
    Participant_Name: "Tên người tham gia",
    Invite_Participant: "Mời người tham gia",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "Tham gia cuộc gọi điện video an toàn bằng cách nhấp vào liên kết sau:",
    There_are_no_Attachments_available: "Không có tệp đính kèm nào có sẵn.",
    Drop_Or_Select_Your_File: "Thả hoặc chọn tệp của bạn",
    Attach_Document: "Đính kèm tài liệu",
    Enter_Your_Name: "Điền tên của bạn",
    I_confirm_that_I_have_read_and_agree_to_the:
      "Tôi xác nhận rằng tôi đã đọc và đồng ý với",
    Search_or_Add_Captured_Image_Label:
      "Tìm kiếm hoặc thêm nhãn hình ảnh đã chụp",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "Nhấn Enter hoặc nhấp vào Thêm nhãn mới vào hình ảnh được chụp bằng nhãn mới",
    To: "Đến",
    Everyone: "Tất cả mọi người",
    Type_a_message: "Gõ một tin nhắn",
    Are_you_sure_you_want_to_delete: "Bạn có chắc chắn muốn xóa ?",
    Room_is_full_Please_try_again_later: "Phòng đã đầy. Vui lòng thử lại sau",
    Comment: "Bình luận",
    Room_2_Measurement: "Phòng 2 Đo lường",
    Room_1_Measurement: "Phòng 1 Đo lường",
    Room_2_Image: "Hình ảnh Phòng 2",
    Room_1_Image: "Phòng 1 Hình ảnh",
    No_of_Rooms: "Số lượng phòng",
    Property_Type: "Loại tài sản",
    House: "nhà ở",
    Inspection_Form: "Hình thức kiểm tra",
    Instructions: "Hướng dẫn",
    Guide_user_to_position_camera_on_the_target:
      "Hướng dẫn người dùng định vị camera trên mục tiêu",
    Click_on_the_Label_once_to_capture_labeled_image:
      "Nhấp vào Nhãn một lần để chụp ảnh có nhãn.",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "Chọn tầng của Nhà, Loại Phòng và Tên Phòng.",
    Choose_GLA_or_Non_GLA: "Chọn GLA hoặc Không GLA.",
    Click_on_the_Start_Measurement_button: "Nhấp vào nút Bắt đầu đo lường",
    Click_the_Auto_Corner_Detection_to_On_Off:
      'Nhấp vào "Tự động phát hiện góc" để Bật / Tắt',
    Position_vertical_Green_Floor:
      "Định vị Điểm đánh dấu tầng màu xanh lá cây thẳng đứng trong Góc phòng và đảm bảo rằng nó chạm vào sàn nhà.",
    App_will_auto_mark_a_corner:
      'Ứng dụng sẽ tự động đánh dấu một góc hoặc nhấn vào nút "Chụp góc theo cách thủ công"',
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "Hướng dẫn người dùng đi đến từng góc phòng để đánh dấu từng góc",
    When_last_line_connects:
      'Khi dòng cuối cùng kết nối với điểm xuất phát, nó sẽ chuyển sang màu đỏ. Ứng dụng sẽ tự động kết nối và hoàn thành bản phác thảo hoặc bạn có thể sử dụng nút "Connect Corner & Finish Sketch" để hoàn thành, đo lường.',
    button_to_view_final_sketch:
      'Nhấp vào nút "Hiển thị bản phác thảo sơ đồ tầng đã hoàn thành" để xem bản phác thảo cuối cùng.',
    Are_You_Sure_You_Want_to_Leave_This_Call:
      "Bạn có chắc chắn muốn rời khỏi cuộc gọi này không?",
    Street_View: "Quang cảnh đường phố",
    Select_Measurement_Type: "Chọn loại đo lường",
    Floor: "Sàn nhà",
    Ceiling: "Trần nhà",
    View_Sketches_by_Floor: "Xem bản phác thảo theo tầng",
    Uploading_Image: "Tải lên hình ảnh",
    Call_Setting: "Thiết lập cuộc gọi",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "Cho phép truy cập vào Micrô của bạn. Nhấp vào ổ khóa",
    to_the_left_of_the_address_bar: "ở bên trái của thanh địa chỉ.",
    Speakers: "Diễn giả",
    Allow_access_to_your_Camera_Click_the_padlock:
      "Cho phép truy cập vào Máy ảnh của bạn. Nhấp vào ổ khóa",
    to_the_address_bar: "vào thanh địa chỉ.",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "Không khởi động được máy quay video. Vui lòng chọn một máy quay video khác.",
    Title: "Tiêu đề",
    New_Note: "Ghi chú mới...",
    There_are_no_Notes_available: "Không có ghi chú nào có sẵn.",
    Are_you_sure_you_want_to_delete_this_note:
      "Bạn có chắc chắn muốn xóa ghi chú này không?",
    Network_Type: "Dạng kết nối",
    Netword_Strength: "Độ mạnh của Netword",
    To_share_live_video:
      "Để chia sẻ video trực tiếp. vui lòng bật quyền máy ảnh trong cài đặt trình duyệt của bạn sau đó",
    click_here: "bấm vào đây",
    to_reload_the_page: "để tải lại trang.",
    we_cant_access_your_microphone:
      "chúng tôi không thể truy cập micrô của bạn. Để cấp quyền truy cập, hãy chuyển đến cài đặt trình duyệt của bạn và sau đó",
    Questionnaire_Form: "Mẫu bảng câu hỏi",
    No_images_found: "Không tìm thấy hình ảnh nào!",
    Image: "Hình ảnh",
    Captured_Images: "Hình ảnh đã chụp",
    Share_your_screen: "Chia sẻ màn hình của bạn",
    Share_your_live_video: "Chia sẻ video trực tiếp của bạn?",
    This_will_stop_your_video:
      "Điều này sẽ dừng video của bạn. Bạn có muốn tiếp tục?",
    This_will_stop_screen_share:
      "Điều này sẽ dừng chia sẻ màn hình. Bạn có muốn tiếp tục?",
    Room_Area_Label: "Nhãn Phòng / Khu vực",
    Room_Area_Name:
      "Tên phòng / khu vực(ví dụ: Phòng ngủ 1, Phòng tắm 2, v.v.)",
    Room_Area_Name_Placeholder: "Tên phòng / khu vực",
    You: "Bạn",
    Are_you_sure_you_want_to_delete_captured_image:
      "Bạn có chắc chắn muốn xóa hình ảnh đã chụp không?",
    Start_Over: "Bắt đầu lại - Bạn có chắc chắn muốn xóa sơ đồ tầng không?",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "Bạn có chắc chắn muốn thoát khỏi quy trình đo lường không?",
    Labels: "Nhãn",
    Select_Language: "Chọn ngôn ngữ",
    This_call_is_being_recorded: "Cuộc gọi video này đang được ghi lại",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "Cuộc gọi này đang được người thẩm định của bạn ghi lại cho hồ sơ công việc riêng tư của họ.",
    It_will_not_be_shared: `Nó sẽ không được chia sẻ với bất kỳ bên ngoài nào kể cả thế chấp của bạn
    người cho vay và người thẩm định của bạn sẽ không thể tải xuống từ
    Trang web RemoteVal. Nó sẽ chỉ được sử dụng để người thẩm định hoàn thành
    thẩm định của bạn.`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "Nếu bạn không muốn màn hình của bạn được ghi lại, vui lòng thông báo cho người thẩm định của bạn ngay bây giờ.",
    Measurement: "Đo đạc",
    Create_Measurement: "Tạo phép đo",
    Fencing: "Đấu kiếm",
    Show: "Chỉ",
    Hide: "Ẩn giấu",
    Live_Floor_Plan: "Sơ đồ tầng trực tiếp",
    Expand: "Mở rộng",
    Collapse: "Sự sụp đổ",
    Instruction_for_Call_Settings: "Hướng dẫn Cài đặt Cuộc gọi",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      'Loa, Mic và Máy ảnh có thể được kiểm tra thông qua cài đặt cuộc gọi từ menu "thêm"',
    To_Check_the_call_settings_now_click_on_the_below_button:
      "Để kiểm tra cài đặt cuộc gọi bây giờ hãy nhấp vào nút bên dưới.",
    Call_recording_by_virtual_inspector:
      "Cuộc gọi video này đang được kiểm tra ảo của bạn ghi lại cho tệp công việc riêng của họ và cho mục đích kiểm toán nội bộ của tổ chức yêu cầu. Nó sẽ không được chia sẻ với bất kỳ bên bên ngoài.",
    Call_and_Screen_Recording_Opt_Out:
      "Nếu bạn không muốn màn hình của bạn và cuộc gọi này sẽ được ghi lại, vui lòng thông báo cho thanh tra ảo của bạn ngay bây giờ.",
    Test_Audio_Video_Components_Button:
      "Loa, micrô & camera có thể được kiểm tra bằng cách nhấp vào nút bên dưới.",
  },
  BUTTONS: {
    Re_Connect: "Kết nối lại",
    End_Session: "Phiên kết thúc",
    Chat: "Trò chuyện",
    Participants: "Những người tham gia",
    Notes: "Ghi chú",
    Attach_Document: "Đính kèm tài liệu",
    Questionnaire: "Bảng câu hỏi",
    Labels: "Nhãn",
    Pointer: "Con trỏ",
    Markup: "Đánh dấu",
    Flash_Off: "Tắt đèn flash",
    Flash_On: "Bật flash",
    Call_Recording: "Thu âm cuộc gọi",
    Capture_Image: "Chụp ảnh",
    Screen_Share: "Chia sẻ màn hình",
    Stop_Screen_Share: "Dừng chia sẻ màn hình",
    Call_Settings: "Cài đặt cuộc gọi",
    Clear_All: "Quet sạch tât cả",
    Exit: "Lối ra",
    Save: "Cứu",
    Close: "Gần",
    Save_and_Exit: "Lưu và thoát",
    Continue: "Tiếp tục",
    Street_View: "Quang cảnh đường phố",
    Open_in_App: "Mở trong ứng dụng",
    Privacy_Policy: "Chính sách bảo mật",
    Invite: "Mời gọi",
    Join: "Tham gia",
    Add_Captured_Image_Label: "Thêm nhãn hình ảnh đã chụp",
    No: "Không",
    Yes: "đúng",
    Delete: "Xóa bỏ",
    Cancel: "Hủy bỏ",
    RETRY: "THỬ LẠI",
    OK: "VÂNG",
    Back: "Mặt sau",
    Leave_Call: "Rời khỏi cuộc gọi",
    Got_It: "Hiểu rồi",
    Start_New_Inspection: "Bắt đầu kiểm tra mới",
    Resume_Existing_Inspection: "Tiếp tục kiểm tra hiện tại",
    View_All_Current_Sketch: "Xem tất cả (các) bản phác thảo hiện tại",
    Open_Sketch: "Mở Sketch",
    Auto_Corner_Detection_ON: "Tự động phát hiện góc BẬT",
    Auto_Corner_Detection_OFF: "Tự động phát hiện góc bị TẮT",
    Manually_Capture_Corner: "Chụp góc theo cách thủ công",
    Undo_Last_Corner_Capture: "Hoàn tác chụp góc cuối cùng",
    Connect_Corner_Finish_Sketch: "Kết nối góc & hoàn thiện phác thảo",
    Capture_3D_Room_Height: "Chụp chiều cao phòng 3D",
    Set_Fixed_3D_Room_Height: "Đặt chiều cao phòng 3D cố định",
    Capture_Door: "Chụp cửa",
    Set_Door: "Đặt cửa",
    Show_Finished_Floor_Plan_Sketch:
      "Hiển thị bản phác thảo sơ đồ tầng đã hoàn thành",
    Back_to_Previous_Screen: "Quay lại màn hình trước",
    Add_Room: "Thêm phòng",
    Publish_the_floor_plan: "Xuất bản sơ đồ tầng",
    Exit_Return_to_Inspection: "Thoát và quay lại kiểm tra",
    Audio: "Âm thanh",
    Video: "Băng hình",
    Microphone: "Cái mic cờ rô",
    Microphone_not_found: "Không tìm thấy micrô",
    Microphone_Blocked: "Micrô bị chặn",
    Default: "Vỡ nợ",
    Grant_Permission: "Cấp phép",
    Speaker_Blocked: "Loa bị chặn",
    Play_Test_Sound: "Phát thử âm thanh",
    Preview: "Xem trước",
    Camera: "Máy ảnh",
    Camera_not_found: "Không tìm thấy máy ảnh",
    Camera_Blocked: "Máy ảnh bị chặn",
    Done: "Xong",
    View: "Quan điểm",
    Capture_Labeled_Images: "Chụp ảnh được gắn nhãn",
    Capture_Measurements: "Chụp các phép đo",
    Instructions: "Hướng dẫn",
    Start_Measurement_Process: "Bắt đầu quá trình đo lường",
    Flash_Light_Not_Supported:
      "Đèn pin từ xa không được hỗ trợ trên thiết bị khách hoặc chưa sẵn sàng",
  },
  TOOLTIP: {
    Mute_My_Audio: "Tắt âm thanh của tôi",
    Unmute_My_Audio: "Bật âm thanh của tôi",
    Stop_My_Video: "Dừng video của tôi",
    Start_My_Video: "Bắt đầu video của tôi",
    Capture_Images_with_No_Labels: "Chụp ảnh không có nhãn",
    Start_Inspection: "Bắt đầu kiểm tra",
    Chat: "Trò chuyện",
    More_Options: "Lựa chọn khác",
    End_Call: "Kết thúc cuộc gọi",
    Unpin_Video: "Bỏ ghim video",
    Pin_Video: "Ghim video",
    Add_New_Note: "Thêm ghi chú mới",
    Refresh: "Làm mới",
    View_More: "Xem thêm",
    Invite_Participant: "Mời người tham gia",
    Delete_Image: "xóa ảnh",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "Lời mời đã được gửi thành công",
    IOS_SAFARI_HELP:
      "Để có kết quả tốt nhất, chúng tôi khuyên bạn nên sử dụng Safari",
    UNEXPECTED_ERROR: "Đã xảy ra lỗi không mong muốn. Thử lại sau.",
    CALL_LINK_NOT_FOUND: "Liên kết cuộc gọi không được tìm thấy.",
    CREATE_BOOKMARK_MESSAGE: "Vui lòng yêu cầu quản trị viên thêm dấu trang",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED: "Kích thước tệp phải nhỏ hơn 10MB",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT: "Định dạng tập tin không được hỗ trợ",
    LIVE_POINTER_STARTED:
      "Đã bắt đầu con trỏ trực tiếp. Bạn có thể di chuyển con trỏ bằng cách kéo.",
    LIVE_POINTER_STOPPED: "Con trỏ trực tiếp bị dừng",
    APPRAISAL_EXIT_APPROVED: "Đã xác nhận thoát thành công.",
    APPRAISAL_EXIT_DENIED: "Thoát bị từ chối.",
    MARKUP_CLOSED: "Đánh dấu bị dừng",
    PRIVACY_POLICY_FAIL: "Không tìm thấy URL Chính sách Bảo mật hợp lệ!",
    SWITCH_CAMERA_REQUEST_REJECTED:
      "Chủ sở hữu nhà đã không chia sẻ chế độ xem camera được yêu cầu.",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email:
      "Vui lòng chọn Điện thoại di động hoặc Email",
    This_field_is_required: "Trường này là bắt buộc",
    Cell_Phone_Must_Be_Valid: "Điện thoại di động phải hợp lệ",
    Name_is_Required: "Tên là bắt buộc",
    Name_Must_Be_Valid: "Tên phải hợp lệ",
    Invalid_Text: "Văn bản không hợp lệ",
    Email_Must_Be_Valid: "Email phải hợp lệ",
    Name_must_be: "Tên phải là",
    characters_max: "ký tự tối đa",
    Please_select_the_Floor: "Vui lòng chọn Tầng",
    Please_enter_the_Room_Area_Name: "Vui lòng nhập Tên Phòng / Khu vực",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "Bạn cần sử dụng HTTPS để chọn thiết bị đầu ra âm thanh",
    Something_went_wrong: "Đã xảy ra lỗi. Vui lòng thử lại sau.",
    Oops_Call_duration_is_over_and_session_is_ended:
      "Giáo sư! Thời lượng cuộc gọi kết thúc và phiên kết thúc.",
    No_participant_has_joined_yet_Ending_the_call_session:
      "Chưa có thành viên nào tham gia! Kết thúc phiên gọi",
    You_are_offline_Check_your_internet_connection:
      "Bạn đang offline. Kiểm tra kết nối Internet của bạn.",
  },
  starting_inspection: "Bắt đầu kiểm tra",
  starting_measurement: "Bắt đầu đo lường",
  error: "Lỗi",
  contact_us: "Liên hệ chúng tôi",
  support_popup_msg:
    "Rất tiếc, Đã xảy ra lỗi, vui lòng thử lại sau đôi khi hoặc báo cáo sự cố cho bộ phận hỗ trợ của chúng tôi.",
  select_po: "Chọn Chủ sở hữu Bất động sản để Bắt đầu Quy trình Kiểm tra",
  request_po_for_camera:
    "Vui lòng yêu cầu Chủ sở hữu bất động sản bật chế độ xem camera để bắt đầu quá trình Kiểm tra",
  wait_until_po_joins:
    "Vui lòng đợi cho đến khi Chủ sở hữu bất động sản tham gia cuộc gọi để bắt đầu quá trình Kiểm tra",
  select_measurement_method: "Chọn phương pháp đo",
  inspection_inprogress: "Kiểm tra đã được tiến hành",
  model_msg_retry:
    "Không thể thiết lập kênh liên lạc với Chủ sở hữu tài sản, Vui lòng thử lại.",
  model_msg_leaveCall:
    "Có vẻ như có sự cố kết nối Internet ở một trong hai bên, vì vậy vui lòng ngắt kết nối cuộc gọi hiện tại và kết nối lại.",
  please_wait: "Vui lòng chờ...",
  loading_sketch: "Đang tải bản phác thảo ...",
  connection_timeout_msg:
    "Kết nối đã quá giờ. Có điều gì đó bất thường đã xảy ra và cần nhiều thời gian hơn dự kiến để phản hồi. Vui lòng thử lại.",
  previous_measurement_prblm_msg:
    "Rất tiếc, đã xảy ra sự cố với phép đo trước đó. Vui lòng thử lại với Quy trình đo mới.",
  measurement_retry_msg: "Quá trình đo chưa bắt đầu. Xin hãy thử lại.",
  opensketch_retry_msg: "Quá trình Open Sketch chưa bắt đầu. Xin hãy thử lại.",
};
