import { combineReducers } from "redux";

import authReducers from "./auth";
import videoCallReducers from "./videoCall";
import listParticipantReducers from "./listParticipant";
import participantTtrackStatusReducers from "./participantTtrackStatus";
import callParticipants from "./callParticipants";
import captureImages from "./captureImages";
import bookmarkView from "./bookmarkView";
import me from "./me";
import messages from "./messages";
import noteView from "./noteView";
import attachmentView from "./attachmentView";
import measurements from "./measurements";
import callSettings from "./callSettings";
import questionnaireReducer from "./questionnaire";
import cachedCapturedImages from "./cachedCapturedImages";

const Reducers = combineReducers({
  auth: authReducers,
  videoCall: videoCallReducers,
  listParticipant: listParticipantReducers,
  participantTtrackStatus: participantTtrackStatusReducers,
  callParticipants,
  captureImages,
  bookmarkView,
  me,
  messages,
  noteView,
  attachmentView,
  measurements,
  callSettings,
  questionnaire: questionnaireReducer,
  cachedCapturedImages: cachedCapturedImages,
});

export default Reducers;
