import * as actionTypes from "./actionTypes";
import MarkupImageService from "../services/markupImageService";

const captureImage = (payload) => {
  return {
    type: actionTypes.CAPTURE_IAMGE,
    payload,
  };
};

export const uploadMarkupImage = (callUUID, data, extraPayload = {}) => {
  return (dispatch, getstate) => {
    const requestData = { image: data, extraPayload };

    if (requestData) {
      return MarkupImageService.upload(
        callUUID,
        requestData.image,
        requestData.extraPayload
      )
        .then((result) => {
          return dispatch(markupImageUploadCompleted(result));
        })
        .catch((error) => {
          return dispatch(markupImageUploadFailed());
        });
    } else {
    }
  };
};

const markupImageUploadCompleted = (payload) => {
  return {
    type: actionTypes.MARKUP_IMAGE_UPLOAD_COMPLETED,
    payload,
  };
};
const markupImageUploadFailed = () => {
  return {
    type: actionTypes.MARKUP_IMAGE_UPLOAD_FAILED,
  };
};
