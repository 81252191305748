import React from "react";
import { Switch } from "react-router-dom";
import CustomRoute from "./CustomRoute";
import { VideoCall } from "./components/VideoCall/VideoCall";
import Home from "./components/Home/Home";
import CallEnd from "./components/CallEnd/CallEnd";
import ErrorModal from "./components/VideoCall/Models/ErrorModal";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MeasurementLinkAppDownloadPage from "./components/MeasurementLinkAppDownloadPage/MeasurementLinkAppDownloadPage";
import ShortCallLink from "./components/VideoCall/shortCallLink";
import ScreenRecorder from "./components/VideoCallOffline/ScreenAndVideoRecord";
import OfflineAuth from "./components/VideoCallOffline/offlineAuth";

export default ({ childProps }) => {
  return (
    <div>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        draggable={false}
        closeOnClick={false}
        autoClose={6000}
      />
      <Switch>
        <CustomRoute
          exact
          path="/link/:id"
          Component={ShortCallLink}
          cProps={{ ...childProps }}
        />
        <CustomRoute
          exact
          path="/joincall/:inviteUUID"
          Component={VideoCall}
          cProps={{ ...childProps }}
        />
        <CustomRoute
          exact
          path="/measurement/:measurementLinkId"
          Component={MeasurementLinkAppDownloadPage}
          cProps={{ ...childProps }}
        />
        <CustomRoute
          exact
          path="/"
          Component={Home}
          cProps={{ ...childProps }}
        />
        <CustomRoute
          exact
          path="/thanks"
          Component={CallEnd}
          cProps={{ ...childProps }}
        />
        <CustomRoute
          exact
          path="/offlineInspection/:inviteUUID"
          Component={OfflineAuth}
          cProps={{ ...childProps }}
        />
        <CustomRoute
          exact
          path="/unauthorised"
          Component={ErrorModal}
          cProps={{ ...childProps }}
        />
      </Switch>
    </div>
  );
};
