import React from "react";

export default function RefreshModal(props) {
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p className="content-title">
              <b>Instructions</b>
            </p>
            <p>
              Please do not refresh the page or minimize the browser during the
              inspection. For a better experience, switch your phone to
              airplane mode. There is a risk of data loss or interruption if
              these precautions are not taken.
            </p>
          </div>
          <div className="bottom-btn">
            <button
              className="blue-btn"
              onClick={() => {
                props.onOk();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
