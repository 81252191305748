import api from "./api";

export default class BookmarkAPI {
  // adding new bookmark
  addBookmark(bookmarkLabel, callUUID) {
    return api.post(
      `/bookmarks?bookmarkName=${bookmarkLabel}&callUUID=${callUUID}`,
      { isToastMessageVisible: false }
    );
  }

  // getting bookmarks by UUID
  getBookmarkByUUID(UUID) {
    return api.get(`bookmarks/${UUID}`);
  }
}
