import api from "./apis/api";
import { dehydrateCallConfig } from "./transformers";

class CallConfigService {
  get(inviteUUID) {
    return api.callConfig
      .get(inviteUUID)
      .then((res) => dehydrateCallConfig(res.data.data));
  }
}

export default new CallConfigService();
