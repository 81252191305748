import React, { useState } from "react";
import "./callend.scss";
import _ from "lodash";
import { connect } from "react-redux";
import { StorageUtil, getCallURL, history } from "../../helpers/";
import { isAppraiser, isOwner } from "../../helpers/commonJSFunction";
import { callEnd as endSession } from "../../actions/videoCallAction";
import { useTranslation } from "react-i18next";
import { appConstants } from "../../constants";
import MemoryModel from "../VideoCallOffline/memorymodel";
import { dataExistInTable } from "../VideoCallOffline/storeIndexedData";
const CallEnd = (props) => {
  const { t } = useTranslation();

  const [, updateState] = useState();
  const [showMemoryModel, setShowMemoryModel] = useState(false);
  const [clickOnReconnect, setClickOnRecconnect] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const logo = props.configs && props.configs.callLogo;
  const style = {};

  if (props.configs && props.configs.backgroundColor && imageLoaded) {
    style.backgroundColor = props.configs.backgroundColor;
    style.padding = "10px";
  }

  const hasUUID = StorageUtil.hasInviteUUID();
  const InviteUUID = StorageUtil.getInviteUUID();
  const ROLE = StorageUtil.getRole();
  const isShowEndCall = ROLE && isAppraiser(ROLE) && hasUUID;
  const shortCallLinkId = localStorage.getItem("shortLink");
  const reConnect = () => {
    if (!navigator.onLine) {
      setClickOnRecconnect(true);
      setShowMemoryModel(true);
    } else {
      const callURL = getCallURL(InviteUUID);
      history.push(callURL);
      window.location.href = callURL;
    }
  };
  const offlineMode = () => {
    if (ROLE == appConstants.USER_ROLES.PROPERTY_OWNER && shortCallLinkId) {
      history.push(`/offlineInspection/${shortCallLinkId}`);
    } else {
      history.push("/unauthorized");
    }
  };
  const handleContinueOffline = async () => {
    setShowMemoryModel(true);
  };
  const performcallEnd = () => {
    /*  call api to end the call */
    props.endSession(InviteUUID);
    StorageUtil.clearAll();
    forceUpdate();
    /* clear all Data */
  };

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  const handleMemoryModelOk = () => {
    if (!clickOnReconnect) {
      offlineMode();
    }
    setShowMemoryModel(false);
    setClickOnRecconnect(false);
  };
  const handleMemoryModelCancel = () => {
    setShowMemoryModel(false);
  };
  (async () => {
    const dbExists = await dataExistInTable("CallDetails");
    if (!dbExists) {
      return;
    }
  })();
  return (
    <section className="callend-pagenot-found">
      <div className="box-center">
        <div className="content">
          {logo && (
            <div className="company-logo" style={{ ...style }}>
              <img
                style={{ display: imageLoaded ? "block" : "none" }}
                src={logo}
                alt="Logo"
                onLoad={handleImageLoaded}
              />
            </div>
          )}
          {hasUUID && isOwner(ROLE) ? (
            <div className="content-div">
              <h2>{t("WEB_LABELS.Secure_Video_Call_Ended")}</h2>
              <p>
                Click on the “Reconnect” button to join your existing live
                virtual inspection session. Click on the “Continue Offline”
                button to proceed forward with your virtual inspection offline.
              </p>
            </div>
          ) : (
            <>
              <h2>{t("WEB_LABELS.Secure_Video_Call_Ended")}</h2>
              <p>{t("WEB_LABELS.Thank_you_for_connecting_with_us")}</p>
            </>
          )}
          <div className="reconnect">
            {hasUUID && (
              <button
                className="green-btn"
                onClick={() => {
                  reConnect();
                }}
              >
                Reconnect
              </button>
            )}
            {hasUUID && isOwner(ROLE) && (
              <button
                className="blue-btn"
                onClick={() => {
                  handleContinueOffline();
                }}
              >
                Continue Offline
              </button>
            )}
            {showMemoryModel && (
              <MemoryModel
                onOk={() => handleMemoryModelOk()}
                clickOnReconnect={clickOnReconnect}
                onCancel={() => handleMemoryModelCancel()}
              />
            )}
            {isShowEndCall && (
              <button
                className="red-btn"
                onClick={() => {
                  performcallEnd();
                }}
              >
                {t("BUTTONS.End_Session")}
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
const mapDispatchToProps = (dispatch) => ({
  endSession: (data) => dispatch(endSession(data)),
});

const mapStateToProps = ({ me }) => ({
  configLoaded:
    me && me.configsLoaded && _.isEmpty(me.configsError) ? true : false,
  configs: _.get(me, ["configs"], {}),
});

const connectedCallEnd = connect(mapStateToProps, mapDispatchToProps)(CallEnd);

export default connectedCallEnd;
