import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_Arabic } from "./Arabic/translations";
import { TRANSLATIONS_Cantonese } from "./Cantonese/translations";
import { TRANSLATIONS_French } from "./French/translations";
import { TRANSLATIONS_Hindi } from "./Hindi/translations";
import { TRANSLATIONS_Japanese } from "./Japanese/translations";
import { TRANSLATIONS_Korean } from "./Korean/translations";
import { TRANSLATIONS_Mandarin } from "./Mandarin/translations";
import { TRANSLATIONS_Spanish } from "./Spanish/translations";
import { TRANSLATIONS_Tagalog } from "./Tagalog/translations";
import { TRANSLATIONS_Vietnamese } from "./Vietnamese/translations";
import { TRANSLATIONS_Russian } from "./Russian/translations";
import { TRANSLATIONS_Turkish } from "./Turkish/translations";
import { TRANSLATIONS_EN } from "./translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      ar: {
        translation: TRANSLATIONS_Arabic,
      },
      yue: {
        translation: TRANSLATIONS_Cantonese,
      },
      fr: {
        translation: TRANSLATIONS_French,
      },
      hi: {
        translation: TRANSLATIONS_Hindi,
      },
      ja: {
        translation: TRANSLATIONS_Japanese,
      },
      ko: {
        translation: TRANSLATIONS_Korean,
      },
      cmn: {
        translation: TRANSLATIONS_Mandarin,
      },
      es: {
        translation: TRANSLATIONS_Spanish,
      },
      tl: {
        translation: TRANSLATIONS_Tagalog,
      },
      vi: {
        translation: TRANSLATIONS_Vietnamese,
      },
      ru: {
        translation: TRANSLATIONS_Russian,
      },
      tr: {
        translation: TRANSLATIONS_Turkish,
      },
    },
  });
if (!localStorage.getItem("selectedLanguage")) {
  i18n.changeLanguage("en");
  localStorage.setItem("selectedLanguage", "english");
} else {
  switch (localStorage.getItem("selectedLanguage")) {
    case "english": {
      i18n.changeLanguage("en");
      break;
    }
    case "arabic": {
      i18n.changeLanguage("ar");
      break;
    }
    case "cantonese": {
      i18n.changeLanguage("yue");
      break;
    }
    case "french": {
      i18n.changeLanguage("fr");
      break;
    }
    case "hindi": {
      i18n.changeLanguage("hi");
      break;
    }
    case "japanese": {
      i18n.changeLanguage("ja");
      break;
    }
    case "korean": {
      i18n.changeLanguage("ko");
      break;
    }
    case "mandarin": {
      i18n.changeLanguage("cmn");
      break;
    }
    case "tagalog": {
      i18n.changeLanguage("tl");
      break;
    }
    case "vietnamese": {
      i18n.changeLanguage("vi");
      break;
    }
    case "spanish": {
      i18n.changeLanguage("es");
      break;
    }
    case "russian": {
      i18n.changeLanguage("ru");
      break;
    }
    case "turkish": {
      i18n.changeLanguage("tr");
      break;
    }
  }
}
export default i18n;
