import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import configureStore from "./store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { history } from "../src/helpers";

const store = configureStore();
const container = document.getElementById("root");
const root = createRoot(container);
if (window.location.host.includes("localhost")) {
  root.render(
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  );
} else {
  root.render(
    <Provider store={store}>
      <Router history={history}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Router>
    </Provider>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);