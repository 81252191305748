export const TRANSLATIONS_French = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired: "Session d'appel vidéo sécurisé expirée",
    Secure_Video_Call_Ended: "Appel vidéo sécurisé terminé",
    Thank_you_for_connecting_with_us: "Merci de vous connecter avec nous!",
    Connected_Waiting_for_Other_Participants:
      "Connecté. En attente d'autres participants...",
    Powered_by: "Alimenté par",
    Connecting: "De liaison...",
    Local_Pike: "Brochet local",
    Would_you_like_to_save_image: "Souhaitez-vous enregistrer l'image ?",
    Message: "Un message",
    Location_Not_shared: "Emplacement : Non partagé",
    Location: "Emplacement",
    Tools: "Outils",
    Ask_PO_to_Turn_on_Location:
      "Demander au propriétaire d'activer l'emplacement",
    Preparing_image_for_markup: "Préparation de l'image pour le balisage...",
    Download_Message: "Télécharger l'application d'évaluation à distance",
    Disclaimer:
      "Il est nécessaire de télécharger l'application RemoteVal depuis l'App Store. Une fois le téléchargement terminé, veuillez rouvrir votre invitation par SMS/e-mail et cliquez à nouveau sur le lien pour rejoindre automatiquement l'appel.",
    MeasurementLinkDisclaimer:
      "Il est nécessaire de télécharger l'application RemoteVal depuis l'App Store. Une fois le téléchargement terminé, veuillez rouvrir votre invitation par SMS/e-mail et cliquez à nouveau sur le lien pour démarrer automatiquement l'inspection.",
    Zoom_Level: "Le niveau de zoom",
    enter_cell_phone: "entrer le téléphone portable",
    Email: "E-mail",
    Cell_Phone: "Téléphone portable",
    Participant_Name: "Nom du participant",
    Invite_Participant: "Inviter un participant",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "Rejoignez l'appel vidéo sécurisé en cliquant sur le lien suivant :",
    There_are_no_Attachments_available:
      "Il n'y a pas de pièces jointes disponibles.",
    Drop_Or_Select_Your_File: "Déposez ou sélectionnez votre fichier",
    Attach_Document: "Joindre un document",
    Enter_Your_Name: "Entrez votre nom",
    I_confirm_that_I_have_read_and_agree_to_the:
      "Je confirme avoir lu et accepté les",
    Search_or_Add_Captured_Image_Label:
      "Rechercher ou ajouter une étiquette d'image capturée",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "Appuyez sur Entrée ou cliquez sur Ajouter une nouvelle étiquette à l'image capturée avec une nouvelle étiquette",
    To: "À",
    Everyone: "Toutes les personnes",
    Type_a_message: "Tapez un message",
    Are_you_sure_you_want_to_delete:
      "Etes-vous sûr que vous voulez supprimer ?",
    Room_is_full_Please_try_again_later:
      "La salle est pleine. Veuillez réessayer plus tard",
    Comment: "Commenter",
    Room_2_Measurement: "Mesure de la salle 2",
    Room_1_Measurement: "Mesure de la salle 1",
    Room_2_Image: "Image de la salle 2",
    Room_1_Image: "Image de la salle 1",
    No_of_Rooms: "Nombre de chambres",
    Property_Type: "Type de propriété",
    House: "loger",
    Inspection_Form: "Formulaire d'inspection",
    Instructions: "Instructions",
    Guide_user_to_position_camera_on_the_target:
      "Guider l'utilisateur pour positionner la caméra sur la cible",
    Click_on_the_Label_once_to_capture_labeled_image:
      "Cliquez une fois sur l'étiquette pour capturer l'image étiquetée.",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "Sélectionnez l'étage de la maison, le type de pièce et un nom de pièce.",
    Choose_GLA_or_Non_GLA: "Choisissez GLA ou Non-GLA.",
    Click_on_the_Start_Measurement_button:
      "Cliquez sur le bouton Démarrer la mesure",
    Click_the_Auto_Corner_Detection_to_On_Off:
      'Cliquez sur "Détection automatique des coins" pour activer/désactiver',
    Position_vertical_Green_Floor:
      "Placez le marqueur de sol vert vertical dans un coin de la pièce et assurez-vous qu'il touche le sol.",
    App_will_auto_mark_a_corner:
      'L\'application marque automatiquement un coin ou appuyez sur le bouton "Capturer manuellement le coin"',
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "Guider l'utilisateur à marcher jusqu'à chaque coin de la pièce pour marquer chaque coin",
    When_last_line_connects:
      'Lorsque la dernière ligne se connecte au point de départ, elle devient rouge. L\'application se connectera automatiquement et terminera le croquis ou vous pouvez utiliser le bouton "Connecter le coin et terminer le croquis" pour terminer la mesure.',
    button_to_view_final_sketch:
      "Cliquez sur le bouton \"Afficher l'esquisse du plan d'étage fini\" pour afficher l'esquisse finale.",
    Are_You_Sure_You_Want_to_Leave_This_Call:
      "Êtes-vous sûr de vouloir quitter cet appel ?",
    Street_View: "Vue sur la rue",
    Select_Measurement_Type: "Sélectionnez le type de mesure",
    Floor: "Sol",
    Ceiling: "Plafond",
    View_Sketches_by_Floor: "Afficher les esquisses par étage",
    Uploading_Image: "Téléchargement d'une image",
    Call_Setting: "Paramètre appel",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "Autorisez l'accès à votre microphone. Cliquez sur le cadenas",
    to_the_left_of_the_address_bar: "à gauche de la barre d'adresse.",
    Speakers: "Haut-parleurs",
    Allow_access_to_your_Camera_Click_the_padlock:
      "Autorisez l'accès à votre appareil photo. Cliquez sur le cadenas",
    to_the_address_bar: "à la barre d'adresse.",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "Échec du démarrage de la caméra vidéo. Veuillez sélectionner une autre caméra vidéo.",
    Title: "Titre",
    New_Note: "Nouvelle remarque...",
    There_are_no_Notes_available: "Il n'y a pas de notes disponibles.",
    Are_you_sure_you_want_to_delete_this_note:
      "Voulez-vous vraiment supprimer cette note ?",
    Network_Type: "Type de réseau",
    Netword_Strength: "Force du réseau",
    To_share_live_video:
      "Pour partager une vidéo en direct. veuillez activer l'autorisation de la caméra dans les paramètres de votre navigateur, puis",
    click_here: "Cliquez ici",
    to_reload_the_page: "pour recharger la page.",
    we_cant_access_your_microphone:
      "nous ne pouvons pas accéder à votre microphone. Pour donner accès, allez dans les paramètres de votre navigateur puis",
    Questionnaire_Form: "Formulaire de questionnaire",
    No_images_found: "Aucune image trouvée !",
    Image: "Image",
    Captured_Images: "Images capturées",
    Share_your_screen: "Partagez votre écran",
    Share_your_live_video: "Partager votre vidéo en direct ?",
    This_will_stop_your_video:
      "Cela arrêtera votre vidéo. Voulez-vous continuer?",
    This_will_stop_screen_share:
      "Cela arrêtera le partage d'écran. Voulez-vous continuer?",
    Room_Area_Label: "Étiquette de pièce/zone",
    Room_Area_Name:
      "Nom de la pièce/zone (par exemple, chambre 1, salle de bain 2, etc.)",
    Room_Area_Name_Placeholder: "Nom de la pièce/zone",
    You: "Tu",
    Are_you_sure_you_want_to_delete_captured_image:
      "Êtes-vous sûr de vouloir supprimer l'image capturée ?",
    Start_Over:
      "Recommencer - Êtes-vous sûr de vouloir supprimer les plans d'étage ?",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "Êtes-vous sûr de vouloir quitter le processus de mesure ?",
    Labels: "Étiquettes",
    Select_Language: "Choisir la langue",
    This_call_is_being_recorded: "Cet appel vidéo est enregistré",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "Cet appel est enregistré par votre évaluateur pour son dossier de travail privé.",
    It_will_not_be_shared: `Il ne sera partagé avec aucune partie extérieure, y compris votre hypothèque
    prêteur, et votre évaluateur ne pourra pas le télécharger à partir du
    Site RemoteVal. Il sera utilisé uniquement pour que l'évaluateur complète
    votre évaluation.`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "Si vous ne souhaitez pas que votre écran soit enregistré, veuillez en informer votre évaluateur dès maintenant.",
    Measurement: "La mesure",
    Create_Measurement: "Créer une mesure",
    Fencing: "Escrime",
    Show: "Spectacle",
    Hide: "Cacher",
    Live_Floor_Plan: "Plan d'étage en direct",
    Expand: "Développer",
    Collapse: "Effondrer",
    Instruction_for_Call_Settings: "Instruction pour les paramètres d'appel",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      "Le haut-parleur, le micro et la caméra peuvent être testés via les paramètres d'appel du menu « Plus »",
    To_Check_the_call_settings_now_click_on_the_below_button:
      "Pour vérifier les paramètres d'appel maintenant, cliquez sur le bouton ci-dessous.",
    Call_recording_by_virtual_inspector:
      "Cet appel vidéo est enregistré par votre inspecteur virtuel pour leur fichier de travail privé et à des fins d'audit interne par l'organisation qui l'a demandé. Il ne sera partagé avec aucune fête extérieure.",
    Call_and_Screen_Recording_Opt_Out:
      "Si vous ne souhaitez pas enregistrer votre écran et cet appel, veuillez informer votre inspecteur virtuel maintenant.",
    Test_Audio_Video_Components_Button:
      "Le haut-parleur, le microphone et la caméra peuvent être testés en cliquant sur le bouton ci-dessous.",
  },
  BUTTONS: {
    Re_Connect: "Se reconnecter",
    End_Session: "Fin de session",
    Chat: "Discuter",
    Participants: "Participants",
    Notes: "Remarques",
    Attach_Document: "Joindre un document",
    Questionnaire: "Questionnaire",
    Labels: "Étiquettes",
    Pointer: "Aiguille",
    Markup: "Balisage",
    Flash_Off: "Flash désactivé",
    Flash_On: "Flash activé",
    Call_Recording: "Enregistrement d'appel",
    Capture_Image: "Capturer l'image",
    Screen_Share: "Partage d'écran",
    Stop_Screen_Share: "Arrêter le partage d'écran",
    Call_Settings: "Paramètres d'appel",
    Clear_All: "Tout effacer",
    Exit: "Sortir",
    Save: "sauvegarder",
    Close: "proche",
    Save_and_Exit: "Sauvegarder et quitter",
    Continue: "Continuer",
    Street_View: "Vue sur la rue",
    Open_in_App: "Ouvrir dans l'application",
    Privacy_Policy: "Politique de confidentialité",
    Invite: "Inviter",
    Join: "Rejoindre",
    Add_Captured_Image_Label: "Ajouter une étiquette d'image capturée",
    No: "Non",
    Yes: "Oui",
    Delete: "Effacer",
    Cancel: "Annuler",
    RETRY: "RÉESSAYEZ",
    OK: "d'accord",
    Back: "Arrière",
    Leave_Call: "Quitter l'appel",
    Got_It: "J'ai compris",
    Start_New_Inspection: "Commencer une nouvelle inspection",
    Resume_Existing_Inspection: "Reprendre l'inspection existante",
    View_All_Current_Sketch: "Afficher toutes les esquisses actuelles",
    Open_Sketch: "Ouvrir l'esquisse",
    Auto_Corner_Detection_ON: "Détection d'angle automatique activée",
    Auto_Corner_Detection_OFF: "Détection d'angle automatique désactivée",
    Manually_Capture_Corner: "Capturer manuellement le coin",
    Undo_Last_Corner_Capture: "Annuler la capture du dernier coin",
    Connect_Corner_Finish_Sketch: "Connecter le coin et terminer l'esquisse",
    Capture_3D_Room_Height: "Capturez la hauteur de la pièce en 3D",
    Set_Fixed_3D_Room_Height: "Définir la hauteur de la pièce 3D fixe",
    Capture_Door: "Capturer la porte",
    Set_Door: "Définir la porte",
    Show_Finished_Floor_Plan_Sketch:
      "Afficher l'esquisse du plan d'étage terminé",
    Back_to_Previous_Screen: "Retour à l'écran précédent",
    Add_Room: "Ajouter une pièce",
    Publish_the_floor_plan: "Publier le plan d'étage",
    Exit_Return_to_Inspection: "Sortie et retour à l'inspection",
    Audio: "l'audio",
    Video: "Vidéo",
    Microphone: "Microphone",
    Microphone_not_found: "Micro introuvable",
    Microphone_Blocked: "Microphone bloqué",
    Default: "Défaut",
    Grant_Permission: "Donner la permission",
    Speaker_Blocked: "Haut-parleur bloqué",
    Play_Test_Sound: "Jouer le son de test",
    Preview: "Aperçu",
    Camera: "Caméra",
    Camera_not_found: "Caméra introuvable",
    Camera_Blocked: "Caméra bloquée",
    Done: "Terminé",
    View: "Vue",
    Capture_Labeled_Images: "Capturer des images étiquetées",
    Capture_Measurements: "Mesures de capture",
    Instructions: "Instructions",
    Start_Measurement_Process: "Démarrer le processus de mesure",
    Flash_Light_Not_Supported:
      "Lampe de poche distante non prise en charge sur l'appareil invité ou pas prêt",
  },
  TOOLTIP: {
    Mute_My_Audio: "Couper mon son",
    Unmute_My_Audio: "Activer mon son",
    Stop_My_Video: "Arrêter ma vidéo",
    Start_My_Video: "Démarrer ma vidéo",
    Capture_Images_with_No_Labels: "Capturez des images sans étiquettes",
    Start_Inspection: "Commencer l'inspection",
    Chat: "Discuter",
    More_Options: "Plus d'options",
    End_Call: "Fin d'appel",
    Unpin_Video: "Détacher la vidéo",
    Pin_Video: "Épingler la vidéo",
    Add_New_Note: "Ajouter une nouvelle note",
    Refresh: "Rafraîchir",
    View_More: "Voir plus",
    Invite_Participant: "Inviter un participant",
    Delete_Image: "Supprimer l'image",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "L'invitation a été envoyée avec succès",
    IOS_SAFARI_HELP:
      "Pour de meilleurs résultats, nous vous recommandons d'utiliser Safari",
    UNEXPECTED_ERROR:
      "Des erreurs inattendues se sont produites. Réessayez plus tard.",
    CALL_LINK_NOT_FOUND: "Le lien d'appel est introuvable.",
    CREATE_BOOKMARK_MESSAGE:
      "Veuillez demander à l'administrateur d'ajouter un signet",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED:
      "La taille du fichier doit être inférieure à 10 Mo",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT:
      "Format de fichier non pris en charge",
    LIVE_POINTER_STARTED:
      "Pointeur en direct démarré. Vous pouvez déplacer le pointeur en le faisant glisser.",
    LIVE_POINTER_STOPPED: "Pointeur en direct arrêté",
    APPRAISAL_EXIT_APPROVED: "Sortie acquittée avec succès.",
    APPRAISAL_EXIT_DENIED: "Sortie refusée.",
    MARKUP_CLOSED: "Le balisage est arrêté",
    PRIVACY_POLICY_FAIL:
      "Aucune URL de politique de confidentialité valide trouvée !",
    SWITCH_CAMERA_REQUEST_REJECTED:
      "Le propriétaire de la maison n'a pas partagé la vue de caméra demandée.",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email:
      "Veuillez sélectionner un téléphone portable ou un e-mail",
    This_field_is_required: "Ce champ est obligatoire",
    Cell_Phone_Must_Be_Valid: "Le téléphone portable doit être valide",
    Name_is_Required: "Le nom est requis",
    Name_Must_Be_Valid: "Le nom doit être valide",
    Invalid_Text: "Texte invalide",
    Email_Must_Be_Valid: "L'email doit être valide",
    Name_must_be: "Le nom doit être",
    characters_max: "caractères max",
    Please_select_the_Floor: "Veuillez sélectionner l'étage",
    Please_enter_the_Room_Area_Name:
      "Veuillez saisir le nom de la pièce/de la zone",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "Vous devez utiliser HTTPS pour sélectionner le périphérique de sortie audio",
    Something_went_wrong:
      "Quelque chose s'est mal passé. Veuillez réessayer plus tard.",
    Oops_Call_duration_is_over_and_session_is_ended:
      "Oups! La durée de l'appel est terminée et la session est terminée.",
    No_participant_has_joined_yet_Ending_the_call_session:
      "Aucun participant n'a encore rejoint ! Fin de la session d'appel",
    You_are_offline_Check_your_internet_connection:
      "Tu es hors ligne. Vérifiez votre connection internet.",
  },
  starting_inspection: "Contrôle de démarrage",
  starting_measurement: "Démarrage de la mesure",
  error: "Erreur",
  contact_us: "Nous contacter",
  support_popup_msg:
    "Oups, quelque chose s'est mal passé, veuillez réessayer après parfois ou signaler un problème à notre support.",
  select_po:
    "Sélectionnez le propriétaire pour démarrer le processus d'inspection",
  request_po_for_camera:
    "Veuillez demander au propriétaire d'activer la vue de la caméra pour démarrer le processus d'inspection.",
  wait_until_po_joins:
    "Veuillez attendre que le propriétaire rejoigne l'appel pour démarrer le processus d'inspection",
  select_measurement_method: "Sélectionnez la méthode de mesure",
  inspection_inprogress: "L'inspection est déjà en cours",
  model_msg_retry:
    "Impossible d'établir un canal de communication avec le propriétaire, veuillez réessayer.",
  model_msg_leaveCall:
    "Il semble qu'il y ait un problème de connectivité Internet de chaque côté, veuillez donc déconnecter l'appel en cours et vous reconnecter à nouveau.",
  please_wait: "S'il vous plaît, attendez...",
  loading_sketch: "Chargement de l'esquisse...",
  connection_timeout_msg:
    "La connexion a expiré. Quelque chose d'inhabituel s'est produit et la réponse prend plus de temps que prévu. Veuillez réessayer.",
  previous_measurement_prblm_msg:
    "Oups, quelque chose s'est mal passé avec la mesure précédente. Veuillez réessayer avec le nouveau processus de mesure.",
  measurement_retry_msg:
    "Le processus de mesure n'a pas démarré. Veuillez réessayer.",
  opensketch_retry_msg:
    "Le processus Open Sketch n'a pas démarré. Veuillez réessayer.",
};
