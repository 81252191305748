import React from "react";

const SpinLoader = () => {
  return (
    <div class="ab-loader-wrapper">
      <div class="inner-wrap-data">
        <div class="loader-spin"></div>
      </div>
    </div>
  );
};

export default SpinLoader;
