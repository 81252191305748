import * as actionTypes from "../actions/actionTypes";

const defaultState = {
  loading: false,
  questionnaire: [],
  error: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_QUESTIONNAIRE_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        loading: false,
        questionnaire: action.payload,
        error: null,
      };
    case actionTypes.GET_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
