export const TRANSLATIONS_Cantonese = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired: "安全視頻通話會話過期",
    Secure_Video_Call_Ended: "安全視頻通話結束",
    Thank_you_for_connecting_with_us: "感謝您與我哋聯繫！",
    Connected_Waiting_for_Other_Participants: "連接。 等待其他參與者...",
    Powered_by: "提供者",
    Connecting: "提供者",
    Local_Pike: "本地派克",
    Would_you_like_to_save_image: "你想保存圖像啊？",
    Message: "消息",
    Location_Not_shared: "位置：唔共享",
    Location: "位置",
    Tools: "工具",
    Ask_PO_to_Turn_on_Location: "要求業主啟用位置",
    Preparing_image_for_markup: "準備標記圖像...",
    Download_Message: "下載遠程評估應用程序",
    Disclaimer:
      "有必要從\n應用商店。 下載完成後，請重新打開您的\n短信/電子郵件邀請並再次單擊連結\n自動加入呼叫。",
    MeasurementLinkDisclaimer:
      "有必要由App Store下載RemoteVal應用程序。 下載完成後，請重新打開您的短信/電子郵件邀請，然後再次單擊連結以自動開始檢查。",
    Zoom_Level: "縮放級別",
    enter_cell_phone: "輸入手機",
    Email: "電子郵件",
    Cell_Phone: "手機",
    Participant_Name: "參與者姓名",
    Invite_Participant: "邀請參與者",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "單擊以下連結加入安全視頻呼叫：",
    There_are_no_Attachments_available: "沒有附件可用。",
    Drop_Or_Select_Your_File: "丟棄或選擇文件",
    Attach_Document: "附加文檔",
    Enter_Your_Name: "輸入您的姓名",
    I_confirm_that_I_have_read_and_agree_to_the: "我確認，我已經閱讀並同意",
    Search_or_Add_Captured_Image_Label: "搜索或添加捕獲嘅圖像標籤",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "按輸入或單擊添加新標籤到捕獲圖像與新標籤",
    To: "自",
    Everyone: "個個都",
    Type_a_message: "鍵入消息",
    Are_you_sure_you_want_to_delete: "你確定要刪除嗎？",
    Room_is_full_Please_try_again_later: "房間已經滿了。 請稍後再試",
    Comment: "評論",
    Room_2_Measurement: "房間2測量",
    Room_1_Measurement: "房間1測量",
    Room_2_Image: "房間1測量",
    Room_1_Image: "房間1圖像",
    No_of_Rooms: "房間1圖像",
    Property_Type: "屬性類型",
    House: "房子",
    Inspection_Form: "檢查表",
    Instructions: "指示",
    Guide_user_to_position_camera_on_the_target: "引導用戶把相機定位喺目標上",
    Click_on_the_Label_once_to_capture_labeled_image:
      "單擊標籤一次以捕獲標記嘅圖像。",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "選擇主頁、房間類型和房間名稱嘅地板。",
    Choose_GLA_or_Non_GLA: "選擇格拉或非格拉。",
    Click_on_the_Start_Measurement_button: '單擊"開始測量"按鈕',
    Click_the_Auto_Corner_Detection_to_On_Off: '單擊"自動角檢測"打開/關閉',
    Position_vertical_Green_Floor:
      "把垂直綠色地板標記放置喺房間角落，並確保它觸及地板。",
    App_will_auto_mark_a_corner:
      '應用程序將自動標記一個角落或點擊"手動捕捉角"按鈕',
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "引導用戶步行到房間的每個角落，標記每個角落",
    When_last_line_connects:
      "当最後一行連接到起點時，它將變為紅色。\n應用程序將自動連接同完整嘅草圖，抑或你可以使用\n徜徉連接角+完成草圖撳掣完成，\n測量。",
    button_to_view_final_sketch: '單擊"顯示完成平面圖草圖"按鈕查看最終草圖。',
    Are_You_Sure_You_Want_to_Leave_This_Call: "你確定要離開呢個電話咩？",
    Street_View: "街景",
    Select_Measurement_Type: "選擇測量類型",
    Floor: "地板",
    Ceiling: "天花板",
    View_Sketches_by_Floor: "按樓層查看草圖",
    Uploading_Image: "上傳圖像",
    Call_Setting: "呼叫設置",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "允許訪問您的麥克風。 單擊掛鎖",
    to_the_left_of_the_address_bar: "地址欄嘅左側。",
    Speakers: "揚聲器",
    Allow_access_to_your_Camera_Click_the_padlock:
      "允許訪問您的相機。 單擊掛鎖",
    to_the_address_bar: "到地址欄。",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "未能啟動攝錄機。 請選擇另一台攝錄機。",
    Title: "標題",
    New_Note: "新注...",
    There_are_no_Notes_available: "新注...",
    Are_you_sure_you_want_to_delete_this_note: "你確定要刪除呢張便條啊？",
    Network_Type: "你確定要刪除呢張便條啊？",
    Netword_Strength: "網字強度",
    To_share_live_video: "分享實時視頻。 請打開瀏覽器設置中嘅相機權限",
    click_here: "單擊此處",
    to_reload_the_page: "重新加載頁面。",
    we_cant_access_your_microphone: "重新加載頁面。",
    Questionnaire_Form: "問卷表",
    No_images_found: "搵唔到圖像！",
    Image: "圖像",
    Captured_Images: "捕獲嘅圖像",
    Share_your_screen: "共享屏幕",
    Share_your_live_video: "分享您的實時視頻？",
    This_will_stop_your_video: "將停止您的視頻。 你想繼續嗎？",
    This_will_stop_screen_share: "將停止屏幕共享。 你想繼續嗎？",
    Room_Area_Label: "房間/區域標籤",
    Room_Area_Name: "房間/區域名稱 （例如睡房1，浴室2等）",
    Room_Name_Placeholder: "房間/區域名稱",
    You: "你",
    Are_you_sure_you_want_to_delete_captured_image:
      "你確定要刪除捕獲嘅圖像啊？",
    Start_Over: "你確定要刪除捕獲嘅圖像啊？",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "你確定要退出測量流程啊？",
    Labels: "標籤",
    Select_Language: "選擇語言",
    This_call_is_being_recorded: "正在錄製此視頻通話",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "此呼叫由您的評估師記錄為其私人工作文件。",
    It_will_not_be_shared: `它不會與任何外部方共享，包括您的抵押貸款
    貸款人，您的評估師將無法從
    遠程Val站點。 它將僅用于評估師完成
    你嘅評價。`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "如果你唔希望您的屏幕被記錄落嚟，請通知您的評估師。",
    Measurement: "測量",
    Create_Measurement: "創建測量",
    Fencing: "擊劍",
    Show: "顯示",
    Hide: "屈",
    Live_Floor_Plan: "實時平面圖",
    Expand: "擴大",
    Collapse: "崩潰",
    Instruction_for_Call_Settings: "呼叫設置說明",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      "扬声器、麦克风和摄像头可以通过“更多”菜单中的通话设置进行测试",
    To_Check_the_call_settings_now_click_on_the_below_button:
      "要檢查呼叫設置，請立即單擊下面嘅掣。",
    Call_recording_by_virtual_inspector:
      "此視頻通話由您的虛擬檢查員錄製，用于其私人工作文件，並由請求它的組織用于內部審計目的。 它唔會與任何外部方共享。",
    Call_and_Screen_Recording_Opt_Out:
      "如果您不希望您的屏幕和此通話被記錄下來，請立即通知您的虛擬檢查器。",
    Test_Audio_Video_Components_Button:
      "揚聲器，咪同攝像頭可以透過單擊下面嘅掣進行測試。",
  },
  BUTTONS: {
    Re_Connect: "標籤",
    End_Session: "結束會話",
    Chat: "聊天",
    Participants: "參與者",
    Notes: "筆記",
    Attach_Document: "附加文檔",
    Questionnaire: "問卷",
    Labels: "標籤",
    Pointer: "指針",
    Markup: "標記",
    Flash_Off: "標記",
    Flash_On: "閃爍打開",
    Call_Recording: "閃爍打開",
    Capture_Image: "閃爍打開",
    Screen_Share: "屏幕共享",
    Stop_Screen_Share: "停止屏幕共享",
    Call_Settings: "呼叫設置",
    Clear_All: "全部清除",
    Exit: "退出",
    Save: "救",
    Close: "關閉",
    Save_and_Exit: "保存同退出",
    Continue: "繼續",
    Street_View: "街景",
    Open_in_App: "在應用程序中打開",
    Privacy_Policy: "私隱策略",
    Invite: "邀請",
    Join: "加入",
    Add_Captured_Image_Label: "添加捕獲嘅圖像標籤",
    No: "唔係",
    Yes: "係",
    Delete: "刪除",
    Cancel: "取消",
    RETRY: "重試",
    OK: "重試",
    Back: "返回",
    Leave_Call: "離開呼叫",
    Got_It: "明喇",
    Start_New_Inspection: "開始新嘅檢查",
    Resume_Existing_Inspection: "恢復現有檢查",
    View_All_Current_Sketch: "查看所有當前草圖",
    Open_Sketch: "打開草圖",
    Auto_Corner_Detection_ON: "自動角檢測打開",
    Auto_Corner_Detection_OFF: "自動角檢測關閉",
    Manually_Capture_Corner: "手動捕獲角",
    Undo_Last_Corner_Capture: "撤消最後一角捕獲",
    Connect_Corner_Finish_Sketch: "連接角=完成草圖",
    Capture_3D_Room_Height: "捕捉3D房間高度",
    Set_Fixed_3D_Room_Height: "設置固定3D房間高度",
    Capture_Door: "捕獲門",
    Set_Door: "設置門",
    Show_Finished_Floor_Plan_Sketch: "顯示完成嘅平面圖草圖",
    Back_to_Previous_Screen: "返抵上一個屏幕",
    Add_Room: "添加房間",
    Publish_the_floor_plan: "發佈平面圖",
    Exit_Return_to_Inspection: "退出並返回檢查",
    Audio: "音頻",
    Video: "視頻",
    Microphone: "咪",
    Microphone_not_found: "未搵到咪",
    Microphone_Blocked: "麥克風已阻止",
    Default: "違約",
    Grant_Permission: "違約",
    Speaker_Blocked: "揚聲器已阻止",
    Play_Test_Sound: "播放測試聲音",
    Preview: "預覽",
    Camera: "相機",
    Camera_not_found: "未搵到相機",
    Camera_Blocked: "相機已阻止",
    Done: "做",
    View: "视圖",
    Capture_Labeled_Images: "捕獲標記嘅圖像",
    Capture_Measurements: "捕獲測量",
    Instructions: "指示",
    Start_Measurement_Process: "開始測量過程",
    Flash_Light_Not_Supported: "遠程手電筒喺來賓設備上不受支持或未準備就緒",
  },
  TOOLTIP: {
    Mute_My_Audio: "靜音我嘅音頻",
    Unmute_My_Audio: "解開我嘅音頻",
    Stop_My_Video: "停止我的視頻",
    Start_My_Video: "開始我嘅視頻",
    Capture_Images_with_No_Labels: "無標籤捕獲圖像",
    Start_Inspection: "開始檢查",
    Chat: "聊天",
    More_Options: "更多選項",
    End_Call: "結束呼叫",
    Unpin_Video: "解開視頻",
    Pin_Video: "引腳視頻",
    Add_New_Note: "添加新註釋",
    Refresh: "刷新",
    View_More: "刷新",
    Invite_Participant: "邀請參與者",
    Delete_Image: "邀請參與者",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "邀請被發送苏克西斯",
    IOS_SAFARI_HELP: "為咗獲得最佳效果，我哋建議使用Safari",
    UNEXPECTED_ERROR: "意外錯誤發生。 稍後再試一次。",
    CALL_LINK_NOT_FOUND: "搵唔到呼叫連結。",
    CREATE_BOOKMARK_MESSAGE: "請要求管理員添加書籤",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED: "文件大小應小於10MB",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT: "唔支持嘅文件格式",
    LIVE_POINTER_STARTED: "開始實時指點。 你可以透過拖動移動指點。",
    LIVE_POINTER_STOPPED: "停止實時指點",
    APPRAISAL_EXIT_APPROVED: "退出成功。",
    APPRAISAL_EXIT_DENIED: "退出食檸檬。",
    MARKUP_CLOSED: "標記已停止",
    PRIVACY_POLICY_FAIL: "未搵到有效的私隱政策URL！",
    SWITCH_CAMERA_REQUEST_REJECTED: "房主冇分享請求嘅相機视圖。",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email: "請選擇手機或電子郵件",
    This_field_is_required: "此字段係必需嘅",
    Cell_Phone_Must_Be_Valid: "手機必須有效",
    Name_is_Required: "需要名稱",
    Name_Must_Be_Valid: "名稱必須有效",
    Invalid_Text: "無效文本",
    Email_Must_Be_Valid: "電子郵件必須有效",
    Name_must_be: "名稱必須係",
    characters_max: "字符最大",
    Please_select_the_Floor: "請選擇樓層",
    Please_enter_the_Room_Area_Name: "請輸入房間/區域名稱",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "你需要使用HTTPS嚟選擇音頻輸出設備",
    Something_went_wrong: "出事了 請稍後再試。",
    Oops_Call_duration_is_over_and_session_is_ended:
      "哎呀！ 呼叫持續時間結束，會話結束。",
    No_participant_has_joined_yet_Ending_the_call_session:
      "未有參與者加入！ 結束呼叫會話",
    You_are_offline_Check_your_internet_connection:
      "你處於脫機狀態。 檢查您的互聯網連接。",
  },
  starting_inspection: "啟動檢查",
  starting_measurement: "開始測量",
  error: "錯誤",
  contact_us: "聯繫我哋",
  support_popup_msg:
    "糟糕，出咗啲問題，請稍後再試，或向我哋嘅支持人員報告問題。",
  select_po: "選擇財產所有者以啟動檢查過程",
  request_po_for_camera: "請要求業主打開攝像頭視圖以開始檢查過程。",
  wait_until_po_joins: "請等到業主加入電話後再開始檢查過程",
  select_measurement_method: "選擇測量方法",
  inspection_inprogress: "檢查已經喺進行中",
  model_msg_retry: "無法與業主建立溝通渠道，請重試。",
  model_msg_leaveCall:
    "睇嚟兩邊都有互聯網連接問題，因此請斷開當前呼叫並連接番。",
  please_wait: "請稍候。。。",
  loading_sketch: "正在加載草圖...",
  connection_timeout_msg:
    "連接已超時。 發生一些唔尋常嘅事情，需要比預期更長的時間才能做出反應。 請重試。",
  previous_measurement_prblm_msg:
    '操作，以前嘅測量出咗問題。 請使用新嘅"測量過程"重試。',
  measurement_retry_msg: "測量過程尚未開始。 請重試。",
  opensketch_retry_msg: "徜徉打開草圖桎梏進程尚未啟動。 請重試。",
};
