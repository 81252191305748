import React from "react";
import "./LeaveModel.scss";
import { isAppraiser } from "./../../../helpers/commonJSFunction";
import { useTranslation } from "react-i18next";
export default function LeaveModel(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p>
              <b>{t("WEB_LABELS.Are_You_Sure_You_Want_to_Leave_This_Call")}</b>
            </p>
          </div>
          <div className="bottom-btn">
            <button className="gray-btn" onClick={() => props.Cancel()}>
              {t("BUTTONS.Cancel")}
            </button>
            <button className="red-btn" onClick={() => props.Confirm()}>
              {t("BUTTONS.Leave_Call")}
            </button>
            {props.role && isAppraiser(props.role) && (
              <button className="red-btn" onClick={() => props.endCall()}>
                {t("BUTTONS.End_Session")}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
