import React from "react";
import { useTranslation } from "react-i18next";

const LoaderModel = (props) => {
  const { t } = useTranslation();
  return (
    <div className="connecting-loading measurement-loader">
      <h4>
        <div className="loader-spin-measurement"></div>
        {props.inspectionLoading && <span>{t("starting_inspection")}</span>}
        {props.measurementLoading && <span>{t("starting_measurement")}</span>}
        {!props.measurementLoading && props.inspectionProgressLoader && (
          <span>{t("inspection_inprogress")}</span>
        )}
        {(props.waitForAcknowledgement || props.addRoomLoading) && (
          <span>{t("please_wait")}</span>
        )}
        {props.loadingSketch && <span>{t("loading_sketch")}</span>}
      </h4>
    </div>
  );
};

export default LoaderModel;
