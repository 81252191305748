export const TRANSLATIONS_Tagalog = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired:
      "Nag-expire na ang Secure na Session ng Call ng Video",
    Secure_Video_Call_Ended: "Natapos ang Secure Video Call",
    Thank_you_for_connecting_with_us: "Salamat sa iyong pagkonekta sa amin!",
    Connected_Waiting_for_Other_Participants:
      "Nakakonekta Naghihintay para sa Ibang Mga Kalahok ...",
    Powered_by: "Pinatatakbo ng",
    Connecting: "Kumokonekta ...",
    Local_Pike: "Lokal na Pike",
    Would_you_like_to_save_image: "Nais mo bang makatipid ng imahe?",
    Message: "Mensahe",
    Location_Not_shared: "Lokasyon: Hindi ibinahagi",
    Location: "Lokasyon",
    Tools: "Wakas",
    Ask_PO_to_Turn_on_Location:
      "Hilingin sa May-ari ng Ari-arian na i-on ang Lokasyon",
    Preparing_image_for_markup: "Inihahanda ang imahe para sa markup ...",
    Download_Message: "Mag-download ng Application ng Remote Appraisal",
    Disclaimer:
      "Kinakailangan na i-download ang application na RemoteVal mula sa App Store. Kapag nakumpleto na ang pag-download, mangyaring muling buksan ang iyong text / email na paanyaya sa pag-imbita at i-click muli ang link upang awtomatikong sumali sa tawag.",
    MeasurementLinkDisclaimer:
      "Kinakailangang i-download ang RemoteVal application mula sa App Store. Kapag nakumpleto na ang pag-download, mangyaring buksan muli ang iyong imbitasyon sa text/email message at i-click muli ang link upang awtomatikong simulan ang inspeksyon.",
    Zoom_Level: "Antas ng Pag-zoom",
    enter_cell_phone: "ipasok ang cell phone",
    Email: "Email",
    Cell_Phone: "Cell Phone",
    Participant_Name: "Pangalan ng Kalahok",
    Invite_Participant: "Anyayahan ang Kalahok",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "Sumali sa ligtas na video call sa pamamagitan ng pag-click sa sumusunod na link:",
    There_are_no_Attachments_available: "Walang magagamit na Mga Attachment.",
    Drop_Or_Select_Your_File: "I-drop O Piliin ang Iyong File",
    Attach_Document: "Mag-attach ng Dokumento",
    Enter_Your_Name: "Ipasok ang Iyong Pangalan",
    I_confirm_that_I_have_read_and_agree_to_the:
      "Kinukumpirma kong nabasa ko at sumasang-ayon ako sa",
    Search_or_Add_Captured_Image_Label:
      "Maghanap o Magdagdag ng Captured Image Label",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "Pindutin ang Enter o Mag-click sa Magdagdag ng Bagong Label sa Nakuhang Larawan na may Bagong Label",
    To: "Sa",
    Everyone: "Lahat po",
    Type_a_message: "Mag-type ng mensahe",
    Are_you_sure_you_want_to_delete: "Sigurado ka bang nais mong tanggalin?",
    Room_is_full_Please_try_again_later: "Puno ang silid. Subukang muli mamaya",
    Comment: "Magkomento",
    Room_2_Measurement: "Pagsukat sa Silid 2",
    Room_1_Measurement: "Pagsukat sa Silid 1",
    Room_2_Image: "Larawan ng Silid 2",
    Room_1_Image: "Larawan ng Silid 1",
    No_of_Rooms: "Bilang ng Mga Kwarto",
    Property_Type: "uri ng ari-arian",
    House: "Bahay",
    Inspection_Form: "Form ng Pagsisiyasat",
    Instructions: "Panuto",
    Guide_user_to_position_camera_on_the_target:
      "Gabayan ang gumagamit sa posisyon ng camera sa target",
    Click_on_the_Label_once_to_capture_labeled_image:
      "Mag-click sa Label nang isang beses upang makuha ang may label na imahe.",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "Piliin ang sahig ng Tahanan, Uri ng Silid at isang Pangalan ng Silid.",
    Choose_GLA_or_Non_GLA: "Pumili ng GLA o Non-GLA.",
    Click_on_the_Start_Measurement_button:
      "Mag-click sa pindutang Simulan ang Pagsukat",
    Click_the_Auto_Corner_Detection_to_On_Off:
      'I-click ang "Auto Corner Detection" upang Buksan / I-off',
    Position_vertical_Green_Floor:
      "Posisyon ng patayo na Green Floor Marker sa isang Room Corner at tiyaking hinahawakan nito ang sahig.",
    App_will_auto_mark_a_corner:
      'Awtomatikong markahan ng app ang isang sulok o i-tap ang pindutang "Manu-manong Kunan ang Sulok"',
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "Gabayan ang gumagamit na maglakad sa bawat sulok ng silid upang markahan ang bawat sulok",
    When_last_line_connects:
      'Kapag nag-uugnay ang huling linya sa panimulang punto, magiging pula ito. Awtomatikong kumokonekta ang app at kumpletuhin ang sketch o maaari mong gamitin ang pindutang "Connect Corner & Finish Sketch" upang makumpleto, ang pagsukat.',
    button_to_view_final_sketch:
      'Mag-click sa pindutang "Ipakita ang Tapos na Sketch ng Planong Pang-Floor" upang matingnan ang pangwakas na sketch.',
    Are_You_Sure_You_Want_to_Leave_This_Call:
      "Sigurado ka bang Nais mong iwan ang Tawag na Ito?",
    Street_View: "STREET View",
    Select_Measurement_Type: "Piliin ang Uri ng Pagsukat",
    Floor: "Palapag",
    Ceiling: "Kisame",
    View_Sketches_by_Floor: "Tingnan ang Mga Sketch ayon sa Palapag",
    Uploading_Image: "Pag-upload ng Larawan",
    Call_Setting: "Pagtakda ng Tawag",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "Payagan ang pag-access sa iyong Mikropono. I-click ang padlock",
    to_the_left_of_the_address_bar: "sa kaliwa ng address bar.",
    Speakers: "Mga nagsasalita",
    Allow_access_to_your_Camera_Click_the_padlock:
      "Payagan ang pag-access sa iyong Camera. I-click ang padlock",
    to_the_address_bar: "sa address bar.",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "Nabigong simulan ang video camera. Mangyaring pumili ng isa pang video camera.",
    Title: "Pamagat",
    New_Note: "Bagong Tala ...",
    There_are_no_Notes_available: "Walang magagamit na Mga Tala.",
    Are_you_sure_you_want_to_delete_this_note:
      "Sigurado ka bang nais mong tanggalin ang tala na ito?",
    Network_Type: "Uri ng network",
    Netword_Strength: "Lakas ng Netword",
    To_share_live_video:
      "Upang magbahagi ng live na video. mangyaring i-on ang pahintulot ng camera sa iyong mga setting ng browser pagkatapos",
    click_here: "pindutin dito",
    to_reload_the_page: "upang mai-reload ang pahina.",
    we_cant_access_your_microphone:
      "hindi namin ma-access ang iyong mikropono. Upang magbigay ng access, Pumunta sa mga setting ng iyong browser at pagkatapos",
    Questionnaire_Form: "Form ng Katanungan",
    No_images_found: "Walang nahanap na mga imahe!",
    Image: "Larawan",
    Captured_Images: "Nakunan ng Mga Larawan",
    Share_your_screen: "Ibahagi ang iyong screen",
    Share_your_live_video: "Ibahagi ang iyong live na video?",
    This_will_stop_your_video:
      "Ititigil nito ang iyong video. Gusto mo bang magpatuloy?",
    This_will_stop_screen_share:
      "Ititigil nito ang pagbabahagi ng screen. Gusto mo bang magpatuloy?",
    Room_Area_Label: "Label ng Kwarto/Lugar",
    Room_Area_Name:
      "Pangalan ng Kwarto/Lugar (hal. Silid-tulugan 1, Paliguan 2, atbp.)",
    Room_Area_Name_Placeholder: "Pangalan ng Kwarto/Lugar",
    You: "Ikaw",
    Are_you_sure_you_want_to_delete_captured_image:
      "Sigurado ka bang nais mong tanggalin ang nakunan ng imahe?",
    Start_Over:
      "Magsimula Pa - Sigurado Ka Bang Nais mong Tanggalin ang Mga Plano sa Palapag?",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "Sigurado ka bang Nais mong Lumabas sa proseso ng Pagsukat?",
    Labels: "Mga label",
    Select_Language: "Piliin ang Wika",
    This_call_is_being_recorded: "Naitala ang video call na ito",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "Ang tawag na ito ay naitala ng iyong appraiser para sa kanilang pribadong file ng trabaho.",
    It_will_not_be_shared: `Hindi ito ibabahagi sa anumang partido sa labas kasama ang iyong mortgage
    nagpapahiram, at hindi ito mai-download ng iyong appraiser mula sa
    RemoteVal na site. Gagamitin lamang ito para makumpleto ang appraiser
    ang iyong pagtatasa`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "Kung hindi mo nais na maitala ang iyong screen mangyaring ipagbigay-alam sa iyong appraiser ngayon.",
    Instruction_for_Call_Settings: "Pagtuturo para sa Mga Setting ng Tawag",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      'Maaaring masuri ang Speaker, Mic at Camera sa pamamagitan ng mga setting ng tawag mula sa menu na "higit pa".',
    To_Check_the_call_settings_now_click_on_the_below_button:
      "Upang Suriin ang mga setting ng tawag ngayon i-click ang button sa ibaba.",
    Call_recording_by_virtual_inspector:
      "Ang tawag sa video na ito ay naitala ng iyong virtual inspector para sa kanilang pribadong file ng trabaho at para sa mga panloob na layunin sa pag -awdit ng samahan na humiling nito. Hindi ito ibabahagi sa anumang partido sa labas.",
    Call_and_Screen_Recording_Opt_Out:
      "Kung hindi mo nais na maitala ang iyong screen at ang tawag na ito, mangyaring ipaalam sa iyong virtual inspector ngayon.",
    Test_Audio_Video_Components_Button:
      "Ang speaker, mikropono at camera ay maaaring masuri sa pamamagitan ng pag -click sa pindutan sa ibaba.",
  },
  BUTTONS: {
    Re_Connect: "Muling Kumonekta",
    End_Session: "Tapusin ang Sisyon",
    Chat: "Chat",
    Participants: "Mga kalahok",
    Notes: "Mga tala",
    Attach_Document: "Mag-attach ng Dokumento",
    Questionnaire: "Katanungan",
    Labels: "Mga label",
    Pointer: "Pointer",
    Markup: "Markup",
    Flash_Off: "Patay na Flash",
    Flash_On: "Flash On",
    Call_Recording: "Pagrekord ng Tawag",
    Capture_Image: "Kunan ng Larawan",
    Screen_Share: "Pagbabahagi ng Screen",
    Stop_Screen_Share: "Ihinto ang Pagbabahagi ng Screen",
    Call_Settings: "Mga Setting ng Tawag",
    Clear_All: "Alisin lahat",
    Exit: "Exit",
    Save: "Magtipid",
    Close: "Isara",
    Save_and_Exit: "I-save at Exit",
    Continue: "Magpatuloy",
    Street_View: "STREET View",
    Open_in_App: "Buksan sa App",
    Privacy_Policy: "Patakaran sa Pagkapribado",
    Invite: "Mag-anyaya",
    Join: "Sumali",
    Add_Captured_Image_Label: "Magdagdag ng Captured Image Label",
    No: "Hindi",
    Yes: "Oo",
    Delete: "Tanggalin",
    Cancel: "Kanselahin",
    RETRY: "RETRY",
    OK: "OK lang",
    Back: "Bumalik",
    Leave_Call: "Leave Call",
    Got_It: "Nakuha ko",
    Start_New_Inspection: "Magsimula ng Bagong Inspeksyon",
    Resume_Existing_Inspection: "Ipagpatuloy ang Umiiral na Inspeksyon",
    View_All_Current_Sketch: "Tingnan ang Lahat ng Kasalukuyang Sketch (es)",
    Open_Sketch: "Buksan ang Sketch",
    Auto_Corner_Detection_ON: "NAKA-ON ang Pagtuklas ng Auto Corner",
    Auto_Corner_Detection_OFF: "NAKA-OFF ang Auto Corner Detection",
    Manually_Capture_Corner: "Mano-manong Makuha ang Sulok",
    Undo_Last_Corner_Capture: "I-undo ang Huling Pagkunan ng Sulok",
    Connect_Corner_Finish_Sketch: "Ikonekta ang Sulok at Tapos na Sketch",
    Capture_3D_Room_Height: "Kunan ang Taas ng 3D Room",
    Set_Fixed_3D_Room_Height: "Itakda ang Fixed 3D Room Taas",
    Capture_Door: "Makuha ang Pinto",
    Set_Door: "Itakda ang Pinto",
    Show_Finished_Floor_Plan_Sketch:
      "Ipakita ang Tapos na Sketch ng Planong Pang-sahig",
    Back_to_Previous_Screen: "Bumalik sa Nakaraang Screen",
    Add_Room: "Magdagdag ng Silid",
    Publish_the_floor_plan: "I-publish ang Floor Plan",
    Exit_Return_to_Inspection: "Lumabas at Bumalik sa Inspeksyon",
    Audio: "Audio",
    Video: "Video",
    Microphone: "Mikropono",
    Microphone_not_found: "Hindi nahanap ang mikropono",
    Microphone_Blocked: "Na-block ang Mikropono",
    Default: "Default",
    Grant_Permission: "Pinayagang pahintulot",
    Speaker_Blocked: "Na-block ang Speaker",
    Play_Test_Sound: "Patugtugin ang Tunog ng Pagsubok",
    Preview: "Preview",
    Camera: "Kamera",
    Camera_not_found: "Hindi nahanap ang camera",
    Camera_Blocked: "Naka-block ang Camera",
    Done: "Tapos na",
    View: "Tingnan",
    Capture_Labeled_Images: "Kunan ang Mga Label na Larawan",
    Capture_Measurements: "Mga Sukat sa Kunan",
    Instructions: "Panuto",
    Start_Measurement_Process: "Simulan ang Proseso ng Pagsukat",
    Measurement: "Pagsukat",
    Create_Measurement: "Lumikha ng Pagsukat",
    Fencing: "Ang eskrima",
    Show: "Ipakita",
    Hide: "Tago",
    Live_Floor_Plan: "Plano ng Live na Palapag",
    Expand: "Palawakin",
    Collapse: "Pagbagsak",
    Flash_Light_Not_Supported:
      "Hindi suportado ang Remote Flashlight sa aparato ng panauhin o hindi handa",
  },
  TOOLTIP: {
    Mute_My_Audio: "I-mute ang Aking Audio",
    Unmute_My_Audio: "I-unmute ang Aking Audio",
    Stop_My_Video: "Itigil ang Aking Video",
    Start_My_Video: "Simulan ang Aking Video",
    Capture_Images_with_No_Labels: "Kunan ang Mga Imahe na Walang Mga Label",
    Start_Inspection: "Simulan ang Pag-iinspeksyon",
    Chat: "Chat",
    More_Options: "Marami pang Mga Pagpipilian",
    End_Call: "Tapusin ang Tawag",
    Unpin_Video: "I-unpin ang Video",
    Pin_Video: "I-pin ang Video",
    Add_New_Note: "Magdagdag ng Bagong Tala",
    Refresh: "Refresh",
    View_More: "Tingnan ang Higit Pa",
    Invite_Participant: "Anyayahan ang Kalahok",
    Delete_Image: "Tanggalin ang Larawan",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "Matagumpay Na Naipadala ang Imbitasyon",
    IOS_SAFARI_HELP:
      "Para sa pinakamahusay na mga resulta, inirerekumenda namin ang paggamit ng Safari",
    UNEXPECTED_ERROR:
      "Hindi Inaasahang mga Error na nangyari. Subukan ulit mamaya.",
    CALL_LINK_NOT_FOUND: "Ang Call Link ay hindi natagpuan.",
    CREATE_BOOKMARK_MESSAGE:
      "Mangyaring Hilingin sa Administrator na Magdagdag ng Bookmark",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED:
      "Ang laki ng file ay dapat mas mababa sa 10MB",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT:
      "Hindi Sinusuportahang Format ng File",
    LIVE_POINTER_STARTED:
      "Nagsimula ng live pointer. Maaari mong ilipat ang pointer sa pamamagitan ng pag-drag.",
    LIVE_POINTER_STOPPED: "Natigil ang live na pointer",
    APPRAISAL_EXIT_APPROVED: "Matagumpay na kinilala ang exit.",
    APPRAISAL_EXIT_DENIED: "Tinanggihan ang exit.",
    MARKUP_CLOSED: "Itinigil na ang Markup",
    PRIVACY_POLICY_FAIL:
      "Walang nahanap na wastong Patakaran sa Privacy ng URL!",
    SWITCH_CAMERA_REQUEST_REJECTED:
      "Ang Home May-ari ay hindi nagbahagi ng hiniling na pagtingin sa camera.",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email: "Mangyaring Piliin ang Cellphone o Email",
    This_field_is_required: "Ang patlang na ito ay kinakailangan",
    Cell_Phone_Must_Be_Valid: "Dapat Maging Bisa ang Cell Phone",
    Name_is_Required: "Kailangan ang Pangalan",
    Name_Must_Be_Valid: "Kailangang Patunayan ang Pangalan",
    Invalid_Text: "Di-wastong Text",
    Email_Must_Be_Valid: "Kailangang Maging Valid ang Email",
    Name_must_be: "Pangalan dapat",
    characters_max: "mga character na max",
    Please_select_the_Floor: "Mangyaring piliin ang Palapag",
    Please_enter_the_Room_Area_Name:
      "Mangyaring ilagay ang Pangalan ng Kwarto/Lugar",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "Kailangan mong gumamit ng HTTPS para sa pagpili ng audio output device",
    Something_went_wrong: "May nangyaring mali. Subukang muli mamaya.",
    Oops_Call_duration_is_over_and_session_is_ended:
      "Naku! Natapos ang tagal ng pagtawag at natapos ang sesyon.",
    No_participant_has_joined_yet_Ending_the_call_session:
      "Wala pang sumali na sumali! Pagtatapos ng sesyon ng pagtawag",
    You_are_offline_Check_your_internet_connection:
      "Offline ka. Suriin ang iyong koneksyon sa internet.",
  },
  starting_inspection: "Pagsisimula ng Inspeksyon",
  starting_measurement: "Panimulang Pagsukat",
  error: "Error",
  contact_us: "Makipag-ugnayan sa amin",
  support_popup_msg:
    "Oops, Nagkaproblema, Pakisubukang muli pagkatapos minsan o mag-ulat ng isyu sa aming suporta.",
  select_po:
    "Piliin ang May-ari ng Ari-arian para Simulan ang Proseso ng Inspeksyon",
  request_po_for_camera:
    "Mangyaring hilingin sa May-ari ng Ari-arian na i-on ang view ng camera upang simulan ang proseso ng Inspeksyon.",
  wait_until_po_joins:
    "Mangyaring maghintay hanggang ang May-ari ng Ari-arian ay sumali sa tawag upang simulan ang proseso ng Inspeksyon",
  select_measurement_method: "Piliin ang Paraan ng Pagsukat",
  inspection_inprogress: "Nagpapatuloy na ang inspeksyon",
  model_msg_retry:
    "Hindi makapagtatag ng channel ng komunikasyon sa May-ari ng Ari-arian, Pakisubukang muli.",
  model_msg_leaveCall:
    "Mukhang may problema sa internet connectivity sa magkabilang panig kaya mangyaring idiskonekta ang kasalukuyang tawag at muling kumonekta.",
  please_wait: "Mangyaring maghintay...",
  loading_sketch: "Nilo-load ang Sketch...",
  connection_timeout_msg:
    "Nag-time out ang koneksyon. May nangyaring hindi pangkaraniwang bagay at mas tumatagal kaysa sa inaasahan ang pagtugon. Pakisubukang muli.",
  previous_measurement_prblm_msg:
    "Ops, nagkaproblema sa nakaraang pagsukat. Pakisubukang muli gamit ang bagong Proseso ng Pagsukat.",
  measurement_retry_msg:
    "Hindi pa nagsisimula ang proseso ng pagsukat. Pakisubukang muli.",
  opensketch_retry_msg:
    "Hindi pa nagsisimula ang proseso ng Open Sketch. Pakisubukang muli.",
};
