import React, { Component } from "react";
import {
  extractUserName,
  getDeviceType,
  getUTCToLocalDateTimeWithFormat,
} from "../../../helpers";
import moment from "moment";
import "./chatViewModel.scss";
import { connect } from "react-redux";
import { sendMessage } from "../../../actions/messagesAction";
import { appConstants } from "../../../constants/app.constants";
import { withTranslation } from "react-i18next";
class ChatViewModel extends Component {
  constructor() {
    super();
    this.state = {
      messageText: "",
      isKeyboardOpen: false,
    };
    this.messagesEndRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
    window.visualViewport.addEventListener("resize", (event) => {
      if (this.state.isKeyboardOpen === true) {
        console.log("event");
        this.handleFocusOut();
      } else {
        this.handleFocusIn();
      }
    });
  }
  componentWillUnmount() {
    // Remove event listeners when component unmounts
    window.visualViewport.addEventListener("resize", (event) => {
      if (this.state.isKeyboardOpen === true) {
        console.log("event");
        this.handleFocusOut();
      } else {
        this.handleFocusIn();
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { messages } = this.props;
    if (messages.length !== prevProps.messages.length) {
      this.scrollToBottom();
    }
  }
  // Event handler for keyboard open
  handleFocusIn = () => {
    this.setState({
      isKeyboardOpen: true,
    });
  };

  // Event handler for keyboard close
  handleFocusOut = () => {
    this.setState({
      isKeyboardOpen: false,
    });
  };

  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  getMessageObject(message) {
    const utcDate = moment.utc(moment());
    const date = utcDate.format(appConstants.DATE_TIME_LONG_FORMAT);
    return {
      deviceType: getDeviceType(),
      participantIdentity: this.props.participantIdentity,
      participantSid: this.props.participantSid,
      message,
      dateSent: date,
      timestamp: utcDate.valueOf(),
      senderName: extractUserName(this.props.participantIdentity) || "",
    };
  }

  onMessageChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onMessageSend = () => {
    const { messageText } = this.state;

    if (messageText && messageText.length > 0) {
      this.props.onMessageSend(this.getMessageObject(messageText));
      this.setState({
        messageText: "",
      });
    }
  };

  formatTime = (date) => {
    return getUTCToLocalDateTimeWithFormat(
      date,
      appConstants.DATE_TIME_LONG_FORMAT,
      appConstants.TIME_SHORT_FORMAT
    );
  };

  render() {
    const { messageText, isKeyboardOpen } = this.state;

    const { participantSid, callParticipants, messages, t } = this.props;
    return (
      <>
        <div
          className={`comman-modal chat-modal left-side open ${
            isKeyboardOpen ? "keyboard" : ""
          }`}
        >
          <div className="comman-modal-main">
            <div className="side-head">
              {t("BUTTONS.Chat")}
              <div className="side-head-buttons">
                <button
                  className="close-modal modal-button-effect"
                  onClick={() => {
                    this.props.hideChatModel();
                  }}
                >
                  <i className="icon-close-image"></i>
                </button>
              </div>
            </div>
            <div className="comman-modal-body">
              <div className="chat-view">
                <div className="chat-list scroll-bar-style">
                  <ul>
                    {messages.map((msg, i) => (
                      <li
                        key={i}
                        className={
                          msg.participantSid === participantSid
                            ? "you"
                            : "other"
                        }
                      >
                        <div className="chat-title">
                          {msg.senderName}&nbsp;
                          {msg.participantSid === participantSid && ` (You)`}
                          &nbsp;&nbsp;
                          <span>{this.formatTime(msg.dateSent)}</span>
                        </div>
                        <div className="details">
                          <p>{msg.message}</p>
                        </div>
                      </li>
                    ))}
                    <li ref={this.messagesEndRef}></li>
                  </ul>
                </div>
                <div className="send-chat">
                  <div className="join-call">
                    {t("WEB_LABELS.To")}:
                    <select>
                      <option value="everyone">
                        {t("WEB_LABELS.Everyone")}
                      </option>
                      {Object.keys(callParticipants)
                        .filter((i) => i !== participantSid)
                        .map((id, i) => (
                          <option key={i} value={id}>
                            {callParticipants[id].userName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="chat-input">
                    <div
                      className="chat-area"
                      data-replicated-value={messageText}
                    >
                      <textarea
                        name="messageText"
                        rows="1"
                        value={messageText}
                        onChange={this.onMessageChange}
                        onKeyPress={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            if (!messageText.replace(/\s/g, "").length) {
                              e.preventDefault();
                            } else {
                              this.onMessageSend();
                            }
                          } else if (
                            e.key === "Enter" &&
                            e.shiftKey &&
                            !messageText.replace(/\s/g, "").length
                          ) {
                            e.preventDefault();
                          }
                        }}
                        placeholder={t("WEB_LABELS.Type_a_message")}
                      ></textarea>
                    </div>
                    <button
                      className={`send ${
                        !messageText.replace(/\s/g, "").length && "disabled"
                      }`}
                      disabled={!messageText.replace(/\s/g, "").length}
                      onClick={this.onMessageSend}
                    >
                      <i className="icon-send"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (data) => dispatch(sendMessage(data)),
});

const mapStateToProps = ({ messages }) => ({
  messages: messages.messages || [],
});

const connectedChatViewModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ChatViewModel));
export { connectedChatViewModel as ChatViewModel };
