import api from "./apis/api";
import { getFileFormData } from "../helpers/commonJSFunction";

class offlineUploadDataService {
  uploadImage(callUUId, data, extraPayload = {}) {
    let extraFields = {};
    if (extraPayload && (extraPayload.bookmarkId || extraPayload.label)) {
      extraFields.bookmarkId = extraPayload.bookmarkId;
      extraFields.bookmarkLabel = extraPayload.label; 
    }
    extraFields.isOffline = extraPayload.isOffline;
    const fileFormData = getFileFormData(data, "image.jpg", extraFields);
    return api.offlineUpload
      .uploadOfflineImage(callUUId, fileFormData)
      .then((res) => {
        return res.data;
      });
  }
  uploadVideo(callUUId, data, callID) {
    return api.offlineUpload
      .uploadOfflineVideo(callUUId, data, callID)
      .then((res) => {
        return res.data;
      });
  }
}

export default new offlineUploadDataService();
