import React from "react";
import { useTranslation } from "react-i18next";
import { deleteTableFromDB } from "./storeIndexedData";
export default function OfflineDataManageJo(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p>
              You have already collected offline inspection data, want to
              delete? Select 'Yes' to proceed with a new inspection. Caution:
              this will result in the removal and loss of the prior collected
              data. Alternatively, choose 'No' if you wish to continue and
              upload the existing inspection data.
            </p>
          </div>
          <div className="bottom-btn">
            <button
              className="gray-btn"
              onClick={() => {
                props.continue();
              }}
            >
              {t("BUTTONS.Yes")}
            </button>
            <button
              className="blue-btn"
              onClick={() => {
                props.clickNo();
              }}
            >
              {t("BUTTONS.No")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
