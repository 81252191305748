import React, { Component } from "react";
import "./../CallEnd/callend.scss";
import { withTranslation } from "react-i18next";
class Home extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    return (
      <section className="callend-pagenot-found">
        <div className="box-center">
          <div className="notfound-img">
            <i className="icon-no-page-found"></i>
          </div>
          <div className="content">
            <h2>{t("WEB_LABELS.Secure_Video_Call_Session_Expired")}</h2>
            <p>
              It appears that the link has expired. Kindly generate a new link.
              Thank you for your cooperation.
            </p>
          </div>
        </div>
      </section>
    );
  }
}
export default withTranslation()(Home);
