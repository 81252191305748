import React from "react";
import { useTranslation } from "react-i18next";

export default function BeforeEndCallModel(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p className="content-title">
              <b>Are you sure you want to end the offline inspection?</b>
            </p>
            <p>
              Please note that once the inspection is ended, you will no longer
              be able to capture images and videos through the video inspection
              link.
            </p>
          </div>
          <div className="bottom-btn">
            <button
              className="gray-btn"
              onClick={() => {
                props.OnNo();
              }}
            >
              {t("BUTTONS.No")}
            </button>
            <button
              className="blue-btn"
              onClick={() => {
                props.OnYes();
              }}
            >
              {t("BUTTONS.Yes")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
