import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RefreshModal from "./RefreshModal";

export default function MemoryModel(props) {
  const [showRefreshInstructionModel, setShowRefreshInstructionModel] =
    useState(false);

  const handleOkClick = () => {
    if (!props.clickOnReconnect) {
      setShowRefreshInstructionModel(false);
      props.onOk();
    }
  };

  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p className="content-title">
              <b>Instructions</b>
            </p>
            {!props.clickOnReconnect ? (
              <p>
                Ensure your device has at least 2GB of available disk space
                prior to proceeding with the inspection.
              </p>
            ) : (
              <p>
                Network is currently unavailable. To proceed with the video
                inspection, please use the offline video mode.
              </p>
            )}
          </div>
          <div className="bottom-btn">
            <button
              className="gray-btn"
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="blue-btn"
              onClick={() => {
                if (props.clickOnReconnect) {
                  props.onOk();
                } else {
                  setShowRefreshInstructionModel(true);
                }
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      {showRefreshInstructionModel && <RefreshModal onOk={handleOkClick} />}
    </>
  );
}
