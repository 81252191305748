import React, { useState } from "react";
import "./connectingMember.scss";
import { useTranslation } from "react-i18next";
export default function ConnectingMember(props) {
  const { t } = useTranslation();
  const [imageLoaded, setImageLoaded] = useState(false);

  const isPowerBy =
    props.configs && props.configs.settings && props.configs.settings.isPowerBy;

  const clientDisplayName =
    props.configs &&
    props.configs.settings &&
    props.configs.settings.clientDisplayName;

  const logo =
    props.configs && props.configs.callLogo
      ? props.configs.callLogo
      : "/images/participants.jpg";

  const style = {};

  if (props.configs && props.configs.backgroundColor && imageLoaded) {
    style.backgroundColor = props.configs.backgroundColor;
    style.padding = "10px";
  }

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  return (
    <>
      {/* <!-- Connecting Member --> */}
      <div className="connecting-member">
        {logo && (
          <div className="image" style={{ ...style }}>
            <img
              style={{ display: imageLoaded ? "block" : "none" }}
              src={logo}
              alt="Logo"
              onLoad={handleImageLoaded}
            />
          </div>
        )}
        <div className="connecting-loading">
          <h4>
            <div className="loader-spin"></div>{" "}
            <span>
              {t("WEB_LABELS.Connected_Waiting_for_Other_Participants")}
            </span>
          </h4>
        </div>
        <div className="powered-by">
          <div className="site-logo">
            {isPowerBy && (
              <h4>
                {t("WEB_LABELS.Powered_by")} {clientDisplayName}
              </h4>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
