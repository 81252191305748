import React, { useState, useEffect } from "react";

export default function OfflineGalleryZoomModel(props) {
  const PREV_DIRECTION = "PREV";
  const NEXT_DIRECTION = "NEXT";
  const [currentImageId, setCurrentImageId] = useState(0);
  const [capturedImages, setCapturedImages] = useState([]);

  useEffect(() => {
    setCapturedImages(props.captureImages);
    setCurrentImageId(props.currentImageIndex ?? 0);
  }, [props.currentImageIndex]);

  const updateCounter = (direction) => {
    const currentIndex = capturedImages.findIndex(
      (image) => image.id === currentImageId
    );

    let updatedImageIndex = currentIndex;

    switch (direction) {
      case PREV_DIRECTION:
        updatedImageIndex =
          currentIndex === capturedImages.length - 1
            ? currentIndex
            : currentIndex + 1;
        break;
      case NEXT_DIRECTION:
        updatedImageIndex = currentIndex === 0 ? 0 : currentIndex - 1;
        break;
      default:
        break;
    }
    let id = capturedImages[updatedImageIndex].id;
    setCurrentImageId(id);
  };
  const filteredImage = capturedImages.find(
    (image) => image.id === currentImageId
  );
  const imageUrl = filteredImage?.imageUrl || "";

  const currentImageNumber =
    capturedImages.length -
    capturedImages.findIndex((image) => image.id === currentImageId);

  const isLastImage = currentImageId === capturedImages[0]?.id;
  const isFirstImage =
    currentImageId === capturedImages[capturedImages.length - 1]?.id;
  return (
    <>
      <div className="download_images_sec offline-image-model">
        <div className="download_img_header">
          <div className="image_count">
            {!props.videoView && (
              <p>
                {currentImageNumber} / {capturedImages?.length}
              </p>
            )}
          </div>
          <div className="download_img_btn">
            <div className="download_img_icon">
              <button
                type="button"
                onClick={() => {
                  props.hideGalleryZoomModel();
                }}
              >
                <i className="icon-close-image"></i>
              </button>
            </div>
          </div>
        </div>
        {!props.videoView && (
          <>
            <div className={`left_arrow ${isFirstImage ? "disabled" : ""}`}>
              <button
                type="button"
                disabled={isFirstImage}
                onClick={() => updateCounter(PREV_DIRECTION)}
              >
                <i className="icon-previous-arrow"></i>
              </button>
            </div>
            <div className={`right_arrow  ${isLastImage ? "disabled" : ""}`}>
              <button
                type="button"
                disabled={isLastImage}
                onClick={() => updateCounter(NEXT_DIRECTION)}
              >
                <i className="icon-next-arrow"></i>
              </button>
            </div>

            <div className="image_content">
              {capturedImages?.length > 0 && (
                <img src={imageUrl} alt="Loading Captured Pic..." />
              )}
            </div>
          </>
        )}
        {props.videoView && (
          <div className="image_content">
            <video src={props.captureVideo} controls autoPlay />
          </div>
        )}
      </div>
    </>
  );
}
