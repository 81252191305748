import api from "./api";

export default class API {
  uploadOfflineImage(callUUId, data) {
    return api.postMultipartFormData(`call/${callUUId}/picture`, data);
  }
  uploadOfflineVideo(callUUId, videoBlob, callID) {
    const formData = new FormData();
    formData.append("file", videoBlob, "video.mp4");
    formData.append("callId", callID);
    return api.postMultipartFormData(`call/${callUUId}/video`, formData);
  }
}
