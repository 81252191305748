import _ from "lodash";

export function dehydrateNote(data) {
  return _.omitBy(
    {
      noteId: data.id,
      noteTitle: data.title,
      noteDescription: data.description,
      noteStatus: data.status,
      updatedAt: data.updatedAt,
      createdBy: data.createdBy,
      createdByName: data.createdByName,
      updatedBy: data.updatedBy,
    },
    _.isUndefined
  );
}

export const dehydrateNotes = (data) => data.map((d) => dehydrateNote(d));
