import React, { useEffect, useRef, useState } from "react";
import RecordRTC from "recordrtc";
import { customToast } from "../../helpers/customToast";
import {
  deleteImage,
  retrieveBookmarks,
  storeImage,
  storeVideo,
  dataExistInTable,
  deleteTableFromDB,
  retrieveCallDetails,
  mergeVideoBlob,
} from "./storeIndexedData";
import OfflineImageModel from "./offlineImageModel";
import OfflineSideBarModel from "./offlineSideBarModel";
import "./offline.scss";
import OfflineGalleryZoomModel from "./offlineGalleryZoomModel";
import DeleteModel from "../VideoCall/Models/DeleteModal";
import OfflineBookMarkModel from "./offlineBookMarkModel";
import Tooltip from "@mui/material/Tooltip";
import OfflineEndPage from "./offlineEndPage";
import Webcam from "react-webcam";
import { markupforCaptureImg } from "../../helpers/markups";
import _ from "lodash";
import { blobToBinary, convertToJPEG, formatTime } from "../../helpers";
import BeforeStartofflinepopup from "./beforeStartofflinepopup";
import BeforeEndCallModel from "./BeforeEndCallModel";
import { UNEXPECTED_ERROR } from "../../constants/commonStrings";
import TokenExpiredPopup from "./tokenExpiredPopup";
const FACING_MODE_USER = "user",
  FACING_MODE_ENVIRONMENT = "environment",
  videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT,
  };

let isCallEnd = false,
  recorderLocal = null;
const ScreenRecorder = (props) => {
  const [recordedVideoUrl, setRecordedVideoUrl] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showEndScreen, setShowEndScreen] = useState(false);
  const [showTokenScreen, setShowTokenScreen] = useState(false);
  const [showbeforeEndCallModel, setShowbeforeEndCallModel] = useState(false);
  const [showBookMarkModel, setShowBookMarkModel] = useState(false);
  const [capturedImages, setCapturedImages] = useState([]);
  const [start, setStart] = useState(false);
  const [isFlashOn, setIsFlashOn] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [bookMark, setBookMark] = useState([]);
  const [isGalleryZoomModelVisible, setIsGalleryZoomModelVisible] =
    useState(false);
  const [deleteModelVisible, setDeleteModelVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [videoView, setVideoView] = useState(false);
  const [count, setCount] = useState(0);
  const [recordingPaused, setRecordingPaused] = useState(false);
  const [microphoneEnabled, setMicrophoneEnabled] = useState(true);
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
  const [flashTooltiptext, setFlashTooltiptext] = useState("");
  const [flashTextIsActive, setFlashTextIsActive] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isTakePhoto, setIsTakePhoto] = useState(false);
  const [isDeleteVideo, setIsDeleteVideo] = useState(false);
  const [isDeleteImage, setIsDeleteImage] = useState(false);
  const webcamRef = useRef(null);
  const [callDetails, setCallDetails] = useState(null);
  const [showBeforeStartPopup, setShowBeforeStartPopup] = useState(false);
  const [loader, setLoader] = useState(true);
  const [recordedVideoBlob, setRecordedVideoBlob] = useState(null);
  const [
    isCameraMicrophonePermissionAllowed,
    setIsCameraMicrophonePermissionAllowed,
  ] = useState(true);
  const [isUploadOneTime, setIsUploadOneTime] = useState(false);
  const [uploaded, setUploaded] = useState(null);
  const [attemptImageUpload, setAttemptImageUpload] = useState(null);
  const [reUpload, setReUpload] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [debounceClick, setDebounceClick] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [userNotWantGuide, setUserNotWantGuide] = useState(false);
  const [restart, setRestart] = useState(false);

  useEffect(() => {
    if (showBeforeStartPopup || restart) {
      setActiveIndex(-1);
    }
  }, [showBeforeStartPopup, restart]);
  useEffect(() => {
    if (props.openSidebar) {
      handleNoBeforeStartPopup();
    }
  }, [props.openSidebar]);
  useEffect(() => {
    if (flashTextIsActive) {
      setTimeout(() => {
        setFlashTextIsActive(false);
      }, 2000);
    }
  }, [flashTextIsActive]);
  useEffect(() => {
    dataExistInTable("bookmarks").then((dbExists) => {
      if (dbExists) {
        retrieveBookmarks("bookmarks")
          .then((data) => {
            // Handle the retrieved data here
            if (data?.[0]?.label) {
              setBookMark([...data[0].label]);
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during retrieval
          });
      }
    });
    dataExistInTable("CallDetails").then((dbExists) => {
      if (dbExists) {
        retrieveCallDetails().then((callData) => {
          let data = {
            lat: callData?.[0]?.latitude,
            long: callData?.[0]?.longitude,
          };
          setCallDetails(data);
        });
      }
    });
    // Immediately Invoked Async Function Expression (IIFE)
    (async () => {
      try {
        // Attempt to get the user's camera and microphone stream
        const stream = await navigator.mediaDevices.getUserMedia({
          video: videoConstraints,
          audio: microphoneEnabled,
        });

        // Assign the stream to the Webcam component using its ref (webcamRef)
        webcamRef.current.srcObject = stream;

        // Close the stream after checking permission
        stream.getTracks().forEach((track) => track.stop());

        // Set the state to indicate that camera and microphone permission is allowed
        setIsCameraMicrophonePermissionAllowed(true);
      } catch (error) {
        // If access to camera and microphone is denied or an error occurs
        setIsCameraMicrophonePermissionAllowed(false);

        // Handle the error here (e.g., display an error message to the user)
      }
    })();

    dataExistInTable("videos").then((dbExists) => {
      if (dbExists) {
        setActiveIndex(-1);
        setShowBeforeStartPopup(true);
      } else {
        setActiveIndex(0);
      }
    });
    return () => {
      // Cleanup: Stop video and audio streams when the component unmounts
      if (webcamRef.current.stream && webcamRef.current.stream.getTracks) {
        const tracks = webcamRef.current.stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    let intervalId = null;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning]);

  useEffect(() => {
    // Add an event listener to the document for the visibilitychange event
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  function showGalleryZoomModel(index) {
    // Show the gallery zoom model
    setIsGalleryZoomModelVisible(true);
    // Set the current image index
    setCurrentImageIndex(index);
  }

  function deleteImagePopUp(index, image) {
    // Show the delete model
    setDeleteModelVisible(true);

    // Set the current image index
    setCurrentImageIndex(index);
    setIsDeleteImage(true);
  }

  function deleteVideo() {
    setIsDeleteVideo(true);
    setDeleteModelVisible(true);
  }

  const handleZoomChange = (event) => {
    // Get the new zoom level from the event target value
    const newZoomLevel = Number(event.target.value);
    // Set the zoom level
    setZoomLevel(newZoomLevel);
  };

  function hideDeleteImageModel() {
    // Hide the delete image model
    setDeleteModelVisible(false);
  }

  function onDeleteCapturedImage() {
    if (isDeleteVideo) {
      setRecordedVideoUrl(null);
      deleteTableFromDB(["videos"]);
    }
    // Delete the image data from storage
    deleteImage(currentImageIndex)
      .then(() => {
        // Image data deleted successfully
      })
      .catch((error) => {
        // Handle any errors that occurred during deletion
      });

    if (capturedImages.length > 0 && isDeleteImage) {
      // Remove the current image from capturedImages
      const updatedItems = capturedImages.filter(
        (item) => item.id !== currentImageIndex
      );
      // Update the capturedImages array
      setCapturedImages(updatedItems);
      // Hide the delete image model
    }

    setDeleteModelVisible(false);
    setIsDeleteVideo(false);
    setIsDeleteImage(false);
  }

  const handleStartRecording = async () => {
    try {
      if (webcamRef.current && webcamRef.current.stream) {
        const tracks = webcamRef.current.stream.getAudioTracks();
        tracks.forEach((track) => {
          track.enabled = microphoneEnabled;
        });
      }
      // Delete existing IndexedDB databases for recorded videos and images
      deleteTableFromDB(["videos", "images"]);

      // Set the recorded video URL to null
      setRecordedVideoUrl(null);

      // Clear the captured images array
      setCapturedImages([]);

      // Hide the sidebar
      setShowSidebar(false);

      // Set the isRunning state to false
      setIsRunning(false);

      // Set the recording resume state
      setRecordingPaused(false);

      // Reset the timer
      setTimer(0);

      isCallEnd = false;

      // Get the video stream from the webcam reference
      const stream = webcamRef.current.stream;

      if (stream) {
        // Create a new recorder instance with the video stream
        const recorder = RecordRTC(stream, {
          type: "video",
          mimeType: "video/mp4",
          timeSlice: 10000,

          // Event handler for when data becomes available from the recorder
          ondataavailable: async function (blob) {
            if (blob?.size > 0) {
              try {
                // Store the video data using the URL
                const binaryData = await blobToBinary(blob);
                const videoData = {
                  videoBinary: binaryData,
                  uploaded: false,
                  attemptImageUpload: false,
                };
                // Use the binary data as needed
                if (binaryData?.byteLength > 0) {
                  try {
                    await storeVideo(videoData)
                      .then(async () => {
                        if (isCallEnd) {
                          setLoader(true);
                          const videoBlob = await mergeVideoBlob();
                          setRecordedVideoBlob(videoBlob);
                          // Set the recorded video URL
                          const url = URL.createObjectURL(videoBlob);
                          setRecordedVideoUrl(url);
                          setLoader(false);
                        }
                      })
                      .catch(async () => {
                        if (isCallEnd) {
                          setLoader(true);
                          const videoBlob = await mergeVideoBlob();
                          setRecordedVideoBlob(videoBlob);
                          // Set the recorded video URL
                          const url = URL.createObjectURL(videoBlob);
                          setRecordedVideoUrl(url);
                          setLoader(false);
                        }
                      });
                  } catch (err) {
                    isCallEnd = true;
                    setShowSidebar(true);
                    handleStopRecording();
                    setShowbeforeEndCallModel(false);

                    throw err;
                  }
                } else {
                  handlePauseRecording();
                }
              } catch (error) {
                handleStopRecording();
                setRecordedVideoUrl(null);
                setLoader(false);
                // Handle the error appropriately (e.g., show an error message)
              }
            } else {
              handlePauseRecording();
            }
          },
        });

        // Set the recorder instance
        recorderLocal = recorder;

        // Set the isRunning state to true
        setIsRunning(true);

        // Start recording the video
        recorderLocal.startRecording();
      }
    } catch (error) {
      // Handle the error appropriately (e.g., show an error message)
    }
  };

  const handleToggleMicrophone = async () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      //this manage mute unmute functionality for IOS  devices
      if (microphoneEnabled) {
        if (webcamRef.current.stream && webcamRef.current.stream.getTracks) {
          const tracks = webcamRef.current.stream.getAudioTracks();
          tracks.forEach((track) => track.stop());
        }
      } else {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        webcamRef.current.stream.addTrack(stream.getAudioTracks()[0]);
      }
      setMicrophoneEnabled((prevEnabled) => !prevEnabled);
    } else {
      //this manage mute unmute functionality for android devices
      // Toggle the microphone enabled state
      setMicrophoneEnabled((prevEnabled) => !prevEnabled);
    }
  };

  const handlePauseRecording = () => {
    if (recorderLocal) {
      // Pause the recording
      recorderLocal.pauseRecording();
      // Set the recording paused state
      setRecordingPaused(true);
      // Set the isRunning state to false
      setIsRunning(false);
    }
  };

  const handleResumeRecording = () => {
    if (recorderLocal) {
      // Resume the recording
      recorderLocal.resumeRecording();
      // Set the isRunning state to false
      setIsRunning(true);
      // Set the recording resume state
      setRecordingPaused(false);
    }
  };

  const handleStopRecording = () => {
    if (isFlashOn) {
      setIsFlashOn(false);
      handleFlash();
    }
    isCallEnd = true;
    setShowSidebar(true);

    if (recorderLocal) {
      recorderLocal.stopRecording((blob) => {
        recorderLocal = null;
      });
    }

    // Set the isRunning state to false
    setIsRunning(false);

    // Reset the timer
    setTimer(0);

    // Hide the beforeEndCallModel
    setShowbeforeEndCallModel(false);
  };

  const handleSwitchCamera = () => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  };
  async function handleFlash() {
    if (facingMode == FACING_MODE_USER) {
      setFlashTooltiptext("Front camera does not support the flashlight.");
      setFlashTextIsActive(true);
      return;
    }
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setFlashTooltiptext("Your flashlight is not supported on this device");
      setFlashTextIsActive(true);
      return;
    }
    setIsFlashOn((pre) => !pre);
    if (webcamRef.current.stream && webcamRef.current.stream.getTracks) {
      const tracks = webcamRef.current.stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(tracks);

      if (imageCapture) {
        // Get camera capabilities
        imageCapture
          .getPhotoCapabilities()
          .then((capabilities) => {
            if (
              capabilities &&
              capabilities.fillLightMode &&
              capabilities.fillLightMode.length > 0 &&
              capabilities.fillLightMode.includes("flash")
            ) {
              // Apply constraints to enable/disable flash
              tracks
                .applyConstraints({
                  advanced: [{ torch: isFlashOn ? false : true }],
                })
                .then(() => {
                  // Flash on successfully
                });
            }
          })
          .catch((err) => {});
      }
    }
  }
  const captureImage = async (extraPayload = {}) => {
    // Set the isTakePhoto state to true
    setIsTakePhoto(true);

    // Get the video element from the webcamRef
    const videoElement = webcamRef.current.video;

    try {
      if (videoElement) {
        // Get the height and width of the video element
        const height = videoElement.videoHeight;
        const width = videoElement.videoWidth;

        // Create a canvas element
        const canvas = document.createElement("canvas");
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;

        let nooflines = 1;
        if (canvas.width <= 620) {
          nooflines = 2;
        }

        // Calculate extra height for the canvas
        const extraheight = _.isEmpty(extraPayload)
          ? 28 * nooflines
          : 28 * nooflines + 18;

        // Set the canvas height to accommodate the video and extra height
        canvas.height = height + extraheight;

        // Get latitude and longitude from callDetails object
        const latitude = callDetails?.lat ? parseFloat(callDetails.lat) : "";
        const longitude = callDetails?.long ? parseFloat(callDetails.long) : "";

        // Get bookmark label from extraPayload object
        const bookMark = extraPayload?.bookmarkLabel || null;
        // Get the 2D rendering context of the canvas
        let context = canvas.getContext("2d");

        // Draw the current frame of the video on the canvas
        context.drawImage(videoElement, 0, 0, width, height);

        // Calculate the canvas dimensions
        const canvasheight = height + extraheight;
        const canvaswidth = width;

        // Call markupforCaptureImg function with relevant parameters
        const markup = markupforCaptureImg(
          canvasheight,
          canvaswidth,
          latitude,
          longitude,
          nooflines,
          bookMark,
          null
        );

        // Create a new Image object
        const image = new Image();

        // Event handler when the image is loaded
        image.onload = (blob) => {
          // Draw the loaded image on the canvas
          context.drawImage(image, 0, 0);

          // Convert the canvas content to JPEG format
          canvas.toBlob(async (blob) => {
            // Create a URL for the captured image blob
            blobToBinary(blob)
              .then(async (binaryData) => {
                // Convert the captured image blob to JPEG format
                const jpegBlob = await convertToJPEG(blob);

                // Create a FileReader object
                const reader = new FileReader();

                // Event handler when the FileReader finishes reading the JPEG blob
                reader.onloadend = async () => {
                  // Create a data object with image details
                  const data = {
                    image: binaryData,
                    imageUrl: reader.result,
                    id: count,
                    label: extraPayload?.bookmarkLabel
                      ? extraPayload?.bookmarkLabel
                      : "",
                    labelID: extraPayload?.bookmarkId
                      ? extraPayload?.bookmarkId
                      : "",
                    uploaded: false,
                    attemptImageUpload: false,
                    mapView: false,
                    isOffline: true,
                  };

                  // Store the image data
                  await storeImage(data)
                    .then(() => {
                      // Add the captured image to the capturedImages array
                      setCapturedImages((prevImages) => [
                        ...prevImages,
                        {
                          image: binaryData,
                          imageUrl: reader.result,
                          id: count,
                          label: extraPayload?.label
                            ? extraPayload?.label
                            : "No Label",
                          isOffline: true,
                        },
                      ]);

                      // Increment the count
                      setCount((prevCount) => prevCount + 1);
                      if (!isToast) {
                        // Display a success message
                        customToast.success("Photo was captured successfully.");
                        setIsToast(true);
                        setTimeout(() => setIsToast(false), 3000);
                      }
                    })
                    .catch(() => {});
                };

                // Read the JPEG blob as a data URL
                reader.readAsDataURL(jpegBlob);
              })
              .catch((error) => {});
          }, "image/jpeg");
        };

        // Event handler if there is an error loading the image
        image.onerror = (err) => {
          customToast.error("Unable to take a photo.");
        };

        // Set the source of the image to the markup value
        image.src = markup;
      }
    } catch (error) {
      customToast.error("Unable to take a photo.");
    } finally {
      // Set the isTakePhoto state to false
      setIsTakePhoto(false);
    }
  };

  const handleDataBeforeStartRecording = async () => {
    setStart(true);
    if (userNotWantGuide) {
      setActiveIndex(-1);
      handleStartRecording();
    } else {
      setActiveIndex(0);
    }
  };

  function handleYesBeforeStartPopup() {
    setUserNotWantGuide(true);
    setRecordedVideoUrl(null);
    setRecordedVideoBlob(null);
    // Reset the zoom level
    setZoomLevel(1);
    setShowBeforeStartPopup(false);
    setRestart(true);
    setStart(false);
    isCallEnd = false;
  }

  const handleNoBeforeStartPopup = async () => {
    try {
      setLoader(true);
      setShowBeforeStartPopup(false);
      setShowSidebar(true);
      setStart(false);
      isCallEnd = true;
      const dbExists = await dataExistInTable("videos");
      if (dbExists) {
        const videoBlob = await mergeVideoBlob();
        try {
          setRecordedVideoBlob(videoBlob);
          const url = URL.createObjectURL(videoBlob);
          setRecordedVideoUrl(url);
          setLoader(false);
        } catch (error) {
          setRecordedVideoUrl(null);
          setLoader(false);
          customToast.error(UNEXPECTED_ERROR);
        }
      }
    } catch (error) {
      setRecordedVideoUrl(null);
      setLoader(false);
      customToast.error(UNEXPECTED_ERROR);
    }
  };
  const handleDebounceClick = () => {
    !isTakePhoto && captureImage();
  };

  const handleCaptureClick = () => {
    if (debounceClick) {
      clearTimeout(debounceClick);
    }
    setDebounceClick(
      setTimeout(() => {
        handleDebounceClick();
      }, 500)
    );
  };

  const handleClickNext = () => {
    if (userNotWantGuide) {
      return;
    }
    if (!start && activeIndex === 4) {
      setActiveIndex(-1);
    } else if (start && activeIndex === 5) {
      setActiveIndex(-1);
      handleStartRecording();
    } else {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleClickSkip = (event) => {
    event.stopPropagation();
    setActiveIndex(-1);
    setUserNotWantGuide(true);
    if (start) {
      handleStartRecording();
    }
  };

  const handleVisibilityChange = (event) => {
    if (document.hidden) {
      handlePauseRecording();
    }
  };

  return (
    <>
      <section className="videocall-wapper offline-videocall-wapper">
        {start && !showEndScreen && !showTokenScreen && (
          <>
            <div className="page-timer">{formatTime(timer)}</div>
            {!recordingPaused && activeIndex === -1 && (
              <div className="screen-recording">
                <span className="point"></span>REC
              </div>
            )}
          </>
        )}
        {!showEndScreen && !showTokenScreen && (
          <div className="participants-main">
            <div className="participants-view full-screen">
              <div className="video-img live-captured-stream">
                <div className="live-stream ">
                  {isCameraMicrophonePermissionAllowed ? (
                    <Webcam
                      ref={webcamRef}
                      className={`scale${zoomLevel}`}
                      audio={true}
                      muted={true}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{
                        ...videoConstraints,
                        facingMode,
                      }}
                      height={720}
                      width={1280}
                      screenshotQuality={1}
                      imageSmoothing={true}
                      onUserMedia={(res) => {
                        if (
                          res &&
                          webcamRef.current &&
                          webcamRef.current.stream
                        ) {
                          const tracks =
                            webcamRef.current.stream.getAudioTracks();
                          tracks.forEach((track) => {
                            track.enabled = microphoneEnabled;
                          });
                        }
                      }}
                    />
                  ) : (
                    <div className="video-audio-permission">
                      <div className="inner-video-permission">
                        <div className="permission-icons">
                          <i className="icon-stop-video">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <i className="icon-mute-voice">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </div>
                        <h1>Permission Required</h1>

                        <p>
                          To record video, please turn on camera and microphone
                          permission in your browser settings then{" "}
                          <a
                            href="javascript:void(0)"
                            className="link-btn"
                            onClick={() => {
                              window.location.reload(false);
                            }}
                          >
                            click here
                          </a>{" "}
                          to reload the page.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {showbeforeEndCallModel && (
          <BeforeEndCallModel
            OnNo={() => {
              setShowbeforeEndCallModel(false);
            }}
            OnYes={() => {
              isCallEnd = true;
              setShowSidebar(true);
              handleStopRecording();
              setShowbeforeEndCallModel(false);
              setZoomLevel(1);
            }}
          />
        )}
        {showEndScreen && (
          <OfflineEndPage
            ShowEndScreen={() => setShowEndScreen(true)}
            reUpload={() => {
              setReUpload(true);
            }}
          />
        )}
        {showTokenScreen && <TokenExpiredPopup />}
        {showBeforeStartPopup && !isCallEnd && (
          <BeforeStartofflinepopup
            onYes={() => handleYesBeforeStartPopup()}
            onNo={() => handleNoBeforeStartPopup()}
          />
        )}
        {isGalleryZoomModelVisible && (
          <OfflineGalleryZoomModel
            videoView={videoView}
            captureImages={capturedImages}
            captureVideo={recordedVideoUrl}
            currentImageIndex={currentImageIndex}
            hideGalleryZoomModel={() => {
              setVideoView(false);
              setIsGalleryZoomModelVisible(false);
            }}
          />
        )}
        {start && !showEndScreen && !showTokenScreen && (
          <OfflineImageModel
            capturedImages={capturedImages}
            showGalleryZoomModel={(currentImageIndex, images) =>
              showGalleryZoomModel(currentImageIndex, images)
            }
            deleteImagePopUp={(currentImageIndex, images) =>
              deleteImagePopUp(currentImageIndex, images)
            }
            bookMarkModelIsOpen={showBookMarkModel}
            hideShowBookMarkModel={() => setShowBookMarkModel(false)}
            activeIndex={activeIndex}
          />
        )}
        {start && !showEndScreen && !showTokenScreen && (
          <OfflineBookMarkModel
            captureBookmark={(label, id) => {
              captureImage({ bookmarkLabel: label, bookmarkId: id, isOffline: true });
            }}
            hideShowBookMarkModel={() => setShowBookMarkModel(false)}
            showSidebar={showBookMarkModel}
          />
        )}

        {isCallEnd && !showTokenScreen && (!showEndScreen || reUpload) && (
          <OfflineSideBarModel
            videoView={() => setVideoView(true)}
            capturedImages={capturedImages}
            recordedVideoUrl={recordedVideoUrl}
            loader={loader}
            showGalleryZoomModel={(currentImageIndex, images) =>
              showGalleryZoomModel(currentImageIndex, images)
            }
            showSidebar={showSidebar}
            showSidebarfun={() => {
              setShowSidebar(false);
            }}
            deleteImagePopUp={(currentImageIndex, images) =>
              deleteImagePopUp(currentImageIndex, images)
            }
            deleteVideo={() => deleteVideo()}
            ShowEndScreen={() => setShowEndScreen(true)}
            ShowTokenScreen={() => setShowTokenScreen(true)}
            restartInspection={handleYesBeforeStartPopup}
            recordedVideoBlob={recordedVideoBlob}
            uploaded={uploaded}
            attemptImageUpload={attemptImageUpload}
            isUploadOneTime={isUploadOneTime}
            reUpload={() => {
              setReUpload(false);
            }}
            setCapturedImages={setCapturedImages}
          />
        )}
        {deleteModelVisible && (
          <DeleteModel
            title={"Are you Sure you Want to Delete Photo ?"}
            Cancel={() => hideDeleteImageModel()}
            Confirm={() => onDeleteCapturedImage()}
          />
        )}
        {!start && !showEndScreen && !showTokenScreen && (
          <div
            className={`top-right-menu ${
              (activeIndex === 0 || activeIndex === 1) &&
              isCameraMicrophonePermissionAllowed
                ? "guideline-active"
                : ""
            } `}
          >
            <ul>
              <li
                className={`filp-camera active ${
                  !isCameraMicrophonePermissionAllowed ? "disable" : ""
                }`}
              >
                <div className="tap-instruction-main" onClick={handleClickNext}>
                  <div className="inner-tap-instruction">
                    <i className="icon-Tap"></i>
                    <p>Tap on the screen to proceed forward.</p>
                    <button
                      className="skip-btn blue-btn"
                      onClick={handleClickSkip}
                    >
                      Skip
                    </button>
                  </div>
                </div>
                <button
                  className={`${activeIndex === 1 ? "flip-camera" : ""}`}
                  disabled={
                    !isCameraMicrophonePermissionAllowed || activeIndex !== -1
                  }
                  onClick={handleSwitchCamera}
                >
                  <i
                    className={`icon-flip-camera ${
                      activeIndex === 1 ? "blink" : ""
                    }`}
                  ></i>
                  <div className="guideline-text">Switch Cameras</div>
                </button>
              </li>
            </ul>
          </div>
        )}
        {start && isCameraMicrophonePermissionAllowed && (
          <div
            className={`zoom_outer  ${
              activeIndex === 5 && isCameraMicrophonePermissionAllowed
                ? "guideline-active"
                : ""
            } `}
          >
            <div className="tap-instruction-main" onClick={handleClickNext}>
              <div className="inner-tap-instruction">
                <i className="icon-Tap"></i>
                <p>Tap on the screen to proceed forward.</p>
                <button className="skip-btn blue-btn" onClick={handleClickSkip}>
                  Skip
                </button>
              </div>
            </div>
            <span className="zoom-level">Zoom Level</span>
            <div className="zoom_main ">
              <div className="guideline-text">
                To adjust the camera zoom level
              </div>
              <div className="level blink">
                <label>
                  <input
                    type="radio"
                    name="zoomlevel"
                    onChange={handleZoomChange}
                    value="3"
                    checked={zoomLevel === 3}
                  />
                  <span>3X</span>
                </label>
              </div>

              <div className="level blink">
                <label>
                  <input
                    onChange={handleZoomChange}
                    value="2"
                    type="radio"
                    name="zoomlevel"
                    className="checked"
                    checked={zoomLevel === 2}
                  />
                  <span>2X</span>
                </label>
              </div>

              <div className="level blink">
                <label>
                  <input
                    onChange={handleZoomChange}
                    value="1"
                    type="radio"
                    name="zoomlevel"
                    className="checked"
                    checked={zoomLevel === 1}
                  />
                  <span>1X</span>
                </label>
              </div>
            </div>
          </div>
        )}
        {!showEndScreen && !showTokenScreen && (
          <div className="bottom-menu">
            <div className="inner-bottom">
              <ul className="call-menu">
                {!start && (
                  <li
                    className={`mice ${
                      activeIndex === 2 && isCameraMicrophonePermissionAllowed
                        ? "guideline-active"
                        : ""
                    } ${
                      activeIndex === 3 && isCameraMicrophonePermissionAllowed
                        ? "guideline-active"
                        : ""
                    } ${!isCameraMicrophonePermissionAllowed ? "disable" : ""}`}
                  >
                    <div
                      className="tap-instruction-main"
                      onClick={handleClickNext}
                    >
                      <div className="inner-tap-instruction">
                        <i className="icon-Tap"></i>
                        <p>Tap on the screen to proceed forward.</p>
                        <button
                          className="skip-btn blue-btn"
                          onClick={handleClickSkip}
                        >
                          Skip
                        </button>
                      </div>
                    </div>

                    <button
                      aria-label="Unmute My Audio"
                      className="second-button"
                      onClick={handleToggleMicrophone}
                      disabled={activeIndex !== -1}
                    >
                      {microphoneEnabled &&
                      isCameraMicrophonePermissionAllowed ? (
                        <i className="icon-microphone blink"></i>
                      ) : (
                        <i className="icon-mute-voice blink ">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      )}
                      {activeIndex === 2 &&
                        isCameraMicrophonePermissionAllowed && (
                          <div className="guideline-text">
                            Mute/Unmute the microphone during the inspection.
                          </div>
                        )}
                      {activeIndex === 3 &&
                        isCameraMicrophonePermissionAllowed && (
                          <div className="guideline-text big-text">
                            During an offline inspection, it is essential to
                            keep the microphone On and verbally provide area
                            guidance. This will enable the inspector to clearly
                            understand and identify the named areas during the
                            inspection process.
                          </div>
                        )}
                    </button>
                  </li>
                )}
                {start && (
                  <>
                    <li
                      className={`video ${
                        activeIndex === 0 && isCameraMicrophonePermissionAllowed
                          ? "guideline-active"
                          : ""
                      }`}
                    >
                      <div
                        className="tap-instruction-main"
                        onClick={handleClickNext}
                      >
                        <div className="inner-tap-instruction">
                          <i className="icon-Tap"></i>
                          <p>Tap on the screen to proceed forward.</p>
                          <button
                            className="skip-btn blue-btn"
                            onClick={handleClickSkip}
                          >
                            Skip
                          </button>
                        </div>
                      </div>

                      <button
                        aria-label="Start My Video"
                        className="third-button"
                        onClick={() => {
                          recordingPaused
                            ? handleResumeRecording()
                            : handlePauseRecording();
                        }}
                        disabled={activeIndex !== -1}
                      >
                        <i
                          className={`${
                            recordingPaused
                              ? "icon-offlinePlay blink"
                              : "icon-offlinepause blink"
                          }`}
                        ></i>
                        <div class="guideline-text">
                          To play/pause the video inspection{" "}
                        </div>
                      </button>
                    </li>
                    <li
                      className={`video ${
                        activeIndex === 1 && isCameraMicrophonePermissionAllowed
                          ? "guideline-active"
                          : ""
                      }`}
                    >
                      <div
                        className="tap-instruction-main"
                        onClick={handleClickNext}
                      >
                        <div className="inner-tap-instruction">
                          <i className="icon-Tap"></i>
                          <p>Tap on the screen to proceed forward.</p>
                          <button
                            className="skip-btn blue-btn"
                            onClick={handleClickSkip}
                          >
                            Skip
                          </button>
                        </div>
                      </div>

                      <button
                        aria-label="Start My Video"
                        className={`flash-button vi-tooltip ${
                          flashTextIsActive ? " " : "hide"
                        }  `}
                        onClick={() => {
                          handleFlash();
                        }}
                        disabled={activeIndex !== -1}
                      >
                        <i
                          className={`${
                            isFlashOn
                              ? "icon-flash-on blink"
                              : "icon-flash-off blink"
                          }`}
                        ></i>
                        <div class="guideline-text">
                          To turn on/off the flash
                        </div>
                        <span class="tooltiptext">{flashTooltiptext}</span>
                      </button>
                    </li>
                    <li
                      className={`capture-image ${
                        activeIndex === 2 && isCameraMicrophonePermissionAllowed
                          ? "guideline-active"
                          : ""
                      }`}
                    >
                      <div
                        className="tap-instruction-main"
                        onClick={handleClickNext}
                      >
                        <div className="inner-tap-instruction">
                          <i className="icon-Tap"></i>
                          <p>Tap on the screen to proceed forward.</p>
                          <button
                            className="skip-btn blue-btn"
                            onClick={handleClickSkip}
                          >
                            Skip
                          </button>
                        </div>
                      </div>

                      <button
                        aria-label="Capture Images with No Labels"
                        className={`${isTakePhoto && "disable"} fourth-button`}
                        onClick={handleCaptureClick}
                        disabled={activeIndex !== -1}
                      >
                        <div className="icon">
                          <i className="icon-capture-image blink"></i>
                        </div>
                        <div class="guideline-text">To add the images</div>
                      </button>
                    </li>
                    <li
                      className={`chat-main ${
                        activeIndex === 3 && isCameraMicrophonePermissionAllowed
                          ? "guideline-active"
                          : ""
                      }`}
                      aria-label="Labels"
                    >
                      <div
                        className="tap-instruction-main"
                        onClick={handleClickNext}
                      >
                        <div className="inner-tap-instruction">
                          <i className="icon-Tap"></i>
                          <p>Tap on the screen to proceed forward.</p>
                          <button
                            className="skip-btn blue-btn"
                            onClick={handleClickSkip}
                          >
                            Skip
                          </button>
                        </div>
                      </div>

                      <button
                        onClick={() => setShowBookMarkModel(true)}
                        className="fifth-button"
                        disabled={activeIndex !== -1}
                      >
                        <div className="icon ">
                          <div className="notified">{bookMark.length}</div>
                          <i className="icon-bookmark blink"></i>
                        </div>
                        <div class="guideline-text">
                          To add the label images
                        </div>
                      </button>
                    </li>
                    <li
                      className={`disconnected offline-connect ${
                        activeIndex === 4 && isCameraMicrophonePermissionAllowed
                          ? "guideline-active"
                          : ""
                      }`}
                    >
                      <div
                        className="tap-instruction-main"
                        onClick={handleClickNext}
                      >
                        <div className="inner-tap-instruction">
                          <i className="icon-Tap"></i>
                          <p>Tap on the screen to proceed forward.</p>
                          <button
                            className="skip-btn blue-btn"
                            onClick={handleClickSkip}
                          >
                            Skip
                          </button>
                        </div>
                      </div>
                      <button
                        aria-label="End Call"
                        className="sixth-button"
                        onClick={() => {
                          setShowbeforeEndCallModel(true);
                        }}
                        disabled={activeIndex !== -1}
                      >
                        <i className="icon-offlineStart-Offline-Inspection blink"></i>
                        <div class="guideline-text">
                          To end the offline video inspection
                        </div>
                      </button>
                    </li>
                  </>
                )}
                {!start && (
                  <li
                    className={`offline-connect ${
                      activeIndex === 4 && isCameraMicrophonePermissionAllowed
                        ? "guideline-active"
                        : ""
                    } ${!isCameraMicrophonePermissionAllowed ? "disable" : ""}`}
                  >
                    <div
                      className="tap-instruction-main"
                      onClick={handleClickNext}
                    >
                      <div className="inner-tap-instruction">
                        <i className="icon-Tap"></i>
                        <p>Tap on the screen to proceed forward.</p>
                        <button
                          className="skip-btn blue-btn"
                          onClick={handleClickSkip}
                        >
                          Skip
                        </button>
                      </div>
                    </div>
                    <Tooltip title="Start Offline Inspection" arrow>
                      <button
                        aria-label="End Call"
                        className="first-button"
                        onClick={() => {
                          handleDataBeforeStartRecording();
                        }}
                        disabled={
                          !isCameraMicrophonePermissionAllowed ||
                          activeIndex !== -1
                        }
                      >
                        <i className="icon-offlineStart-Offline-Inspection blink"></i>
                        <div class="guideline-text">
                          Start the offline video inspection.
                        </div>
                      </button>
                    </Tooltip>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default ScreenRecorder;
