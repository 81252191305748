/* eslint-disable import/no-anonymous-default-export */
import {
  APPRAISAL_START_APPRAISAL,
  APPRAISAL_MEASUREMENT,
  APPRAISAL_CORNER_MARKING,
  APPRAISAL_2D_FLOOR_PLAN,
} from "../actions/actionTypes";

export const defaultState = {
  currentStep: "",
};

export default function (
  state = defaultState,
  action = { type: null, payload: {} }
) {
  switch (action.type) {
    case APPRAISAL_START_APPRAISAL: {
      return {
        ...state,
        currentStep: APPRAISAL_START_APPRAISAL,
      };
    }
    case APPRAISAL_MEASUREMENT: {
      return {
        ...state,
        currentStep: APPRAISAL_MEASUREMENT,
      };
    }
    case APPRAISAL_CORNER_MARKING: {
      return {
        ...state,
        currentStep: APPRAISAL_CORNER_MARKING,
      };
    }
    case APPRAISAL_2D_FLOOR_PLAN: {
      return {
        ...state,
        currentStep: APPRAISAL_2D_FLOOR_PLAN,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
