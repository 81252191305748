import React, { Component, createRef } from "react";
// import Streetview from "react-google-streetview";
import "./map_View_Model.scss";
import { appConstants } from "../../../constants/";
import { withTranslation } from "react-i18next";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import StreetView from "./streetView";
import _ from "lodash";

class MapViewModel extends Component {
  streetViewRef = createRef();
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      _.isEqual(
        nextProps.remoteParticipantLatLong,
        this.props.remoteParticipantLatLong
      )
    ) {
      return false;
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="map-view-modal">
          <div className="map-view-inner">
            <div className="modal-header">
              <div className="modal-title">
                <h3>{t("WEB_LABELS.Street_View")}</h3>
              </div>
              <div className="model_button">
                <button
                  type="button"
                  onClick={() => {
                    this.props.captureStreetViewImage();
                  }}
                >
                  <i className="icon-capture-image"></i>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    this.props.toggleStreetViewMap();
                  }}
                >
                  <i className="icon-close-image"></i>
                </button>
              </div>
            </div>
            {/* <div
              className="modal-body"
              id="street-view-modal"
              ref={this.streetViewRef}
            >
              <Streetview
                apiKey={appConstants.API_KEYS.GOOGLE_MAP_API_KEY}
                streetViewPanoramaOptions={this.props.streetViewPanoramaOptions}
                onPovChanged={(pov) => {
                  this.props.onPovChanged(pov);
                }}
                onZoomChanged={(zoom) => {
                  this.props.onZoomChanged(zoom);
                }}
                onPositionChanged={(position) => {
                  const { videoWidth, videoHeight } = document.getElementById(
                    "street-view-modal"
                  );
                  var streetViewWidth = document.getElementById(
                    "street-view-modal"
                  ).width;
                  var streetViewRect = document
                    .getElementById("street-view-modal")
                    .getBoundingClientRect();
                  this.props.onPositionChanged(position, streetViewRect);
                }}
                onPanoChanged={(pano) => {}}
              />
            </div> */}
            <Wrapper apiKey={appConstants.API_KEYS.GOOGLE_MAP_API_KEY}>
              <StreetView
                remoteParticipantLatLong={this.props.remoteParticipantLatLong}
                onPovChanged={this.props.onPovChanged}
                onZoomChanged={this.props.onZoomChanged}
                onPositionChanged={this.props.onPositionChanged}
              />
            </Wrapper>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(MapViewModel);
