import moment from "moment";
import { func } from "prop-types";

export function getUTCToLocalDateTimeWithFormat(
  dateTime,
  inputFormat,
  outputFormat
) {
  return moment.utc(dateTime, inputFormat).local().format(outputFormat);
}

export function getCurrentDay() {
  return moment().format("dddd");
}

export function getCurrentTime() {
  return moment().format("h:mm A");
}

//Convert datetime from local timezone to UTC
export function getLocalToUTCDateTimeWithFormat(
  dateTime,
  inputFormat,
  outputFormat
) {
  var timeZone = moment.tz.guess();

  var localDate = moment(dateTime, inputFormat);

  //var DateTime = currentDate+" "+Time;
  var UTCDateTime = moment.utc(localDate).format(outputFormat);

  return UTCDateTime;
}

//Change formate

export function changeDateFormat(dateTime, inputFormat, outputFormat) {
  var localDate = moment(dateTime, inputFormat);
  var outputDateTime = localDate.format(outputFormat);

  return outputDateTime;
}

//Add duration in given date/time
export function addDuration(
  dateTime,
  inputFormat,
  outputFormat,
  unit,
  duration
) {
  var localDate = moment(dateTime, inputFormat).add(duration, unit);

  var outputDateTime = localDate.format(outputFormat);

  return outputDateTime;
}

//Get difference in years for given date vs current date
export function dateIsLarger(dateTime1, dateTime2, inputFormat) {
  var localDate1 = moment(dateTime1, inputFormat);
  var localDate2 = moment(dateTime2, inputFormat);

  return localDate1.isAfter(localDate2);
}

//Get difference in given unit for given date vs current date
export function getDateDifferenceBetweenTwoDates(dateTime1,dateTime2,inputFormat,unit) {
	
	var localDate1 = moment(dateTime1,inputFormat)

	var localDate2 = moment(dateTime2,inputFormat)

	//var diffInYears = currentDate.diff(localDate, unit,true);    
	var timeDifference = localDate2.diff(localDate1, unit);    


	return timeDifference;
}

//Get difference in years for given date vs current date
export function getDateDifference(dateTime, inputFormat,unit) {
	
	
	
	var localDate = moment(dateTime,inputFormat)
	var currentDate = moment(new Date());
	
	

	//var diffInYears = currentDate.diff(localDate, unit,true);    
	var timeDifference = localDate.diff(currentDate, unit);    
	

	return timeDifference;
}