import api from "./api";

export default class AuthAPI {
  getDetail() {
    return api.get("/todos/1");
  }
  // fetchToken(payload) {
  //   return api.post(
  //     `/user-link/verify-access-token?actual=${encodeURI(
  //       payload.actual.replaceAll(" ", "%20")
  //     )}&encoded=${payload.encoded}`
  //   );
  // }

  fetchToken(payload) {
    return api.get(`/getlink?encToken=${encodeURIComponent(payload)}`);
  }
}
