import { isAndroid, isIOS, isSafari, isMobile } from "react-device-detect";
import ShowDownloadAppPage from "../VideoCall/ShowDownloadAppPage";
import {
  ANDROID_PLAY_STORE_LINK,
  IOS_APP_STORE_LINK,
} from "../VideoCall/VideoCall";

const MeasurementLinkAppDownloadPage = (props) => {
  const measurementLinkId = props.match.params.measurementLinkId;
  const encParam = new URLSearchParams(props.location.search).get("enc");
  return (
    <ShowDownloadAppPage
      isMeasurementLink={true}
      isMobile={isMobile}
      isSafari={isSafari}
      isAndroid={isAndroid}
      isIOS={isIOS}
      handleGoToAppClicked={() => {
        let appURL = window.location.href;
        let id = localStorage.getItem("shortLink");
        if (isIOS) {
          // window.location =
          //   "vsite://remoteval/measurement/" +
          //   measurementLinkId +
          //   "?enc=" +
          //   encParam;
          window.location = "vsite://remoteval/joincall/" + id;
        } else if (isAndroid) {
          // window.location =
          //   "remoteval://open.remoteval/measurement/" +
          //   measurementLinkId +
          //   "?enc=" +
          //   encParam;
          window.location = "remoteval://open.remoteval/joincall/" + id;
        } else {
          console.log("appURL - ", appURL);
          window.location.href = appURL;
        }

        setTimeout(() => {}, 1000);
      }}
      handleDownloadAppClicked={async () => {
        let appURL = isAndroid ? ANDROID_PLAY_STORE_LINK : IOS_APP_STORE_LINK;

        setTimeout(() => {
          window.location.href = appURL;
        }, 1000);
      }}
      bodyBackgroundColor="#1C375C"
      bodyTextColor="#000000"
      buttonBackgroundColor="#1C375C"
      buttonTextColor="#FFFFFF"
      headerBackgroundColor="#E6E6FA"
      headerTextColor="#C6C6CA"
      lenderId="2432ba86-fc90-45b4-96ae-968af307fec1"
      logoFile={null}
      logoPath="https://admin.remoteval.net/static/media/blue-logo.f7b20ab2.svg"
      name="VSITE"
      privacyPolicyFile={null}
      privacyPolicyPath={
        "https://www.freeprivacypolicy.com/blog/privacy-policy-url/"
      }
    />
  );
};

export default MeasurementLinkAppDownloadPage;
