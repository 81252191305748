import React, { useState, useEffect } from "react";
import "./POsForInspectionModel.scss";
import { extractUserName } from "./../../../helpers/commonJSFunction";
import { useTranslation } from "react-i18next";

const POsForInspectionModel = (props) => {
  const { t } = useTranslation();
  const [selectedPO, setSelectedPO] = useState("");

  const startInspection = () => {
    props.togglePOsModal();
    props.onShowStartAppraisal(selectedPO);
    props.selectPoFromMultiplePOs(selectedPO);
  };

  return (
    <div className="error-popup open">
      <div className="error-info text-left">
        <div className="content">
          <button onClick={props.togglePOsModal} className="close-modal">
            <i className="icon-close-image"></i>
          </button>
          <p>
            <b>{t("select_po")}</b>
          </p>
          <div className="multiple-po-list">
            <form>
              <div className="form-group">
                {props.remotePOsForInspection.length > 0 &&
                  props.remotePOsForInspection.map((po) => (
                    <div className="radio-btn">
                      <label>
                        <input
                          type="radio"
                          name="check"
                          onClick={() => setSelectedPO(po.sid)}
                        />
                        <span>{po.identity}</span>
                      </label>
                    </div>
                  ))}
              </div>
              <div className="button-group">
                <button className="green-btn" onClick={startInspection}>
                  {t("TOOLTIP.Start_Inspection")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default POsForInspectionModel;
