import offlineUploadDataService from "../services/offlineUploadDataService";

export const offlineUploadImageAction = (callUUID, data, extraPayload) => {
  return (dispatch) => {
    return offlineUploadDataService
      .uploadImage(callUUID, data, extraPayload)
      .then((res) => {
        dispatch({ type: "UPLOAD_IMAGES_SUCCESS" });
        return res;
      })
      .catch((error) => {
        dispatch({ type: "UPLOAD_IMAGES_FAILURE", payload: error.message });
        return error.response.data.code
      });
  };
};
export const offlineUploadVideoAction = (callUUID, data, callID) => {
  return (dispatch) => {
    return offlineUploadDataService
      .uploadVideo(callUUID, data, callID)
      .then((res) => {
        dispatch({ type: "UPLOAD_VIDEO_SUCCESS" });
        return res;
      })
      .catch((error) => {
        dispatch({ type: "UPLOAD_VIDEO_FAILURE", payload: error.message });
        return error.response.data.code
      });
  };
};
