import * as actionTypes from "../actions/actionTypes";

export const defaultState = {
  loading: false,
  data: [],
  error: null,
};

export default (state = defaultState, action = {}) => {

  
  

  switch (action.type) {

      case actionTypes.LIST_PARTICIPANT_START:
        return {
          ...state,
          loading: true,
          data: [],
          error: null,
        };
      case actionTypes.LIST_PARTICIPANT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: null,
        };
      case actionTypes.LIST_PARTICIPANT_ERROR:
        return {
          ...state,
          loading: false,
          data: [],
          error: action.payload,
        };

    default:
      return state;
  }
};
