import React, { Component } from "react";
import { connect } from "react-redux";
import Routes from "./routes";
import { withOrientationChange } from "react-device-detect";

import { history } from "../src/helpers";
import "./index.scss";

class App extends Component {
  constructor() {
    super();

    history.listen((location, action) => {
      // clear alert on location change
    });
  }

  componentDidMount() {}
  render() {
    const { isLandscape, isPortrait } = this.props;
    const childProps = { isLandscape, isPortrait };
    return (
      <div className="App">
        <Routes childProps={childProps} />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  details: auth.data,
});

const mapDispatchToProps = (dispatch) => ({});
const appWithOrientation = withOrientationChange(App);

export default connect(mapStateToProps, mapDispatchToProps)(appWithOrientation);
