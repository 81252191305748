import { appConstants } from "../../constants";
import { customToast } from "../../helpers/customToast";

const dbName = appConstants.DatabaseName;
const dbVersion = 1;

const openDatabase = (dbName, version) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, version);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore("bookmarks", {
        keyPath: "id",
        autoIncrement: true,
      });
      db.createObjectStore("videos", {
        keyPath: "id",
        autoIncrement: true,
      });
      db.createObjectStore("images", {
        keyPath: "id",
        autoIncrement: true,
      });
      db.createObjectStore("CallDetails", {
        keyPath: "id",
        autoIncrement: true,
      });
    };
    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

const addDataToIndexedDB = async (objectStoreName, data) => {
  try {
    const db = await openDatabase(dbName, dbVersion);
    const transaction = db.transaction(objectStoreName, "readwrite");
    const objectStore = transaction.objectStore(objectStoreName);

    // Perform the add operation and get the request
    const addRequest = objectStore.add(data);

    // Return a promise that resolves when the transaction is complete
    return new Promise((resolve, reject) => {
      transaction.oncomplete = () => {
        // Transaction successfully completed, resolve with the key of the added object
        resolve(addRequest.result);
      };

      transaction.onerror = () => {
        // An error occurred during the transaction, reject with the error
        reject(transaction.error);
      };
    });
  } catch (error) {
    customToast.error("An error occurred while adding data to IndexedDB.");
    throw error;
  }
};

export const storeImage = async (imageData) => {
  const objectStoreName = "images";
  try {
    return await addDataToIndexedDB(objectStoreName, imageData);
  } catch (err) {
    throw err;
  }
};

export const storeBookmarks = async (data) => {
  const objectStoreName = "bookmarks";
  const bookMarkData = { label: data };

  try {
    const dbExists = await dataExistInTable("bookmarks");
    if (dbExists) {
      await deleteTableFromDB(["bookmarks"]);
    }
    return await addDataToIndexedDB(objectStoreName, bookMarkData);
  } catch (err) {
    throw err;
  }
};

export const storeVideo = async (videoData) => {
  const objectStoreName = "videos";
  try {
    return await addDataToIndexedDB(objectStoreName, videoData);
  } catch (err) {
    throw err;
  }
};

export const storeCallDetails = async (keyData) => {
  const objectStoreName = "CallDetails";
  try {
    try {
      const callDetailsExist = await dataExistInTable(objectStoreName);
      if (callDetailsExist) {
        await deleteTableFromDB([objectStoreName]);
      }
      return await addDataToIndexedDB(objectStoreName, keyData);
    } catch (err) {
      throw err;
    }
  } catch (err) {
    throw err;
  }
};

export const retrieveBookmarks = async (objectStoreName) => {
  try {
    const db = await openDatabase(dbName, dbVersion);
    if (db.objectStoreNames.contains(objectStoreName)) {
      const transaction = db.transaction(objectStoreName, "readwrite");
      const objectStore = transaction.objectStore(objectStoreName);
      const getDataRequest = objectStore.getAll();

      const data = await new Promise((resolve, reject) => {
        getDataRequest.onsuccess = (event) => resolve(event.target.result);
        getDataRequest.onerror = (event) => reject(event.target.error);
      });

      return data || "";
    } else {
      return "";
    }
  } catch (error) {
    throw error;
  }
};

export const retrieveImages = async () => {
  const objectStoreName = "images";

  try {
    const db = await openDatabase(dbName, dbVersion);
    if (db.objectStoreNames.contains(objectStoreName)) {
      const transaction = db.transaction(objectStoreName, "readwrite");
      const objectStore = transaction.objectStore(objectStoreName);
      const request = objectStore.getAll();

      const imageData = await new Promise((resolve, reject) => {
        request.onsuccess = (event) => resolve(event.target.result);
        request.onerror = (event) => reject(event.target.error);
      });

      return imageData || [];
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};

export const retrieveVideo = async () => {
  const objectStoreName = "videos";

  try {
    const db = await new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, dbVersion);

      request.onsuccess = (event) => resolve(event.target.result);
      request.onerror = () => reject(new Error("Failed to open IndexedDB."));
    });

    if (db.objectStoreNames.contains(objectStoreName)) {
      const transaction = db.transaction(objectStoreName, "readonly");
      const objectStore = transaction.objectStore(objectStoreName);

      return new Promise((resolve, reject) => {
        const cursorRequest = objectStore.openCursor();
        const videoData = [];

        cursorRequest.onsuccess = (event) => {
          const cursor = event.target.result;

          if (cursor) {
            videoData.push(cursor.value);
            cursor.continue();
          } else {
            if (!videoData.length) {
              reject(new Error("No video data found in IndexedDB."));
            } else {
              resolve(videoData);
            }
          }
        };

        cursorRequest.onerror = () => {
          reject(
            new Error("Error while retrieving video data from IndexedDB.")
          );
        };
      });
    } else {
      throw new Error("Object store 'videos' does not exist.");
    }
  } catch (error) {
    customToast.error("Data is invalid.");
    throw error;
  }
};

export const retrieveCallDetails = async () => {
  const objectStoreName = "CallDetails";

  try {
    const db = await openDatabase(dbName, dbVersion);
    if (db.objectStoreNames.contains(objectStoreName)) {
      const transaction = db.transaction(objectStoreName, "readwrite");
      const objectStore = transaction.objectStore(objectStoreName);
      const request = objectStore.getAll();

      const keyData = await new Promise((resolve, reject) => {
        request.onsuccess = (event) => resolve(event.target.result);
        request.onerror = (event) => reject(event.target.error);
      });

      return keyData || [];
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};

export const deleteImage = async (key) => {
  const objectStoreName = "images";

  try {
    const db = await openDatabase(dbName, dbVersion);
    const transaction = db.transaction(objectStoreName, "readwrite");
    const objectStore = transaction.objectStore(objectStoreName);
    await objectStore.delete(key);
    customToast.success("Photo Deleted successfully");
  } catch (error) {
    customToast.error("Failed to Delete Photo");
    throw error;
  }
};

export const deleteTableFromDB = async (tableNames) => {
  try {
    const db = await openDatabase(dbName, dbVersion);
    tableNames.forEach((tableName) => {
      if (db.objectStoreNames.contains(tableName)) {
        const transaction = db.transaction(tableName, "readwrite");
        const objectStore = transaction.objectStore(tableName);
        objectStore.clear();
      }
    });
  } catch (error) {
    throw error;
  }
};

export const dataExistInTable = async (tableName) => {
  try {
    const db = await openDatabase(dbName, dbVersion);

    if (db.objectStoreNames.contains(tableName)) {
      const transaction = db.transaction(tableName, "readonly");
      const objectStore = transaction.objectStore(tableName);
      const cursorRequest = objectStore.openCursor();

      return new Promise((resolve) => {
        cursorRequest.onsuccess = (event) => {
          const cursor = event.target.result;
          // Resolve with true if at least one record exists, otherwise false
          resolve(cursor !== null);
        };

        cursorRequest.onerror = () => {
          // No need to reject, simply resolve with false on error
          resolve(false);
        };
      });
    } else {
      // Object store does not exist, so no data can exist in it
      return false;
    }
  } catch (error) {
    throw error;
  }
};

export function mergeVideoBlob() {
  return new Promise((resolve, reject) => {
    retrieveVideo()
      .then((videoData) => {
        const blobPromises = videoData.map(
          async (data) =>
            data && new Blob([data.videoBinary], { type: "video/mp4" })
        );
        return Promise.all(blobPromises);
      })
      .then((videoBlobs) => {
        resolve(new Blob(videoBlobs, { type: "video/mp4" }));
      })
      .catch((error) => {
        reject(error);
      });
  });
}
