/* eslint-disable import/no-anonymous-default-export */
import {
  START_LOAD_ATTACHMENTS,
  LOAD_ATTACHMENTS_SUCCESS,
  LOAD_ATTACHMENTS_ERROR,
  START_UPLOAD_ATTACHMENT,
  UPLOAD_ATTACHMENT_SUCCESS,
  UPLOAD_ATTACHMENT_ERROR,
  START_DELETE_ATTACHMENT,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_ERROR,
  ATTACHMENT_RECEIVED_SUCCESS,
} from "../actions/actionTypes";

export const uploadAttachmentDefaultState = {
  uploading: false,
  uploaded: {},
  uploadingError: null,
};

export const deleteAttachmentDefaultState = {
  deleting: false,
  deleted: {},
  deletingError: null,
};

export const defaultState = {
  attachments: [],
  loading: false,
  error: null,
  ...uploadAttachmentDefaultState,
};

export default function (
  state = defaultState,
  action = { type: null, payload: {} }
) {
  switch (action.type) {
    case START_LOAD_ATTACHMENTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOAD_ATTACHMENTS_SUCCESS: {
      return {
        ...state,
        attachments: [...action.payload],
        loading: false,
      };
    }

    case LOAD_ATTACHMENTS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case START_UPLOAD_ATTACHMENT: {
      return {
        ...state,
        uploading: true,
      };
    }

    case UPLOAD_ATTACHMENT_SUCCESS: {
      const attachments = [action.payload, ...state.attachments];
      return {
        ...state,
        uploaded: {
          ...action.payload,
        },
        attachments,
        uploading: false,
      };
    }

    case UPLOAD_ATTACHMENT_ERROR: {
      return {
        ...state,
        uploadingError: action.payload,
        uploading: false,
      };
    }

    case START_DELETE_ATTACHMENT: {
      return {
        ...state,
        deleting: true,
      };
    }

    case DELETE_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        deleted: {
          ...action.payload,
        },
        deleting: false,
      };
    }

    case DELETE_ATTACHMENT_ERROR: {
      return {
        ...state,
        deletingError: action.payload,
        deleting: false,
      };
    }

    case ATTACHMENT_RECEIVED_SUCCESS: {
      const attachments = [
        { ...action.payload, isReceived: true },
        ...state.attachments,
      ];
      return {
        ...state,
        attachments,
      };
    }

    default: {
      return state;
    }
  }
}
