const GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_GOOGLE_MAP_API_KEY ||
  "AIzaSyCd43gt-fH6TLVqgsyoQF185fQJnmiM2-w";
export const appConstants = {
  MAX_PARTICIPANT_IN_CALL: 4,
  S3Image: ".s3.",

  TRACK_STATUS: {
    ENABLED: "1",
    DISABLED: "0",
  },

  DEVICE_TYPE: {
    DEVICE_TYPE_WEB_ANDROID: "1",
    DEVICE_TYPE_WEB_IOS: "2",
    DEVICE_TYPE_WEB_PC: "3",
    DEVICE_TYPE_ANDROID: "4",
    DEVICE_TYPE_IOS: "5",
  },

  ZOOM_LEVEL: {
    ZOOM_LEVEL_1: "1(1x)",
    ZOOM_LEVEL_2: "2(2x)",
    ZOOM_LEVEL_3: "3(3x)",
  },

  CAMERA_TYPE: {
    CAMERA_TYPE_FRONT: "front",
    CAMERA_TYPE_BACK: "back",
  },

  USER_ROLES: {
    PROPERTY_OWNER: "PROPERTY_OWNER",
    APPRAISER: "APPRAISER",
    ASSISTANT: "ASSISTANT",
    OPEN_USER: "OPEN_USER",
  },

  CALL_STATUS: {
    CONNECTING: 1,
    CONNECTED: 2,
    DISCONNECTED: 3,
  },

  PIN_VIDEO_TYPE: {
    CAMERA: "camera",
    SCREEN_SHARE: "screen_share",
  },

  APPOINTMENTS: {
    ALL: 1,
    UPCOMING: 3,
    PAST: 2,
    TODAY: 4,
  },

  FLASH_STATUS: {
    ON: "1",
    OFF: "2",
    ENABLED: "3",
    DISABLED: "4",
  },

  ZOOM_STATUS: {
    ENABLED: "1",
    DISABLED: "2",
  },

  TwilioConnectedStatus: {
    CONNECTED: "connected",
  },

  ANNOTATION_ACK: {
    REQUEST_FOR_IMAGE_CAPTURE: "0",
    UPLOADING: "1",
    UPLOADED: "2",
    DOWNLOADING: "3",
    STARTED: "4",
    STOPPED: "5",
    CLEAR: "6",
    MANUALLY_CANCEL: "7",
    SERVERERROR: "8",
  },

  SHAPE_STATUS: {
    DRAW: "1",
    MOVE: "2",
    UNDO: "3",
    REDO: "4",
  },

  SHAPE_TYPE: {
    PENCIL_DRAW: "1",
    RECTANGLE: "2",
    TEXTDRAW: "3",
    CIRCLE: "4",
    ARROW: "6",
    LINE: "5",
    TEXT: "7",
  },

  MARKUP_VIEW: {
    PENCIL_STROKE_WIDTH: 8,
    HIGHLIGHT_STROKE_WIDTH: 22,
  },

  API_KEYS: {
    GOOGLE_MAP_API_KEY: GOOGLE_MAP_API_KEY,
  },

  POINTER_STATUS: {
    START: "1",
    STOP: "2",
  },

  POINTER_COLORS: [
    "#96FC98",
    "#FF7578",
    "#E9FF5B",
    "#00FFFF",
    "#cbe059",
    "#9aa98c",
    "#743a4b",
    "#821728",
    "#b6af77",
    "#849b82",
    "#a99758",
    "#ab4b31",
  ],
  DATE_TIME_LONG_FORMAT: "DD-MM-YYYY hh:mm:ss A",
  TIME_SHORT_FORMAT: "hh:mm A",
  DATE_TIME_API_FORMAT: "YYYY-MM-DDTHH:mm:ss.SSSS",

  FLOOR_DATA: [
    { value: "2", label: "Basement" },
    { value: "5", label: "1st Floor" },
    { value: "6", label: "2nd Floor" },
    { value: "3", label: "Ground Floor" },
    { value: "1", label: "2nd Basement" },
    { value: "0", label: "3rd Basement" },
    { value: "4", label: "Exterior" },
    { value: "7", label: "3rd Floor" },
    { value: "8", label: "4th Floor" },
    { value: "9", label: "5th Floor" },
    { value: "10", label: "6th Floor" },
    { value: "11", label: "7th Floor" },
    { value: "12", label: "8th Floor" },
    { value: "13", label: "9th Floor" },
    { value: "14", label: "10th Floor" },
    { value: "15", label: "11th Floor" },
    { value: "16", label: "12th Floor" },
    { value: "17", label: "13th Floor" },
    { value: "18", label: "14th Floor" },
    { value: "19", label: "15th Floor" },
    { value: "20", label: "16th Floor" },
    { value: "21", label: "17th Floor" },
    { value: "22", label: "18th Floor" },
    { value: "23", label: "19th Floor" },
    { value: "24", label: "20th Floor" },
    { value: "25", label: "21st Floor" },
    { value: "26", label: "22nd Floor" },
    { value: "27", label: "23rd Floor" },
    { value: "28", label: "24th Floor" },
    { value: "29", label: "25th Floor" },
    { value: "30", label: "26th Floor" },
    { value: "31", label: "27th Floor" },
    { value: "32", label: "28th Floor" },
    { value: "33", label: "29th Floor" },
    { value: "34", label: "30th Floor" },
    { value: "35", label: "31st Floor" },
    { value: "36", label: "32nd Floor" },
    { value: "37", label: "33rd Floor" },
    { value: "38", label: "34th Floor" },
    { value: "39", label: "35th Floor" },
    { value: "40", label: "36th Floor" },
    { value: "41", label: "37th Floor" },
    { value: "42", label: "38th Floor" },
    { value: "43", label: "39th Floor" },
    { value: "44", label: "40th Floor" },
    { value: "45", label: "41st Floor" },
    { value: "46", label: "42nd Floor" },
    { value: "47", label: "43rd Floor" },
    { value: "48", label: "44th Floor" },
    { value: "49", label: "45th Floor" },
    { value: "50", label: "46th Floor" },
    { value: "51", label: "47th Floor" },
    { value: "52", label: "48th Floor" },
    { value: "53", label: "49th Floor" },
    { value: "54", label: "50th Floor" },
    { value: "55", label: "Roof" },
  ],

  ROOM_DATA: [
    { value: "Attic / Loft", label: "Attic / Loft" },
    { value: "Balcony", label: "Balcony" },
    { value: "Bathroom", label: "Bath" },
    { value: "Bedroom", label: "Bedroom" },
    { value: "Cellar", label: "Cellar" },
    { value: "Closet", label: "Closet" },
    { value: "Deck", label: "Deck" },
    { value: "Den", label: "Den" },
    { value: "Dining Room", label: "Dining" },
    { value: "Exterior", label: "Exterior" },
    { value: "Furnace Room", label: "Furnace Room" },
    { value: "Garage", label: "Garage" },
    { value: "Hall", label: "Hall" },
    { value: "Hallway", label: "Hallway" },
    { value: "Kitchen", label: "Kitchen" },
    { value: "Laundry Room", label: "Laundry Room" },
    { value: "Living Room", label: "Living Room" },
    // { value: "Master Bathroom", label: "Master Bath" },
    // { value: "Master Bedroom", label: "Master Bedroom" },
    { value: "Music Room", label: "Music Room" },
    { value: "Outbuilding", label: "Outbuilding" },
    { value: "Patio", label: "Patio" },
    { value: "Playroom", label: "Playroom" },
    { value: "Porch", label: "Porch" },
    { value: "Stairway", label: "Stairway" },
    { value: "Study", label: "Study" },
    { value: "Toilet", label: "Toilet" },
    // { value: "Unfinished Basement", label: "Unfinished Basement" },
    { value: "Vestibule", label: "Vestibule" },
    { value: "WorkShop", label: "WorkShop" },
  ],
  mediaPermissions: {
    prompt: "prompt",
    granted: "granted",
    denied: "denied",
    error: "error",
  },

  mediaType: {
    camera: 1,
    microphone: 2,
    speaker: 3,
  },

  userRoles: {
    PROPERTY_OWNER: 2,
  },
  twilioMaxParticipantReachedErrorCode: 53105,

  MeasurementTypes: [
    { value: "1", label: "Floor Cross-Room Measurement", lidar: 0 },
    { value: "2", label: "Mid-Wall Cross-Room Measurement (LIDAR)", lidar: 1 },
    { value: "3", label: "Ceiling Cross-Room Measurement (LIDAR)", lidar: 1 },
    { value: "4", label: "Floor Perimeter", lidar: 0 },
    { value: "5", label: "Mid-Wall Perimeter (LIDAR)", lidar: 1 },
    { value: "6", label: "Ceiling Perimeter (LIDAR)", lidar: 1 },
  ],

  AppURLs: {
    dev: "https://portal.v-site.xyz/",
    prod: "https://portal.remoteval.net/",
  },
  DatabaseName: "vpiece-inspection",
};
