import React from "react";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import "./savedImagesModel.scss";
import { useTranslation } from "react-i18next";
import AsyncImage from "../../common/AsyncImage";

const SavedImagesModel = ({
  closeModel,
  toggleModel,
  updateZoomLevel,
  isSavedImagesFullViewVisible,
  isScaleZoomVisible,
  zoomLevel,
  isAppraiserOrAssistant,
  captureImages,
  showGalleryZoomModel,
  showDeleteImageModel,
  isAutoShowSavedImagesFullViewVisible,
}) => {
  const { t } = useTranslation();
  const showSidebar =
    isSavedImagesFullViewVisible && isAutoShowSavedImagesFullViewVisible;

  const renderImages = () => {
    if (!captureImages.images.length) {
      return (
        <div className="no-image-found">{t("WEB_LABELS.No_images_found")}</div>
      );
    }

    return (
      <div className="thumb-body scroll-bar-style">
        {[...captureImages.images].reverse().map((image, id) => {
          return (
            <div className="thumb-image-div" key={id}>
              <div className="thumb_bottom_postion">
                <div className="viewbtn">
                  <button
                    onClick={() => {
                      showGalleryZoomModel({
                        currentImageIndex: captureImages.images.indexOf(image),
                      });
                    }}
                  >
                    {t("BUTTONS.View")}
                  </button>
                </div>
                <div className="thumb-image">
                  <AsyncImage imageUrl={image.thumb} />
                </div>
              </div>
              <div className="thumb-image-info">
                <div className="caption">
                  <i className="icon-save-images"></i>
                  {t("WEB_LABELS.Image")}:{" "}
                  {captureImages.images.indexOf(image) + 1}
                </div>
                <div className="thumb-image-delete">
                  <Tooltip title={t("TOOLTIP.Delete_Image")} arrow>
                    <button
                      onClick={() =>
                        showDeleteImageModel(
                          captureImages.images.indexOf(image),
                          image
                        )
                      }
                    >
                      <i className="icon-fill-delete"></i>
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className={"thumb-container " + (showSidebar ? "open" : "")}>
        {isAppraiserOrAssistant && (
          <button className="side-toggle" onClick={() => toggleModel()}>
            <i
              className={showSidebar ? "icon-arrow-right" : "icon-arrow-left"}
            ></i>
            <span>
              {captureImages.images.length ? captureImages.images.length : 0}
            </span>
          </button>
        )}

        {isAppraiserOrAssistant && showSidebar && (
          <div className="thumb-container-inner">
            <div className="side-head">
              <div className="icon">
                <i className="icon-save-images"></i>
                <span className="count">
                  {captureImages.images.length
                    ? captureImages.images.length
                    : 0}
                </span>
              </div>
              {t("WEB_LABELS.Captured_Images")}
              <button className="close-modal" onClick={() => closeModel()}>
                <i className="icon-close-image"></i>
              </button>
            </div>
            {renderImages()}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    captureImages: state.captureImages,
  };
};

export default connect(mapStateToProps)(SavedImagesModel);
