import * as actionTypes from "./actionTypes";

const updateRole = (payload) => {
  return {
    type: actionTypes.UPDATE_LOCALUSER_ROLE,
    payload,
  };
};

export const updateLocalUserRole = (role) => {
  return (dispatch) => {
    dispatch(updateRole(role));
  };
};
