import api from "./api";

export default class API {
  uploadCaptureImage(eitherCallUUIdOrJobOrderId, filedata) {
    return api.postMultipartFormData(
      `call/${eitherCallUUIdOrJobOrderId}/picture`,
      filedata
    );
  }

  deleteImage(data) {
    return api.post(`/call/delete-picture`, data);
  }
}
