export const TRANSLATIONS_Hindi = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired:
      "सुरक्षित वीडियो कॉल सत्र की समय सीमा समाप्त",
    Secure_Video_Call_Ended: "सुरक्षित वीडियो कॉल समाप्त",
    Thank_you_for_connecting_with_us: "हमसे जुड़ने के लिए धन्यवाद!",
    Connected_Waiting_for_Other_Participants:
      "जुड़े हुए। अन्य प्रतिभागियों की प्रतीक्षा में...",
    Powered_by: "द्वारा संचालित",
    Connecting: "कनेक्ट हो रहा है...",
    Local_Pike: "स्थानीय पाइक",
    Would_you_like_to_save_image: "क्या आप छवि सहेजना चाहेंगे?",
    Message: "संदेश",
    Location_Not_shared: "स्थान: साझा नहीं किया गया",
    Location: "स्थान",
    Tools: "उपकरण",
    Ask_PO_to_Turn_on_Location:
      "संपत्ति के मालिक से स्थान सक्षम करने के लिए कहें",
    Preparing_image_for_markup: "मार्कअप के लिए चित्र तैयार किया जा रहा है...",
    Download_Message: "दूरस्थ मूल्यांकन आवेदन डाउनलोड करें",
    Disclaimer:
      "रिमोटवैल एप्लिकेशन को ऐप स्टोर से डाउनलोड करना आवश्यक है। एक बार डाउनलोड पूरा हो जाने पर, कृपया अपने टेक्स्ट/ईमेल संदेश आमंत्रण को फिर से खोलें और कॉल में स्वचालित रूप से शामिल होने के लिए एक बार फिर लिंक पर क्लिक करें।",
    MeasurementLinkDisclaimer:
      "रिमोटवैल एप्लिकेशन को ऐप स्टोर से डाउनलोड करना आवश्यक है। एक बार डाउनलोड पूरा हो जाने पर, कृपया अपने टेक्स्ट/ईमेल संदेश आमंत्रण को फिर से खोलें और स्वचालित रूप से निरीक्षण शुरू करने के लिए एक बार फिर लिंक पर क्लिक करें।",
    Zoom_Level: "ज़ूम लेवल",
    enter_cell_phone: "सेल फोन दर्ज करें",
    Email: "ईमेल",
    Cell_Phone: "सेल फोन",
    Participant_Name: "भाग लेने वाले का नाम",
    Invite_Participant: "प्रतिभागी को आमंत्रित करें",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "निम्नलिखित लिंक पर क्लिक करके सुरक्षित वीडियो कॉल में शामिल हों:",
    There_are_no_Attachments_available: "कोई अटैचमेंट उपलब्ध नहीं है।",
    Drop_Or_Select_Your_File: "ड्रॉप या अपनी फ़ाइल चुनें",
    Attach_Document: "दस्तावेज़ संलग्न करें",
    Enter_Your_Name: "अपना नाम दर्ज करें",
    I_confirm_that_I_have_read_and_agree_to_the:
      "मैं पुष्टि करता हूं कि मैंने पढ़ लिया है और इससे सहमत हूं",
    Search_or_Add_Captured_Image_Label: "कैप्चर की गई छवि लेबल खोजें या जोड़ें",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "एंटर दबाएं या नए लेबल के साथ कैप्चर की गई छवि में नया लेबल जोड़ें पर क्लिक करें",
    To: "प्रति",
    Everyone: "सब लोग",
    Type_a_message: "एक संदेश लिखें",
    Are_you_sure_you_want_to_delete: "क्या आप वाकई हटाने के इच्छुक हैं ?",
    Room_is_full_Please_try_again_later:
      "कमरा भरा हुआ है। बाद में पुन: प्रयास करें",
    Comment: "टिप्पणी",
    Room_2_Measurement: "कक्ष 2 मापन",
    Room_1_Measurement: "कक्ष 1 मापन",
    Room_2_Image: "कमरा 2 छवि",
    Room_1_Image: "कक्ष 1 छवि",
    No_of_Rooms: "कमरों की संख्या",
    Property_Type: "सम्पत्ती के प्रकार",
    House: "मकान",
    Inspection_Form: "निरीक्षण प्रपत्र",
    Instructions: "निर्देश",
    Guide_user_to_position_camera_on_the_target:
      "लक्ष्य पर कैमरा लगाने के लिए उपयोगकर्ता का मार्गदर्शन करें",
    Click_on_the_Label_once_to_capture_labeled_image:
      "लेबल की गई छवि को कैप्चर करने के लिए एक बार लेबल पर क्लिक करें।",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "घर के फर्श, कमरे के प्रकार और कमरे के नाम का चयन करें।",
    Choose_GLA_or_Non_GLA: "GLA या गैर-GLA चुनें.",
    Click_on_the_Start_Measurement_button: "माप प्रारंभ करें बटन पर क्लिक करें",
    Click_the_Auto_Corner_Detection_to_On_Off:
      'चालू/बंद करने के लिए "ऑटो कॉर्नर डिटेक्शन" पर क्लिक करें',
    Position_vertical_Green_Floor:
      "एक कमरे के कोने में वर्टिकल ग्रीन फ्लोर मार्कर रखें और सुनिश्चित करें कि यह फर्श को छूता है।",
    App_will_auto_mark_a_corner:
      'ऐप एक कोने को ऑटो-मार्क करेगा या "मैन्युअल रूप से कैप्चर कॉर्नर" बटन पर टैप करेगा',
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "प्रत्येक कोने को चिह्नित करने के लिए कमरे के प्रत्येक कोने में जाने के लिए उपयोगकर्ता का मार्गदर्शन करें",
    When_last_line_connects:
      'जब अंतिम पंक्ति प्रारंभिक बिंदु से जुड़ती है, तो वह लाल हो जाएगी। ऐप ऑटो-कनेक्ट और स्केच पूरा करेगा या आप माप को पूरा करने के लिए "कनेक्ट कॉर्नर एंड फिनिश स्केच" बटन का उपयोग कर सकते हैं।',
    button_to_view_final_sketch:
      'अंतिम स्केच देखने के लिए "शो फिनिश्ड फ्लोर प्लान स्केच" बटन पर क्लिक करें।',
    Are_You_Sure_You_Want_to_Leave_This_Call:
      "क्या आप वाकई इस कॉल को छोड़ना चाहते हैं?",
    Street_View: "सड़क का दृश्य",
    Select_Measurement_Type: "मापन प्रकार चुनें",
    Floor: "फ़र्श",
    Ceiling: "छत",
    View_Sketches_by_Floor: "तल के अनुसार रेखाचित्र देखें",
    Uploading_Image: "छवि अपलोड करना",
    Call_Setting: "काल सेटटिंग",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "अपने माइक्रोफ़ोन तक पहुंच की अनुमति दें। ताला पर क्लिक करें",
    to_the_left_of_the_address_bar: "पता बार के बाईं ओर।",
    Speakers: "वक्ताओं",
    Allow_access_to_your_Camera_Click_the_padlock:
      "अपने कैमरे तक पहुंच की अनुमति दें। ताला पर क्लिक करें",
    to_the_address_bar: "एड्रेस बार को।",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "वीडियो कैमरा प्रारंभ करने में विफल. कृपया कोई दूसरा वीडियो कैमरा चुनें.",
    Title: "शीर्षक",
    New_Note: "नया नोट...",
    There_are_no_Notes_available: "कोई नोट उपलब्ध नहीं हैं।",
    Are_you_sure_you_want_to_delete_this_note:
      "क्या आप पक्का इस नोट को मिटाना चाहते हैं?",
    Network_Type: "नेटवर्क प्रकार",
    Netword_Strength: "नेटवर्क ताकत",
    To_share_live_video:
      "लाइव वीडियो शेयर करने के लिए। कृपया अपनी ब्राउज़र सेटिंग में कैमरा अनुमति चालू करें",
    click_here: "यहाँ क्लिक करें",
    to_reload_the_page: "पृष्ठ को पुनः लोड करने के लिए।",
    we_cant_access_your_microphone:
      "हम आपके माइक्रोफ़ोन तक नहीं पहुंच सकते. एक्सेस देने के लिए, अपनी ब्राउज़र सेटिंग में जाएं और फिर",
    Questionnaire_Form: "प्रश्नावली प्रपत्र",
    No_images_found: "कोई चित्र नहीं मिला!",
    Image: "छवि",
    Captured_Images: "कैप्चर की गई छवियां",
    Share_your_screen: "अपनी स्क्रीन साझा करें",
    Share_your_live_video: "अपना लाइव वीडियो साझा करें?",
    This_will_stop_your_video:
      "इससे आपका वीडियो बंद हो जाएगा। क्या आप जारी रखना चाहते हैं?",
    This_will_stop_screen_share:
      "यह स्क्रीन शेयर करना बंद कर देगा। क्या आप जारी रखना चाहते हैं?",
    Room_Area_Label: "कक्ष/क्षेत्र लेबल",
    Room_Area_Name: "कमरे/क्षेत्र का नाम (जैसे शयन कक्ष 1, स्नानागार 2, आदि)",
    Room_Area_Name_Placeholder: "कमरे/क्षेत्र का नाम",
    You: "आप",
    Are_you_sure_you_want_to_delete_captured_image:
      "क्या आप वाकई कैप्चर की गई छवि को हटाना चाहते हैं?",
    Start_Over:
      "फिर से शुरू करें - क्या आप सुनिश्चित हैं कि आप फ़्लोर प्लान हटाना चाहते हैं?",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "क्या आप वाकई मापन प्रक्रिया से बाहर निकलना चाहते हैं?",
    Labels: "लेबल",
    Select_Language: "भाषा चुने",
    This_call_is_being_recorded: "यह वीडियो कॉल रिकॉर्ड किया जा रहा है",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "यह कॉल आपके मूल्यांकक द्वारा उनकी निजी कार्य फ़ाइल के लिए रिकॉर्ड की जा रही है।",
    It_will_not_be_shared: `इसे आपके बंधक सहित किसी बाहरी पार्टी के साथ साझा नहीं किया जाएगा
    ऋणदाता, और आपका मूल्यांकक इसे से डाउनलोड नहीं कर पाएगा
    रिमोटवैल साइट। इसका उपयोग पूरी तरह से मूल्यांकनकर्ता को पूरा करने के लिए किया जाएगा
    आपका मूल्यांकन।`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "यदि आप नहीं चाहते कि आपकी स्क्रीन रिकॉर्ड की जाए तो कृपया अपने मूल्यांकक को अभी सूचित करें।",
    Measurement: "माप",
    Create_Measurement: "माप बनाएं",
    Fencing: "बाड़ लगाना",
    Show: "प्रदर्शन",
    Hide: "छिपाना",
    Live_Floor_Plan: "लाइव फ्लोर प्लान",
    Expand: "विस्तार करना",
    Collapse: "ढहने",
    Instruction_for_Call_Settings: "कॉल सेटिंग के लिए निर्देश",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      'स्पीकर, माइक और कैमरा का परीक्षण "अधिक" मेनू से कॉल सेटिंग के माध्यम से किया जा सकता है',
    To_Check_the_call_settings_now_click_on_the_below_button:
      "कॉल सेटिंग चेक करने के लिए अब नीचे दिए गए बटन पर क्लिक करें।",
    Call_recording_by_virtual_inspector:
      "यह वीडियो कॉल आपके वर्चुअल इंस्पेक्टर द्वारा उनकी निजी कार्य फ़ाइल के लिए और उस संगठन द्वारा आंतरिक ऑडिटिंग उद्देश्यों के लिए रिकॉर्ड किया जा रहा है जिसने इसका अनुरोध किया था। इसे किसी भी बाहरी पार्टी के साथ साझा नहीं किया जाएगा।",
    Call_and_Screen_Recording_Opt_Out:
      "यदि आप अपनी स्क्रीन की कामना नहीं करते हैं और यह कॉल रिकॉर्ड किया जाना है, तो कृपया अपने वर्चुअल इंस्पेक्टर को अभी सूचित करें।",
    Test_Audio_Video_Components_Button:
      "नीचे दिए गए बटन पर क्लिक करके स्पीकर, माइक्रोफोन और कैमरा का परीक्षण किया जा सकता है।",
  },
  BUTTONS: {
    Re_Connect: "फिर से कनेक्ट",
    End_Session: "सत्र समाप्त करें",
    Chat: "चैट",
    Participants: "प्रतिभागियों",
    Notes: "टिप्पणियाँ",
    Attach_Document: "दस्तावेज़ संलग्न करें",
    Questionnaire: "प्रश्नावली",
    Labels: "लेबल",
    Pointer: "सूचक",
    Markup: "मार्कअप",
    Flash_Off: "बंद फ्लैश",
    Flash_On: "फ्लैश ऑन",
    Call_Recording: "कॉल रिकॉर्डिंग",
    Capture_Image: "छवि कैप्चर करें",
    Screen_Share: "स्क्रीन शेयर",
    Stop_Screen_Share: "स्क्रीन शेयर बंद करो",
    Call_Settings: "कॉल सेटिंग",
    Clear_All: "सभी साफ करें",
    Exit: "बाहर जाएं",
    Save: "सहेजें",
    Close: "बंद करे",
    Save_and_Exit: "सुरषित और बहार",
    Continue: "जारी रखना",
    Street_View: "सड़क का दृश्य",
    Open_in_App: "ऐप में खोलें",
    Privacy_Policy: "गोपनीयता नीति",
    Invite: "आमंत्रण",
    Join: "शामिल हों",
    Add_Captured_Image_Label: "कैप्चर की गई छवि लेबल जोड़ें",
    No: "नहीं",
    Yes: "हां",
    Delete: "हटाएं",
    Cancel: "रद्द करें",
    RETRY: "पुन: प्रयास",
    OK: "ठीक है",
    Back: "वापस",
    Leave_Call: "कॉल छोड़ें",
    Got_It: "मिल गई",
    Start_New_Inspection: "नया निरीक्षण शुरू करें",
    Resume_Existing_Inspection: "मौजूदा निरीक्षण फिर से शुरू करें",
    View_All_Current_Sketch: "सभी वर्तमान स्केच देखें",
    Open_Sketch: "खुला स्केच",
    Auto_Corner_Detection_ON: "ऑटो कॉर्नर डिटेक्शन चालू",
    Auto_Corner_Detection_OFF: "ऑटो कॉर्नर डिटेक्शन बंद",
    Manually_Capture_Corner: "मैन्युअल रूप से कॉर्नर कैप्चर करें",
    Undo_Last_Corner_Capture: "लास्ट कॉर्नर कैप्चर को पूर्ववत करें",
    Connect_Corner_Finish_Sketch: "कनेक्ट कॉर्नर और फिनिश स्केच",
    Capture_3D_Room_Height: "3D कमरे की ऊँचाई कैप्चर करें",
    Set_Fixed_3D_Room_Height: "निश्चित 3D कमरे की ऊँचाई सेट करें",
    Capture_Door: "दरवाजा कैप्चर करें",
    Set_Door: "दरवाजा सेट करें",
    Show_Finished_Floor_Plan_Sketch: "तैयार मंजिल योजना स्केच दिखाएँ",
    Back_to_Previous_Screen: "पिछली स्क्रीन पर वापस जाएं",
    Add_Room: "कमरा जोड़ें",
    Publish_the_floor_plan: "तल योजना प्रकाशित करें",
    Exit_Return_to_Inspection: "बाहर निकलें और निरीक्षण पर लौटें",
    Audio: "ऑडियो",
    Video: "वीडियो",
    Microphone: "माइक्रोफ़ोन",
    Microphone_not_found: "माइक्रोफ़ोन नहीं मिला",
    Microphone_Blocked: "माइक्रोफ़ोन अवरोधित",
    Default: "चूक जाना",
    Grant_Permission: "अनुदान अनुमति",
    Speaker_Blocked: "स्पीकर ब्लॉक किया गया",
    Play_Test_Sound: "टेस्ट साउंड खेलें",
    Preview: "पूर्वावलोकन",
    Camera: "कैमरा",
    Camera_not_found: "कैमरा नहीं मिला",
    Camera_Blocked: "कैमरा अवरुद्ध",
    Done: "किया हुआ",
    View: "राय",
    Capture_Labeled_Images: "लेबल की गई छवियों को कैप्चर करें",
    Capture_Measurements: "माप कैप्चर करें",
    Instructions: "निर्देश",
    Start_Measurement_Process: "मापन प्रक्रिया प्रारंभ करें",
    Flash_Light_Not_Supported:
      "रिमोट टॉर्च अतिथि डिवाइस पर समर्थित नहीं है या तैयार नहीं है",
  },
  TOOLTIP: {
    Mute_My_Audio: "मेरा ऑडियो म्यूट करें",
    Unmute_My_Audio: "मेरा ऑडियो अनम्यूट करें",
    Stop_My_Video: "मेरा वीडियो बंद करो",
    Start_My_Video: "मेरा वीडियो शुरू करें",
    Capture_Images_with_No_Labels: "बिना लेबल वाली इमेज कैप्चर करें",
    Start_Inspection: "निरीक्षण शुरू करें",
    Chat: "चैट",
    More_Options: "अधिक विकल्प",
    End_Call: "कॉल समाप्त करें",
    Unpin_Video: "वीडियो अनपिन करें",
    Pin_Video: "पिन वीडियो",
    Add_New_Note: "नया नोट जोड़ें",
    Refresh: "ताज़ा करना",
    View_More: "और देखो",
    Invite_Participant: "प्रतिभागी को आमंत्रित करें",
    Delete_Image: "छवि हटाएं",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "आमंत्रण सफलतापूर्वक भेजा गया",
    IOS_SAFARI_HELP:
      "सर्वोत्तम परिणामों के लिए, हम सफारी का उपयोग करने की सलाह देते हैं",
    UNEXPECTED_ERROR: "अनपेक्षित त्रुटियां हुईं। बाद में पुन: प्रयास।",
    CALL_LINK_NOT_FOUND: "कॉल लिंक नहीं मिला।",
    CREATE_BOOKMARK_MESSAGE: "कृपया व्यवस्थापक से बुकमार्क जोड़ने के लिए कहें",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED: "फ़ाइल का आकार 10MB से कम होना चाहिए",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT: "असमर्थित फ़ाइल स्वरूप",
    LIVE_POINTER_STARTED:
      "लाइव पॉइंटर शुरू किया। आप पॉइंटर को खींचकर ले जा सकते हैं।",
    LIVE_POINTER_STOPPED: "रुका हुआ लाइव पॉइंटर",
    APPRAISAL_EXIT_APPROVED: "निकास सफलतापूर्वक स्वीकार किया गया।",
    APPRAISAL_EXIT_DENIED: "बाहर निकलने से इनकार किया।",
    MARKUP_CLOSED: "मार्कअप रोक दिया गया है",
    PRIVACY_POLICY_FAIL: "कोई मान्य गोपनीयता नीति URL नहीं मिला!",
    SWITCH_CAMERA_REQUEST_REJECTED:
      "गृह स्वामी ने अनुरोधित कैमरा दृश्य साझा नहीं किया।",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email: "कृपया सेलफोन या ईमेल चुनें",
    This_field_is_required: "यह फ़ील्ड आवश्यक है",
    Cell_Phone_Must_Be_Valid: "सेल फोन मान्य होना चाहिए",
    Name_is_Required: "नाम आवश्यक है",
    Name_Must_Be_Valid: "नाम मान्य होना चाहिए",
    Invalid_Text: "अमान्य टेक्स्ट",
    Email_Must_Be_Valid: "ईमेल मान्य होना चाहिए",
    Name_must_be: "नाम होना चाहिए",
    characters_max: "अक्षर अधिकतम",
    Please_select_the_Floor: "कृपया मंजिल का चयन करें",
    Please_enter_the_Room_Area_Name: "कृपया कमरे/क्षेत्र का नाम दर्ज करें",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "ऑडियो आउटपुट डिवाइस का चयन करने के लिए आपको HTTPS का उपयोग करने की आवश्यकता है",
    Something_went_wrong: "कुछ गलत हो गया। बाद में पुन: प्रयास करें।",
    Oops_Call_duration_is_over_and_session_is_ended:
      "उफ़! कॉल की अवधि समाप्त हो गई है और सत्र समाप्त हो गया है।",
    No_participant_has_joined_yet_Ending_the_call_session:
      "अभी तक कोई प्रतिभागी शामिल नहीं हुआ है! कॉल सत्र समाप्त करना",
    You_are_offline_Check_your_internet_connection:
      "आप संपर्क में नहीं हैं। अपना इंटरनेट संपर्क जांचे।",
  },
  starting_inspection: "निरीक्षण शुरू",
  starting_measurement: "माप शुरू करना",
  error: "त्रुटि",
  contact_us: "संपर्क करें",
  support_popup_msg:
    "ओह, कुछ गलत हो गया, कृपया कुछ समय बाद पुन: प्रयास करें या हमारे समर्थन को किसी समस्या की रिपोर्ट करें।",
  select_po: "निरीक्षण प्रक्रिया शुरू करने के लिए संपत्ति के मालिक का चयन करें",
  request_po_for_camera:
    "कृपया संपत्ति के मालिक से निरीक्षण प्रक्रिया शुरू करने के लिए कैमरा दृश्य चालू करने का अनुरोध करें",
  wait_until_po_joins:
    "कृपया तब तक प्रतीक्षा करें जब तक कि संपत्ति का मालिक निरीक्षण प्रक्रिया शुरू करने के लिए कॉल में शामिल न हो जाए",
  select_measurement_method: "मापन विधि का चयन करें",
  inspection_inprogress: "जांच पहले से चल रही है",
  model_msg_retry:
    "संपत्ति के मालिक के साथ संचार चैनल स्थापित करने में असमर्थ, कृपया पुनः प्रयास करें।",
  model_msg_leaveCall:
    "ऐसा लगता है कि दोनों तरफ इंटरनेट कनेक्टिविटी की समस्या है, इसलिए कृपया वर्तमान कॉल को डिस्कनेक्ट करें और फिर से कनेक्ट करें।",
  please_wait: "कृपया प्रतीक्षा करें...",
  loading_sketch: "स्केच लोड हो रहा है...",
  connection_timeout_msg:
    "संम्पर्क का समय खत्म हो गया है। कुछ असामान्य हुआ है और प्रतिक्रिया देने में अपेक्षा से अधिक समय लग रहा है। कृपया पुन: प्रयास करें।",
  previous_measurement_prblm_msg:
    "ऑप्स, पिछले माप के साथ कुछ गलत हुआ। कृपया नई मापन प्रक्रिया के साथ पुन: प्रयास करें।",
  measurement_retry_msg:
    "माप प्रक्रिया शुरू नहीं हुई है। कृपया पुन: प्रयास करें।",
  opensketch_retry_msg:
    "ओपन स्केच प्रक्रिया शुरू नहीं हुई है। कृपया पुन: प्रयास करें।",
};
