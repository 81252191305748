import React, { Component, memo, useState } from "react";
import { connect } from "react-redux";
import {
  createNote,
  deleteNote,
  updateNote,
} from "../../../actions/notesAction";
import { getUTCToLocalDateTimeWithFormat } from "../../../helpers";
import { appConstants } from "../../../constants";
import * as _ from "lodash";
import "./notesViewModel.scss";
import DeleteModal from "./DeleteModal";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation, withTranslation } from "react-i18next";

const EditNote = memo(
  ({ note, onUpdateNote, onDeleteNote, onCancelEditNote, inviteUUID }) => {
    const { t } = useTranslation();

    const [noteTitle, setNoteTitle] = useState(note.noteTitle);
    const [noteDescription, setNoteDescription] = useState(
      note.noteDescription
    );
    const onCancel = (e) => e.key === "Escape" && onCancelEditNote();
    return (
      <div className="note-list">
        <div className="text">
          <div className="text-area">
            <input
              type="text"
              name="noteTitle"
              value={noteTitle}
              placeholder={t("WEB_LABELS.Title")}
              onChange={(e) => setNoteTitle(e.target.value)}
              onKeyDown={onCancel}
            />
            <div className="note-area" data-replicated-value={noteDescription}>
              <textarea
                name="noteDescription"
                value={noteDescription}
                rows="2"
                placeholder={t("WEB_LABELS.Type_a_message")}
                onChange={(e) => setNoteDescription(e.target.value)}
                onKeyDown={onCancel}
              ></textarea>
            </div>
            <div className="bottom-btn">
              <button
                className="gray-btn"
                disabled={
                  _.isEmpty(noteDescription) ||
                  _.isEmpty(noteTitle) ||
                  (noteTitle && noteTitle.trim().length < 1) ||
                  (noteDescription && noteDescription.trim().length < 1)
                }
                onClick={() =>
                  onUpdateNote({
                    ...note,
                    noteTitle,
                    noteDescription,
                    updatedBy: inviteUUID,
                  })
                }
              >
                {t("BUTTONS.Done")}
              </button>
              <button className="delete" onClick={() => onDeleteNote()}>
                <i className="icon-fill-delete"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

class NotesViewModel extends Component {
  constructor() {
    super();
    this.state = {
      showNewNote: false,
      title: "",
      description: "",
      editingNotes: [],
      showDeleteDialog: false,
      tempDeleteNote: null,
    };
  }

  componentDidMount() {
    this.setState({
      notes: this.props.notes,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.notes !== prevProps.notes) {
      this.setState({
        notes: this.props.notes,
      });
    }
  }

  onSaveNote() {
    const { title, description } = this.state;
    this.setState({
      title: "",
      description: "",
      showNewNote: false,
    });
    this.props
      .addNote({
        title,
        description,
        jobOrderId: this.props.jobOrderId,
        createdBy: this.props.inviteUUID,
        updatedBy: this.props.inviteUUID,
      })
      .then((data) => {
        this.props.fetchNote();
      });
  }

  onDeleteNote(note) {
    this.setState({
      showNewNote: false,
      showDeleteDialog: true,
      tempDeleteNote: note,
    });
  }

  onDeleteLocalNote() {
    this.setState({
      showNewNote: false,
      showDeleteDialog: false,
      tempDeleteNote: null,
      title: "",
      description: "",
    });
  }
  onCancelDelete() {
    this.setState({
      showNewNote: false,
      showDeleteDialog: false,
      tempDeleteNote: null,
      title: "",
      description: "",
    });
  }

  onConfirmDelete() {
    let { tempDeleteNote } = this.state;
    this.onCancelDelete();
    if (tempDeleteNote && tempDeleteNote !== null) {
      let { noteId } = tempDeleteNote;
      this.props.deleteNote(noteId).then((data) => {
        this.props.fetchNote();
      });
    }
  }

  onEditNote(index) {
    let { editingNotes } = this.state;
    this.setState({
      editingNotes: [...editingNotes, index],
    });
  }

  onCancelEditNote(index) {
    let { editingNotes } = this.state;
    this.setState({
      editingNotes: [...editingNotes.filter((i) => i !== index)],
    });
  }

  onUpdateNote(note, noteId) {
    let { editingNotes } = this.state;
    this.setState({
      editingNotes: [...editingNotes.filter((i) => i !== noteId)],
    });
    this.props
      .updateNote(note.noteId, {
        title: note.noteTitle,
        description: note.noteDescription,
        jobOrderId: this.props.jobOrderId,
        updatedBy: note.updatedBy,
        createdBy: note.createdBy,
      })
      .then((data) => {
        this.props.fetchNote();
      });
  }

  handleChangeNewNote = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  formatTime = (date) => {
    return getUTCToLocalDateTimeWithFormat(
      date,
      appConstants.DATE_TIME_API_FORMAT,
      appConstants.TIME_SHORT_FORMAT
    );
  };

  render() {
    const {
      showNewNote,
      title,
      description,
      editingNotes,
      showDeleteDialog,
    } = this.state;
    const { notes, t } = this.props;

    return (
      <>
        <div className="comman-modal left-side open">
          <div className="comman-modal-main">
            <div className="side-head">
              {t("BUTTONS.Notes")}
              <div className="side-head-buttons">
                {!showNewNote && (
                  <div className="add-new-note">
                    <Tooltip title={t("TOOLTIP.Add_New_Note")} arrow>
                      <button
                        className="modal-button-effect"
                        onClick={() => {
                          this.setState({ showNewNote: true });
                        }}
                      >
                        <i className="icon-note"></i>
                      </button>
                    </Tooltip>
                  </div>
                )}
                <Tooltip title={t("TOOLTIP.Refresh")} arrow>
                  <button
                    className="refresh modal-button-effect"
                    onClick={() => {
                      this.props.fetchNote();
                    }}
                  >
                    <i className="icon-refresh"></i>
                  </button>
                </Tooltip>
                <button
                  className="close-modal modal-button-effect"
                  onClick={() => this.props.hideNoteModel()}
                >
                  <i className="icon-close-image"></i>
                </button>
              </div>
            </div>
            <div className="comman-modal-body">
              <div className="notes-view scroll-bar-style">
                {showNewNote && (
                  <div className="note-list">
                    <div className="text">
                      <div className="text-area">
                        <input
                          type="text"
                          name="title"
                          value={title}
                          placeholder={t("WEB_LABELS.Title")}
                          onChange={this.handleChangeNewNote}
                        />
                        <div
                          className="note-area"
                          data-replicated-value={description}
                        >
                          <textarea
                            name="description"
                            value={description}
                            rows="2"
                            placeholder={t("WEB_LABELS.New_Note")}
                            onChange={this.handleChangeNewNote}
                          ></textarea>
                        </div>
                        <div className="bottom-btn">
                          <button
                            className="gray-btn"
                            disabled={
                              _.isEmpty(description) ||
                              _.isEmpty(title) ||
                              (title && title.trim().length < 1) ||
                              (description && description.trim().length < 1)
                            }
                            onClick={() => this.onSaveNote()}
                          >
                            {t("BUTTONS.Done")}
                          </button>
                          <button
                            className="delete"
                            onClick={() => this.onDeleteLocalNote()}
                          >
                            <i className="icon-fill-delete"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {notes?.length > 0 ? (
                  notes.map((note, i) => (
                    <>
                      {editingNotes.indexOf(note.noteId) !== -1 ? (
                        <EditNote
                          key={note.noteId}
                          note={note}
                          inviteUUID={this.props.inviteUUID}
                          onUpdateNote={(updated) =>
                            this.onUpdateNote(updated, note.noteId)
                          }
                          onDeleteNote={() =>
                            this.onDeleteNote(note, note.noteId)
                          }
                          onCancelEditNote={() =>
                            this.onCancelEditNote(note.noteId)
                          }
                        />
                      ) : (
                        <div className="note-list" key={i}>
                          <div className="left-image">
                            <i className="icon-note"></i>
                          </div>
                          <div
                            className="text"
                            onClick={() => {
                              if (note.createdBy == this.props.inviteUUID) {
                                this.onEditNote(note.noteId);
                              }
                            }}
                          >
                            <div className="title">{note.noteTitle}</div>
                            {note.noteDescription}
                          </div>
                          <div className="bottom-list">
                            <div className="user">
                              <i className="icon-user-management"></i>
                              {note.createdByName}
                            </div>
                            <div className="bottom-btn">
                              <div className="time">
                                <i className="icon-time"></i>
                                {this.formatTime(note.updatedAt)}
                              </div>
                              <div className="edit-del">
                                {note.createdBy == this.props.inviteUUID && (
                                  <button
                                    className="delete"
                                    onClick={() => this.onDeleteNote(note)}
                                  >
                                    <i className="icon-fill-delete"></i>
                                  </button>
                                )}
                                {note.createdBy == this.props.inviteUUID && (
                                  <button
                                    className="edit"
                                    onClick={() => this.onEditNote(note.noteId)}
                                  >
                                    <i className="icon-edit"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))
                ) : (
                  <div className="no-notes">
                    <p>{t("WEB_LABELS.There_are_no_Notes_available")}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showDeleteDialog && (
          <DeleteModal
            title={t("WEB_LABELS.Are_you_sure_you_want_to_delete_this_note")}
            Cancel={() => this.onCancelDelete()}
            Confirm={() => this.onConfirmDelete()}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addNote: (data) => dispatch(createNote(data)),
  deleteNote: (data) => dispatch(deleteNote(data)),
  updateNote: (noteId, data) => dispatch(updateNote(noteId, data)),
});

const mapStateToProps = ({ noteView, me }) => ({
  notes: noteView.notes || [],
  me: me,
});

const connectedNoteViewModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NotesViewModel));
export { connectedNoteViewModel as NotesViewModel };
