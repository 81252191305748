import React from "react";

export default function TokenExpiredPopup(props) {
  return (
    <>
      <section className="callend-pagenot-found z-index3">
        <div className="box-center">
          <div className="notfound-img">
            <i className="icon-no-page-found"></i>
          </div>
          <div className="content">
            <div className="content-div">
              <p>
                Your session has expired. Please contact the virtual inspector
                to generate a new video call link. You will need to use the new
                link to reupload your inspection data.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
